import { SET_SUBSCRIPTION_MODAL } from "../actions/types";

const initialState = {
    loading: false,
    show: false
};

export default function(state = initialState, action) {
    switch (action.type) {
      case SET_SUBSCRIPTION_MODAL:
          return {
              ...state,
              loading: action.payload.loading,
              show: action.payload.show
          };
      default:
          return state;
    }
}

