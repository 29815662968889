import {
    SPORTS_SUCCESS,
    SPORTS_ERRORS,
    SPORTS_CLEAR
} from "../actions/types";

const initialState = {
    result: false,
    errors: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SPORTS_SUCCESS:
      return {
        ...state,
        result: action.payload,
        errors: false
      };
    case SPORTS_ERRORS:
      return {
        ...state,
        result: false,
        errors: action.payload
      };
    case SPORTS_CLEAR:
      return {
        ...state,
        result: false,
        errors: false
      }
    default:
      return state;
  }
}
