import React, { Component }  from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Navbar from "./pages/navbar";
import Login from "./modals/login";
import Header1 from "./pages/login";
import Header2 from "./pages/loggedInHeader";

import { getActiveProviders } from "../../../actions/provider";
import { notification } from "../../../helpers/landingpage";

class Header extends Component {
    state = {
        showLogin: false
    };

    handleClose() {
        const protectedGames = ['/minigame/cockfighting', '/live-vegas'];
        this.setState({ showLogin: false });
        
        if(protectedGames.includes(window.location.pathname)) {
            this.props.history.push('/');
        }
    }

    handleOpen() {
        this.setState({ showLogin: true });
    }

    processNotification = (data) => {
        const action = data.action;

        switch(action) {
            case 'closeGame':
                this.props.history.push('/');
                break;
            default:
                break;
        }
    };

    componentDidMount() {
        notification(data => {
            this.processNotification(data);
        });
    }

    render() {
        const token = localStorage.getItem("appToken");

        return (
            <header id="header">  
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <Navbar/>
                        { token ? 
                        <Header2/>
                        :
                        <Header1 handleOpen={this.handleOpen.bind(this)}/>
                        }
                    </div>
                </div>
                <Login show={this.state.showLogin} handleClose={this.handleClose.bind(this)} />
            </header>
        );
    }
}

Header.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps,{ getActiveProviders })(withRouter((Header)));
