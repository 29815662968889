import {
    REQUEST_POST_CASINO,
    REQUEST_POST_FAVOURITE,
    GET_CASINO_GAMES,
    GET_CASINO_PROVIDERS,
    GET_CASINO_CATEGORIES,
    GET_CASINO_MINI_CATEGORIES,
    CASINO_GAME_UPDATE,
    REQUEST_POST_CASINO_OPEN,
    GET_CASINO_GAME_LIST,
    CASINO_CLEAR_RESULT,
    CASINO_RESET_ERRORS,
    CASINO_GAME_SUCCESS,
    SET_LIVE_CASINO_SELECTED_PROVIDER
} from "./types";

export const clearErrors = () => {
  return {
    type: CASINO_RESET_ERRORS
  };
};

export const clearResult = () => {
  return {
    type: CASINO_CLEAR_RESULT
  };
};

export const postOpen = payload => {
  return {
    type: REQUEST_POST_CASINO_OPEN,
    payload
  };
};

export const postPlay = payload => {
  return {
    type: REQUEST_POST_CASINO,
    payload
  };
};

export const postFavourite = payload => {
  return {
    type: REQUEST_POST_FAVOURITE,
    payload
  };
};

export const getGames = (param) => {
 
  return {
    type: GET_CASINO_GAMES,
    payload: param
  }
}

export const getGameList = (param) => {
 
  return {
    type: GET_CASINO_GAME_LIST,
    payload: param
  }
}

export const getProviders = (product) => {
  return {
    type: GET_CASINO_PROVIDERS,
    product: product
  }
}

export const getCategories = (param) => {
  return {
    type: GET_CASINO_CATEGORIES,
    payload: param
  }
}

export const updateGames = payload => {
 
  return {
    type: CASINO_GAME_UPDATE,
    payload
  }
}

export const getMiniCategories = (param) => {
  return {
    type: GET_CASINO_MINI_CATEGORIES,
    payload: param
  }
}

export const loadCasinoGames = (param) => {
  return {
    type: CASINO_GAME_SUCCESS,
    payload: param
  }
}

export const setLiveCasinoSelectedProvider = (payload) => {
  return {
    type: SET_LIVE_CASINO_SELECTED_PROVIDER,
    payload
  }
}
