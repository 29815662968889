import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    postPlay,
    postFavourite,
    getGames,
    getProviders,
    getCategories,
    getMiniCategories,
    postOpen,
    getGameList
} from "../api/casino"

import {
    REQUEST_POST_CASINO,
    REQUEST_POST_FAVOURITE,
    CASINO_SUCCESS,
    CASINO_ERRORS,
    FAVOURITE_SUCCESS,
    FAVOURITE_ERRORS,
    GET_CASINO_GAMES,
    GET_CASINO_PROVIDERS,
    GET_CASINO_CATEGORIES,
    GET_CASINO_MINI_CATEGORIES,
    CASINO_GAME_SUCCESS,
    CASINO_GAME_ERRORS,
    CASINO_PROVIDER_SUCCESS,
    CASINO_PROVIDER_ERRORS,
    CASINO_CATEGORY_SUCCESS,
    CASINO_CATEGORY_ERRORS,
    GET_ERRORS,
    REQUEST_POST_CASINO_OPEN,
    GET_CASINO_GAME_LIST,
    CASINO_GAME_LIST_SUCCESS,
    CASINO_GAME_LIST_ERRORS,
    SET_LIVE_CASINO_SELECTED_PROVIDER
} from "../actions/types";

import { auth } from "../selectors/auth.js";
export { casinoWatcher };

const brand = process.env.REACT_APP_BRAND

function* casinoWatcher() {
    yield takeLatest(REQUEST_POST_CASINO, fetchPlay)
    yield takeLatest(REQUEST_POST_FAVOURITE, fetchFavourite)
    yield takeLatest(GET_CASINO_GAMES, fetchGames)
    yield takeLatest(GET_CASINO_GAME_LIST, fetchGameList)
    yield takeLatest(GET_CASINO_PROVIDERS, fetchProviders)
    yield takeLatest(GET_CASINO_CATEGORIES, fetchCategories)
    yield takeLatest(GET_CASINO_MINI_CATEGORIES, fetchMiniCategories)
    yield takeLatest(REQUEST_POST_CASINO_OPEN, fetchOpen)
    yield takeLatest(SET_LIVE_CASINO_SELECTED_PROVIDER, setSelectedProvider)
}

function* fetchGameList(param){
    try{
        const result = yield call(getGameList, { product: param.payload.product, new: param.payload.new, code: param.payload.code, provider: param.payload.provider, popular: param.payload.popular, name: param.payload.name });
            yield put({
                type: CASINO_GAME_LIST_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: GET_ERRORS,
                payload: error.response.data
            });
    }
}

function* fetchGames(param){
    try{
        const result = yield call(getGames, { product: param.payload.product, new: param.payload.new, code: param.payload.code, provider: param.payload.provider, popular: param.payload.popular, name: param.payload.name, transfer: param.payload.transfer });
            yield put({
                type: CASINO_GAME_SUCCESS,
                payload: result.data.data
            });
    }catch(error){ 
        yield put({
            type: GET_ERRORS,
            payload: error.response.data
        });
    }
}

function* fetchProviders(param){
    try{
        const result = yield call(getProviders, { transfer: param.product.transfer });
        yield put({
            type: CASINO_PROVIDER_SUCCESS,
            payload: result.data.data
        });
    }catch(error){ 
        yield put({
            type: GET_ERRORS,
            payload: error.response.data
        });
    }
}

function* fetchCategories(param){
    try{
        const result = yield call(getCategories, { product: param.payload.product, provider: param.payload.provider });
        yield put({
            type: CASINO_CATEGORY_SUCCESS,
            payload: result.data.data
        });
    }catch(error){ 
        yield put({
            type: GET_ERRORS,
            payload: error.response.data
        });
    }
}

function* fetchMiniCategories(param){
    try{
        const result = yield call(getMiniCategories, { product: param.payload.product, provider: param.payload.provider });
            yield put({
                type: CASINO_CATEGORY_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: CASINO_CATEGORY_ERRORS,
                payload: error
            });
    }
}

function* fetchPlay(action) {
    const { wallet } = yield select(auth);
  
    try {
        const result = yield call(postPlay,{
                "playerID": wallet.player.PlayerID,
                "username": wallet.username,
                "hashid": action.payload.hashid,
                "gameType": action.payload.type,
                "gameID": action.payload.gameID,
                "lang": action.payload.language,
                "launchType": action.payload.launchtype,
                "currency": "krw",
                "product": action.payload.product,
                "provider": action.payload.provider,
                "systemName": action.payload.systemName,
                "id": action.payload.id,
                "brandID": brand,
                "domain": window.location.protocol + "//" + window.location.hostname
            });
            yield put({
                type: CASINO_SUCCESS,
                payload: result.data
            });
    }catch(error){
            yield put({
                type: CASINO_ERRORS,
                payload: error
            });
    }
}

function* fetchOpen(action) {
    const { wallet } = yield select(auth);
  
    try {
        const result = yield call(postOpen,{
                "hashid": action.payload.hashid,
                "gameType": action.payload.type,
                "gameID": action.payload.gameID,
                "lang": action.payload.language,
                "launchType": action.payload.launchtype,
                "currency": "krw",
                "product": action.payload.product,
                "provider": action.payload.provider,
                "systemName": action.payload.systemName,
                "id": action.payload.id,
                "brandID": brand,
                "domain": window.location.protocol + "//" + window.location.hostname
            });
            yield put({
                type: CASINO_SUCCESS,
                payload: result.data
            });
    }catch(error){
            yield put({
                type: CASINO_ERRORS,
                payload: error
            });
    }
}


function* fetchFavourite(action) {
    
    try {
        const result = yield call(postFavourite,{
            "product": action.payload.product,
            "provider": action.payload.provider,
            "status": action.payload.status,
            "game": action.payload.game
        });

        yield put({
            type: FAVOURITE_SUCCESS,
            payload: result.data
        });

    }catch(error){
            yield put({
                type: FAVOURITE_ERRORS,
                payload: error
            });
    }
}

function* setSelectedProvider(action) {
    //
}
