import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../assets/img/w365/loader.gif';
import { getCarousel, getCarouselSetting } from '../../../actions/landingpage';

const BannerSlider = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const carousel = useSelector(state => state.landingpage.carousel.listing);
    const carouselSetting = useSelector(state => state.landingpage.carousel.settings);
    const [carouselInnerContainerMinHeightApplied, setCarouselInnerContainerMinHeightApplied] = useState(false);

    useEffect(() => {
        dispatch(getCarousel());
        dispatch(getCarouselSetting());
    }, []);

    const onSlide = () => {
        if(!carouselInnerContainerMinHeightApplied) {
            const carouselInnerContainer = document.querySelector('.carousel-inner');
            const activeCarouselImageHeight = document.querySelector('.carousel-item.active').getElementsByTagName('img')[0].offsetHeight;

            carouselInnerContainer.style.minHeight = `${activeCarouselImageHeight}px`;
            setCarouselInnerContainerMinHeightApplied(true);
        }
    }

    return (
        <div id="carousel-container">
            {(carousel.loading || carouselSetting.loading) || (carousel.error || carouselSetting.error) ?
            <div id="carousel-loader" className="d-flex align-items-center justify-content-center">
                {carousel.loading || carouselSetting.loading ? <img src={Loader} alt="" /> : null}
                {carousel.error || carouselSetting.error ? <p>{t('error 500 toast')}</p> : null}
            </div>
            :
            <Carousel
                interval={carouselSetting.data.AutoSlide > 0 ? carouselSetting.data.TransitionDelay : null}
                className={!carousel.loading && !carouselSetting.loading && !carousel.error && !carouselSetting.error ? '' : 'd-none'}
                onSlide={onSlide}
            >
                {carousel.data.map((item, index) => {
                    let targetURL = item.TargetUrl;
                    let title = '';

                    if(targetURL !== '') {
                        if(targetURL.charAt(0) === '/') {
                            targetURL = window.location.origin + targetURL;
                        }

                        title = targetURL;
                    } else {
                        targetURL = '#';
                    }

                    return (
                        <Carousel.Item key={`landing-page-carousel-${index}`}>
                            <a href={targetURL} title={title} style={{cursor:targetURL === '#' ? 'default' : 'pointer'}}>
                                <img className="d-block w-100" src={`${process.env.REACT_APP_BO_STORAGE + item.ImagePath}`} alt="" />
                                <Carousel.Caption>
                                    <div dangerouslySetInnerHTML={{__html: item.LongDescription}} />
                                </Carousel.Caption>
                            </a>
                        </Carousel.Item>
                    );
                })}
            </Carousel>}
        </div>
    );
}

export default BannerSlider;
