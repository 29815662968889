import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import className from "classnames";
import { useDispatch } from "redux-react-hook";

import {
    toggleModal,
    closeModal
} from "../../actions/modal";

const Modal = () => {
    const { isOpen } = useSelector(state => state.modal);
    const dispatch = useDispatch();
    const onClick = useCallback(() => {
        dispatch(closeModal());
    });

    return (<div className={className("", {
        overlay: isOpen
    })}
    onClick={onClick}
    ></div>)
}

export default Modal;
