import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import HelpLink from './HelpLink';

class Help extends Component {

    state = {
        showSide: false
    };

    render() {
        const { t } = this.props;
        const url = this.props.location.pathname;
        const token = localStorage.getItem("appToken");

        return (
            <div className="sidemenu-afterlogin-section">
                <div
                    className={`sideContactMenus ${this.state.showSide ? "active" : ""}`}
                    onMouseLeave={() => this.setState({ showSide:false })}
                >
                    <div className="row align-items-center justify-content-center">
                        <ul>
                            {/* <li>
                                <a class="livechat" href="javascript:void(Tawk_API.toggle())" >
                                    {t("live chat")}
                                </a>
                                <div class="content row align-items-center justify-content-center">
                                    {t("cs support")}
                                </div>
                            </li> */}
                            {token ?
                            <li>
                                <HelpLink
                                    link="/myaccount/messages"
                                    label={t("contact us")}
                                    innerText={t("send message")}
                                    className="contactus"
                                />
                            </li> : null}
                            <li>
                                <HelpLink
                                    link="/frequently-asked-questions"
                                    label={t("faqs")}
                                    innerText={t("frequently asked questions")}
                                    className="faq"
                                    innerClassName={t("faq")}
                                />
                            </li>
                            <li>
                                <HelpLink
                                    link="#"
                                    label={t("telegram")}
                                    innerText={t("cs-telegram")}
                                    className="telegram"
                                    innerClassName=""
                                    anchorTagOnly
                                />
                            </li>
                            <li>
                                <HelpLink
                                    link={"mailto:"+t("cs support email")}
                                    label={t("cs support email inqury")}
                                    innerText={t("cs support email")}
                                    className="email"
                                    innerClassName="email-content"
                                    anchorTagOnly
                                />
                            </li>
                            {/* <li>
                                <Link to="/banking" className="bank-info">
                                    {t("bank info")}
                                </Link>
                                <div class="content row align-items-center justify-content-center">
                                {t("banking options")}
                                </div>
                            </li> */}
                        </ul>
                        <div
                            class="sideBtn"
                            onMouseEnter={() => this.setState({ showSide:true })}
                        >
                            <a href="#"></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation()(Help));
