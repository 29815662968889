import axios from "axios";
import { ROOT_URL } from "../config/api";

export { getCurrencies, getCountries, getPartner, checkCanRegister, postRegister, postVerify, postVerifyAffiliate, postRegisterAffiliate, fetchSMSSetting };

const brand = process.env.REACT_APP_BRAND;

function getCurrencies() {
  return axios.get(`${ROOT_URL}/brand/${brand}`);
}

function getCountries() {
  return axios.get(`${ROOT_URL}/countries`);
}

function getPartner(domain) {
   return axios.get(`${ROOT_URL}/partner`, {params: domain});
 }

function checkCanRegister(domain) {
   return axios.get(`${ROOT_URL}/canRegister`, {params: domain});
}

function postRegister(register) {
  return axios.post(`${ROOT_URL}/register`, register, { brand_token: process.env.REACT_APP_BRAND_TOKEN});
}

function postVerify(players) {
  return axios.post(`${ROOT_URL}/verify`, players);
}

function postVerifyAffiliate(players) {
  return axios.post(`${ROOT_URL}/verifyAffiliate`, players);
}

function postRegisterAffiliate(register) {
  return axios.post(`${ROOT_URL}/register-affiliate`, register);
}

function fetchSMSSetting() {
  return axios.get(`${ROOT_URL}/registration/sms`);
}
