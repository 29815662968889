import {
  SET_BRAND_CURRENCY,
  SET_COUNTRIES,
  SET_PARTNER,
  SET_CAN_REGISTER,
  REGISTER,
  REGISTER_SET_PAGE,
  VERIFY,
  GET_REGISTER_ERRORS,
  GET_REGISTER2_ERRORS,
  GET_REGISTER_SUCCESS,
  REGISTER_RESET_ERROR,
  REQUEST_POST_VERIFY,
  REQUEST_POST_VERIFY_AFFILIATE,
  SET_SMS_SETTING
} from "../actions/types";

const initialState = {
  currencies: [],
  countries: [],
  partners: [],
  canRegister: [],
  currentPage: 1,
  result: {},
  errors: {},
  loader: false,
  sms: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REQUEST_POST_VERIFY_AFFILIATE:
      return {
        ...state,
        loader: true
      };
    case REQUEST_POST_VERIFY:
      return {
        ...state,
        loader: true
      };
    case SET_BRAND_CURRENCY:
      return {
        ...state,
        currencies: action.payload
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      };
    case SET_PARTNER:
       return {
         ...state,
         partners: action.payload
       };
    case SET_CAN_REGISTER:
       return {
         ...state,
         canRegister: action.payload
       };
    case REGISTER:
      return {
        ...state,
        result: action.payload,
        errors: false
      };
    case REGISTER_SET_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        loader: false
      };
    case VERIFY:
      return {
        ...state,
        result: action.payload
      };
    case GET_REGISTER_SUCCESS:
      return {
        ...state,
        result: action.payload,
        errors: false
      };
    case GET_REGISTER_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case GET_REGISTER2_ERRORS:
      return {
        ...state,
        errors: action.payload,
        loader: false
      };
    case REGISTER_RESET_ERROR:
      return {
        ...state,
        errors: false
      };
    case SET_SMS_SETTING:
      return {
        ...state,
        sms: action.payload
      }
    default:
      return state;
  }
}
