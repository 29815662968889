import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook"; 
import classname from "classnames"; 
import Modal from "react-bootstrap/Modal"; 
import NumberFormat from 'react-number-format'; 
import ReactHtmlParser from "react-html-parser";
import { displayAmount, realAmount, totalAmount } from "../../../../../helpers"; 
import { postTransfer, successModal,postTransferWallet,postTransferAll } from "../../../../../actions/payment";
import { useTranslation } from 'react-i18next'; 
import { Loader } from "../../../../common";
import classnames from "classnames";
import { getActiveProviders, resetActiveCount } from '../../../../../actions/provider';
import { getPlayer, clearWallet } from "../../../../../actions/player";
 
const All = ({show, handleClose, total, success}) => { 
 
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false); 
    const [amount, setAmount] = useState(total); 

    const onClick = useCallback(() => { 
    let amount = total.toString().replace(/,/g,"");
    amount = realAmount(Math.trunc(amount));
        setAmount(total);
        if (amount > 0) {
            setLoader(true);
            dispatch(postTransferAll({ amount: amount }));
            dispatch(resetActiveCount());
            dispatch(clearWallet());
            dispatch(getPlayer());

            window.setTimeout(() => {
                dispatch(getActiveProviders());
            }, 3000);
        }
    }); 

    useEffect(() => {
        if(success === true) {
            handleClose();
            setLoader(false);
        }
    }, [success]);

    const onClose = useCallback(() => { 
       handleClose();
    });

    return ( 
        <> 
        <div 
            className={classname("modal fade show",{ 
            })} 
            style={{ 
                display: show ? "block" : "none" 
            }} 
            id="InsufficientModal" tabIndex={-1} role="dialog" aria-labelledby="InsufficientModal" aria-hidden="true"> 
            <Modal 
                centered 
                show={show}> 
                <Modal.Header> 
                    <h5 className="modal-title" id="LoginModal">{t('transfer confirmation').toUpperCase()}</h5> 
                    <button 
                        type="button" 
                        className="close" 
                        onClick={ () => onClose()} 
                    > 
                        <span aria-hidden="true">&times;</span> 
                    </button> 
                </Modal.Header> 
                <Modal.Body className="modal-body text-center"> 
                    <h5 class="pt-3">{t('transfer all to main wallet')}?</h5>
                                    <div class="mb-3">
                <p class="mb-2">{ReactHtmlParser(t('you are about to transfer currency amount to your main wallet', { currency: 'KRW', amount: loader ? total : amount}))}</p>
                
                
                <div class="col-md-6 offset-md-3 text-center">
                  <div class="row">
                  <div class="col-md-6">
                   <a aria-label="Close" data-dismiss="modal" class="btn transCancel" onClick = {()=>onClose()} >{t('cancel')}</a>
                 </div>
                 <div class="col-md-6">
                   <a href="#" class="btn btn-success" data-dismiss="modal" id="quickIngameTransfer" onClick = {()=>onClick()}>
                    <span className={classnames("",{
                        "d-none": loader
                        })}>
                        {t("transfer all")}
                    </span>
                    <Loader hide={!loader} style={{ width: "16px" }}/>
                   </a>
                 </div>
               </div>
             </div>
              </div>
                </Modal.Body> 
            </Modal> 
        </div> 
            
        </>
    );
};

export default All;



