import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation();
        return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <h2 className="form-title page-title2">{t('contact us')}</h2>
                    <div className="box" style={{marginBottom:'113px'}}>
                        <h2 className="page-title2 color-green mt-0">{t('contact us service philosophy')}</h2>
                        <p style={{marginBottom:'26px'}}>{t('contact us description 1')}</p>
                        <div className="row" style={{marginLeft: '85px', marginTop: '50px'}}>
                            <div className="col-lg-3">
                                <div className="row mb-5">
                                    <div className="d-flex">
                                        <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/24-7-cs.svg" alt="" /></div>
                                        <div><p className="m-0"><span className="opacity60">24/7 online</span><br /><span className="font-bold">{t('hotline number')}</span></p></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="d-flex">
                                        <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/cs-email.svg" alt="" /></div>
                                        <div><p className="m-0"><span className="opacity60">Customer Service E-mail Address</span><br /><span className="font-bold">{t('cs support email')}</span></p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="row">
                                    <div className="d-flex">
                                        <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/telegram.svg" alt="" /></div>
                                        <div><p className="m-0"><span className="opacity60">{t('telegram')}</span><br /><span className="font-bold">{t('cs-telegram')}</span></p></div>
                                    </div>
                                    <div className="row">
                                        <div className="d-flex tg-qr-icon">
                                            <img src="/assets/img/w365/qr_footer2.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-3">
                                <div className="d-flex">
                                    <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/cs-email.svg" alt="" /></div>
                                    <div><p className="m-0"><span className="opacity60">Customer Service E-mail Address</span><br /><span className="font-bold">{t('cs support email')}</span></p></div>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-3">
                                <div className="d-flex">
                                    <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/affiliate-email.svg" alt="" /></div>
                                    <div><p className="m-0"><span className="opacity60">Affiliate E-mail Address</span><br /><span className="font-bold">{t('cs support affiliate email')}</span></p></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
