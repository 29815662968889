import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { getPlayer, getWallet, clearWallet } from "../../../actions/player";
import { getRebateBalance } from '../../../actions/rebate';
import { getActiveProviders, resetActiveCount } from '../../../actions/provider';
import { totalAmount, realAmount, displayAmount, thousandAmount } from "../../../helpers";
import { Loader } from "../../common";
import {
    postTransferAll, mainWalletSpin
} from '../../../actions/payment';
import { resetBonus } from '../../../actions/bonus';
import{ resetTransfer } from '../../../actions/payment';
import { selectProvider1 , selectProvider2 } from "../../../actions/modal";

import refreshIcon from "../../../assets/img/logo/wallet/refresh-icon.svg";
import mainWallet from "../../../assets/img/logo/wallet/main-wallet.png";
import allGamesWallet from "../../../assets/img/logo/wallet/all-games.png";
import rebateWallet from "../../../assets/img/logo/wallet/rebate-wallet.png";


class Walletz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainWallet: 0,
            rebate: 0,
            wallet: {},
            spin: false,
            spin2: false,
            spin3: false,
        };
    }

    componentDidMount() {
        this.props.getRebateBalance()
        this.props.getActiveProviders()
    } 

    componentWillReceiveProps(nextProps){
        let rebate = 0;
        if (nextProps.rebate.success) {
            rebate = nextProps.rebate.balance
        }
        this.setState({
            mainWallet: nextProps.mainwallet,
            rebate: rebate,
            wallet: nextProps.wallet,
            spin: nextProps.spin
        })
    }

    refreshWalletProviders = () => {
        // this.setState({ spin: true });
        this.props.mainWalletSpin(true)
        this.props.getPlayer();
        this.props.resetActiveCount();
        this.props.clearWallet()
        this.props.getActiveProviders()
        this.props.getRebateBalance();
        this.setState({ spin3: true });
        window.setTimeout(() => {
            // this.setState({ spin: false });
            this.setState({ spin3: false });
            this.props.mainWalletSpin(false)
        }, 5000);
    }

    refreshMainWallet = (e) => {
        e.preventDefault();
        this.setState({ spin: true });
        this.props.getPlayer();
        this.refreshWalletProviders();
        // and after timeout
        window.setTimeout(() => {
            this.setState({ spin: false });
        }, 5000);
    }

    refreshRebateWallet = (e) => {
        e.preventDefault();
        this.setState({ spin3: true });
        this.props.getRebateBalance();
        this.refreshWalletProviders();
        // and after timeout
        window.setTimeout(() => {
            this.setState({ spin3: false });
        }, 5000);
    }

    refreshAllGameWallet = (e) => {
        e.preventDefault();
        this.props.resetActiveCount();
        this.props.clearWallet()
        this.props.getActiveProviders()
    }

    checkAllBalance(){
        const { wallet } = this.state;
        delete wallet[0]; //special case for transfer > main wallet
        delete wallet[undefined]; //special case for transfer > main wallet
        return (this.objectLen(this.props.providers) <= this.objectLen(wallet));
    }

    objectLen(obj){
        return Object.keys(obj).length;
    }

    selectWallet =  () => {
        this.props.selectWallet();
    }

    transferAll = () => {
        this.props.selectAll();
    }

    render() {
        const { t } = this.props;
        const { spin, spin2, spin3 } = this.state;
        const deposit = window.location.pathname.includes("deposit");
        const transfer = window.location.pathname.includes("transfer");
        const withdrawal = window.location.pathname.includes("withdrawal");

        return (
                 <div className="row mb-4">
                    
                    <div className="col-md-6 ">
                        <div class="wallet-container-box">
                            <div class="wrapper">
                                <div class="row align-items-start">
                                    <div class="col-auto pr-0">
                                        <div class="icon">
                                            <img src={mainWallet} className="img-fluid mainIconWallet" />
                                        </div>
                                    </div>
                                    <div class="col pl-2">
                                    <p className={classnames("money",{"d-none": spin})}>₩ {displayAmount(this.state.mainWallet)}</p>
                                    <p className={classnames("money",{"d-none": !spin})}><Loader hide={!spin} style={{ width: "20px" }}/></p>
                                    <p class="wallet-text">{t('main wallet')}</p>
                                    {
                                        withdrawal ? <p class="note">{t("if you want to withdraw all balance you need to transfer all to mainwallet" )}</p> : ''
                                    }
                                    </div>
                                </div>
                                <a href="#" onClick = {this.refreshWalletProviders.bind(this)} className="refresh"></a>
                            </div>
                        </div>
                    </div>

                    

                    <div className="col-md-6 ">
                        <div class="wallet-container-box">
                            <div class="wrapper">
                                <div class="row align-items-start">
                                    <div class="col-auto pr-0">
                                        <div class="icon">
                                        <img src={rebateWallet} className="img-fluid mainIconWallet" />
                                        </div>
                                    </div>
                                    <div class="col pl-2">
                                    <p className={classnames("money",{"d-none": spin3})}>₩ {displayAmount(this.state.rebate)}</p>
                                    <p className={classnames("money",{"d-none": !spin3})}><Loader hide={!spin3} style={{ width: "20px" }}/></p>
                                    <p class="wallet-text">{t('rebate wallet')}</p>
                                    </div>
                                </div>
                                <a href="#" onClick = {this.refreshWalletProviders.bind(this)} className="refresh"></a>
                            </div>
                        </div>
                    </div>

                </div>
        );
    }
}



const mapStateToProps = (state) => ({
    mainwallet: state.auth.wallet.wallet,
    currency: state.auth.wallet.player,
    providers: state.providers.providers,
    wallet: state.player.wallet,
    bonus: state.auth.wallet.bonus,
    products: state.products.result,
    rebate: state.rebate.rebate,
    spin: state.payment.spin,
});

export default connect(
    mapStateToProps,
    {  getPlayer, getWallet, getRebateBalance, getActiveProviders, resetBonus, resetTransfer, selectProvider1, selectProvider2, resetActiveCount, clearWallet, postTransferAll,mainWalletSpin }
)(withRouter(withTranslation() (Walletz)));
