import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";
import classname from 'classnames';


const Wincoin = ({ show, handleClose }) => {
    const { t } = useTranslation();
    return (
        <div
            style={{display: show ? "block" : "none"}}
            id="wincoinModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="wincoinModal"
            aria-hidden="true"
        >
            <Modal
                centered
                show={show}
                //onHide={handleClose}
                className={classname("modal-confirm", {
                })}
            >
                <Modal.Header>
                    <h5 className="modal-title-deposit modal-header-deposit" id="LoginModal">
                        {t('deposit request sent')}
                    </h5>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="p-1 col-12">
                        <div id='containerForm'>
                            <div className='row' style={{ marginTop: '0px' }}>
                                <div className="m-2 col-12 wincoin-text">{ReactHtmlParser(t('wincoin_payment_msg'))}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#1D2C31' }}>
                        <a href={t('payment_wincoin_url')} target="_Blank" onClick={handleClose} className={classname("btn btn-success btn-loader")}>
                            {t('purchase')}
                        </a>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Wincoin;
