import {
    SET_BONUS_FILTER,
    BONUS_HISTORY_SUCCESS,
    BONUS_HISTORY_ERRORS,
    SET_BONUS_HISTORY_PAGE,
    RESET_BONUS_FILTER,
} from "../actions/types";

const initialState = {
    bonuses: {
        data: [],
        meta: {},
        links: {}
    },
    selectedFilter: {
        type: 0,
        date: 0
    },
    searchFields: {
        date: [
            { name: "Today", value: 0 },
            { name: "Yesterday", value: 1 },
            { name: "Last 3 days", value: 3 },
            { name: "Last 7 days", value: 7 },
            { name: "Last 30 days", value: 30 }
        ],
        type: [
            { name: "All", value: 0 },
            { name: "Deposit", value: 1 },
            { name: "Coupon", value: 2 }
        ]
    }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_BONUS_FILTER:
        return {
            ...state,
            selectedFilter: {
                provider: 0,
                type: 0,
                date: 0
            },
            page: 0,
            success: {
                message: false
            }
        };
      case SET_BONUS_FILTER:
          return {
              ...state,
              selectedFilter: action.payload,
              page: null,
              success: {
                  message: false
              }
          };
      case BONUS_HISTORY_SUCCESS:
          return {
              ...state,
              bonuses: action.payload,
              errors: {
                  subscription: { BonusAmount: '' }
              }
          };

      case BONUS_HISTORY_ERRORS:
          return {
              ...state,
              errors: action.payload
          };
      case SET_BONUS_HISTORY_PAGE:
          return {
              ...state,
              page: action.payload
          };
      default:
          return state;
  }
}
