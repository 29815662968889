import {
  MINI_SUCCESS,
  MINI_ERRORS,
  MINI_GAME_SUCCESS,
  MINI_GAME_ERRORS,
  UPDATE_MINI_GAMES,
  MINI_RECENT_ERRORS,
  MINI_RECENT_SUCCESS
} from "../actions/types";

const initialState = {
games: [],
providers: [],
categories: [{}],
result: [],
errors: [],
recent: []
};

export default function(state = initialState, action) {
switch (action.type) {
  case MINI_SUCCESS:
    return {
      ...state,
      result: action.payload,
      errors: false
    };
  case MINI_GAME_ERRORS:
  case MINI_ERRORS:
    return {
      ...state,
      result: false,
      errors: action.payload
    };
  case MINI_RECENT_SUCCESS:
    return {
      ...state,
      recent: action.payload,
      errors: false
    };
  case MINI_RECENT_ERRORS:
    return {
      ...state,
      errors: action.payload
    };
  case MINI_GAME_SUCCESS:
    return {
      ...state,
      games: action.payload,
      errors: false
    };
  case UPDATE_MINI_GAMES:
    const key = action.payload.key;
          const statez = action.payload.state;
          if(statez === "recent") {
              return  {
                  ...state,
                  recent: state.recent.map((item) => {
                      if(item.ID !== key) {
                          return item
                      }
                      return {
                          ...item,
                          FavStatus: item.FavStatus === 1 ? 0 : 1
                      }
                  }),
                  games: state.games.map((item) => {
                      if(item.ID !== key) {
                          return item
                      }
                      return {
                          ...item,
                          FavStatus: item.FavStatus === 1 ? 0 : 1
                      }
                  })
              }
          } else {
              return  {
                  ...state,
                  games: state.games.map((item) => {
                      if(item.ID !== key) {
                          return item
                      }
                      return {
                          ...item,
                          FavStatus: item.FavStatus === 1 ? 0 : 1
                      }
                  })
              }
          }
  default:
    return state;
}
}