import { SET_BANKS } from "../actions/types"; 
 
const initialState = { 
  banks: [], 
}; 
 
export default function(state = initialState, action) { 
     
    switch (action.type) { 
      case SET_BANKS: 
          return { 
              ...state, 
              banks: action.payload, 
          }; 
      default: 
          return state; 
    } 
} 
 