import React, { Component } from "react";
import Account from "./account";
import Bank from "./bank";
import Password from "./password";
import { withTranslation } from "react-i18next";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'account-details'
        }
    }

    changeTab(e, tabName) {
        e.preventDefault();
        this.setState({tab: tabName});
    }

    render() {
        const { t } = this.props;
        
        return (
            <div id="my-account-profile">
                <h2 className="form-title">{t('my account details')}</h2>
                <ul>
                    <li><a href="/#" className={this.state.tab === 'account-details' ? 'active' : ''} onClick={e => this.changeTab(e, 'account-details')}>{t('account details')}</a></li>
                    <li><a href="/#" className={this.state.tab === 'bank-details' ? 'active' : ''} onClick={e => this.changeTab(e, 'bank-details')}>{t('bank details')}</a></li>
                    <li><a href="/#" className={this.state.tab === 'change-password' ? 'active' : ''} onClick={e => this.changeTab(e, 'change-password')}>{t('change password')}</a></li>
                </ul>
                {this.state.tab === 'account-details' ? <Account /> : null}
                {this.state.tab === 'bank-details' ? <Bank /> : null}
                {this.state.tab === 'change-password' ? <Password /> : null}
            </div>
        );
    }
}

export default withTranslation()(Profile);
