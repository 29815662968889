import { takeLatest, put, call, select } from 'redux-saga/effects';
import { realAmount } from '../helpers';

import {
    checkBonus,
    getBonus,
    getCash,
    postBonus,
    postCancelSubscription
} from "../api/bonus"

import {
    BONUS_CHECK,
    SET_BONUS_PAGE,
    GET_BONUS,
    BONUS_SUCCESS,
    BONUS_ERRORS,
    RESET_BONUS_ERRORS,
    BONUS_REDEEM_SUCCESS,
    BONUS_REDEEM_ERRORS,
    BONUS_SET_ONGOING,
    REQUEST_POST_BONUS_REDEEM,
    REQUEST_POST_SUBSCRIPTION_CANCEL,
    SUBSCRIPTION_CANCEL_SUCCESS,
    SUBSCRIPTION_CANCEL_ERRORS,
    UPDATE_MAIN_WALLET
} from "../actions/types";

import { bonuses } from "../selectors/bonus";
import { auth } from "../selectors/auth";

const brand = process.env.REACT_APP_BRAND

function* bonusWatcher() {
    yield takeLatest(SET_BONUS_PAGE, fetchBonus);
    yield takeLatest(GET_BONUS, fetchBonus);
    yield takeLatest(BONUS_CHECK, fetchCheckBonus);
    yield takeLatest(REQUEST_POST_BONUS_REDEEM, fetchRedeemBonus);
    yield takeLatest(REQUEST_POST_SUBSCRIPTION_CANCEL, fetchCancelSubscription);
    yield takeLatest(BONUS_REDEEM_SUCCESS, fetchBonus);
}

function* fetchBonus(action) {
    const provider = action.provider ? action.provider.provider : "";
    const type = action.provider ? action.provider.type : "";
    const { selectedFilter, page } = yield select(bonuses);
    try {
        const result = yield call(getBonus, {
            ...page,
            ...selectedFilter,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            provider: provider,
            type: type,
            per_page: 5
        });
        yield put({
            type: BONUS_SUCCESS,
            payload: result.data
        });
    }catch(err){
        yield put({
            type: BONUS_ERRORS,
            payload: err.response
        });
    }
}

function* fetchCheckBonus(action) {
    try {
        yield call(checkBonus, action.payload);
        //reset to initial state
        yield put({
            type: RESET_BONUS_ERRORS
        });
        yield put({
            type: BONUS_SET_ONGOING,
            payload: {}
        });
    }catch(err){
        yield put({
            type: BONUS_ERRORS,
            payload: err.response.data
        });
        yield put({
            type: BONUS_SET_ONGOING,
            payload: err.response.data.subscription
        });
    }
}

function* fetchCancelSubscription(action) {
    try {
        const result = yield call(postCancelSubscription, { ...action.payload, brand });
        //reset to initial state
        yield put({
            type: SUBSCRIPTION_CANCEL_SUCCESS,
            payload: result.data
        });
    }catch(err){
        yield put({
            type: SUBSCRIPTION_CANCEL_ERRORS,
            payload: err.response.data
        });
    }
}

function* fetchRedeemBonus(action) {
    try {
        const result = yield call(postBonus, { ...action.payload, brand });
        const { wallet: { wallet }} = yield select(auth);
        const main_wallet = realAmount(wallet) + realAmount(action.payload.amount);
            yield put({
                type: BONUS_REDEEM_SUCCESS,
                payload: result.data,
            });
        //     yield put({
        //     type: UPDATE_MAIN_WALLET,
        //     payload: main_wallet
        // });
    }catch(err){
        yield put({
            type: BONUS_REDEEM_ERRORS,
            payload: err.response.data
        });
    }
}


export {
    bonusWatcher
};
