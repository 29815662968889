import React, { useState, useEffect, useCallback } from "react"; 
import { useSelector } from "react-redux";
import classname from "classnames";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../../common";
import { displayAmount } from "../../../../../helpers";
import { resetModal } from "../../../../../actions/payment";
import classnames from "classnames";
import { useDispatch } from "redux-react-hook"; 

const Success = ({ show, handleClose, amount  }) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false); 

    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div
                className={classname("modal fade show", {})}
                style={{
                    display: show ? "block" : "none"
                }}
                id="SuccessModal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="DepositModal"
                aria-modal="true"
            >
                <Modal centered show={show} onHide={() => {
                    handleClose()
                    dispatch(resetModal())
                }}>
                    <Modal.Header>
                        <h5 className="modal-title" id="LoginModal">
                            {t("transfer complete")}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            onClick={() => {
                                handleClose()
                                dispatch(resetModal())
                            }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body text-center">
                        <div className="p-3">
                            <h5 >
                                 {t("transfer successful")}
                            </h5>
                            <p className="mb-2">
                                <b>KRW {displayAmount(amount)}</b> {t('has been transferred to your')} <b>{t('main wallet')}</b>
                            </p>
                            <span
                                onClick={() => {
                                    handleClose()
                                    dispatch(resetModal())
                                }}
                                style={{ cursor: "pointer" }}
                                className="btn btn-success"
                            >
                                <span className={classnames("")}>
                                    {t("ok")}
                                </span>
                            </span>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
};

export default Success;
