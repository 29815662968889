import { takeLatest, put, call, delay, select } from "redux-saga/effects";
import { postUser, getUser, getUnreadMessageCount,getUnreadNoticeCount } from "../api/authentication";
import setAuthToken from "../setAuthToken";

import {
    SET_CURRENT_USER,
    RESET_CURRENT_USER,
    SET_PLAYER,
    GET_ERRORS,
    GET_USER,
    DELETE_USER,
    RESET_ACTIVE_COUNT,
    WALLET_CLEAR,
    REGISTER_SET_PAGE,
    PROCESS_MAIN_WALLET_MESSAGE,
    UPDATE_MAIN_WALLET,
    UPDATE_BADGE_COUNT,
    GET_UNREAD_MESSAGE,
    SET_UNREAD_MESSAGE,
    GET_UNREAD_NOTICE,
    SET_UNREAD_NOTICE,
    UPDATE_UNREAD_MESSAGE,
    UPDATE_UNREAD_MESSAGE_FALSE,
    KICK_USER,
} from "../actions/types";

import { realFloatAmount, realAmount } from "../helpers"
import { auth } from "../selectors/auth";
export { authenticationWatcher };

const getItems = state => state.auth;

function* authenticationWatcher() {
    yield takeLatest(GET_USER, fetchUser);
    yield takeLatest(DELETE_USER, removeUser);
    yield takeLatest(DELETE_USER, removeUserSession);
    yield takeLatest(KICK_USER, kickUser);
    yield takeLatest(PROCESS_MAIN_WALLET_MESSAGE, mainWalletUpdateMessage);
    yield takeLatest(UPDATE_BADGE_COUNT, updateBadgeCount);
    yield takeLatest(GET_UNREAD_MESSAGE, getUnreadMessage);
    yield takeLatest(GET_UNREAD_NOTICE, getUnreadNotice);
    yield takeLatest(UPDATE_UNREAD_MESSAGE, updateUnreadMessage);
}

function* fetchUser(action) {
    try {
        const result = yield call(postUser, action.payload.user);
        const { token } = result.data;

        localStorage.setItem("appToken", token);

        setAuthToken(token);

        yield put({
            type: SET_CURRENT_USER,
            payload: result.data.token
        });
        if(window.location.pathname === "/promotions") {
            window.location.reload();
        } else {
            window.location = "/";
        }
    } catch (error) {
        yield put({
            type: GET_ERRORS,
            payload: error.response.data
        });
    }
}

function* removeUserSession() {
    try {
    yield call(getUser);
    } catch (error) {
    }
}

function* removeUser(action) {
    try {
        
        // yield call(getUser);

        yield put({
            type: RESET_CURRENT_USER,
            payload: {}
        });
        yield put({
            type: RESET_ACTIVE_COUNT,
            payload: {}
        });
        yield put({
            type: WALLET_CLEAR,
            payload: {}
        });
        yield put({
            type: REGISTER_SET_PAGE,
            payload: 1
        });
    
        var i18 = localStorage.getItem('i18nextLng');
        var appVersion = localStorage.getItem('appVersion');
        localStorage.clear();
        localStorage.setItem('i18nextLng', i18);
        localStorage.setItem('appVersion', appVersion);
    
        setAuthToken(false);

        if(window.location.pathname === '/') {
            window.location.reload();
        } else {
            window.location = "/";
        }

        // window.location.href = '/?rand=' + Math.random().toString(36).slice(2);
    } catch (error) {

    } finally {
        // caches.keys().then((names) => {
        //   names.forEach((name) => {
        //     caches.delete(name);
        //   });
        // });
    }
}

function* kickUser(action) {
    try {

        yield put({
            type: RESET_CURRENT_USER,
            payload: {}
        });
        yield put({
            type: RESET_ACTIVE_COUNT,
            payload: {}
        });
        yield put({
            type: WALLET_CLEAR,
            payload: {}
        });
        yield put({
            type: REGISTER_SET_PAGE,
            payload: 1
        });
    
        var i18 = localStorage.getItem('i18nextLng');
        var appVersion = localStorage.getItem('appVersion');
        localStorage.clear();
        localStorage.setItem('i18nextLng', i18);
        localStorage.setItem('appVersion', appVersion);
    
        setAuthToken(false);

        caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
        });
    } catch (error) {

    } finally {
        window.location.href = '/?rand=' + Math.random().toString(36).slice(2);
    }
}

function* mainWalletUpdateMessage(action){
    try {

        yield put({
            type: UPDATE_MAIN_WALLET,
            payload: realAmount(action.payload.balance)
        });
    } catch(error) {

    }
}

function* updateBadgeCount(action){
    try {
        const { wallet,unreadMessage, unreadNotice } = yield select(auth);

        if (action.payload.type === 'notice') {
            // wallet.unread_notice_count = wallet.unread_notice_count+1;

            if (action.payload.action === 'add') {
                unreadNotice.unread_notice_count = unreadNotice.unread_notice_count+1;
            }

            if (action.payload.action === 'delete' && action.payload.status === 'unread' ) {
                unreadNotice.unread_notice_count = unreadNotice.unread_notice_count-1;
            }

        } else {
            // console.log(action)
            if (action.payload.action === 'add') {
                if (!unreadMessage.unread_message_ids.includes(action.payload.parentID)){
                    unreadMessage.unread_message_replies_count = unreadMessage.unread_message_replies_count+1;
                    unreadMessage.unread_message_ids.push(action.payload.parentID);
                }

                // if (action.payload.action === 'delete' && action.payload.status === 'unread' ) {
                //     wallet.unread_message_replies_count = wallet.unread_message_replies_count-1;
                // }

                // console.log(123456)
            }
        }
        yield put({
            type: SET_UNREAD_NOTICE,
            payload: unreadNotice
        });

        yield put({
            type: SET_UNREAD_MESSAGE,
            payload: unreadMessage
        });
    } catch(error) {

    }
}

function* getUnreadMessage(action){
    try {
        const result = yield call(getUnreadMessageCount, action.payload);
        yield put({
            type: SET_UNREAD_MESSAGE,
            payload: result.data
        });

    } catch(error) {

    }
}

function* getUnreadNotice(action){
    try {
        const result = yield call(getUnreadNoticeCount, action.payload);
        yield put({
            type: SET_UNREAD_NOTICE,
            payload: result.data
        });

    } catch(error) {

    }
}

function* updateUnreadMessage(action){
    try {
        
        yield put({
            type: UPDATE_UNREAD_MESSAGE_FALSE,
            payload: false
        });
    } catch(error) {

    }
}

