import React, { Component } from "react";
import { connect } from "react-redux";
import { getCurrencies } from "../../../actions/registration";
import { withTranslation, useTranslation } from "react-i18next";
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

class Banking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depositIsShown: true,
            withdrawaltIsShown: true
        };
    }

    transactionSelection(e) {
        if (e.target.value === "1") {
            this.setState({ depositIsShown: true, withdrawaltIsShown: true });
        } else if (e.target.value === "2") {
            this.setState({ depositIsShown: true, withdrawaltIsShown: false });
        } else {
            this.setState({ depositIsShown: false, withdrawaltIsShown: true });
        }
    }

    componentDidMount(){
        this.props.getCurrencies();
        var elem = document.getElementById("main-wrap");
        elem.classList.add("banking-info");
    }

    componentWillUnmount = () => {
        var elem = document.getElementById("main-wrap");
        elem.classList.remove("banking-info");
    };

    render() {
        const { t } = this.props;
        const { currencies } = this.props;
        const token = localStorage.getItem("appToken");
        const depositIsShown = this.state.depositIsShown;
        const withdrawaltIsShown = this.state.withdrawaltIsShown;

        const bankingHeader = ["transaction type", "bank check time"];

        return (
            <Tabs>
                <div className="container default-top-space">
                <div className="banking-page tab-content tab-pane pr-5 pl-5 mr-4 ml-4">
                <p class="banking-form-title page-title2 mb-2">{t("my banking options")}</p>
                <TabList className="nav nav-tabs mb-3">
                    <Tab className="nav-item" key={0}>
                        <a className="nav-link">{t(String(bankingHeader[0]).toUpperCase())}</a>
                    </Tab>
                    <Tab className="nav-item" key={1}>
                        <a className="nav-link">{t(String(bankingHeader[1]).toUpperCase())}</a>
                    </Tab>
                </TabList>
                <TabPanel key={0}>
                    
                        <div className="row align-items-center justify-content-center">
                            <div className="col">
                                <p class="banking-text mt-4 mb-0">{t("please select your preferred currency and transaction type from the lists")}</p>
                            </div>
                            {/* <div className="col-auto">
                                <a href="#" class={`cs-support row align-items-center justify-content-start ${token ? "" : "hidden"}`}>
                                    <div class="text-area">
                                        <h3>{t("need help")}</h3>
                                        <p>{t("our cs support is available 24 hours")}</p>
                                    </div>
                                </a>
                            </div> */}
                        </div>
                        <div className="row mt-4">
                            <div className="col banking-currency-flex">
                                <div className="form-group row align-items-center">
                                    <div class="col-auto banking-currency-col">
                                        <div class="row">
                                            <label class="banking-label" for="">{t("preferred currency")}:</label>
                                        </div>
                                        <div class="row">
                                            <select className="form-control custom-select dark banking-dropdown">
                                                {currencies.map(currency => (
                                                    <option
                                                        key={currency.CurrencyID}
                                                        value={currency.CurrencyID}
                                                        selected={String(currency.Abbreviation).toLowerCase() === "krw" ? true : false}
                                                    >
                                                        {currency.Abbreviation + " - " + t(String(currency.Name).toLowerCase())}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col offset-sm-1 ml-0">
                                <div class="form-group row align-items-center">
                                    <div class="col-auto banking-currency-col">
                                        <div class="row">
                                            <label class="banking-label" for="">{t("transaction type")}:</label>
                                        </div>
                                        <div class="row">
                                            <select
                                                className="form-control custom-select dark banking-dropdown"
                                                onChange={(e) => this.transactionSelection(e)}
                                            >
                                                <option value="1" selected="">
                                                    {t("all")}
                                                </option>
                                                <option value="2">{t("deposit")}</option>
                                                <option value="3">{t("withdrawal")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        {depositIsShown ? <Deposit /> : ""}
                        {withdrawaltIsShown ? <Withdrawal /> : ""}
                    
                </TabPanel>
                <TabPanel key={1}>
                    <div className="container default-top-space">
                        <div className="row align-items-center justify-content-center mt-0">
                            <div class="contentbox bank-checktime-box mt-0">
                                <div class="row">
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('kook min bank')}</p>
                                        <ul>
                                            <li>{t('daily  2350~0005')}</li>
                                            <li>{t('regular  0000~0700 (monthly on 3rd sunday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('shinhan bank')}</p>
                                        <ul>
                                            <li>{t('daily  0000~0010')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4">
                                        <p class="bank-checktime-label">{t('woori bank')}</p>
                                        <ul>
                                            <li>{t('daily(week)  0000~0010')}</li>
                                            <li>{t('friday  0000~0040')}</li>
                                            <li>{t('regular  0200~0600 (monthly on 2nd sunday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('nh bank')}</p>
                                        <ul>
                                            <li>{t('daily  0000~0030')}</li>
                                            <li>{t('regular  0000~0400 (monthly on 3rd monday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('sc jeil bank')}</p>
                                        <ul>
                                            <li>{t('daily  2359~0005')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4">
                                        <p class="bank-checktime-label">{t('hana bank')}</p>
                                        <ul>
                                            <li>{t('daily  0000~0010')}</li>
                                            <li>{t('regular  0000~0400 (monthly on 3rd monday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('ibk')}</p>
                                        <ul>
                                            <li>{t('daily  0000~0030')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('citi bank')}</p>
                                        <ul>
                                            <li>{t('daily  2340~0005')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4">
                                        <p class="bank-checktime-label">{t('epost bank')}</p>
                                        <ul>
                                        <li>{t('daily  0400~0500')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('busan bank')}</p>
                                        <ul>
                                        <li>{t('regular  0000~0300 (monthly on 1st monday, 3rd monday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4 banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('daegu bank')}</p>
                                        <ul>
                                        <li>{t('regular  0200~0600 (monthly on 3rd sunday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col mb-4">
                                        <p class="bank-checktime-label">{t('kyungnam bank')}</p>
                                        <ul>
                                        <li>{t('daily  0000 ~ 0020')}</li>
                                            <li>{t('regular  2350 ~ 0600 (monthly on 2nd saturday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('jeju bank')}</p>
                                        <ul>
                                        <li>{t('daily  0000 ~ 0010')}</li>
                                            <li>{t('regular  0430 ~ 0500 (on thursday/sunday)')}</li>
                                        </ul>
                                    </div>
                                    <div class="col banking-check-time-col-width">
                                        <p class="bank-checktime-label">{t('jeonbuk bank')}</p>
                                        <ul>
                                        <li>{t('daily  2330 ~ 0100')}</li>
                                        </ul>
                                    </div>
                                    <div class="col">
                                        <p class="bank-checktime-label">{t('kwangju bank')}</p>
                                        <ul>
                                        <li>{t('daily  2330 ~ 0100')}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel> 
                {/* <div className="container default-top-space">
                    <div className="row align-items-center justify-content-center">
                        <div className="col">
                            <p class="form-title page-title2">{t("my banking options")}</p>
                            <p class="banking-text">{t("please select your preferred currency and transaction type from the lists")}</p>
                        </div>
                        <div className="col-auto">
                            <a href="#" class={`cs-support row align-items-center justify-content-start ${token ? "" : "hidden"}`}>
                                <div class="text-area">
                                    <h3>{t("need help")}</h3>
                                    <p>{t("our cs support is available 24 hours")}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col">
                            <div className="form-group row align-items-center justify-content-center">
                                <div class="col-auto">
                                    <label class="banking-label" for="">{t("preferred currency")}:</label>
                                </div>
                                <div class="col">
                                    <select className="form-control custom-select dark banking-dropdown">
                                        {currencies.map(currency => (
                                            <option
                                                key={currency.CurrencyID}
                                                value={currency.CurrencyID}
                                                selected={String(currency.Abbreviation).toLowerCase() === "krw" ? true : false}
                                            >
                                                {currency.Abbreviation + " - " + t(String(currency.Name).toLowerCase())}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col offset-sm-1">
                            <div class="form-group row align-items-center justify-content-center">
                                <div class="col-auto">
                                    <label class="banking-label" for="">{t("transaction type")}:</label>
                                </div>
                                <div class="col">
                                    <select
                                        className="form-control custom-select dark banking-dropdown"
                                        onChange={(e) => this.transactionSelection(e)}
                                    >
                                        <option value="1" selected="">
                                            {t("all")}
                                        </option>
                                        <option value="2">{t("deposit")}</option>
                                        <option value="3">{t("withdrawal")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    {depositIsShown ? <Deposit /> : ""}
                    {withdrawaltIsShown ? <Withdrawal /> : ""} */}
                    </div>
                </div>
            </Tabs>
        );
    }
}

const Deposit = () => {
    const { t } = useTranslation();
    return (
        <div class="contentbox box">
            <p class="banking-title pb-3">{t("deposit")}</p>
            <table class="table table-striped table-dark rounded">
                <thead>
                    <tr>
                        <th class="banking-col-title" scope="col" rowspan="2">
                            {t("banking methods")}
                        </th>
                        <th class="banking-col-title banking-mode-table-entry-width"  scope="col" rowspan="2">
                            {t("mode")}
                        </th>
                        <th class ="banking-col-title py-3" scope="col" colspan="2">
                            {t("limit per transaction")}
                        </th>
                        <th class="banking-col-title" scope="col" rowspan="2">{t("fee")}</th>
                        <th class="banking-col-title" scope="col" rowspan="2">{t("processing time")}</th>
                    </tr>
                    <tr>
                        <th class="banking-col-title banking-max-table-entry-width py-3" scope="col">{t("minimum")}</th>
                        <th class="banking-col-title banking-max-table-entry-width py-3" scope="col">{t("maximum")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="td-hide-border">
                            {/* <img src="/assets/img/bank.svg"/> */}
                            <p>{t("local bank transfer")}</p>
                        </td>
                        <td class="td-hide-border">
                            <ul>
                                <li>{t("atm")}</li>
                                <li>{t("online transfer")}</li>
                                <li>{t("cash deposit machine")}</li>
                                <li>{t("over bank counter")}</li>
                            </ul>
                        </td>
                        <td class="td-hide-border">10,000</td>
                        {/* <td>{t("unlimited")}</td> */}
                        <td class="td-hide-border">9,000,000  </td>
                        <td class="td-hide-border">0%</td>
                        <td class="td-hide-border banking-table-entry-width">{t("15 minutes")}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const Withdrawal = () => {
    const { t } = useTranslation();
    return (
        <div class="contentbox box">
            <p class="banking-title pb-3">{t("withdrawal")}</p>
            <table class="table table-striped table-dark">
            <thead>
                <tr>
                    <th class="banking-col-title" scope="col" rowspan="2">
                        {t("banking methods")}
                    </th>
                    <th class="banking-col-title banking-mode-table-entry-width"  scope="col" rowspan="2">
                        {t("mode")}
                    </th>
                    <th class ="banking-col-title py-3" scope="col" colspan="2">
                        {t("limit per transaction")}
                    </th>
                    <th class="banking-col-title" scope="col" rowspan="2">{t("fee")}</th>
                    <th class="banking-col-title" scope="col" rowspan="2">{t("processing time")}</th>
                </tr>
                <tr>
                    <th class="banking-col-title banking-max-table-entry-width py-3" scope="col">{t("minimum")}</th>
                    <th class="banking-col-title banking-max-table-entry-width py-3" scope="col">{t("maximum")}</th>
                </tr>
            </thead>
                <tbody>
                <tr>
                        <td class="td-hide-border">
                            {/* <img src="/assets/img/bank.svg"/> */}
                            <p>{t("local bank transfer")}</p>
                        </td>
                        <td class="td-hide-border">
                            <ul>
                                <li>{t("atm")}</li>
                                <li>{t("online transfer")}</li>
                                <li>{t("cash deposit machine")}</li>
                                <li>{t("over bank counter")}</li>
                            </ul>
                        </td>
                        <td class="td-hide-border">10,000</td>
                        {/* <td>{t("unlimited")}</td> */}
                        <td class="td-hide-border">100,000,000 </td>
                        <td class="td-hide-border">0%</td>
                        <td class="td-hide-border banking-table-entry-width">{t("within 30 minutes")}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = state => ({
    currencies: state.register.currencies,
    error: state.register.errors,
    success: state.register.result,
});

export default connect(
    mapStateToProps,
    { getCurrencies }
)(withTranslation()(Banking));
