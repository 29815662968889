import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { OPEN_BETCONSTRUCT } from '../actions/types';
import { openingBetConstructSuccess, openingBetConstructError } from '../actions/betconstruct';
import { findToken } from '../config/api';
import { postSports, postPlay } from "../api/sports";

function* openBetConstruct() {
    try {
        let params = {};
        let result;
        const providerID = 32;
        const integrationID = 32;
        const productID = 1;
        const language = localStorage.getItem('i18nextLng') === null ? 'ko' : localStorage.getItem('i18nextLng').slice(0, 2);

        if (findToken()) {
            const playerState = yield select(state => state.auth.wallet);

            params = {
                brandID: 5,
                gameID: 0,
                isMobile: 0,
                gameType: 'sports',
                launchType: 'html5',
                hashid: '',
                lang: language,
                currency: playerState.player.currency.Abbreviation,
                mode: 1,
                launchType: 'html5',
                playerID: playerState.player.PlayerID,
                username: playerState.username,
                productID: productID,
                providerID: providerID,
                integrationID: integrationID
            };

            result = yield call(postPlay, params);
        } else {
            params = {
                IsLive: 0,
                gameID: 0,
                isMobile: 0,
                mode: 0,
                lang: language,
                gameType: 'sports',
                integrationID: integrationID,
                providerID: providerID,
                productID: productID,
                domain: window.location.hostname === 'localhost' ? 'frontend-test.15gaming.com' : window.location.hostname
            };

            result = yield call(postSports, params);
        }

        yield put(openingBetConstructSuccess(result.data.game_url));
    } catch(err) {
        yield put(openingBetConstructError(err.response.data.errors));
    }
}

function* betConstructWatcher() {
    yield all([takeLatest(OPEN_BETCONSTRUCT, openBetConstruct)]);
}

export { betConstructWatcher };
