import React, { Component } from "react"; 
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
 
class Footer extends Component { 
    showLoginModal(e) {
        if(localStorage.getItem('appToken') === null) {
            document.getElementById('login-btn').click();
            e.preventDefault();
        }
    }
    
    render() { 
        const { t } = this.props;
        return ( 
            <div className="footer-section" id="footer">
                <footer className="footer-container"> 
                    <div className="container">
                       <div className="row indent mb-5"> 
                            <div className="col-auto">
                                <h3>{t('quick links')}</h3>
                                <ul>
                                    <li><Link to="/sports-betting-game-rules">{t("betting rules")}</Link></li>
                                    <li><Link to="/privacy-policy">{t("privacy policy")}</Link></li>
                                    <li><Link to="/responsible-gaming">{t("responsible gaming")}</Link></li>
                                    <li><Link to="/terms-and-conditions">{t("terms and conditions")}</Link></li>
                                </ul>
                            </div>
                            <div className="col-auto">
                                <h3>{t('products')}</h3>
                                <div className="row">
                                    <div className="col-auto">
                                        <ul>
                                            <li><Link to="/bti-sports">{t("bti sports")}</Link></li>
                                            <li><Link to="/bt1-sports">{t("bt1")}</Link></li>
                                            {/* <li><Link to="/live-sports">{t("betradar sports")}</Link></li> */}
                                            {/* <li><Link to="/betconstruct">{t("betconstruct")}</Link></li> */}
                                            {/* <li><Link to="/e-sports">{t("e-sports")}</Link></li> */}
                                            <li><Link to="/live-casino">{t("live casino")}</Link></li>
                                            <li><Link to="/slots">{t("slots")}</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-auto">
                                        <ul>
                                            {/* <li><Link to="/minigame/cockfighting" onClick={this.showLoginModal.bind(this)}>{t("minigame")}</Link></li> */}
                                            {/* <li><Link to="/live-vegas" onClick={this.showLoginModal.bind(this)}>{t("poker")}</Link></li> */}
                                            {/* <li><Link to="/powerball">{t("powerball")}</Link></li> */}
                                            <li><Link to="/promotions" onClick={this.showLoginModal.bind(this)}>{t("promotions")}</Link></li>
                                            {/* <li><Link to="/vip">{t("vip club")}</Link></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <h3>{t('help')}</h3>
                                <ul>
                                    <li><Link to="/banking">{t("deposit and withdrawal")}</Link></li>
                                    <li><Link to="/frequently-asked-questions">{t("faqs")}</Link></li>
                                    <li><Link to="/contact-us">{t("contact us")}</Link></li>
                                </ul>
                            </div>
                            <div className="col-auto">
                                <h3>{t('support')}</h3>
                                <div className="row">
                                    <div className="col-auto">
                                        <img className="ImgFluid py-2 pr-2" src="/assets/img/w365/email-icon.svg"/>  {t("cs-email")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <img className="ImgFluid py-2 pr-2" src="/assets/img/w365/telegram-icon.svg"/>  {t("cs-telegram")}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto qr_footer">
                                        <img src="/assets/img/w365/qr_footer2.png"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <h3 className="pl-3">{t('license')}</h3>
                                <div className="row">
                                    <div className="col">
                                        {/* <img className="ImgFluid pr-3 pl-0" src="/assets/img/w365/provider-logo/responsible-gaming-logo/pagcor.png"/> */}
                                        {/* <img className="ImgFluid px-3" src="/assets/img/w365/provider-logo/responsible-gaming-logo/gamble-aware.png"/> */}
                                        <img className="ImgFluid pl-3 pr-0" src="/assets/img/w365/provider-logo/responsible-gaming-logo/mga-logo.png"/>
                                        <img className="ImgFluid pl-3 pr-0" src="/assets/img/w365/provider-logo/responsible-gaming-logo/gaming-curacao.png"/>
                                    </div>
                                </div>
                            </div>
                       </div>
                       <div className="row mb-5">
                            <div className="col-12">
                                <div className="provider-section">
                                    <h3>{t('providers')}</h3>
                                    <div className="row d-flex align-items-center icon-indent">
                                        <div className="col-auto p-0">
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/bt1.png"/>
                                        </div>
                                        {/* <div className="col-auto pl-0">
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/betradar.png"/>
                                        </div> */}
                                        {/* <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/betconstruct.png" style={{width: '140px'}}/>
                                        </div> */}
                                        <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/WGB.png" style={{width: '80px'}}/>
                                        </div>
                                        <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/bota.png" style={{width: '80px'}}/>
                                        </div>
                                        <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/evolution.png"/>
                                        </div>
                                        <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/pragmatic-play.png"/>
                                        </div>
                                        <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/asia-gaming.png"/>
                                        </div>
                                        <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/microgaming.png"/>
                                        </div>
                                        <div className="col-auto" style={{padding: '0 12px'}}>
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/netent.png"/>
                                        </div>
                                        <div className="col-auto pr-0">
                                            <img className="ImgFluid" src="/assets/img/w365/provider-logo/red-tiger.png"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                       {/* <div className="row mb-3">
                            <div style={{maxWidth: '1400px', margin: '0 auto'}}>
                                <div className="col">
                                    <p className="text-center">{t('footer text')}</p>
                                </div>
                            </div>
                       </div> */}
                    </div>
                </footer> 
            </div>
        ); 
    } 
} 
 
export default withTranslation()(Footer); 
