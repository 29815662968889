export const ROOT_URL = process.env.REACT_APP_LOCAL;
export const GAME_URL = process.env.REACT_APP_GAME;
export const SPORT_URL = process.env.REACT_APP_SPORT;
export const OWSPORT_URL = process.env.REACT_APP_OWSPORT;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET;
export const BRAND_TOKEN = process.env.REACT_APP_BRAND_TOKEN;

export function findToken() {
  return localStorage.getItem("appToken");
}




