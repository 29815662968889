import { OPEN_GAME, OPENING_GAME_SUCCESS, OPENING_GAME_ERROR, RESET_GAME } from './types';
  
export const openGame = (isLogin) => ({
    type: OPEN_GAME,
    isLogin
});
  
export const openingGameSuccess = (url) => ({
    type: OPENING_GAME_SUCCESS,
    url
});
  
export const openingGameError = response => ({
    type: OPENING_GAME_ERROR,
    sucess: false,
    errors: response,
    loading: false
});

export const resetGame = () => ({
    type: RESET_GAME
});
