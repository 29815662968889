import {
    TOGGLE_MODAL,
    CLOSE_MODAL,
    SET_PROVIDER,
    SET_PROVIDER1,
    SET_PROVIDER2,
    TOGGLE_MODAL_TO,
    TOGGLE_MODAL_FROM
} from "./types";

export const toggleModal = () => {
  noScroll();
  return {
    type: TOGGLE_MODAL
  };
};

export const toggleModalTo = () => {
  noScroll();
  return {
    type: TOGGLE_MODAL_TO
  };
};

export const toggleModalFrom = () => {
  noScroll();
  return {
    type: TOGGLE_MODAL_FROM
  };
};

export const closeModal = () => {
  noScroll();
  return {
    type: CLOSE_MODAL
  };
};

export const selectProvider = payload => {
  return {
    type: SET_PROVIDER,
    payload
  };
};

export const selectProvider1 = payload => {
  return {
    type: SET_PROVIDER1,
    payload
  };
};

export const selectProvider2 = payload => {
  return {
    type: SET_PROVIDER2,
    payload
  };
};

const noScroll = () => {
    if(!document.body.style.overflow){
      if(!window.location.pathname === "/transfer" || !window.location.pathname === "/rebate") {
        document.body.style.overflow = "hidden"
      }
    }else{
        document.body.style.overflow = ""
    }
}
