import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { getBanners, getPromotions, getContent, getProducts, getProviders,
    getProviderPromotions, getPromotionMinDeposit, getDepositPromotion, checkDepositPromotion, getPromotionContentCategory };

function getBanners() {
  return axios.get(`${ROOT_URL}/banners?category=Promotion`);
}

function getPromotions(params) {
  let lang = localStorage.getItem("i18nextLng") || 'en-US';
  lang = lang.split('-');
  params['lang'] = lang[0];
  return axios.get(`${ROOT_URL}/promotions`, { params });
}

function getProviderPromotions(params) {
    let lang = localStorage.getItem("i18nextLng") || 'en-US';
    lang = lang.split('-');
    params['lang'] = lang[0];
    return axios.get(`${ROOT_URL}/providerPromotions`, {
        headers: {
            Authorization: `Bearer ${findToken()}`
        },
        params
    });
}

function getContent(id) {
  let lang = localStorage.getItem("i18nextLng") || 'en-US';
  lang = lang.split('-');
  return axios.get(`${ROOT_URL}/promotions/${id}?lang=${lang[0]}`);
}

function getProducts(){
  return axios.get(`${ROOT_URL}/products`);
}

function getProviders(params){
  const auth= findToken();
  let Authorization = null;
  if(auth){
      Authorization = `Bearer ${findToken()}`
  }

  return axios.get(`${ROOT_URL}/providers`, {
      headers: {
          Authorization
      }, params
  });
}

function getPromotionMinDeposit(params) {
   return axios.get(`${ROOT_URL}/promotionMinDeposit`, {
      headers: {
          Authorization: `Bearer ${findToken()}`
      }, params
   });
 }

 function getDepositPromotion(params) {
  return axios.get(`${ROOT_URL}/depositPromotion`, {
     headers: {
         Authorization: `Bearer ${findToken()}`,
         'brand-token': process.env.REACT_APP_BRAND_TOKEN
     }, params
  }); 
}

function checkDepositPromotion(params) {
  return axios.get(`${ROOT_URL}/depositPromotionCheck`, {
     headers: {
         Authorization: `Bearer ${findToken()}`,
         'brand-token': process.env.REACT_APP_BRAND_TOKEN
     }
  });
}

function getPromotionContentCategory(params) {
  return axios.get(`${ROOT_URL}/promotionsCategory`, {
     headers: {
         'brand-token': process.env.REACT_APP_BRAND_TOKEN
     }, params
  }); 
}
