import {
    TRANSFER_SUCCESS,
    TRANSFER_ERRORS,
    DEPOSIT_SUCCESS,
    DEPOSIT_ERRORS,
    DEPOSIT_CHECK,
    RESEND_SUCCESS,
    RESEND_ERRORS,
    SET_BANK_ACCOUNT,
    SET_PROVIDER,
    SET_PROVIDER1,
    SET_PROVIDER2,
    RESET_DEPOSIT_SUCCESS,
    RESET_WITHDRAWAL_SUCCESS,
    WITHDRAWAL_SUCCESS,
    WITHDRAWAL_ERRORS,
    WITHDRAWAL_CHECK,
    TRANSFER_SUCCESS_WITHOUT_SUCCESS,
    TRANSFER_ERRORS_WITHOUT_SUCCESS,
    TRANSFER_MODAL_SUCCESS,
    TRANSFER_MODAL_RESET,
    MAIN_WALLET_SPIN,
    RESET_RESEND_DEPOSIT,
    RESET_RESEND_ERROR,
    SET_PLAYER_DEPOSIT_TRON_BALANCE,
    SET_PLAYER_DEPOSIT_TRON_PURCHASE,
    SET_PLAYER_DEPOSIT_TRON_STATUS,
    SET_PLAYER_DEPOSIT_OPTION,
    SET_PLAYER_DEPOSIT_OPTION_STATUS,
    INITIAL_PLAYER_DEPOSIT_OPTION,
    SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS,
    INITIAL_PLAYER_DEPOSIT_TRON_BALANCE,
    RESET_DEPOSIT_ERROR,
    RESET_WITHDRAWAL_ERROR,
    SET_DEPOSIT_COUNT
} from "../actions/types";

import all from "../assets/img/logo/wallet/main-wallet.png";

const initialState = {
    depositResult: {},
    resend: {},
    resendError: {},
    depositErrors: {},
    transferErrors: false,
    withdrawResult: {},
    withdrawErrors: {},
    //transaction: {"account":{"BankAccountID":19,"PlayerID":25,"BankID":8,"Branch":"","AccountName":"daveee","AccountNo":"1234512345","CountryID":0,"StateID":0,"CityID":0,"ColorTaggingID":0,"CreatedBy":1,"DateCreated":"2019-03-18 16:35:02","UpdatedBy":1,"DateUpdated":"2019-03-18 16:35:02","Status":"1","IsPrimary":"1","bank_details":{"BankID":8,"Name":"Bank of Beijing","Description":"Bank of Beijing","CreatedBy":1,"DateCreated":"2018-12-03 04:55:04","UpdatedBy":1,"DateUpdated":"2018-12-12 05:30:46","Status":1}}},
    transaction: {},
    pendingWithdrawal: false,
    provider: { ID: 0, Name: "Main Wallet", Logo: all },
    provider1: { ID: 1, Name: "Main Wallet", Logo: all },
    provider2: { ID: 2, Name: "Main Wallet", Logo: all },
    success: false,
    modal: 0,
    providerTo: { ID: 0, Name: "Main Wallet", Logo: all },
    providerFrom: { ID: 0, Name: "Main Wallet", Logo: all },
    spin: false,
    tron: {
        balance: 0,
        url: null,
        id: null,
        password: null,
        status: INITIAL_PLAYER_DEPOSIT_TRON_BALANCE
    },
    paymentType: {
        IDS: [],
        status: INITIAL_PLAYER_DEPOSIT_OPTION
    },
    depositCount: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                pendingWithdrawal: false
            }
        case RESET_DEPOSIT_SUCCESS:
            return {
                ...state,
                depositResult: {},
                // depositErrors: {},
            }

        case DEPOSIT_SUCCESS:
            return {
                ...state,
                depositResult: action.payload,
                depositErrors: {}
            };
        case RESET_DEPOSIT_ERROR:
            return {
                ...state,
                depositResult: {},
                depositErrors: {}
            };

        case RESEND_SUCCESS:
            return {
                ...state,
                resend: action.payload
            };
        case RESET_RESEND_DEPOSIT:
            return {
                ...state,
                resend: false
            };

        case RESEND_ERRORS:
            return {
                ...state,
                resendError: action.payload
            };

        case RESET_RESEND_ERROR:
            return {
                ...state,
                resendError: false
            };

        case DEPOSIT_ERRORS:
            return {
                ...state,
                depositResult: {},
                depositErrors: action.payload
            };

        case DEPOSIT_CHECK:
            return {
                ...state,
                transaction: action.payload
            };

        case TRANSFER_SUCCESS:
            return {
                ...state,
                transferResult: action.payload,
                transferErrors: false
            };

        case TRANSFER_ERRORS:
            return {
                ...state,
                transferResult: false,
                transferErrors: action.payload
            };

        case TRANSFER_SUCCESS_WITHOUT_SUCCESS:
            return {
                ...state,
                transferResult: action.payload,
                transferErrors: false
            };

        case TRANSFER_ERRORS_WITHOUT_SUCCESS:
            return {
                ...state,
                transferResult: false,
                transferErrors: action.payload
            };

        case WITHDRAWAL_SUCCESS:
            return {
                ...state,
                withdrawResult: action.payload,
                withdrawErrors: false
            };
        case WITHDRAWAL_ERRORS:
            return {
                ...state,
                withdrawResult: false,
                withdrawErrors: action.payload
            };
        case RESET_WITHDRAWAL_ERROR:
            return {
                ...state,
                withdrawResult: false,
                withdrawErrors: {}
            };
        case WITHDRAWAL_CHECK:
            return {
                ...state,
                pendingWithdrawal: (action.payload.transaction > 0)
            };
        case SET_BANK_ACCOUNT:
            return {
                ...state,
                transaction: action.payload.data.bankAccount
            };
        case SET_PROVIDER:
            return {
                ...state,
                provider: action.payload,
                depositResult: false
            }
        case SET_PROVIDER1:
            return {
                ...state,
                provider1: action.payload,
                depositResult: false
            }
        case SET_PROVIDER2:
            return {
                ...state,
                provider2: action.payload,
                depositResult: false
            }
        case SET_PLAYER_DEPOSIT_TRON_BALANCE:
            return {
                ...state,
                tron: {
                    ...state.tron,
                    balance: action.payload
                }
            }
        case SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS:
            return {
                ...state,
                tron: {
                    ...state.tron,
                    status: action.payload
                }
            }
        case SET_PLAYER_DEPOSIT_TRON_PURCHASE:
            return {
                ...state,
                tron: {
                    ...state.tron,
                    url: action.payload.action_url,
                    id: action.payload.mb_id,
                    password: action.payload.mb_password
                }
            }
        case SET_PLAYER_DEPOSIT_OPTION:
            return {
                ...state,
                paymentType: {
                    IDS: action.payload
                }
            }
        case SET_PLAYER_DEPOSIT_OPTION_STATUS:
            return {
                ...state,
                paymentType: {
                    ...state.paymentType,
                    status: action.payload
                }
            }
        case TRANSFER_MODAL_RESET:
            return {
                ...state,
                success: false,
                modal: 0
            }
        case TRANSFER_MODAL_SUCCESS:
            return {
                ...state,
                success: true,
                modal: state.modal + 1
            }
        case MAIN_WALLET_SPIN:
            return {
                ...state,
                spin: action.payload,
            }
        case SET_DEPOSIT_COUNT:
            return {
                ...state,
                depositCount: action.payload
            }
        default:
            return state;
    }
}
