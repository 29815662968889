import { takeLatest, put, call, select } from "redux-saga/effects";

import { postPlay, postSports } from "../api/sports";

import {
    REQUEST_POST_SPORTS,
    REQUEST_POST_SPORTS_PLAY,
    SPORTS_SUCCESS,
    SPORTS_ERRORS,
    GET_ERRORS
} from "../actions/types";

import { auth } from "../selectors/auth.js";

export { sportsWatcher };

function* sportsWatcher() {
    yield takeLatest(REQUEST_POST_SPORTS, fetchSports);
    yield takeLatest(REQUEST_POST_SPORTS_PLAY, fetchPlay);
}

function* fetchSports(action) {

    try {
        const result = yield call(postSports, {
            providerID: action.payload.providerID,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            mode: 0,
            gameType: "sports",
            gameID: "0",
            domain: window.location.hostname,
            IsLive: action.payload.IsLive ? action.payload.IsLive : 0,
            isMobile: 0,
            integrationID: action.payload.integrationID,
            ...action.payload
        });
        yield put({
            type: SPORTS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        yield put({
            type: SPORTS_ERRORS,
            payload: error.response.data.errors
        });
    }
}

function* fetchPlay(action) {
   
    const { wallet } = yield select(auth);
    try {
        const result = yield call(postPlay, {
            providerID: action.payload.providerID ,
            playerID: wallet.player.PlayerID,
            username: wallet.username,
            gameType: "sports",
            gameID: "0",
            lang: window.localStorage.i18nextLng.slice(0, 2),
            launchType: "html5",
            currency: "krw",
            mode: 1,
            domain: window.location.protocol + "//" + window.location.hostname,
            productID: action.payload.productID ? action.payload.productID : 1,
            IsLive: action.payload.IsLive ? action.payload.IsLive : 0,
            isMobile: 0,
            integrationID: action.payload.integrationID,
            ...action.payload
        });
        yield put({
            type: SPORTS_SUCCESS,
            payload: result.data
        });
    } catch (error) {
        yield put({
            type: SPORTS_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });

        // yield put({
        //     type: SPORTS_ERRORS,
        //     payload: error.data
        // });
    }
}
