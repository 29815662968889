import React from 'react';
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

const Center = ({content, show, closePopup}) => {
    const { t } = useTranslation();
    return (
        <div className='col-4' style={{display: show ? 'block': 'none'}}>
            <div className="modal-content popup-notice-modal-content">
                <div className="modal-header">
                    {/* <p className="modal-title">{content[0].HeaderTitle}</p> */}
                    <p className="modal-title">&nbsp;</p>
                    {
                        content[0].RequireClose === 0 ? <></> : 
                        <button type="button" className="close popup-notice-close-btn" onClick={() => closePopup()} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    }
                </div>
                {
                    content[0].Format === 1 ?
                    <div className="modal-body p-0">
                        <div className="img-content">
                            <img src={process.env.REACT_APP_S3+"/"+content[0].ImageDesktopPath}/>
                            <div className="btn-container">
                            { content[0].RequireConfirm === 0 ? <></> : <button className="btn btn-success btn-confirm" onClick={() => closePopup()}>{t('confirm')}</button> }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="modal-body" style={{backgroundColor:'#1D2C31'}}>
                        <div className="text-content">
                            <div className='popup-text-secondary'>
                                { ReactHtmlParser(content[0].Content)}
                            </div>
                            <div className="btn-container">
                            { content[0].RequireConfirm === 0 ? <></> : <button className="btn btn-success btn-confirm" onClick={() => closePopup()}>{t('confirm')}</button> }
                            </div>
                        </div>
                    </div>
                } 
            </div>
        </div>
    )
}

export default Center;