import {
    GET_SLOTS_GAMES,
    UPDATE_SLOTS_GAMES,
    GET_SLOTS_RECENT,
    GET_SLOTS_CATEGORIES,
    UPDATE_SLOTS_RECENT,
    SLOTS_GAME_SUCCESS
} from "./types";

export const getGames = (param) => {
    return {
        GET_SLOTS_GAMES,
        type: GET_SLOTS_GAMES,
        payload: param
    }
}

export const getRecent = (param) => {
    return {
        GET_SLOTS_RECENT,
        type: GET_SLOTS_RECENT,
        payload: param
    }
}


export const updateGames = payload => {
    return {
        type: UPDATE_SLOTS_GAMES,
        payload
    }   
}


export const getCategories = (param) => {
    return {
      type: GET_SLOTS_CATEGORIES,
      payload: param
    }
}

export const updateRecent = payload => {
    return {
        type: UPDATE_SLOTS_RECENT,
        payload
    }   
}

export const loadSlots = payload => {
    return {
        type: SLOTS_GAME_SUCCESS,
        payload
    }   
}
