import axios from "axios"; 
import { ROOT_URL, findToken } from "../config/api"; 
 
function postTransferWallet(payment){ 
  return axios 
    .post(`${ROOT_URL}/transfer`, payment, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
} 
 
function postTransferWalletWithoutSuccess(payment){ 
  return axios 
    .post(`${ROOT_URL}/transfer`, payment, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
} 
 
function postTransfer(payment){ 
  return axios 
    .post(`${ROOT_URL}/transfer`, payment, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
} 
 
function postTransferWithoutSuccess(payment){ 
  return axios 
    .post(`${ROOT_URL}/transfer`, payment, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
} 
 
function postTransferAll(params){ 
  return axios 
    .post(`${ROOT_URL}/transferAll`, params, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
} 
 
function postDeposit(payment){ 
  let path = '/deposit';
  return axios 
    .post(`${ROOT_URL}${path}`, payment, {
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
} 
 
function postWithdrawal(payment){ 
  return axios 
    .post(`${ROOT_URL}/withdrawal`, payment, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
} 
 
function getBankAccount(type){ 
  return axios 
    .get(`${ROOT_URL}/bankAccount`, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      },  params: type
    }) 
} 
 
function checkDeposit(){ 
  return axios 
    .get(`${ROOT_URL}/checkPendingTransaction`, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      }, params: { transaction_type: 1000 } 
    }) 
} 
 
function checkWithdrawal(){ 
  return axios 
    .get(`${ROOT_URL}/checkPendingTransaction`, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      }, params: { transaction_type: 2000 } 
    }) 
} 
 
function postResend(payment){ 
  return axios 
    .post(`${ROOT_URL}/resend`, payment, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      } 
    }) 
}

function getTronBalance(){
  return axios
    .get(`${ROOT_URL}/paymentMerchantBalance`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

function getTronPurchase(){
  return axios
    .get(`${ROOT_URL}/paymentMerchantPurchase`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

function getTronStatus(){
  return axios
    .get(`${ROOT_URL}/paymentMerchant`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

function getDepositOptions(params){
  return axios
    .get(`${ROOT_URL}/depositOptions`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      },
      params
    })
}

function getChungDamMinMax(){
  return axios
    .get(`${ROOT_URL}/chungDamInfo`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

function getDepositCount(){
  return axios
    .get(`${ROOT_URL}/checkDepositCount`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

export {
  postTransfer,
  postTransferAll,
  postTransferWallet,
  postDeposit,
  postWithdrawal,
  getBankAccount,
  checkDeposit,
  checkWithdrawal,
  postTransferWithoutSuccess,
  postTransferWalletWithoutSuccess,
  postResend,
  getTronBalance,
  getTronPurchase,
  getTronStatus,
  getDepositOptions,
  getChungDamMinMax,
  getDepositCount
}