import { select, takeLatest, takeLeading, put, call } from 'redux-saga/effects';
import { realFloatAmount, realAmount } from '../helpers';
import {
    checkWithdrawal,
    postDeposit,
    postTransfer,
    postTransferAll,
    postTransferWallet,
    postWithdrawal,
    getBankAccount,
    postResend,
    getTronBalance,
    getTronPurchase,
    getTronStatus,
    getDepositOptions,
    getDepositCount
} from "../api/payment";

import {
    getDepositAccount,
  getWallet
} from "../api/player";

import {
    getProviders,
    getProviderPromotions
} from "../api/promotion";

import {
    BANKS_ERRORS,
    DEPOSIT_CHECK,
    DEPOSIT_ERRORS,
    DEPOSIT_SUCCESS,
    TRANSFER_ERRORS,
    TRANSFER_SUCCESS,
    WITHDRAWAL_CHECK,
    WITHDRAWAL_ERRORS,
    WITHDRAWAL_SUCCESS,

    RESEND_ERRORS,
    RESEND_SUCCESS,
    SET_BANK_ACCOUNT,
    GET_PROVIDERS,
    SET_PROVIDERS,
    PROVIDERS_ERRORS,
    SET_PROMOTIONS,
    LOAD_PROMOTIONS,
    PROMOTION_ERRORS,
    SET_PROVIDER,
    SUBSCRIPTION_CANCEL_SUCCESS,
    REQUEST_POST_DEPOSIT,
    REQUEST_POST_RESEND,
    REQUEST_POST_TRANSFER,
    REQUEST_POST_TRANSFER_ALL,
    REQUEST_POST_TRANSFER_WALLET,
    REQUEST_POST_WITHDRAWAL,
    WALLET_GET,
    WALLET_SUCCESS,
    WALLET_CLEAR,
    GET_BANK_ACCOUNT,
    REQUEST_POST_TRANSFER_WITHOUT_SUCCESS,
    TRANSFER_SUCCESS_WITHOUT_SUCCESS,
    TRANSFER_ERRORS_WITHOUT_SUCCESS,
    REQUEST_POST_TRANSFER_WALLET_WITHOUT_SUCCESS,
    UPDATE_MAIN_WALLET,
    SET_PLAYER_DEPOSIT_TRON_BALANCE,
    ERROR_PLAYER_DEPOSIT_TRON_BALANCE,
    GET_PLAYER_DEPOSIT_TRON_BALANCE,
    GET_PLAYER_DEPOSIT_TRON_PURCHASE,
    SET_PLAYER_DEPOSIT_TRON_PURCHASE,
    ERROR_PLAYER_DEPOSIT_TRON_PURCHASE,
    GET_PLAYER_DEPOSIT_TRON_STATUS,
    SET_PLAYER_DEPOSIT_TRON_STATUS,
    ERROR_PLAYER_DEPOSIT_TRON_STATUS,
    SET_PLAYER_DEPOSIT_OPTION,
    ERROR_PLAYER_DEPOSIT_OPTION,
    GET_PLAYER_DEPOSIT_OPTION,
    INITIAL_PLAYER_DEPOSIT_OPTION,
    SUCCESS_PLAYER_DEPOSIT_OPTION,
    SET_PLAYER_DEPOSIT_OPTION_STATUS,
    LOADING_PLAYER_TRON_BALANCE,
    SUCCESS_PLAYER_TRON_BALANCE,
    LOADING_PLAYER_DEPOSIT_TRON_BALANCE,
    SUCCESS_PLAYER_DEPOSIT_TRON_BALANCE,
    SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS,
    GET_DEPOSIT_COUNT,
    SET_DEPOSIT_COUNT
} from "../actions/types";

import { payment } from "../selectors/payment";
import { player } from "../selectors/auth";
import { auth } from "../selectors/auth";
import playerState from "../selectors/player";

export { paymentWatcher };

const brand = process.env.REACT_APP_BRAND

function* resetWithdrawalErrors(){
    yield put({
        type: WITHDRAWAL_ERRORS,
        payload: {}
    });
}

function* fetchTransferWallet(action){
    const { wallet: { username }} = yield select(auth);
    const player = yield select(playerState);
    const { subscription: { SubscriptionID } } = action.payload;
    const { wallet: { wallet }} = yield select(auth);
    const main_wallet = realAmount(wallet) + realAmount(action.payload.amount);
    try {
        const result = yield call(postTransferWallet, {
            amount: action.payload.amount,
            provider: action.payload.provider,
            username,
            brand: brand,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            subscription: SubscriptionID
        });
        yield put({
            type: WALLET_SUCCESS,
            payload: {
                [action.payload.provider] :
                {
                    Status: player.wallet[action.payload.provider].Status,
                    UnderMaintenance: player.wallet[action.payload.provider].UnderMaintenance,
                    balance: realAmount(player.wallet[action.payload.provider].balance) - realAmount(action.payload.amount),
                    currencyCode: player.wallet[action.payload.provider].currencyCode,
                    hasError: player.wallet[action.payload.provider].hasError,
                    status: player.wallet[action.payload.provider].status,
                    success: player.wallet[action.payload.provider].success
                }
            },
            payload2: {
                [action.payload.provider]:realFloatAmount(player.wallet[action.payload.provider].balance) - realFloatAmount(action.payload.amount)
            }
        });
        yield put({
            type: TRANSFER_SUCCESS,
            payload: result.data
        });
        yield put({
            type: UPDATE_MAIN_WALLET,
            payload: main_wallet
        });
    }catch(error){
        yield put({
            type: TRANSFER_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchTransferWalletWithoutSuccess(action){
    const { wallet: { username }} = yield select(auth);
    const player = yield select(playerState);
    const { subscription: { SubscriptionID } } = action.payload;
    const { wallet: { wallet }} = yield select(auth);
    const main_wallet = realAmount(wallet) + realAmount(action.payload.amount);
    try {
        const result = yield call(postTransferWallet, {
            amount: action.payload.amount,
            providerFrom: action.payload.providerFrom,
            providerTo: action.payload.providerTo,
            username,
            brand: brand,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            subscription: SubscriptionID
        });
        // yield put({
        //     type: WALLET_SUCCESS,
        //     payload: {
        //         [action.payload.provider] :
        //         {
        //             Status: player.wallet[action.payload.provider].Status,
        //             UnderMaintenance: player.wallet[action.payload.provider].UnderMaintenance,
        //             balance: realAmount(player.wallet[action.payload.provider].balance) ,
        //             currencyCode: player.wallet[action.payload.provider].currencyCode,
        //             hasError: player.wallet[action.payload.provider].hasError,
        //             status: player.wallet[action.payload.provider].status,
        //             success: player.wallet[action.payload.provider].success
        //         }
        //     },
        //     payload2: {
        //         [action.payload.provider]: realFloatAmount(player.wallet[action.payload.provider].balance)
        //     }
        // });
        yield put({
            type: TRANSFER_SUCCESS_WITHOUT_SUCCESS,
            payload: {
                status: result.data.status,
                success: false,
                message: result.data.message
            }
        });
        // yield put({
        //     type: UPDATE_MAIN_WALLET,
        //     payload: main_wallet
        // });
        // yield call(getWallet, {
        //     provider: action.payload.provider,
        //     lang: window.localStorage.i18nextLng.slice(0, 2)
        // });
    }catch(error){
        yield put({
            type: TRANSFER_ERRORS_WITHOUT_SUCCESS,
            payload: {
                ...error.response.data,
                status: error.response.status,
            }
        });
    }
}

function* fetchTransfer(action){
    const { currency: { Abbreviation } } = yield select(player);
    const { wallet: { username }} = yield select(auth);
    const _player = yield select(playerState);
    const { wallet: { wallet }} = yield select(auth);
    const main_wallet = realAmount(wallet) - realAmount(action.payload.amount);
    try {
        const result = yield call(postTransfer, {
            amount: action.payload.amount,
            provider: action.payload.provider,
            currency: Abbreviation,
            username,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            brand: brand
        });
        yield put({
            type: WALLET_SUCCESS,
            payload: {
                [action.payload.provider] :
                {
                    Status: _player.wallet[action.payload.provider].Status,
                    UnderMaintenance: _player.wallet[action.payload.provider].UnderMaintenance,
                    balance: realAmount(_player.wallet[action.payload.provider].balance) + realAmount(action.payload.amount),
                    currencyCode: _player.wallet[action.payload.provider].currencyCode,
                    hasError: _player.wallet[action.payload.provider].hasError,
                    status: _player.wallet[action.payload.provider].status,
                    success: _player.wallet[action.payload.provider].success
                }
            },
            payload2: {
                [action.payload.provider]: realFloatAmount(_player.wallet[action.payload.provider].balance) + realFloatAmount(action.payload.amount)
            }
        });
        yield put({
            type: TRANSFER_SUCCESS,
            payload: result.data
        });
        yield put({
            type: UPDATE_MAIN_WALLET,
            payload: main_wallet
        });
    }catch(error){
        yield put({
            type: TRANSFER_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchTransferWithoutSuccess(action){
    const { currency: { Abbreviation } } = yield select(player);
    const { wallet: { username }} = yield select(auth);
    const _player = yield select(playerState);
    const { wallet: { wallet }} = yield select(auth);
    const main_wallet = realAmount(wallet) - realAmount(action.payload.amount);
    try {
        const result = yield call(postTransfer, {
            amount: action.payload.amount,
            provider: action.payload.provider,
            currency: Abbreviation,
            username,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            brand: brand
        });
        yield put({
            type: WALLET_SUCCESS,
            payload: {
                [action.payload.provider] :
                {
                    Status: _player.wallet[action.payload.provider].Status,
                    UnderMaintenance: _player.wallet[action.payload.provider].UnderMaintenance,
                    balance: realAmount(_player.wallet[action.payload.provider].balance) ,
                    currencyCode: _player.wallet[action.payload.provider].currencyCode,
                    hasError: _player.wallet[action.payload.provider].hasError,
                    status: _player.wallet[action.payload.provider].status,
                    success: _player.wallet[action.payload.provider].success
                }
            },
            payload2: {
                [action.payload.provider]: realFloatAmount(_player.wallet[action.payload.provider].balance)
            }
        });
        yield put({
            type: TRANSFER_SUCCESS_WITHOUT_SUCCESS,
            payload: {
                status: result.data.status,
                success: false,
                message: result.data.message
            }
        });
        yield put({
            type: UPDATE_MAIN_WALLET,
            payload: main_wallet
        });

    }catch(error){
        yield put({
            type: TRANSFER_ERRORS_WITHOUT_SUCCESS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchTransferAll(action){
    const { currency: { Abbreviation } } = yield select(player);
    const { wallet: { username }} = yield select(auth);
    const { amount } = action.payload;
    try {
        const result = yield call(postTransferAll, {
            currency: Abbreviation,
            username,
            brand,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            amount
        });
        yield put({
            type: TRANSFER_SUCCESS,
            payload: {
                ...result.data,
                success: false
            }
        });
    }catch(error){
        yield put({
            type: TRANSFER_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchPromotions(action){
    const { provider: { ID: provider } } = yield select(payment);
    const { currency: { CurrencyID } } = yield select(player);
    // yield put({
    //     type: LOAD_PROMOTIONS
    // });
    // try{
    //     const result = yield call(getProviderPromotions, {
    //         provider,
    //         currency: CurrencyID
    //     });
    //     yield put({
    //         type: SET_PROMOTIONS,
    //         payload: result.data.data
    //     });
    // }catch(error){
    //     yield put({
    //         type: PROMOTION_ERRORS,
    //         payload: {
    //             ...error.response.data.errors,
    //             status: error.response.status
    //         }
    //     });
    // }
}

function* fetchProviders(){
    try {

    const result = yield call(getProviders, { status: 1 });
    yield put({
        type: SET_PROVIDERS,
        payload: result.data.data
    });
    }catch(error){
        yield put({
            type: PROVIDERS_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}


function* fetchCheckWithdrawal(){
    try{
        const result = yield call(checkWithdrawal);
        yield put({
            type: WITHDRAWAL_CHECK,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: WITHDRAWAL_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchPostResend(action){
    try {
        const result = yield call(postResend, action.payload);
        yield put({
            type: RESEND_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: RESEND_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchPostDeposit(action){
    try {
        const result = yield call(postDeposit, action.payload);
        yield put({
            type: DEPOSIT_SUCCESS,
            payload: result.data
        });
    }catch(error){
        let errors;
        if(error.response.data.errors){
            if(typeof error.response.data.errors === 'object'){
                errors = {
                    ...error.response.data.errors,
                    message: error.response.data.message ? error.response.data.message : ""
                }
            }else{
                errors = error.response.data.errors;
            }
        }else{
            errors = {
                error: error.response.data.message
            }
        }
        yield put({
            type: DEPOSIT_ERRORS,
            payload: {
                ...errors,
                status: error.response.status
            }
        });
    }
}

function* fetchPostWithdrawal(action){

    const _player = yield select(playerState);

    try {
        const result = yield call(postWithdrawal, action.payload);
        const { wallet: { wallet }} = yield select(auth);
        const main_wallet = realAmount(wallet) - realAmount(action.payload.amount);
        yield put({
            type: WITHDRAWAL_SUCCESS,
            payload: result.data
        });

        // yield put({
        //     type: WALLET_SUCCESS,
        //     payload: {
        //         [action.payload.provider] :
        //         {
        //             Status: _player.wallet[action.payload.provider].Status,
        //             UnderMaintenance: _player.wallet[action.payload.provider].UnderMaintenance,
        //             balance: realAmount(_player.wallet[action.payload.provider].balance),
        //             currencyCode: _player.wallet[action.payload.provider].currencyCode,
        //             hasError: _player.wallet[action.payload.provider].hasError,
        //             status: _player.wallet[action.payload.provider].status,
        //             success: _player.wallet[action.payload.provider].success
        //         }
        //     },
        //     payload2: {
        //         [action.payload.provider]: realFloatAmount(_player.wallet[action.payload.provider].balance)
        //     }
        // });
        // yield put({
        //     type: UPDATE_MAIN_WALLET,
        //     payload: main_wallet
        // });
        yield* fetchCheckWithdrawal()
    }catch(error){
        yield put({
            type: WITHDRAWAL_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchBankAccount(action){
    try {
        const result = yield call(getBankAccount, action.payload);
        yield put({
            type: SET_BANK_ACCOUNT,
            payload: result.data
        });
        const { pendingWithdrawal } = yield select(payment);
        if(pendingWithdrawal === null){
            yield* fetchCheckWithdrawal()
        }
    }catch(error){
        yield put({
            type: DEPOSIT_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchTronBalance(){
    try {
    yield put({
        type: SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS,
        payload: LOADING_PLAYER_DEPOSIT_TRON_BALANCE,
    });
    const result = yield call(getTronBalance);
    yield put({
        type: SET_PLAYER_DEPOSIT_TRON_BALANCE,
        payload: result.data.balance
    });
    yield put({
        type: SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS,
        payload: SUCCESS_PLAYER_DEPOSIT_TRON_BALANCE,
    });
    }catch(error){
        yield put({
            type: SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS,
            payload: {
                ...error.response.data.errors,
                code: error.response.status,
                status: ERROR_PLAYER_DEPOSIT_TRON_BALANCE
            }
        });
    }
}

function* fetchTronPurchase(){
    try {
        const result = yield call(getTronPurchase);
        yield put({
            type: SET_PLAYER_DEPOSIT_TRON_PURCHASE,
            payload: result.data.content
        });
    }catch(error){
        yield put({
            type: ERROR_PLAYER_DEPOSIT_TRON_PURCHASE,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchTronStatus(){
    try {
        const result = yield call(getTronStatus);
        yield put({
            type: SET_PLAYER_DEPOSIT_TRON_STATUS,
            payload: result.data.showTronExchangeOption
        });
    }catch(error){
        yield put({
            type: ERROR_PLAYER_DEPOSIT_TRON_STATUS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchDepositOptions(action){
    try {
        yield put({
            type: SET_PLAYER_DEPOSIT_OPTION_STATUS,
            payload: INITIAL_PLAYER_DEPOSIT_OPTION,
        });
        const result = yield call(getDepositOptions, action.payload);
        yield put({
            type: SET_PLAYER_DEPOSIT_OPTION,
            payload: result.data.data
        });
        yield put({
            type: SET_PLAYER_DEPOSIT_OPTION_STATUS,
            payload: SUCCESS_PLAYER_DEPOSIT_OPTION,
        });
    }catch(error){
        yield put({
            type: ERROR_PLAYER_DEPOSIT_OPTION,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}
function* fetchDepositCount(){
    try {
        const result = yield call(getDepositCount);
        yield put({
            type: SET_DEPOSIT_COUNT,
            payload: result.data.depositCount,
        });
    }catch(error){

    }
}

function* paymentWatcher() {
    yield takeLatest(GET_PROVIDERS, fetchProviders)
    yield takeLeading(REQUEST_POST_DEPOSIT, fetchPostDeposit)
    yield takeLeading(REQUEST_POST_RESEND, fetchPostResend)
    yield takeLeading(REQUEST_POST_TRANSFER, fetchTransfer)
    yield takeLeading(REQUEST_POST_TRANSFER_ALL, fetchTransferAll)
    yield takeLeading(REQUEST_POST_TRANSFER_WALLET, fetchTransferWallet)
    yield takeLatest(GET_BANK_ACCOUNT, fetchBankAccount)
    yield takeLatest(GET_BANK_ACCOUNT, resetWithdrawalErrors)
    yield takeLeading(REQUEST_POST_WITHDRAWAL, fetchPostWithdrawal)
    yield takeLatest(SET_PROVIDER, fetchPromotions)
    yield takeLatest(SUBSCRIPTION_CANCEL_SUCCESS, fetchPromotions)
    yield takeLatest(REQUEST_POST_TRANSFER_WITHOUT_SUCCESS, fetchTransferWithoutSuccess)
    yield takeLatest(REQUEST_POST_TRANSFER_WALLET_WITHOUT_SUCCESS, fetchTransferWalletWithoutSuccess)
    yield takeLatest(GET_PLAYER_DEPOSIT_TRON_BALANCE, fetchTronBalance)
    yield takeLatest(GET_PLAYER_DEPOSIT_TRON_PURCHASE, fetchTronPurchase)
    yield takeLatest(GET_PLAYER_DEPOSIT_TRON_STATUS, fetchTronStatus)
    yield takeLatest(GET_PLAYER_DEPOSIT_OPTION, fetchDepositOptions)
    yield takeLatest(GET_DEPOSIT_COUNT, fetchDepositCount)
}