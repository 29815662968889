// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";
// import en from "./locales/en-US.json";
// import ko from "./locales/ko-KR.json";

// i18n.use(initReactI18next)
//     .use(LanguageDetector)
//     .init({
//         // we init with resources
//         resources: {
//             ...en,
//             ...ko
//         },
//         fallbackLng: "en",
//         debug: true,

//         // have a common namespace used around the full app
//         ns: ["translations"],
//         defaultNS: "translations",

//         keySeparator: false, // we use content as keys

//         interpolation: {
//             escapeValue: false, // not needed for react!!
//             formatSeparator: ","
//         },

//         react: {
//             wait: true
//         }
//     });

// export default i18n;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { ROOT_URL, BRAND_TOKEN } from './config/api';


i18n
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        backend: {
            loadPath: function(lng, ns) {
                return `${ROOT_URL}/${ns}/${lng}.json`;
            },
            customHeaders: () => ({
                'brand-token': BRAND_TOKEN,
            }),
            crossDomain: false,
            withCredentials: false,
            reloadInterval: false,
        },
        fallbackLng: false,
        debug: false,
        load: 'languageOnly',
        ns: 'translations',
        defaultNS: 'translations',
        fallbackNS: false,
        // supportedLngs: ['ko-KR', 'en-US'],
        keySeparator: false, // we use content as keys
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
        },
        react: {
            useSuspense: false,
            wait: true,
        },
    });


  //set default language to kr
  i18n.on('initialized', function(options){
      if (localStorage.getItem('loaded') === null) {
          localStorage.setItem('loaded', 1);
          i18n.changeLanguage('ko-KR');
      }
  })

export default i18n;
