import {
  GET_MESSAGES,
  RESET_MESSAGES,
  GET_ALL_MESSAGES,
  SET_MESSAGE_DATE,
  SET_MESSAGE_TYPE,
  SET_MESSAGES_CURRENT_PAGE,
  SET_MESSAGES_FILTER,
  RESET_MESSAGES_FILTER,
  PLAYER_RESET_ERRORS,
  REQUEST_POST_PLAYER,
  REQUEST_POST_ACCOUNT,
  REQUEST_POST_PASSWORD,
  GET_PLAYER,
  WALLET_GET,
  WALLET_SUCCESS,
  WALLET_CLEAR,
  PROCESS_WALLET_MESSAGE,
  GET_PLAYER_DEPOSIT_ACCOUNT,
  SET_PLAYER_DEPOSIT_ACCOUNT,
  CLEAR_PLAYER_DEPOSIT_ACCOUNT,
  ERROR_PLAYER_DEPOSIT_ACCOUNT
} from "./types";

export const reloadWallet = () => {
  return {
    type: WALLET_SUCCESS
  };
};

export const getWallet = payload => {
  return {
    type: WALLET_GET,
    payload
  };
};

export const postPlayer = player => {
  return {
    type: REQUEST_POST_PLAYER,
    payload: player
  };
};

export const postAccount = player => {
  return {
    type: REQUEST_POST_ACCOUNT,
    payload: player
  };
};

export const postPassword = player => {
  return {
    type: REQUEST_POST_PASSWORD,
    payload: player
  };
};

export const getPlayer = () => {
  return {
    type: GET_PLAYER
  };
};

export const getMessages = () => {
  return {
    type: GET_MESSAGES
  };
}

export const getAllMessages = () => {
  return {
    type: GET_ALL_MESSAGES
  };
}

export const readMessage = player => {
  return {
      type: GET_MESSAGES,
      payload: player
  };
}

export const resetPlayerError = () => {
  return {
      type: PLAYER_RESET_ERRORS,
      payload: false
  };
}

export const selectType = event => {
  let params = {
    message_type: event.target.value,
    type: SET_MESSAGE_TYPE
  };
    return params;
};

export const selectDate = event => {
    let params = {
        date: event.target.value,
        type: SET_MESSAGE_DATE
    };
    return params;
};

export const selectPage = page => {
    let params = {
        page,
        type: SET_MESSAGES_CURRENT_PAGE
    };
    return params;
};

export const setFilter = (payload) => {
    return {
        type: SET_MESSAGES_FILTER,
        payload
    };
}

export const resetFilter = (payload) => {
    return {
        type: RESET_MESSAGES_FILTER,
    };
}

export const clearWallet = () => {
    return {
        type: WALLET_CLEAR,
    };
}

export const processWalletMesage = (payload) => {
    return {
        type: PROCESS_WALLET_MESSAGE,
        payload
    };
}

export const getDepositAccount = () => {
  return {
    type: GET_PLAYER_DEPOSIT_ACCOUNT
  };
};

export const setDepositAccount = (payload) => {
  return {
      type: SET_PLAYER_DEPOSIT_ACCOUNT,
      payload
  };
}

export const clearDepositAccount = () => {
  return {
      type: CLEAR_PLAYER_DEPOSIT_ACCOUNT
  };
}

export const errorDepositAccount = (payload) => {
  return {
      type: ERROR_PLAYER_DEPOSIT_ACCOUNT,
      payload
  };
}

export const resetMessages = () => {
  return {
    type: RESET_MESSAGES
  }
}

