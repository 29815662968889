import React, { useEffect, useState, } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';


const Providers = ({ show, handleClose, provider }) => {
    const { t } = useTranslation();
    return (
        <div
            style={{display: show ? "block" : "none"}}
            id="bonusHistoryProviderModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="bonusHistoryProviderModal"
            aria-hidden="true"
        >
            <Modal show={show} centered>
                <Modal.Header>
                    <p className="modal-title" id="noticeModal">{t('list of valid providers')}</p>
                    <button type="button" className="close" onClick={handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div>
                        <p className='valid-on'>{t('this promo is valid on')}:</p>
                        {
                            provider.FilteredProduct && provider.FilteredProduct.length > 0 ?
                                provider.FilteredProduct.map((product, index) => {
                                let x =  [];
                                return (
                                    <div key={index} style={{paddingBottom: '10px'}}>
                                        <span className='product-list'>{t(product.Data.Name.toLowerCase())}{' '}</span>
                                        <span className='provider-list'>({ 
                                            provider.Products.sort((a, b) => {
                                                return (a.Data.SortOrder > b.Data.SortOrder) ? 1 : -1 
                                            }).map((p, i) => {
                                                if (product.Data.ID === p.Data.ID) {
                                                    x.push(t(p.Data.ProviderName.toLowerCase()))
                                                }
                                            })
                                            }<>{x.join(', ')}</>)</span>{
                                                provider.FilteredProduct.length === 1 ? '' : index < provider.FilteredProduct.length - 1 ? '' : ''
                                            }
                                    </div>
                                )
                            })
                            : t('no valid provider')
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: '#1D2C31' }}>
                    <button onClick={handleClose} className="btn cancelBtn closeBtn-deposit">
                        {t('close')}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Providers;
