import {
    BONUS_CHECK,
    BONUS_SUCCESS,
    BONUS_ERRORS,
    RESET_BONUS_ERRORS,
    BONUS_REDEEM_SUCCESS,
    BONUS_REDEEM_ERRORS,
    BONUS_SET_ONGOING,
    SET_BONUS_PAGE,
    SUBSCRIPTION_CANCEL_SUCCESS,
    SUBSCRIPTION_CANCEL_ERRORS,
} from "../actions/types";

const initialState = {
    bonuses: {
        data: [],
        meta: {},
        links: {}
    },
    errors: {
        subscription: {
            BonusAmount: "",
            promotion: {
                Title: ""
            }
        }
    },
    ongoing: {},
    success: false
};

export default function(state = initialState, action) {
  switch (action.type) {
      case BONUS_CHECK:
          return {
              ...state,
              loading: true
          }
      case BONUS_REDEEM_SUCCESS:
          return {
              ...state,
              errors: false,
              success: action.payload
          };
      case BONUS_REDEEM_ERRORS:
          return {
              ...state,
              errors: action.payload,
              success: {
                  message: false
              }
          };
      case BONUS_SUCCESS:
          return {
              ...state,
              bonuses: action.payload,
              errors: {
                  subscription: {
                      BonusAmount: "",
                      promotion: {
                          Title: ""
                      }
                  },
                  message: false,
                  success: true
              },
              loading: false
          };
      case BONUS_ERRORS:
          return {
              ...state,
              errors: action.payload,
              loading: false
          };
      case SUBSCRIPTION_CANCEL_SUCCESS:
          return {
              ...state,
              cancel: action.payload
          };
      case SUBSCRIPTION_CANCEL_ERRORS:
          return {
              ...state,
              errors: {
                  ...state.errors,
                  cancel: {
                      ...action.payload
                  }
              }
          };
      case RESET_BONUS_ERRORS:
          return {
              ...state,
              cancel: false,
              errors: {
                  subscription: {
                      BonusAmount: "",
                      promotion: {
                          Title: ""
                      }
                  },
                  message: false,
                  success: true
              },
              loading: false,
              success: false,
          };
      case SET_BONUS_PAGE:
          return {
              ...state,
              page: action.payload
          };
      case BONUS_SET_ONGOING:
          return {
              ...state,
              ongoing: action.payload
          };
      default:
          return state;
  }
}
