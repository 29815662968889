import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { getPages, getPage, getParnetPages,getTemplateContent, getBrandGameSetup };

let lang = localStorage.getItem("i18nextLng") || 'en-US';
lang = lang.split('-');

function getPages() {
  return axios.get(`${ROOT_URL}/pages?lang=${lang[0]}`);
}

function getPage(slug) {
    return axios.get(`${ROOT_URL}/pages${slug}?lang=${lang[0]}`);
}

function getParnetPages() {
  return axios.get(`${ROOT_URL}/partnersPages?lang=${lang[0]}`);
}

function getTemplateContent(name) {
   return axios.get(`${ROOT_URL}/getPage`, {params: name});
}

function getBrandGameSetup(params) {
  return axios.get(`${ROOT_URL}/brandGameSetup`, {params});
}

