import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";
import { gmtParams } from "../helpers";

export {
    getCash,
}

function getCash(params){
    params = gmtParams(params);
    return axios
        .get(`${ROOT_URL}/cash`, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }, params
        });
}
