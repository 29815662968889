import React from 'react';
import loading from "../../assets/img/loader.gif";
import classnames from "classnames";

const Loader = ({hide, style, classes, className}) => {
    const cn =className;
    return (<img
        style={ style ? style : { width: '36px' }}
        className={classnames(classes !== "" ? classes : "float-right", {
            'd-none': hide,
        }) }
        alt="loader"
        src={loading} />)
}

export default Loader;
