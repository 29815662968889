import { takeLatest, put, call } from 'redux-saga/effects';
import { getBanners } from "../api/banner"
import {
    GET_BANNERS,
    SET_BANNERS
} from "../actions/types";

export { bannerWatcher };

function* bannerWatcher() {
    yield takeLatest(GET_BANNERS, fetchBanners)
}

function* fetchBanners() {
    const result = yield call(getBanners);
    yield put({
        type: SET_BANNERS,
        payload: result.data.data
    });
}
