import React, {useState} from "react";
import { useTranslation } from 'react-i18next';


const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 198)+"..." : text}
      <span onClick={toggleReadMore} className="read-or-hide readmore-text">
        <p></p>
        {isReadMore ? "Read more" : "Show less"}
      </span>
    </p>
  );
};

const Marketing = () => {
    const {t} = useTranslation();
    const { i18n } = useTranslation();

    return (
        <section class="animation-element details-section padding-top" id="details">
      <div class="container">
        <div class="row align-items-start justify-content-center">
          
          <div class="col">
            <div class="box-container highlighted pt-4">
              <div class="icon register"></div>
              <p class="title">{t('register')}</p>
                <ReadMore>{t('register text')}</ReadMore>
            </div>
          </div>
         
          <div class="col">
            <div class="box-container highlighted pt-4">
              <div class="icon promote"></div>
              <p class="title">{t('promote')}</p>
              {i18n.language === "en-US" ? (
                <ReadMore>{t('promote text')}</ReadMore>
              ) : (
                <p className="text">{t('promote text')}</p>
              )}
            </div>
          </div>
        
          <div class="col">
            <div class="box-container highlighted pt-4">
              <div class="icon earn"></div>
              <p class="title">{t('earn')}</p>
              {i18n.language === "en-US" ? (
                <ReadMore>{t('earn text')}</ReadMore>
              ) : (
                <p className="text">{t('earn text')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
    );
};

export default Marketing;
