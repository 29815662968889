import React, { useEffect, useState } from "react";
import { useSelector, useDispatch  } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProviders, postPlay, postOpen, clearResult } from "../../../actions/casino";
import { ToastContainer, toast } from 'react-toastify';

const Message = ({ closeMessage, onSubmit, play}) => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { providers } = useSelector((state) => state.casino);
    const { transactionHistory } = useSelector((state) => state);
    const { result } = useSelector((state) => state.casino);
    const [toastz, setToastz] = useState(false);
    const [gamez, setGame] = useState(false);
    const language = i18n.language;
    

    const data = providers.filter((val) => {
        return transactionHistory.transactionHistory.data.some((item, key) => {
            if(key < 5) {
                return val.ID === item.provider_id;
            }
        })
    })

    useEffect(() => {
        dispatch({type:"GET_TRANSACTION_HISTORY"});
    }, []);


    return (
        <div className="col-md-6 accountInfo mb-5 animated fadeIn" id="deposit-message">
            <div class="depSentContent">
                <div class="depSent">
                    <h2 class="requestSentTitle"><i class="fas fa-check-circle"></i>{" "}{t('transfer message title')}</h2>
                </div>
                <p class="requestTransSub pt-3">{t('transfer message')}</p>
                <div class="action-btn-modal text-center">
                    <button class="btn btn-confirm-deposit cancelBtn" type="reset" onClick={closeMessage}>{t("close")}</button>
                </div>
                <div class="col-md-12 mt-4">
                    <p class="quickLinkTitle">{t("quick links")}</p>
                    {
                        data.map((val, key) => {
                            if(val.ID === 21) {
                                let textPlay = language === "ko-KR" ? "textPlay-kr" : "textPlay"; 
                                let joaSports = language === "ko-KR" ? "btnJoaSports-kr" : "btnJoaSports";
                                let liveSports = language === "ko-KR" ? "btnLive-kr" : "btnLive";
                                return (
                                    <div className="quickLinks" key={key}>
                                        <div className=" inline">
                                            <div className="col-md-3">
                                                <img src={val.Logo} className="img-fluid imgTransfer" alt={val.Slug}/>
                                            </div>
                                            <div class="col-md-9">
                                                <div className={`${textPlay}`}><Link to={"/"} className={`btn ButtonStyle ${joaSports}`}></Link> </div>
                                                <div className={`${textPlay}`}><Link to={"/live-sports"} className={`btn ButtonStyle  ${liveSports}`}></Link> </div>
                                            </div>
                                        </div>
                                    </div> 
                                );
                            } else {
                                return (
                                    <div className="quickLinks" key={key}>
                                        <div className=" inline">
                                            <div className="col-md-3">
                                                <img src={val.Logo} className="img-fluid imgTransfer" alt={val.Slug}/>
                                            </div>
                                            <div class="col-md-9">
                                                    {
                                                        val.Product.map((product, key) => {
                                                            let productType = "";
                                                            let link = "";
                                                            let textPlay = language === "ko-KR" ? "textPlay-kr" : "textPlay";
                                                            if (product.GameProductID === 9) {
                                                                productType = language === "ko-KR" ? "btnEsports-kr" : "btnEsports";
                                                            } else if (product.GameProductID === 1) {
                                                                productType = language === "ko-KR" ? "btnJoaSports-kr" : "btnJoaSports";
                                                            } else if (product.GameProductID === 5) {
                                                                productType = language === "ko-KR" ? "btnVirtual-kr" : "btnVirtual";
                                                            } else if (product.GameProductID === 10) {
                                                                productType = language === "ko-KR" ? "btnPowerball-kr" : "btnPowerball";
                                                            }
                                                            return (
                                                                <div key={key} className={`${textPlay}`}><Link to={link + "/" + val.Slug} className={`btn ButtonStyle ${productType}`}></Link> </div>
                                                            );
                                                        })
                                                    }
                                            </div>
                                        </div>
                                    </div> 
                                );
                            }
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Message;
