import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";
import { gmtParams } from "../helpers";

export {
    checkBonus,
    getBonus,
    getBonusHistory,
    getCash,
    postBonus,
    postCancelSubscription
}

function getBonus(params){
    params = gmtParams(params);
    return axios
        .get(`${ROOT_URL}/bonus`, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }, params
        });
}

function getCash(params){
    params = gmtParams(params);
    return axios
        .get(`${ROOT_URL}/cash`, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }, params
        });
}

function getBonusHistory(params){
    params = gmtParams(params);

    return axios
        .get(`${ROOT_URL}/bonusHistory`, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }, params
        });
}

function checkBonus(params){
    let lang = localStorage.getItem("i18nextLng") || 'en-US';
    lang = lang.split('-');
    params['lang']=lang[0];
    return axios
        .get(`${ROOT_URL}/bonus/check`, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }, params
        });
}

function postBonus(params){
    return axios
        .post(`${ROOT_URL}/bonus/redeem`, params, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }
        });
}

function postCancelSubscription(subscription){
  return axios
        .post(`${ROOT_URL}/bonus/cancel`, subscription, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }
    })
}
