import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import Fund from "./modals/Fund";
import All from "./modals/All";
import Unable from "./modals/Unable";
import {
    postWithdrawal,
    resetWithdrawal,
    getBankAccount,
    resetTransfer,
    resetWithdrawalError,
    getDepositCount
} from "../../../actions/payment";
import Wallet from "../../layouts/Walletz/";
import Provider from "../../layouts/Walletz/provider";
import {
    selectProvider as selectModalProvider
} from "../../../actions/modal";
import {
    checkBonus,
    resetBonus,
} from "../../../actions/bonus";
import { updateMainWallet } from "../../../actions/authentication";
import { reloadWallet, getWallet, clearWallet, getPlayer } from "../../../actions/player";

import { realAmount } from "../../../helpers";
import { withTranslation } from "react-i18next";
import { getProviders } from "../../../actions/casino";
import { getProductProviders } from "../../../actions/product";

import { getActiveProviders, resetActiveCount } from '../../../actions/provider';
import { displayAmount, totalAmount } from "../../../helpers";

import { Loader } from "../../common";

import RequestFailed from '../../common/RequestFailed';
import ConfirmWithdrawal from './modals/Confirm';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Withdrawal extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            amount: "",
            received: 0,
            bankID: 0,
            total: 0,
            counter: 0,
            providerID: 0,
            minAmount: 10000,
            maxAmount: 100000000,
            success: false,
            error: false,
            isSubmitted: false,
            validated: false,
            show: true,
            insufficient: false,
            wagering: false,
            withdrawalError: false,
            withdrawalBlacklistError: false,
            withdrawalSuspensionError: false,
            withdrawalClosedAccountError: false,
            minError: false,
            maxError: false,
            showFund: false,
            showAll: false,
            showUnable: false,
            checker: false,
            successTransfer: false,
            errors: {},
            bank: [],
            bankName: "",
            bankAccountName: "",
            bankAccountNumber: "",
            providerSelectDropdown: false,
            wallet1: [],
            providersWithWallet: [],
            minReceivedError: false,
            minReceivedAmount: 10000,
            walletError: false,
            init:0,
            mainWallet:0,
            availableAmount:0,
            showConfirm: false,
            submitDisabled: false,
            isSuspended: false,
            errorStatusCode: 400
        };
    }

    resetForm = () => {
        let allin = this.props.wallet.wallet;
        allin = allin.toString().replace(/,/g,"")
        allin = realAmount(Math.trunc(allin));
        this.setState({ amount: allin, insufficient:false,maxError:false,minError:false,error:false,errors:{} });
    };

    onClick = () => {
        this.setState({ insufficient: false, maxError:false,minError:false, minReceivedError: false});
    };

    componentDidMount() {
        const type = { transaction_type: 2000};
        this.props.getBankAccount(type);
        // this.props.getProductProviders({});
        this.props.reloadWallet();
        // this.props.resetBonus();
        // this.props.resetTransfer();
        // this.props.checkBonus({provider:0})
        // this.props.getProviders({ transfer: true });
        // this.refreshWallet();
        this.props.getDepositCount();
        document.addEventListener('click', e => this.dropDownBlurHandle(e));
    }

    dropDownBlurHandle(e) {
        if(e.target.classList.contains('custom-selectbox')) {
            return false;
        }

        this.setState({providerSelectDropdown: false});
    }

    componentWillReceiveProps(nextProps) {
        try {
            if(nextProps.errors.player_blacklisted !== undefined) {
                this.setState({withdrawalBlacklistError: true, errorStatusCode: nextProps.errors.status});
            }
            if(nextProps.errors.player_suspended !== undefined) {
                this.setState({withdrawalSuspensionError: true, errorStatusCode: nextProps.errors.status});
            }
            if(nextProps.errors.player_closed !== undefined) {
                this.setState({withdrawalClosedAccountError: true, errorStatusCode: nextProps.errors.status});
            }

            if (nextProps.player.bank) {
                this.setState({
                    bankName: nextProps.player.bank.bank_details.LocalName
                });
                this.setState({
                    bankAccountName: nextProps.player.bank.AccountName
                });
                this.setState({
                    bankAccountNumber: nextProps.player.bank.AccountNo
                });
            }

            if(nextProps.wallet){
                this.setState({amount: displayAmount(nextProps.wallet.withdrawAmount)})
                this.setState({availableAmount: displayAmount(nextProps.wallet.withdrawAmount)})
                this.setState({mainWallet: displayAmount(nextProps.wallet.wallet)})
                
                if (nextProps.errors.transaction === undefined) {
                    this.changeReceivedValue(displayAmount(nextProps.wallet.withdrawAmount))
                }
                
            }

            if (nextProps.player) {
                this.setState({isSuspended: nextProps.player.Status === 4 ? true : false})
            }
            // if (nextProps.bonuses) {
            //    this.setState({counter:Object.keys(nextProps.bonuses.ongoing).length})
            // }

            // if (nextProps.provider) {
            //     this.setState({providerID:nextProps.provider.ID})
            // }
           
            // if (Object.keys(nextProps.successTransfer).length > 0) {
            //     this.setState({successTransfer:true})
            // }

            let bank = nextProps.bankAccount
            if (bank !== undefined) {
                if (bank.length > 0) {
                    let maxAmount = 0;
                    let minAmount = bank[0].MinWithdrawalAmount;
                    bank.map(function(data, key) {
                        let maximum = realAmount(data.MaxWithdrawalAmount)
                        let minimum = realAmount(data.MinWithdrawalAmount)
                        if (maxAmount <= maximum) { maxAmount = maximum }
                        if (minAmount >= minimum) { minAmount = minimum }
                    });
                    this.setState({ maxAmount:maxAmount, minAmount:minAmount, bank: bank });
                }
            }

            // if (nextProps.walletz) {
            //     this.setState({total: totalAmount(nextProps.walletz)})
            // }

            if (nextProps.errors.amount) {
                this.setState({ error: true });
                this.setState({ success: false });
                let amount = Array.isArray(nextProps.errors.amount)
                    ? nextProps.errors.amount[0].toLowerCase()
                    : nextProps.errors.amount.toLowerCase();
                let balance = nextProps.errors.balance;
                let withdrawAmount = nextProps.errors.withdrawAmount;
                this.setState((prevState) => ({ errors: { amount, balance, withdrawAmount } }));
            }
            const { t } = this.props;
    
            if (nextProps.errors.player_suspended !== undefined ||
                nextProps.errors.player_closed !== undefined) {
                toast.error(t('please contact cs'), {
                    className: "toast toast-error",
                });
            }

            if (nextProps.errors.transaction) {
                this.setState({ error: true });
                this.setState({ success: false });
                    let transaction = nextProps.errors.transaction;
                this.setState((prevState) => ({ errors: { transaction } }));

                if (nextProps.errors.transaction !== undefined) {

                    if (nextProps.errors.transaction[0].toLowerCase() === 'you have pending withdrawal.') {
                        toast.error(t('you have pending withdrawal'), {
                            className: "toast toast-error",
                        });
                    }

                    if (nextProps.errors.transaction[0].toLowerCase() === 'you have unfinished wagering requirements') {
                        toast.error(t('you have unfinished wagering requirements'), {
                            className: "toast toast-error",
                        });
                    }
                    
                }
            }

            if ((nextProps.success.success || nextProps.pendingWithdrawal) && this.state.init === 0) {
                this.setState({ error: false });
                this.setState({ success: true });
                this.setState({ amount: 0, init: 1 });
            }

            const { isSubmitted } = this.state;
        } catch (err) {
            //console.log(err);
        }

        // if(this.checkAllBalanceReceived(nextProps.walletz)) {
        //     const dump = this.props.provz.map((val) => {
        //         return { 
        //             ...val,
        //             wallet: realAmount(nextProps.walletz[val.ID].balance)
        //         }; 
        //     }).filter((val) => {
        //         return val.HasPendingBets.isSubscribed === false;
        //     }).sort((a, b) => parseFloat(b.wallet) - parseFloat(a.wallet))

        //     const dump2 = this.props.provz.map((val) => {
        //         return { 
        //             ...val,
        //             wallet: realAmount(nextProps.walletz[val.ID].balance)
        //         }; 
        //     }).filter((val) => {
        //         return val.HasPendingBets.isSubscribed === false;
        //     })

            
        //     if(dump2.length != 0  && dump2 != undefined) {
        //         this.setState({providersWithWallet: dump2}, () => {
        //             if(dump.length != 0) {
        //                 if(this.checkBalanceCount() != 0){
        //                     this.selectWallet({ID: dump[0].ID, Name: dump[0].ProviderName, Logo: dump[0].Logo, Balance: dump[0].wallet});
        //                 } else {
        //                     this.selectWallet({ID: 0, Name: "no available balance", Balance: 0});
        //                 }
        //             }  
        //         });
        //     }
        // }
    }

    componentWillUnmount() {
        this.props.resetWithdrawal();
        this.props.resetTransfer();
        this.props.resetBonus();
    }

    onChange = (e) => {
        const value = e.target.value
        this.setState({
            [e.target.name]: value
        });

        // this.checkAmount(value);

        if(e.target.name === "amount") {
            this.changeReceivedValue(value)
        }
    };

    changeReceivedValue(amount) {
        let receive = amount.toString().replace(/,/g,"")
        const rounddown = Math.floor(Math.trunc(receive) / 10000) * 10000;
        let excessAmount = receive % 10000;
        let thousand = Math.floor(Math.trunc(excessAmount) / 1000) * 1000;
        let withdrawableAmount = 0
        if (receive >= 10000) {
            withdrawableAmount = rounddown
            // withdrawableAmount = rounddown + thousand
        }
        this.setState({
            received: withdrawableAmount
        })
    }

    closeConfirm = () => {
        this.setState({showConfirm:false})
        this.setState({ success: false });
        this.setState({ withdrawalError: false });
        this.setState({ withdrawalBlacklistError: false });
        this.setState({ withdrawalSuspensionError: false });
        this.setState({ withdrawalClosedAccountError: false });
        this.props.resetWithdrawal();
        this.props.resetWithdrawalError();
        this.props.getPlayer();
    }


    showConfirm = () => {
        
        let amount =  this.state.received.toString().replace(/,/g,"")
        amount = realAmount(Math.trunc(amount));

        if(amount > this.state.maxAmount) {
            this.setState({maxError: true});
            return false;
        }

        let checkAmount =  this.state.amount.toString().replace(/,/g,"")
        checkAmount = realAmount(Math.trunc(checkAmount));

        const min = this.checkMin(checkAmount);
        const bankID = this.checkBank(amount);

        const minReceived = this.checkMinReceived(amount);
        const wageringInsufficient = this.checkWageringInsufficientAmount(amount);
    

        this.setState({ validated: true });
        if (this.form.checkValidity() && ! min  && ! minReceived) { // && ! max && ! insufficient
            this.setState({showConfirm:true})
        }
    }

    

    checkAmount = (amount) => {
        // if(this.state.wallet1.length != 0) {
        //     if(this.state.wallet1.Name != "select wallet" && this.state.wallet1.Name != "no available balance") {
        //         const wallet = this.state.wallet1.Balance;
        //         if (realAmount(amount) > realAmount(wallet)) {
        //             this.setState({
        //                 insufficient: true,
        //             });
        //         } else {
        //             this.setState({
        //                 insufficient: false,
        //             });
        //         }
        //     }
        // }

        if (realAmount(amount) > realAmount(this.state.mainWallet)) {
            this.setState({
                insufficient: true,
            });
        } else {
            this.setState({
                insufficient: false,
            });
        }
    }

    checkWageringInsufficientAmount = (amount) => {


        if ( realAmount(amount) > realAmount(this.state.availableAmount)  && realAmount(amount) <  realAmount(this.state.mainWallet) ) {
            if (realAmount(amount) > realAmount(this.state.availableAmount) ) {
                this.setState({
                    wagering: true,
                });return true;
            } else {
                this.setState({
                    wagering: false,
                });return false;
            }
        } else if (realAmount(amount) > realAmount(this.state.mainWallet)){
            if (realAmount(amount) > realAmount(this.state.mainWallet) ) {
                this.setState({
                    insufficient: true,
                    wagering: false,
                });return true;
            }else {
                this.setState({
                    insufficient: false,
                });return false;
            }
        }
    }


    checkInsufficientAmount = (amount) => {
        if (realAmount(amount) > realAmount(this.state.mainWallet)) {
            this.setState({
                insufficient: true,
            });return true;
        } else {
            this.setState({
                insufficient: false,
            });return false;
        }
    }

    checkBank = (amount) => {
        let bank = this.state.bank;
        let bankID = 0;
        if (bank.length > 0) {
            for (let i = 0; i < bank.length; i++) {
                if (realAmount(amount) >= realAmount(bank[i].MinWithdrawalAmount) && realAmount(amount) <= realAmount(bank[i].MaxWithdrawalAmount)) {
                    bankID =  bank[i].BankAccountID;
                    break;
                }
            }
            this.setState({bankID: bankID});
        }
        return bankID;
    }

    checkWallet() {
        if(this.state.wallet1.length === 0 || this.state.wallet1.Name === "no available balance") {
            this.setState({walletError: true}); return true;
        } else {
            this.setState({walletError: false}); return false;
        }
    }

    // checkMax = (amount) => {
    //     const maxAmount = this.state.maxAmount;

    //     if (realAmount(amount) > maxAmount) {
    //         this.setState({maxError:true});return true;
    //     } else {
    //         this.setState({maxError:false}); return false;
    //     }
    // }

    checkMin = (amount) => {
        const minAmount = this.state.minAmount;

        if (realAmount(amount) < minAmount) {
            this.setState({minError:true}); return true;
        } else {
            this.setState({minError:false}); return false;
        }
    }

    checkMinReceived = (amount) => {
        const minAmount = this.state.minReceivedAmount;

        if (realAmount(amount) < minAmount) {
            this.setState({minReceivedError:true}); return true;
        } else {
            this.setState({minReceivedError:false}); return false;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ isSubmitted: true });

        // if(this.props.wallet.player.Status === 5) {
        //     this.setState({withdrawalBlacklistError: true});
        //     return false;
        // }

        let amount =  this.state.received.toString().replace(/,/g,"")
        amount = realAmount(Math.trunc(amount));

        let checkAmount =  this.state.amount.toString().replace(/,/g,"")
        checkAmount = realAmount(Math.trunc(checkAmount));

        const min = this.checkMin(checkAmount);
        // const max = this.checkMax(checkAmount);
        const bankID = this.checkBank(amount);

        const minReceived = this.checkMinReceived(amount);
        const wageringInsufficient = this.checkWageringInsufficientAmount(amount);
        // const insufficient = this.checkInsufficientAmount(amount);

        // const walletError = this.checkWallet();

        const payment = {
            amount: realAmount(amount),
            bank: bankID,
            provider: this.state.wallet1.ID,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            brand: process.env.REACT_APP_BRAND,
            username: this.props.wallet.username
        };
        // console.log(! min )
        // console.log(!  this.state.insufficient )
        // console.log(! minReceived )
        if (! min  && ! minReceived) { // && ! max && ! this.state.insufficient  && ! minReceived bankID === 0  &&  && ! insufficient 
            
            if (bankID === 0) {
                this.setState({
                    withdrawalError: true
                })
            }
        }

        this.setState({ validated: true });
        if (this.form.checkValidity() && bankID !== 0 && ! min  && ! minReceived) { // && ! max && ! insufficient
            this.setState({ submitDisabled: true });
            this.props.postWithdrawal(payment)
            //this.props.updateMainWallet(walletz);
            this.setState({ validated: false, init: 0 });

            window.setTimeout(() => {
                this.setState({ submitDisabled: false });
            }, 2000)
        }
    };

    quickAddAmount = (amountToAdd) => {
        let currentAmount = this.state.amount;
        currentAmount = realAmount(currentAmount) + amountToAdd;
        
        // this.checkAmount(currentAmount);
        this.setState({ amount: currentAmount });
        this.changeReceivedValue(currentAmount);
    };

    hideMessage = () => {
        this.setState({ success: false });
        this.props.resetWithdrawal();
        this.props.getPlayer();

        // let allin = this.props.wallet.wallet;
        // allin = allin.toString().replace(/,/g,"")
        // allin = realAmount(Math.trunc(allin));

        // this.setState({
        //     amount: allin,
        //     received: allin
        // });
    };

    selectModalProvider = (provider) => {
        this.setState({showFund: true, checker: true})
        this.props.selectModalProvider(provider);
        this.props.resetBonus();
        this.props.checkBonus({provider: provider.ID });
    }

    handleClose() {
        this.setState({ showFund: false });
    }

    handleCloseAll = () =>{
        this.setState({ showAll: false});
    }

    handleCloseUnable = () => {
        this.setState({ showUnable: false});
    }

    selectAll = () => {
      
        if (this.state.counter === 0) {
            this.setState({ showAll: true});
        } else {
            this.setState({ showUnable: true});
        }
        
    }

    checkAllBalance(){
        if(this.objectLen(this.props.providersz) === 0) return false
        return (this.objectLen(this.props.providersz) <= this.objectLen(this.props.walletz));
    }

    checkAllBalanceReceived(wallet){
        if(this.objectLen(this.props.providersz) === 0) return false
        return (this.objectLen(this.props.providersz) <= this.objectLen(wallet));
    }

    objectLen(obj){
        return Object.keys(obj).length;
    }

    closeDropdown() {
        this.setState({
            providerSelectDropdown: false
        })
    }
    
    selectWallet(wallet) {
        let allin = wallet.Balance;
        this.setState({
            wallet1: wallet,
            amount: allin
        });

        if(allin != undefined) {
            this.changeReceivedValue(wallet.Balance);
        }
    }

    checkBalanceCount() {
        if(this.state.providersWithWallet.length !=0 && this.state.providersWithWallet != undefined) {
            return this.state.providersWithWallet
            .filter((val) => {
                return val.wallet != 0;
            }).length;
        }
    }

    refreshWallet(){
        if(!this.checkAllBalance()){
            this.props.clearWallet();
            this.props.resetActiveCount();
            this.props.getActiveProviders();
        }
    };

    shouldBeDisabled() {
        return (
            this.state.wallet1.length === 0
        )
    }

    resetAmount() {
        this.setState({ amount: "", received: 0}, () => {
            this.checkAmount(this.state.amount);
            this.checkBank(this.state.amount);
        })
        this.setState({ insufficient:false,maxError:false,minError:false, minReceivedError: false,error:false,errors:{} });
    }

    render() {
        const { t,i18n } = this.props;
        const { amount, errors, error, received } = this.state;
        const language = i18n.language;
        const accountNumber = "**********" + this.state.bankAccountNumber.substr(this.state.bankAccountNumber.length - 4);
        return (
            <div  id="containerForm">
                <h2 className="form-title">{t('withdrawal')}</h2>
                <div className="formContent">
                        <ToastContainer autoClose={8000} />
                        <form className={this.state.validated ? "needs-validation was-validated": ""}
                            noValidate method="post"
                            onSubmit={this.onSubmit.bind(this)}
                            ref={(form)=> this.form = form}
                        >
                        <div class="row">
                                <div class="col-md-5 animated fadeIn" id="deposit-form" style={{position:'relative'}}>
                                    {
                                        this.props.depositCount === 0 ?
                                        <div className="stopBetOverlay" style={{
                                            top: 0,
                                            left: 0,
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(18, 36, 47, 0.95)',
                                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                            zIndex: 1,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection:'column'
                                            }}>
                                            <div className="row1" style={{
                                                marginBottom:'10px'
                                            }}>
                                                <img src="/assets/img/icons/caution-icon.svg"/>
                                            </div>
                                            <div className="row2" style={{
                                                fontWeight: 700,
                                                fontSize: '14px',
                                                textAlign: 'center',
                                                fontSize: '20px',
                                                lineHeight: '30px',
                                                marginTop: '15px',
                                                textTransform: 'uppercase',
                                                paddingLeft: '140px',
                                                paddingRight: '140px'
                                            }}>
                                                <p className="mb-4">{t('please make a deposit first to enable your withdrawal')}</p>
                                                <a className="btn btn-success py-3" style={{fontSize: '16px'}} href="/myaccount/deposit">{t('deposit now')}</a>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {/* {
                                        this.state.isSuspended ?

                                            <div className="stopBetOverlay" style={{
                                                top: 0,
                                                left: 0,
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(18, 36, 47, 0.95)',
                                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                                zIndex: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                flexDirection:'column'
                                            }}>
                                            <div className="row1" style={{
                                                marginBottom:'10px'
                                            }}>
                                            <img src="/assets/img/icons/caution-icon.svg"/>
                                            </div>
                                            <div className="row2" style={{
                                                fontWeight: 700,
                                                fontSize: '14px',
                                                textAlign: 'center',
                                                fontSize: '20px',
                                                lineHeight: '30px',
                                                marginTop: '15px'
                                            }}><p>{t('please contact cs')}</p>
                                            </div>
                                        </div>

                                        : null
                                    } */}
                                    <div class="accountInfo mb-5">
                                        <div class="form-group row">
                                            <div class="col-md-12 ">
                                                <label class="col-form-label">{t("amount")}</label>
                                            </div>
                                            <div class="col-md-12 txtbox-min-max">
                                                <div class="form-group mb-4" style={{overflow: "initial"}}>
                                                    <NumberFormat
                                                        className={classnames("form-control ")}
                                                        displayType={"input"}
                                                        thousandSeparator={true}
                                                        decimalSeparator={false}
                                                        onChange={this.onChange}
                                                        onClick={this.onClick}
                                                        decimalScale={0}
                                                        type="text"
                                                        id="amount"
                                                        name="amount"
                                                        placeholder="0"
                                                        value={this.state.amount}
                                                        required
                                                    />
                                                    <div class="min-max-amount" style={{marginRight:'15px'}}>
                                                        {t('minimum')}/{t('maximum')} {displayAmount(this.state.minAmount)}/{displayAmount(this.state.maxAmount)}
                                                    </div>
                                                    <div className={classnames("invalid-tooltip",
                                                        {"d-block":  (this.state.insufficient) || (this.state.maxError) || (this.state.minError)    }
                                                    )}>
                                                        {!this.state.validated && error && errors.amount ? t(errors.amount, { balance: displayAmount(errors.balance), withdrawAmount: displayAmount(errors.withdrawAmount)}) : ' '}
                                                        {/* {errors && errors.transaction ? t(errors.transaction)  : ' '} */}
                                                        <div className={classnames({"d-none": !this.state.validated})}>
                                                            {this.state.amount === '' ? t("this field is required") : ' '}
                                                        </div>
                                                        <div className={classnames({"d-none": !this.state.insufficient})}>
                                                            {this.state.insufficient === true ? t("player has no fund") : ' '}
                                                        </div>

                                                        {/* <div className={classnames({"d-none": !this.state.wagering})}>
                                                            {this.state.wagering === true ? t("you have unfinished wagering requirements") : ' '}
                                                        </div> */}

                                                        {
                                                            <div className={classnames({"d-none": !this.state.maxError})}>
                                                            {this.state.maxError === true ? t("the amount must not more than") +' '+ displayAmount(this.state.maxAmount) + '.' : ' '}
                                                            </div>
                                                        }
                                                        <div className={classnames({"d-none": !this.state.minError})}>
                                                            {this.state.minError === true ? t("the amount must be at least") +' '+ displayAmount(this.state.minAmount) + '.' : ' '}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row m-0">
                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(10000))}>{language === "ko-KR" ? "1만" : "10K"}</button>
                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(50000))}>{language === "ko-KR" ? "5만" : "50K"}</button>
                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(100000))}>{language === "ko-KR" ? "10만" : "100K"}</button>
                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(500000))}>{language === "ko-KR" ? "50만" : "500K"}</button>
                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(1000000)) }>{language === "ko-KR" ? "100만" : "1M"}</button>
                                                    <button className="btn btn-dark col" type="button" onClick={() => {this.resetAmount()}}>{t('reset')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.bankName ?
                                            <React.Fragment>
                                                <div class="form-group row">
                                                    <div class="col-md-12 ">
                                                        <label class="col-form-label">{t("amount received")}</label>
                                                    </div>
                                                    <div class="col-md-12 ">
                                                        <input type="text" class="form-control accountNameDisabled" placeholder={displayAmount(received)} disabled/>
                                                        <div className={classnames("invalid-tooltip",
                                                                {"d-block": this.state.minReceivedError }
                                                            )}>
                                                                <div className={classnames({"d-none": !this.state.minReceivedError})}>
                                                                    {this.state.minReceivedError === true ? t("the amount must be at least") +' '+ displayAmount(this.state.minReceivedAmount) + '.' : ' '}
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div class="bank-details">
                                                    <div class="form-group row">
                                                        <div class="col-md-12 ">
                                                            <label class="col-form-label">{t("bank name")}</label>
                                                        </div>
                                                        <div class="col-md-12 ">
                                                            <input type="text" class="form-control accountNameDisabled" value={this.state.bankName}  disabled/>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12 ">
                                                            <label class="col-form-label">{t("account name")}</label>
                                                        </div>
                                                        <div class="col-md-12 ">
                                                            <input type="text" class="form-control accountNameDisabled" value={this.state.bankAccountName}  disabled/>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row mb-0">
                                                        <div class="col-md-12 ">
                                                            <label class="col-form-label">{t("account number")}</label>
                                                        </div>
                                                        <div class="col-md-12 ">
                                                            <input type="text" class="form-control accountNameDisabled" value={accountNumber} disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="action-btn-modal text-center">
                                                    <button class="btn btn-success small-caps" type="button" onClick={this.showConfirm} >{t('submit')}</button>
                                                </div>

                                                <ConfirmWithdrawal
                                                    show={this.state.showConfirm}
                                                    handleClose={this.closeConfirm}
                                                    bankAccountName={this.state.bankAccountName}
                                                    bankName={this.state.bankName}
                                                    accountNumber={accountNumber}
                                                    amountReceived={this.state.received}
                                                    onSubmit={this.onSubmit}
                                                    success={this.state.success}
                                                    errors={this.state.withdrawalError}
                                                    propsSuccess={this.props.success}
                                                    submitDisabled={this.state.submitDisabled}
                                                    isBlacklisted={this.state.withdrawalBlacklistError}
                                                    isSuspended={this.state.withdrawalSuspensionError}
                                                    isClosedAccount={this.state.withdrawalClosedAccountError}
                                                    errorStatusCode={this.state.errorStatusCode}
                                                />
                                            </React.Fragment>
                                            :
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div
                                                        className="alert alert-danger"
                                                        role="alert"
                                                    >
                                                        {t("no bank account yet")}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        
                                    </div>
                                </div>
                                 {/* : null} */}
                            
                            <Provider select={this.selectModalProvider}/>
                        </div>

                        </form>

                        {
                            this.state.providerID > 0 ?
                            <Fund
                                show={this.state.showFund}
                                handleClose={this.handleClose}
                                id={this.state.providerID}
                            /> : ""
                        }

                        <All
                            show={this.state.showAll}
                            handleClose={this.handleCloseAll}
                            total={this.state.total}
                            success={this.state.successTransfer}
                        />

                        <Unable
                            show={this.state.showUnable}
                            handleClose={this.handleCloseUnable}
                        />
                </div>
            </div>
        );
    }
}

Withdrawal.propTypes = {
    postWithdrawal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    errors: state.payment.withdrawErrors,
    success: state.payment.withdrawResult,
    bankAccount: state.payment.transaction,
    pendingWithdrawal: state.payment.pendingWithdrawal,
    wallet: state.auth.wallet,
    player: state.auth.wallet.player,
    provider: state.payment.provider,
    walletz: state.player.wallet,
    bonuses: state.bonuses,
    successTransfer: state.payment.transferResult,
    providers: state.auth.providers,
    provz: state.casino.providers,
    providersz: state.providers.providers,
    productProviders: state.products.result,
    depositCount: state.payment.depositCount
});

export default connect(
    mapStateToProps,
    {
        postWithdrawal,
        getBankAccount,
        reloadWallet,
        resetWithdrawal,
        updateMainWallet,
        selectModalProvider,
        checkBonus,
        resetBonus,
        resetTransfer,
        getProviders,
        getWallet,
        getProductProviders,
        clearWallet,
        resetActiveCount,
        getActiveProviders,
        getPlayer,
        resetWithdrawalError,
        getDepositCount
    }
)(withRouter(withTranslation()(Withdrawal)));
