import {
    SET_LANDING_PAGE_WITHDRAWALS,
    SET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    SET_LANDING_PAGE_WINNER_LIST,
    SET_LANDING_PAGE_WINNER_LIST_SUMMARY,
    UPDATE_LANDING_PAGE_WITHDRAWALS,
    SET_LANDING_PAGE_WINNER_LIST_30_DAYS,
    SET_LANDING_PAGE_POPUP_NOTICE,
    GET_LANDING_PAGE_CAROUSEL,
    SET_LANDING_PAGE_CAROUSEL,
    GET_LANDING_PAGE_CAROUSEL_SETTING,
    SET_LANDING_PAGE_CAROUSEL_SETTING
} from "../actions/types"; 
 
const initialState = { 
  withdrawals: [],
  withdrawalSummary: [],
  winnerList: [],
  winnerListSummary: [],
  winnerList30Days: [],
  popupNotice: [],
  carousel: {
    listing: {
      data: [],
      error: false,
      loading: true
    },
    settings: {
      data: {},
      error: false,
      loading: true
    }
  }
};
 
export default function(state = initialState, action) { 
     
    switch (action.type) { 
        case SET_LANDING_PAGE_WITHDRAWALS: 
          return { 
              ...state, 
              withdrawals: action.payload, 
            }; 
        case SET_LANDING_PAGE_WITHDRAWAL_SUMMARY: 
          return { 
              ...state, 
              withdrawalSummary: action.payload, 
            };
        case SET_LANDING_PAGE_WINNER_LIST: 
          return { 
              ...state, 
              winnerList: action.payload, 
          }; 
        case SET_LANDING_PAGE_WINNER_LIST_SUMMARY: 
          return { 
              ...state, 
              winnerListSummary: action.payload, 
          };
        case SET_LANDING_PAGE_WINNER_LIST_30_DAYS: 
        return { 
            ...state, 
            winnerList30Days: action.payload, 
        };
        case SET_LANDING_PAGE_POPUP_NOTICE: 
          return { 
              ...state, 
              popupNotice: action.payload, 
          };
        case SET_LANDING_PAGE_CAROUSEL:
          return { 
              ...state, 
              carousel: {
                ...state.carousel,
                listing: {
                  data: action.payload.data,
                  loading: action.payload.loading,
                  error: action.payload.error
                }
              }
          };
        case SET_LANDING_PAGE_CAROUSEL_SETTING:
          return { 
            ...state, 
            carousel: {
              ...state.carousel,
              settings: {
                data: action.payload.data,
                loading: action.payload.loading,
                error: action.payload.error
              }
            }
        };
        default: 
          return state; 
    }
}
