import {
    BONUS_CHECK,
    BONUS_ERRORS,
    BONUS_SET_ONGOING,
    RESET_BONUS_ERRORS,
    GET_BONUS,
    GET_BONUS_HISTORY,
    SET_BONUS_PAGE,
    SET_BONUS_HISTORY_PAGE,
    SET_BONUS_FILTER,
    RESET_BONUS_FILTER,
    REQUEST_POST_BONUS_REDEEM,
    REQUEST_POST_SUBSCRIPTION_CANCEL
} from "./types";

export const getBonus = payload => {
    return {
        type: GET_BONUS,
        provider: payload,
    };
}

export const getBonusHistory = payload => {
    return {
        type: GET_BONUS_HISTORY,
        provider: payload,
    };
}

export const resetBonus = () => {
    return {
        type: RESET_BONUS_ERRORS
    };
}

export const resetOngoingPromotion = () => {
    return {
        type: BONUS_SET_ONGOING,
        payload: {}
    };
}

export const cancelSubscription = (payload) => {
    return {
        type: REQUEST_POST_SUBSCRIPTION_CANCEL,
        payload
    };
}

export const checkBonus = (payload) => {
    return {
        type: BONUS_CHECK,
        payload
    };
}

export const postBonus = (payload) => {
    return {
        type: REQUEST_POST_BONUS_REDEEM,
        payload
    };
}

export const setPage = (payload) => {
    return {
        type: SET_BONUS_PAGE,
        payload
    };
}

export const setHistoryPage = (payload) => {
    return {
        type: SET_BONUS_HISTORY_PAGE,
        payload
    };
}

export const setFilter = (payload) => {
    return {
        type: SET_BONUS_FILTER,
        payload
    };
}

export const resetFilter = (payload) => {
    return {
        type: RESET_BONUS_FILTER,
    };
}
