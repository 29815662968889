import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    BANKS_ERRORS,
    SESSION_ERROR,
    DEPOSIT_ERRORS,
    GET_ERRORS,
    MESSAGES_ERRORS,
    PLAYER_ERRORS,
    PROMOTION_ERRORS,
    PROVIDERS_ERRORS,
    PRODUCT_PROVIDER_ERRORS,
    RESEND_ERRORS,
    TRANSFER_ERRORS,
    TRANSFER_ERRORS_WITHOUT_SUCCESS,
    WALLET_ERRORS,
    P2P_GAME_ERRORS,
    WITHDRAWAL_ERRORS,
    CASINO_ERRORS,
    CASINO_CATEGORY_ERRORS,
    FAVOURITE_ERRORS,
    SPORTS_ERRORS
} from "../actions/types";

function* errorsWatcher() {
    // yield takeLatest(DEPOSIT_ERRORS, handleError)
    // yield takeLatest(PROMOTION_ERRORS, handleError)
    // yield takeLatest(BANKS_ERRORS, handleError)
    // yield takeLatest(TRANSFER_ERRORS, handleError)
    // yield takeLatest(WITHDRAWAL_ERRORS, handleError)
    // yield takeLatest(RESEND_ERRORS, handleError)
    // yield takeLatest(PROVIDERS_ERRORS, handleError)
    // yield takeLatest(PROMOTION_ERRORS, handleError)
    // yield takeLatest(PRODUCT_PROVIDER_ERRORS, handleError)
    // yield takeLatest(MESSAGES_ERRORS, handleError)
    // yield takeLatest(GET_ERRORS, handleError)
    // yield takeLatest(PLAYER_ERRORS, handleError)
    // yield takeLatest(WALLET_ERRORS, handleError)
    // yield takeLatest(P2P_GAME_ERRORS, handleError)
    // yield takeLatest(CASINO_ERRORS, handleError)
    // yield takeLatest(CASINO_CATEGORY_ERRORS, handleError)
    // yield takeLatest(FAVOURITE_ERRORS, handleError)
    // yield takeLatest(TRANSFER_ERRORS_WITHOUT_SUCCESS, handleError)
    // yield takeLatest(SPORTS_ERRORS, handleError)
}

function* handleError(action){
    if(action.payload.status === 403){
        yield put({
            type: SESSION_ERROR,
            payload: { history: { push: () => {} }}
        });
    }
}


export { errorsWatcher };
