import {
  GET_BRAND_CURRENCY,
  GET_COUNTRIES,
  GET_PARTNER,
  GET_CAN_REGISTER,
  SET_CURRENT_USER,
  REQUEST_POST_REGISTER,
  REQUEST_POST_VERIFY,
  REGISTER_SET_PAGE,
  REQUEST_POST_VERIFY_AFFILIATE,
  REQUEST_POST_REGISTER_AFFILIATE,
  REGISTER_RESET_ERROR,
  GET_SMS_SETTING,
  SET_SMS_SETTING
} from "./types";

export const resetPage = () => {
    return {
        type: REGISTER_SET_PAGE,
        payload: 1
    };
}

export const getCurrencies = () => {
  return {
    type: GET_BRAND_CURRENCY
  };
};

export const getCountries = () => {
  return {
    type: GET_COUNTRIES
  };
};

export const getPartner = domain => {
   return {
     type: GET_PARTNER,
     payload: domain
   };
 };

 export const checkCanRegister = domain => {
   return {
     type: GET_CAN_REGISTER,
     payload: domain
   };
 };

export const postRegister = register => {
  return {
    type: REQUEST_POST_REGISTER,
    payload: register
  };
};

export const postRegisterAffiliate = register => {
  return {
    type: REQUEST_POST_REGISTER_AFFILIATE,
    payload: register
  };
};

export const postVerify = player => {
  return {
    type: REQUEST_POST_VERIFY,
    payload: player
  };
};

export const postVerifyAffiliate = player => {
  return {
    type: REQUEST_POST_VERIFY_AFFILIATE,
    payload: player
  };
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};


export const resetError = () => {
  return {
    type: REGISTER_RESET_ERROR
  };
};

export const getSMSSetting = () => ({
  type: GET_SMS_SETTING
});

export const setSMSSetting = (payload) => ({
  type: SET_SMS_SETTING,
  payload
});
