import { takeLatest, put, call } from 'redux-saga/effects';

import {
    postContact
} from "../api/partner"

import {
    REQUEST_POST_PARTNER_CONTACT,
    PARTNER_CONTACT_SUCCESS,
    PARTNER_CONTACT_ERRORS
} from "../actions/types";

function* partnerWatcher() {
    yield takeLatest(REQUEST_POST_PARTNER_CONTACT, fetchContact)
}

function* fetchContact(action) {
    try {
        const result = yield call(postContact, action.payload);
        localStorage.setItem("players", JSON.stringify(action.payload));
        yield put({
            type: PARTNER_CONTACT_SUCCESS,
            message: result.data.message,
            status: result.data.status
        });
    } catch (error) {

        yield put({
            type: PARTNER_CONTACT_ERRORS,
            errors: error.response.data.errors,
            message: error.response.data.message,
            status: error.response.data.status
        });
    }
}

export { partnerWatcher };