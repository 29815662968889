import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Register1 from "./Register1";
import Register2 from "./Register2";
import Register3 from "./Register3";
import { resetPage } from "../../../actions/registration";


class PartnerRegister extends Component {
    components = {
        1: Register1,
        2: Register2,
        3: Register3
    }

    componentDidMount(){
        this.props.resetPage();
    }

    render() {
        const Register = this.components[this.props.currentPage];
        return (
            <Register />
        );
    }
}

const mapStateToProps = state => ({
    currentPage: state.register.currentPage,
});

export default connect(
    mapStateToProps,
    {resetPage}
)(withRouter(PartnerRegister));
