import axios from "axios";
import { ROOT_URL } from "../config/api";

export { getBanners };

function getBanners() {
  const lang = localStorage.getItem("i18nextLng") || "en-US";
  let lang_id = 1;
  switch(lang) {
    case "en-US":
      lang_id = 1;
      break;
    case "ko-KR":
      lang_id = 3;
      break;
    default:
      break;
  }
  
  return axios.get(`${ROOT_URL}/banners?category=sliders&language=${lang_id}`);
}
