import {
  SET_CURRENT_USER,
  RESET_CURRENT_USER,
  LOGIN_REQUEST,
  GET_LOGIN_SUCCESS,
  GET_ERRORS,
  SET_PLAYER,
  UPDATE_MAIN_WALLET,
  SET_UNREAD_MESSAGE,
  SET_UNREAD_NOTICE,
  UPDATE_UNREAD_MESSAGE_FALSE,
  GET_PLAYER
} from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  isAuthenticated: false,
  unreadMessage: {unread_message_replies_count:0,unread_message_ids:[]},
  unreadNotice: {unread_notice_count:0},
  isCallReadMessage: false,
  user: {},
  wallet: {
      wallet: 0,
      providers: [{}],
      player: {
          currency: {
              Abbreviation: "",
              CurrencyID: 2
          },
          address: {
              country: {
                  Name: ""
              }
          }
      },
      bonus: { amount: 0 }
  },
  player: {},
  errors: {},
  pending: false,
  providers: [
    { id: 2 },
    { id: 19 },
    { id: 20 }
  ],
  loadingWallet: false
};

export default function(state = initialState, action) {
  switch (action.type) {
      case RESET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        errors: {},
        pending: false
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        errors: {},
        pending: false
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
        errors: {}
      };
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        pending: false
      };
    case GET_LOGIN_SUCCESS:
      return {
        ...state,
        player: action.payload,
        errors: false
      };
    case GET_PLAYER:
      return {
        ...state,
        loadingWallet: true,
      };
    case SET_PLAYER:
      return {
        ...state,
        wallet: action.payload,
        errors: {},
        pending: false,
        loadingWallet: false,
      };
    case UPDATE_MAIN_WALLET:
      return {
        ...state,
        wallet: {
          ...state.wallet,
          wallet: action.payload
        }
      };
    case SET_UNREAD_MESSAGE:
      return {
        ...state,
        unreadMessage: action.payload,
        isCallReadMessage: true
      };
    case SET_UNREAD_NOTICE:
      return {
        ...state,
        unreadNotice: action.payload,
      };
    case UPDATE_UNREAD_MESSAGE_FALSE:
      return {
        ...state,
        isCallReadMessage: false
      };
    default:
      return state;
  }
}
