import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook"; 
import classname from "classnames"; 
 
import Modal from "react-bootstrap/Modal"; 
import NumberFormat from 'react-number-format'; 
 
import { displayAmount, realAmount, thousandAmount } from "../../../../../helpers"; 
import { postTransfer, successModal,postTransferWallet } from "../../../../../actions/payment";
import { useTranslation } from 'react-i18next'; 
import { Loader } from "../../../../common";
import Success from "./Success"; 
import classnames from "classnames";
import mainWallet from "../../../../../assets/img/logo/wallet/main-wallet.png";
 
const Fund = ({show, handleClose, id}) => { 
 
    const wallet = useSelector(state => state.player.wallet);
    const [success, setSuccess] = useState(false);
    const [min, setMin] = useState(1);
    const [disbled, setDisbled] = useState(false);
    const [displayTo, setDisplayTo] = useState(0);
    const [from, setFrom] = useState(0);
    const [max, setMax] = useState(0);
    const [subscription, setSubscription] = useState('');
    const [isZero, setIsZero] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch(); 
    const [errorMax, setErrorMax]  = useState(false);
    const provider = useSelector(state => state.payment.provider);


    useEffect(() => {
        const amount = wallet[provider.ID] || { balance: 0 };
        const balance = realAmount(Math.trunc(amount.balance.toString().replace(/,/g,"")));
        let from = balance - min <= 0 ? 0 :  balance - min;
        setFrom( min > balance ? balance : from);
        setMax(balance);
        setDisplayTo(min)
        setErrorMax(balance < min)
        setIsZero(balance <= 0)
    }); 

    const onChange = useCallback((e)=>{
        const value = e.target.value;
        const amount  = realAmount(value)
        const min = amount === 0 ? 1 : amount;
        const disabledBtn = min <= 0 ? true : false;
        setMin(min)
        setErrorMax(false)
        setDisbled(false)
        setDisbled(disabledBtn)
        
        let balance = wallet[provider.ID] || { balance: 0 };
            balance = realAmount(Math.trunc(balance.balance.toString().replace(/,/g,"")));

        if (balance !== 0) {
            if (balance < amount) {
                setErrorMax(true)
                setDisbled(true)
            }
        }
    }, [min]); 
 
    const onClick = useCallback(() => { 
        if (!errorMax) {
            dispatch(postTransferWallet({amount: min, provider: id, subscription}))
            handleClose();
        } 
    }, 
        [min] 
    ); 
 
    const handleSuccess = useCallback(() => { 
        setSuccess(false);
    });

    const onClose = useCallback(() => { 
        setSuccess(false);
        setErrorMax(false);
        setDisbled(false);
        setMin(1);
        handleClose()
    });


    return ( 
        <> 
        <div 
            className={classname("modal fade show",{ 
            })} 
            style={{ 
                display: show ? "block" : "none" 
            }} 
            id="InsufficientModal" tabIndex={-1} role="dialog" aria-labelledby="InsufficientModal" aria-hidden="true"> 
            <Modal 
                centered 
                show={show} 
                onHide={() => onClose()} > 
                <Modal.Header> 
                    <h5 className="modal-title text-center" id="LoginModal">{t('transfer funds')}</h5> 
                    <button 
                        type="button" 
                        className="close" 
                        onClick={ () => onClose()} 
                    > 
                        <span aria-hidden="true">&times;</span> 
                    </button> 
                </Modal.Header> 
                <Modal.Body className="modal-body text-center row transferAmount pt-4 pb-4"> 
                   <div class="p-3"> 
                        <div class="row"> 
                             

                            <div class="col-md-5"> 
                                <img src={provider.Logo} class="img-fluid walletLogo"/> 
                                <br/> 
                                <span className="transWithAmnt">KRW </span> 
                                <span id="transferSource" 
                                    className="currency transWithAmnt">{thousandAmount(from)}</span> 
                            </div>
 
                            <div class="col-md-2"> 
                                <i class="fas fa-arrow-right fa-2x"></i> 
                            </div> 
                            
                            <div class="col-md-5">
                                <img src={mainWallet} class="img-fluid withImgModal"/>
                                <h5 className="withTransModal">{t('main wallet')}</h5> 
                                <span className="transWithAmnt" >KRW </span><span id="transferSource" className="currency transWithAmnt">{thousandAmount(displayTo)}</span> 
                            </div>
                             
 
                            <div class="col-10 offset-1 mt-3 mb-3"> 
                                <div class="form-group text-left"> 
                                    <div className="col-12 ">
                                       <label >{t('amount')}:<span class="red"> *</span></label> 
                                    </div>
                                    <div className="col-12 ">
                                        <NumberFormat
                                            name="amount" 
                                            value={min}
                                            onChange={onChange}
                                            allowNegative={false} 
                                            allowLeadingZeros={false} 
                                            className="form-control input-lg" 
                                            thousandSeparator={true} 
                                            decimalSeparator={false} 
                                            required
                                            min={1}
                                            id="transAmount"
                                        />
                                        <div className={classnames("invalid-tooltip",{"d-block":errorMax})}>
                                            <div className={classnames({
                                                        "d-none": ! errorMax
                                                    })}>
                                                    {t("insufficient transfer amount.") }
                                            </div>
                                    
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="col-md-10 offset-md-1 mb-5 text-center">
                                <div class="row">
                                    <div class="col-2"><span class="colgray">{t('minimum')}</span></div>
                                    <div class="col-8">
                                        <input type="range"
                                            value={min}
                                            min={1}
                                            max={max}
                                            defaultValue={min}
                                            step={1}
                                            className="rangeSlider"
                                            id="myRange"
                                            onChange={onChange}
                                        />
                                    </div>
                                    <div class="col-2"><span class="colgray">{t('maximum')}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 offset-md-3 text-center">
                            <div class="row">
                                <div class="col-md-6">
                                    <a data-dismiss="modal" class="btn transCancel" onClick={ () => onClose()}> {t('cancel')}</a>
                                </div>
                                <div class="col-md-6">
                                   <button data-toggle="modal"
                                    className={classname("btn btn-success")}
                                    style={{cursor: "pointer"}}
                                    data-dismiss="modal"
                                    disabled={disbled || isZero}
                                    onClick={() => {
                                        onClick()
                                        setSuccess(true)
                                    }}
                                    id="quickIngameTransfer">{t('transfer')}
                                </button>
                                </div>
                            </div>
                        </div>            
                    </div> 
 
                </Modal.Body> 
            </Modal> 
        </div> 
            <Success 
                show={success} 
                handleClose={onClose}
                amount={min} 
            />
        </>
    );
};

export default Fund;



