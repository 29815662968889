import React from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";

const Tools = () => {
    const {t} = useTranslation();
    return (
    <section class="animation-element marketing-tools-section padding-top" id="marketingtools">
      <div class="container partners-commission-container">
        <div class="row align-items-center">
          <p class="title">{t('marketing tools')}</p>
          {/* <div class="toolbox"> */}

          <div class="container">
            <div class="row align-items-start justify-content-center">
              
              <div class="col">
                <div class="box-container highlighted">
                  <div class="icon url-icon"></div>
                    <p>{ReactHtmlParser(t('url tracking'))}</p>
                </div>
              </div>
            
              <div class="col">
                <div class="box-container highlighted">
                <div class="icon commission-icon"></div>
                    <p>{ReactHtmlParser(t('commission report'))}</p>
                </div>
              </div>
            
              <div class="col">
                <div class="box-container highlighted">
                <div class="icon banner-icon"></div>
                    <p>{ReactHtmlParser(t('banner management'))}</p>
                </div>
              </div>

              <div class="col">
                <div class="box-container highlighted">
                <div class="icon support-icon"></div>
                    <p>{ReactHtmlParser(t('24/7 quick support'))}</p>
                </div>
              </div>
            </div>
          </div>      

            {/* <div class="container-box">
              <div class="row align-items-center justify-content-center">

                <div class="col-6">
                  <div class="link-box">
                    <div class="icon url-icon"></div>
                    <p>{ReactHtmlParser(t('url tracking'))}</p>
                  </div>
                </div>

                <div class="col-6">
                  <div class="link-box">
                    <div class="icon commission-icon"></div>
                    <p>{ReactHtmlParser(t('commission report'))}</p>
                  </div>
                </div>

                <div class="col-6">
                  <div class="link-box">
                    <div class="icon banner-icon"></div>
                    <p>{ReactHtmlParser(t('banner management'))}</p>
                  </div>
                </div>

                <div class="col-6">
                  <div class="link-box">
                    <div class="icon support-icon"></div>
                    <p>{ReactHtmlParser(t('24/7 quick support'))}</p>
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </section>
    );
};

export default Tools;
