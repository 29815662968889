import React from 'react';
import { withRouter, Link } from 'react-router-dom';

const HelpLink = ({location, link, label, innerText, className, innerClassName = '', anchorTagOnly = false}) => {
    return (
        <>
            {location.pathname === '/' || anchorTagOnly ?
            <a className={className} href={link}>{label}</a> :
            <Link className={className} to={link}>{label}</Link>}
            <div class={`content row align-items-center justify-content-center ${innerClassName}`}>{innerText}</div>
        </>
    );
}

export default withRouter(HelpLink);
