import React, { Component } from "react";
import { Link } from "react-router-dom";

class NotFound extends Component {
    render() {
        return (
            <div className="error_page_1" style={{ position: "fixed", top: 0, left: 0, zIndex:"2000000", width: "100%"}}>
        <div className="error_splash row">
          <div className="logo col-md-6 my-auto"><img src="/assets/img/15bet_logo.svg" alt="15bet" /></div>
          <div className="col-md-6 my-auto">
            <p className="code">Code: <b>404</b></p>
            <h2>Oh no!</h2>
            <h4>There was an error with your request</h4>
            <p>If you believe you receiving this message in error,<br />Please contact us for assistance</p>
          
            <Link to="/" className="btn btn-primary">Back to Homepage <i className="fas fa-arrow-right" /></Link>
          </div>
        </div>
      </div>
        );
    }
}



export default NotFound;

