import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Error = ({ errors, id }) => {
    const { t } = useTranslation();

    let style;

    switch (id) {
        case "password":
        case "currentPassword":
            style = { marginTop: "2px" };
            break;
        case "accountNumber":
            style = { marginTop: "0" };
            break;
        case "bank": 
            style = { marginTop: "4px" };
            break;
        default:
            style = { marginTop: "0" };
    }

    // on change password error
    // edge case dont display error for current password
    if (
        id === "currentPassword" &&
        (errors["password"] && !errors["password"].includes("match."))
    ) {
        return <></>;
    }

    return (
        <div
            className={classNames("invalid-tooltip", {
                "d-block":
                    errors.hasOwnProperty("currentPassword") &&
                    id === "currentPassword"
            })}
            style={style}
        >
            {errors[id]
                ? t(errors[id][0].toLowerCase()) === 'the account name has already been taken.' ? t('duplicate bank account name')  : t(errors[id][0].toLowerCase())
                : t("this field is required")}
        </div>
    );
};
export default Error;
