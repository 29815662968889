import { takeLatest, select, put, call } from "redux-saga/effects";
import { getTransactions } from "../api/transaction";
import {
    GET_TRANSACTION_HISTORY,
    SET_TRANSACTION_HISTORY,
    SET_TRANSACTION_DATE,
    SET_TRANSACTION_TYPE
} from "../actions/types";

const getItems = state => state.transactionHistory;

export { transactionHistoryWatcher };

function* transactionHistoryWatcher() {
    yield takeLatest(GET_TRANSACTION_HISTORY, fetchTransactions);
    yield takeLatest(SET_TRANSACTION_TYPE, fetchTransactions);
    yield takeLatest(SET_TRANSACTION_DATE, fetchTransactions);
}

function* fetchTransactions(action) {
    const { selectedFilter, page } = yield select(getItems);
    const { date, type } = selectedFilter;
    try {

        const result = yield call(getTransactions, {
            date,
            transaction_type: type,
            provider: action.provider,
            page,
        });

        yield put({
            type: SET_TRANSACTION_HISTORY,
            payload: result.data
        });
    }catch(error){
        console.log("Transaction history error:", error);
    }
}
