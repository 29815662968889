import {
    P2P_SUCCESS,
    P2P_ERRORS,
    P2P_GAME_SUCCESS,
    P2P_GAME_ERRORS,
    UPDATE_P2P_GAMES,
    P2P_RECENT_ERRORS,
    P2P_RECENT_SUCCESS
} from "../actions/types";

const initialState = {
  games: [],
  providers: [],
  categories: [{}],
  result: [],
  errors: [],
  recent: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case P2P_SUCCESS:
      return {
        ...state,
        result: action.payload,
        errors: false
      };
    case P2P_GAME_ERRORS:
    case P2P_ERRORS:
      return {
        ...state,
        result: false,
        errors: action.payload
      };
    case P2P_RECENT_SUCCESS:
      return {
        ...state,
        recent: action.payload,
        errors: false
      };
    case P2P_RECENT_ERRORS:
      return {
        ...state,
        errors: action.payload
      };
    case P2P_GAME_SUCCESS:
      return {
        ...state,
        games: action.payload,
        errors: false
      };
    case UPDATE_P2P_GAMES:
      const key = action.payload.key;
            const statez = action.payload.state;
            if(statez === "recent") {
                return  {
                    ...state,
                    recent: state.recent.map((item) => {
                        if(item.ID !== key) {
                            return item
                        }
                        return {
                            ...item,
                            FavStatus: item.FavStatus === 1 ? 0 : 1
                        }
                    }),
                    games: state.games.map((item) => {
                        if(item.ID !== key) {
                            return item
                        }
                        return {
                            ...item,
                            FavStatus: item.FavStatus === 1 ? 0 : 1
                        }
                    })
                }
            } else {
                return  {
                    ...state,
                    games: state.games.map((item) => {
                        if(item.ID !== key) {
                            return item
                        }
                        return {
                            ...item,
                            FavStatus: item.FavStatus === 1 ? 0 : 1
                        }
                    })
                }
            }
    default:
      return state;
  }
}
