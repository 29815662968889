import { takeEvery, takeLatest, put, call } from 'redux-saga/effects';
import { useParams } from 'react-router-dom';
import {
    getBanners,
    getProducts,
    getPromotions,
    getContent,
    getPromotionMinDeposit,
    getDepositPromotion,
    checkDepositPromotion,
    getPromotionContentCategory
} from "../api/promotion"
import {
    GET_PROMO_BANNERS,
    SET_PROMO_BANNERS,
    GET_PRODUCTS,
    GET_PROMOTIONS,
    SET_PRODUCTS,
    SET_PROMOTIONS,
    GET_PROMO_CONTENT,
    SET_PROMO_CONTENT,
    GET_PROMO_MIN_DEPOSIT,
    SET_PROMO_MIN_DEPOSIT,
    GET_DEPOSIT_PROMOTIONS,
    SET_DEPOSIT_PROMOTIONS,
    SET_DEPOSIT_PROMOTIONS_CHECK,
    GET_DEPOSIT_PROMOTIONS_CHECK,
    GET_PROMOTION_CONTENT_CATEOGRY,
    SET_PROMOTION_CONTENT_CATEOGRY,
    SET_HOTLINK_PROMOTION
} from "../actions/types";



export { promotionWatcher };

function* promotionWatcher() {
    yield takeLatest(GET_PROMO_BANNERS, fetchBanners)
    yield takeLatest(GET_PRODUCTS, fetchProducts)
    yield takeEvery(GET_PROMOTIONS, fetchPromotions)
    yield takeLatest(GET_PROMO_CONTENT, fetchContent)
    yield takeLatest(GET_PROMO_MIN_DEPOSIT, fetchPromotionMinDeposit)
    yield takeLatest(GET_DEPOSIT_PROMOTIONS, fetchDepositPromotions)
    yield takeLatest(GET_DEPOSIT_PROMOTIONS_CHECK, fetchCheckDepositPromotions)
    yield takeLatest(GET_PROMOTION_CONTENT_CATEOGRY, fetchPromotionCategory)
}

function* fetchBanners() {
    try {
        const result = yield call(getBanners);

        yield put({
            type: SET_PROMO_BANNERS,
            payload: result.data.data
        });
    } catch (error) {
    }
}

function* fetchPromotions(action) {
    const params = {
        ...action.payload,
        featured: action.payload.featured,
        application: 1
    }
    try {
        const result = yield call(getPromotions, params);
        yield put({
            type: SET_PROMOTIONS,
            payload: result.data
        });

    } catch (error) {
    }
}

function* fetchProducts() {
    try {
        const result = yield call(getProducts);

        yield put({
            type: SET_PRODUCTS,
            payload: result.data.data
        });
    } catch (error) {
    }
}

function* fetchContent(action) {
    try {
        const result = yield call(getContent, action.id);

        yield put({
            type: SET_PROMO_CONTENT,
            payload: result.data.data
        });

    } catch (error) {
    }
}

function* fetchPromotionMinDeposit(action) {
    try {
        const result = yield call(getPromotionMinDeposit, action.payload);
        yield put({
            type: SET_PROMO_MIN_DEPOSIT,
            payload: result.data
        });

    } catch (error) {
    }
}

function* fetchDepositPromotions(action) {
    const params = {
        lang: 'en',
        currency: 2
    };
    try {
        const result = yield call(getDepositPromotion, params);

        yield put({
            type: SET_DEPOSIT_PROMOTIONS,
            payload: result.data,
            id: action.payload
        });
    } catch (error) {
    }
}


function* fetchCheckDepositPromotions() {
    try {
        const result = yield call(checkDepositPromotion);
        yield put({
            type: SET_DEPOSIT_PROMOTIONS_CHECK,
            payload: false
        });
    } catch (error) {
        yield put({
            type: SET_DEPOSIT_PROMOTIONS_CHECK,
            payload: true
        });
    }
}

function* fetchPromotionCategory() {
    try {
        const result = yield call(getPromotionContentCategory);
        yield put({
            type: SET_PROMOTION_CONTENT_CATEOGRY,
            payload: result.data
        });
    } catch (error) {
    }
}

