import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

const ga_code = process.env.REACT_APP_GA_CODE;
const tagManagerArgs = {
    gtmId: ga_code,
    // dataLayer: {
    //     userId: '001',
    //     userProject: 'project'
    // }
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
