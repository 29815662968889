import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import en from "../../../../assets/img/en.png";
import kr from "../../../../assets/img/kr.png";

const Language = () => {
    const useToggleState = () => {
        const [isOpen, setOpen] = useState(false);
        return { isOpen, toggle: () => setOpen((isOpen) => !isOpen) };
    };

    const changeLanguage = (lng = "en-US") => {
        i18n.changeLanguage(lng);
        window.location.reload();
    };

    const { i18n } = useTranslation();
    const { isOpen, toggle } = useToggleState();

    // useEffect(() => {
    //     if(localStorage.getItem('defaultLng') === '' || localStorage.getItem('defaultLng') === null) {
    //         localStorage.setItem('defaultLng', 'ko-KR');
    //         changeLanguage("ko-KR");
    //         return false;
    //     }

    //     switch (i18n.language) {
    //         case "ko":
    //             changeLanguage("ko-KR");
    //             break;
    //         case "ko-KR":
    //             break;
    //         case "en-US":
    //             break;
    //         default:
    //             changeLanguage("en-US");
    //     }
    // },[])

    return (
        <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle id="dropdownMenuLink" caret>
                {i18n.language === "en-US" ? (
                    <React.Fragment>
                        <img src={en} alt="EN" /> 
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <img src={kr} alt="KR" />
                    </React.Fragment>
                )}
            </DropdownToggle>

            <DropdownMenu>
                {i18n.language !== "en-US" ? (
                    <DropdownItem onClick={() => changeLanguage("en-US")}>
                        <img src={en} alt="EN" /> English
                    </DropdownItem>
                ) : (
                    <DropdownItem onClick={() => changeLanguage("ko-KR")}>
                        <img src={kr} alt="KR" /> 한국어
                    </DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    );
}

export default Language;
