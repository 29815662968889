import {
    CASINO_SUCCESS,
    CASINO_ERRORS,
    CASINO_GAME_SUCCESS,
    CASINO_GAME_ERRORS,
    CASINO_PROVIDER_SUCCESS,
    CASINO_PROVIDER_ERRORS,
    CASINO_CATEGORY_SUCCESS,
    CASINO_CATEGORY_ERRORS,
    CASINO_GAME_UPDATE,
    CASINO_GAME_LIST_SUCCESS,
    CASINO_GAME_LIST_ERRORS,
    REQUEST_POST_CASINO,
    CASINO_CLEAR_RESULT,
    CASINO_RESET_ERRORS,
    SET_LIVE_CASINO_SELECTED_PROVIDER
} from "../actions/types";

const initialState = {
    games: [],
    providers: [],
    categories: [{}],
    result: [],
    errors: [],
    recent: [],
    game_list: [],
    load: true,
    selectedProvider: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CASINO_RESET_ERRORS:
            return {
                ...state,
                errors: false,
            };
        case CASINO_CLEAR_RESULT:
            return {
                ...state,
                result: [],
            };
        case CASINO_SUCCESS:
            return {
                ...state,
                result: action.payload,
                errors: false,
                load: false
            };

        case CASINO_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload,
                load: false
            };  

        case REQUEST_POST_CASINO:
            return {
                ...state,
                load: true
            }

        case CASINO_GAME_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload
            };

        case CASINO_GAME_SUCCESS:
            return {
                ...state,
                games: action.payload,
                errors: false
            };

        case CASINO_PROVIDER_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload
            };

        case CASINO_PROVIDER_SUCCESS:
            return {
                ...state,
                providers: action.payload,
                errors: false
            };

        case CASINO_CATEGORY_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload
            };

        case CASINO_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                errors: false
            };

        case CASINO_GAME_UPDATE:
            const key = action.payload;
            return  {
                ...state,
                games: state.games.map((item) => {
                    if(item.ID !== key) {
                        return item
                    }
                    return {
                        ...item,
                        FavStatus: item.FavStatus === 1 ? 0 : 1
                    }
                })
            }

        case CASINO_GAME_LIST_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload
            };

        case CASINO_GAME_LIST_SUCCESS:
            return {
                ...state,
                game_list: action.payload,
                errors: false
            };
        case SET_LIVE_CASINO_SELECTED_PROVIDER:
            return {
                ...state,
                selectedProvider: action.payload
            };
        default:
            return state;
    }
}
