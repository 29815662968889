import React, { useEffect, useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Card } from 'react-bootstrap';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from 'react-accessible-accordion';
import { useTranslation } from 'react-i18next';


const data = {
    "en-US": [
    {
        id: 0,
        name: "Join Partners Program",
        content : [
            {
                question: "What is the W365 Partners Program?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "Is the W365 Group partner program free of charge?",
                answer: "The opening of the W365 Partners Program is free. It might be change upon our management decision later."
            },
            {
                question: "How do I register for the W365 Partners Program?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
            {
                question: "Can the partner be a player at the same time?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
            {
                question: "Can a partner have multiple partner accounts?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
            {
                question: "If I have multiple websites, do I need to register multiple proxy accounts?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
            {
                question: "How much partner commission can I get?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
            {
                question: "Will my negative profit be accumulated?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
            {
                question: "How do I know the status or revenue of my subscribers?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
            {
                question: "As a partner, what is my responsibility?",
                answer: "You only need to fill out the application form on the registration page on our website, and our partner manager will contact you within 48 hours. By the time you register, you are agreeing to the terms of the W365 Partners Program."
            },
        ]
    },
    {
        id: 0,
        name: "Commission Payment",
        content : [
            {
                question: "When can I get a partner commission?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "How is the commission paid?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
        ]
    },
    {
        id: 0,
        name: "Statistical Report",
        content : [
            {
                question: "How can I verify my personal account?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "What can I see on our data page?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
        ]
    },
    {
        id: 0,
        name: "Account Information",
        content : [
            {
                question: "Can I edit my account information? How can I do it?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "Can I convert my account currency?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "I do not have a partner-only admin address, what should I enter in the address bar?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "If I don't have a Telegram or Skype account, what do I need to fill out in this option?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "What do I need to do if I forget my login information?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
        ]
    },
    {
        id: 0,
        name: "Marketing",
        content : [
            {
                question: "How do I check the addresses I can use for promotion?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "Can I request a banner for my ad? How can I get it?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
            {
                question: "Can we get an exclusive offer? How to get it?",
                answer: "The W365 Partner Program is that partners who have a partnership with us introduce W365 into other players groups and then generate their own sales by betting of players who are subscribed to W365 through an introduction."
            },
        ]
    },
    ],
    'ko-KR': [
        {
            id: 0,
            name: "JoaBet Partners Program",
            content : [
                {
                    question: "대박벳 파트너제휴 프로그램은 무엇입니까?",
                    answer: "대박벳 파트너제휴 프로그램이란 본사와 제휴를 맺은 파트너가 자신이 소개할수있는 그룹에 대박벳을 소개한뒤, 소개를통해 대박벳에 가입된 플레이어의 베팅행위에서 자신의 매출을 창출할수 있는 프로그램입니다."
                },
                {
                    question: "대박벳 파트너제휴 프로그램은 무료입니까?",
                    answer: "대박벳 파트너제휴 프로그램 개설은  무료로 가능하며, 이는 차후 변경이 될수있습니다."
                },
                {
                    question: "대박벳 파트너 프로그램에 등록하려면 어떻게 해야 합니까?",
                    answer: "웹사이트의 등록 페이지에서 신청서를 작성하면 파트너 관리자가 48시간 이내에 연락을 드릴 것입니다. 등록하시게되면 대박벳 파트너 프로그램 약관에 동의하는 것으로 간주됩니다."
                },
                {
                    question: "파트너인 동시에 플레이어가 될 수 있습니까?",
                    answer: "대박벳 파트너 계정은 플레이어 계정과 별도로 운영됩니다. 배팅에 참여하려면 플레이어 계정을 별도로 가입하여야 합니다. 단, 정상적인 배팅이 아닌경우로 판단되는경우에는 파트너계정과 플레이어계정은 삭제처리됩니다."
                },
                {
                    question: "1명의 파트너가 여러 파트너계정을 가질 수 있습니까?",
                    answer: "파트너 계정이 다른 파트너 또는 다른사람에 의해 운영되는지 여부와 상관없이 1인 다계정을 허용하지 않습니다. 개인이 하나 이상의 파트너 계정을 가지고 있는 것으로 확인되면, 초과 계정을 폐쇄하고 해당 계정에 대한 수수료를 발급하지 않습니다. 자세한 내용은 규칙 및 규정을 참조하십시오"
                },
                {
                    question: "여러개의 웹 사이트가 있는 경우 여러 개의 파트너 계정을 등록해야 합니까?",
                    answer: "대박벳은 파트너가 여러 개의 계정을 가질 수 없도록 하고 있습니다. 웹사이트가 여러 개인 경우 파트너 계정을 각가 등록할 필요가 없습니다. 대박벳에서 제공하는 링크를 사용하여 여러사이트에 적용할 수 있습니다. 이 링크를 클릭하여 가입하는 모든 회원은 추천플레이어로 간주 됩니다."
                },
                {
                    question: "얼마나 많은 파트너 수수료를 받을 수 있습니까?",
                    answer: "대박벳 은 현재 귀하에게 30% ~ 50%까지 제공하고있습니다. 파트너의 마케팅능력에따라 차등지급되며, 자세한내용은 담당자와 상담을 통해 안내받으시길 바랍니다."
                },
                {
                    question: "파트너 계정에서의 손실금은 누적이 됩니까?",
                    answer: "그렇습니다. 대박벳은 일일정산 시스템을 제공하며,전일의 손실금은 다음날로 누적이됩니다. 예로 전전일 -1,000,000원, 전일 +500,000, 금일 +1,000,000의 매출이 정산되었다면, 당일 출금가능금액은 +500,000원 이 됩니다."
                },
                {
                    question: "추천플레이어의 가입현황 또는 매출은 어떻게 알 수 있습니까?",
                    answer: "대박벳은 점검시간을 제외한 하루 24시간, 연중 무휴로 관련 데이터를 제공 합니다. 해당 데이터를 확인 하려면 파트너계정의 사용자ID와 비밀번호를 사용하여 파트너 전용 관리자에 로그인 하시면 됩니다."
                },
                {
                    question: "제휴를 맺은 파트너가 해야할일은 무엇입니까?",
                    answer: "파트너로서 귀하는 웹사이트 광고, 배너, SMS/TM , 이메일 또는 기타 효과적인 수단을 통해 자신에게 제공된 대박벳 도메인을 광고하셔야 합니다."
                },
            ]
        },
        {
            id: 0,
            name: "Commission Payment",
            content : [
                {
                    question: "커미션을 언제 받을수 있습니까?",
                    answer: "대박벳의 수수료 정산 및 출금은 일일정산 시스템입니다. 매일 오전 전일의 정산금액이 적립되며, 월~금요일 AM10:00 ~ PM04:00까지 파트너수수료 출금가능금액에 한하여 출금신청이 가능합니다. 단 주말 및 담당자가 사전에 공지를통해 안내한 공휴일에는 처리가되지 않습니다. "
                },
                {
                    question: "커미션은 어떻게 받게됩니까?",
                    answer: "수수료 지불방법은 국내은행 계좌이체로만 가능합니다. 출금가능 시간대에 파트너계정에 로그인하신후 출금신청을 클릭하시고 출금가능금액 한도내의 금액을 기재한후 은행계좌정보를 입력하시고 신청하여주시면 당일내로 신청하신은행계좌로 입금처리됩니다."
                },
            ]
        },
        {
            id: 0,
            name: "Statistical Report",
            content : [
                {
                    question: "개인 계정을 확인하려면 어떻게 해야 합니까?",
                    answer: "파트너전용 관리자 주소에 사용자 ID와 암호를 입력하여 계정정보를 확인할 수 있습니다."
                },
                {
                    question: "데이터 페이지에서는 무엇을 볼 수 있습니까?",
                    answer: "대박벳 파트너제휴 시스템은 파트너계정에 대한 상세한 데이터 분석 정보를 제공 합니다. 플레이어 게임 계정에서의 정확한 금액, 베팅 금액 및 입금,출금,가입등록 건수를 쉽게 확인 할 수 있으며, 물론 매출표공유도 각각 표시 됩니다. 이러한 기능은 새로운 마케팅 캠페인을 만들 때 도움이 됩니다. "
                },
            ]
        },
    {
        id: 0,
        name: "Account Information",
        content : [
            {
                question: "내 파트너계정 정보를 수정 할 수 있습니까? 하는 방법은 어떻게 됩니까?",
                answer: "직접 계정정보를 수정하지 못할 수도 있습니다. 계정정보를 변경해야 하는 경우 평일주간시간에 파트너전용 상담 서비스센터에 문의 하십시오, 최대한 빨리 지원해 드리겠습니다."
            },
            {
                question: "계정 통화를 변경 할 수 있습니까?",
                answer: "현재는 국내 통화(KWN)만 가능합니다."
            },
            {
                question: "파트너 전용관리자 주소가 없는데, 주소 표시 줄에 무엇을 입력 해야 합니까?",
                answer: "파트너 전용관리자 주소는 대박벳과 제휴를 맺은 파트너에게만 담당자가 메신저를 통해 별도 안내를 해드리고있습니다. 주소안내는 파트너 전용콜센터로 문의하시기 바랍니다."
            },
            {
                question: "Telegram , Skype 계정이없는 경우, 이 옵션에서 무엇을 기입해야합니까?",
                answer: "채팅계정은 필수 항목입니다. 개인 채팅 계정을 가지고 있으면 편리하고, 시기 적절한 방법으로 세밀한 지원 을 받을 수 있습니다. 아래 링크를 통해 쉽게 자신의 계정을 등록 할 수 있습니다 <br/> <br/>텔레그램 : http://www.telegram.pe.kr/ <br/><br/> Skype : 스카이프 : https://login.skype.com/login"
            },
            {
                question: "파트너 계정 로그인 정보를 잊어 버린 경우 어떻게해야합니까?",
                answer: "최초등록하신 전화번호 또는 메신저를 통해 대박벳의 파트너전용 콜센터에 연락하십시오. 최대한 빨리 처리해 드리겠습니다."
            },
        ]
    },
        {
            id: 0,
            name: "Marketing",
            content : [
                {
                    question: "제가 광고에 사용할수있는 주소는 어떻게 확인할수 있나요?",
                    answer: "파트너 전용 관리자에 접속하신후 도메인리스트에서 사용가능한 도메인을 확인하십시요"
                },
                {
                    question: "광고에 사용할수있는 배너를 요청할수 있습니까? 어떻게 얻을수 있습니까?",
                    answer: "그렇습니다. 미디어 스튜디오에서 배너의 언어, 유형, 크기, 파일형식을 선택하는 요청을 할 수 있습니다. 커스텀 형식과 사이즈가 필요한 경우 파트너 전용콜센터로 문의주시기 바랍니다."
                },
                {
                    question: "독점적인 혜택을 받을 수 있습니까? 어떻게 받을 수 있습니까?",
                    answer: "그렇습니다. 다른 규칙 및 조건에 대한 제안사항이 있으면 알려 주시기 바랍니다. 양 당사자에게 적합한 협력계획을 발전시키기 위해 귀하와 협의 할 것입니다."
                },
            ]
        },
    ]
}

const Faq = () => {
    const {t, i18n} = useTranslation();
    const language = localStorage.getItem('i18nextLng') !== null ? localStorage.getItem('i18nextLng') : 'en-US';

    useEffect(() => {
        window.changePlusMinusAccordion();
    }, []);

    const handleSelect = () =>  {
        window.changePlusMinusAccordion();
    }
    
    return (
         <section class="animation-element in-view faqs-section" id="faqs">
            <div class="container">
                <div class="row align-items-center justify-content-start">
                    <div class="col col-faq-partners">
                        <p class="title pr-3">{t('faqs')}</p>
                        <div class="accordion-container">
                        <Tabs onSelect={()=>{handleSelect()}}>
                        <TabList className="nav nav-tabs mb-3">
                            {typeof data[language] !== 'undefined' ? data[language].map((data, key) => {

                                return (
                                    // <Tab className="nav-item" key={key} onSelect={handleSelect()}>
                                    <Tab className="nav-item" key={key}>
                                        <a className="nav-link">{t(String(data.name).toLowerCase())}</a>
                                    </Tab>
                                )
                            }) : null}
                        </TabList>
                        {typeof data[language] !== 'undefined' ? data[language].map((test, keyz) => {
                            return (
                                <TabPanel key={keyz}>
                                    <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    {test.content.map((content, key) => {
                                        return (
                                            <Card >
                                                <AccordionItem key={key} >
                                                    <AccordionItemHeading >
                                                        <AccordionItemButton>
                                                            <Card.Header >
                                                            <h5 className="mb-0">
                                                                <a className="btn collapsed faq-partners-a">{content.question} <i class="fa fa-plus i-faq"></i></a>
                                                            </h5>
                                                            </Card.Header>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel >
                                                        <Card.Body className="pl-faq-acc"><div dangerouslySetInnerHTML={{__html: content.answer}} /></Card.Body>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            </Card>
                                        )
                                    })}
                                    </Accordion>
                                </TabPanel>
                            )
                         }) : null}
                    </Tabs>
                            
                            

                        </div>
                    </div>
                    {/* <div class="woman">
                        <img src="assets/img/model-faq.png"/>
                    </div> */}
                    <div class="col height p-0">
                        <div>
                            <img src="assets/img/model-faq.png" />
                        </div>
                    </div>
                    
                </div>
            </div>
         </section>
    )
};

export default Faq;
