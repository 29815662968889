import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { postPlay, getGames, getProviders, getRecent }

function postPlay(params) {
    return axios.post(`${ROOT_URL}/casino/play`, params, {
        headers: {
            Authorization: `Bearer ${findToken()}`
        }
    });
}

function getGames(params){
    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/slot/games`, {
        headers: {
            Authorization
        }, params
    });
}

function getProviders(params){
    
    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/slot/games`, {
        headers: {
            Authorization
        }, params
    });
}


function getRecent(params){
    let page = "recentSlotTest";

    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/${page}`, {
        headers: {
            Authorization
        }, params
    });
}
