import {
    TOGGLE_MODAL,
    TOGGLE_MODAL_TO,
    TOGGLE_MODAL_FROM,
    CLOSE_MODAL
} from "../actions/types";

const initialState = {
    isOpen: false,
    toModalz: false,
    fromModal: false
};

export default function(state = initialState, action) {
  switch (action.type) {
        case TOGGLE_MODAL:
            return {
                ...state,
                isOpen: !state.isOpen
            };
        case TOGGLE_MODAL_TO:
          return {
              ...state,
              isOpen: !state.isOpen,
              toModalz: !state.toModalz
          };
        case TOGGLE_MODAL_FROM:
          return {
              ...state,
              isOpen: !state.isOpen,
              fromModal: !state.fromModal
          };
        case CLOSE_MODAL:
            return {
                ...state,
                isOpen: false,
                toModalz: false,
                fromModal: false
            };
        default:
            return state;
  }
}
