import {
    CASH_SUCCESS,
    CASH_ERRORS,
    SET_CASH_PAGE
} from "../actions/types";

const initialState = {
    cashs: {
        data: [],
        meta: {},
        links: {}
    },
    errors: {
    },
    success: false
};

export default function(state = initialState, action) {
  switch (action.type) {
      case CASH_SUCCESS:
          return {
              ...state,
              cashs: action.payload,
              errors: {
              }
          };
      case CASH_ERRORS:
          return {
              ...state,
              errors: action.payload
          };
      case SET_CASH_PAGE:
          return {
              ...state,
              page: action.payload
          };
      default:
          return state;
  }
}
