import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { postVerifyAffiliate, postRegisterAffiliate, resetError } from "../../../actions/registration";
import ReactCodeInput from "./ReactCodeInput";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

class Register2 extends Component {
    state = {
        smscode: "",
        page: false,
        errors: false,
        disabled: false,
        disabled2: true,
        loader: false
    };

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    componentDidMount() {
        this.props.resetError();
        var elem = document.getElementById("main-wrap");
        elem.classList.add("register-section");
    }
    
    componentWillUnmount() {
        this.props.resetError();
    }

    componentWillReceiveProps(nextProps) {
        const loaderz = nextProps.register.loader;
        this.setState({loader: loaderz});
    }

    updateSMSCode(){
        this.setState({
            smscode: localStorage.getItem("smscode")
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const players = JSON.parse(localStorage.getItem("players"));
        const smscode = localStorage.getItem("smscode");
        const brand = process.env.REACT_APP_BRAND;

        const register = {
            ...players,
            code: smscode,
            brand: brand
        };

        this.props.postVerifyAffiliate(register);
    };

    onResend = (e) => {
        e.preventDefault();
        const players = JSON.parse(localStorage.getItem("players"));
        this.props.postRegisterAffiliate(players);
        this.setState({ disabled: true });
        window.setTimeout(() => {
            this.setState({ disabled: false });
        }, 60000);
    };

    shouldComponentUpdate(nextProps, nextState) {
        try {
            if (
                nextProps.auth.isAuthenticated &&
                !this.props.auth.isAuthenticated
            ) {
                localStorage.removeItem("players");
            }
        } catch (err) {
            alert("something went wrong");
            return false;
        }
        return true;
    }

    render() {
        const { errors } = this.props;
        const { t } = this.props;

        return (
                <section className="contentWrap partner-registration-bg">
                    <div className="container">
                        <div className="row align-items-center justify-content-end">
                            <div className="forms-container">
                                <form onSubmit={this.onSubmit}>
                                    <div className="mb-3"><p className="div-title-partners2">{t('verify account')}</p></div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <p className="sub-title-partners">{t('please enter verification code partners')}</p>
                                            <p className="p-partners">{t('we sent you an access code via sms for mobile number verification')}</p>
                                        </div>
                                        <div className="col">
                                            <div className="form-group verification-box">
                                                <div className="txt-top">
                                                    <p className="p-partners">{t('enter verification code here or')}</p>
                                                    <button 
                                                        className="btn resend btn-transparent p-1"
                                                        onClick={this.onResend.bind(this)}
                                                        disabled={this.state.disabled === true}
                                                    >
                                                        {t('resend code')}
                                                    </button>
                                                </div>
                                                <div className="p-2">
                                                    { 
                                                        errors && Object.keys( errors ).length > 0 ?
                                                            <div
                                                                className="alert alert-danger"
                                                                role="alert"
                                                            >
                                                                {t("invalid verification code")}
                                                            </div>
                                                        :
                                                            // <div
                                                            //     className="alert alert-success"
                                                            //     role="alert"
                                                            // >
                                                            //     {t("verification completed")}
                                                            // </div>
                                                            <></>
                                                    }
                                                </div>
                                                <div class="row mt-1 input-box input-box-partners">
                                                    <ReactCodeInput
                                                        type="text"
                                                        name="smscode"
                                                        fields={6}
                                                        onChange={(e) => this.updateSMSCode()}
                                                        className="partners-code-input"
                                                    />
                                                </div>
                                                {/* <div
                                                    className={classNames(
                                                        "small invalid-tooltip",
                                                        {
                                                            "d-block was-validated":
                                                                errors &&
                                                                Object.keys(
                                                                    errors
                                                                ).length > 0
                                                                    ? true
                                                                    : false
                                                        }
                                                    )}
                                                    style={{
                                                        "margin-top": "-16px"
                                                    }}
                                                >
                                                    {t(
                                                        "invalid verification code"
                                                    )}
                                                </div> */}

                                               
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <p className="p-partners">{t('you are just a few moment away from joining the w365')}</p>
                                            <p className="p-partners">{t('please enter your correct verification code')}</p>
                                        </div>

                                        <button 
                                            type="submit" 
                                            name="register" 
                                            id="register" 
                                            className="mt-3 btn btn-primary btn-block btn-verify-partners"
                                            disabled={
                                                this.state.smscode.length === 6 ? 
                                                this.state.loader === true ? true 
                                                : false
                                                : true
                                            }
                                            >
                                                {t('verify')}
                                        </button>

                                        <div className="col-md-12 mt-4">
                                            <div className="text-right">
                                              <img src="/assets/img/18PlusSSSL.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
        );
    }
}

Register2.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.register.errors,
    register: state.register
});

export default connect(
    mapStateToProps,
    { postVerifyAffiliate, postRegisterAffiliate, resetError }
)(withRouter(withTranslation()(Register2)));
