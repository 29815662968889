import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { openMinigame, resetMiniGame } from '../../../actions/minigame';
import BlackListedError from '../../common/BlackListedError';
import { findToken } from '../../../config/api';

const MiniGame = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const minigameState = useSelector(state => state.minigame);
    const brandGameSetup = useSelector(state => state.page.brandGameSetup);

    const { language } = i18n;
    let lang = '';
    
    if(language !== undefined) {
        lang = language.split('-')[0];
    } else {
        lang = 'ko';
    }

    useEffect(() => {
        if(findToken()) {
            dispatch(openMinigame());
        } else {
            document.getElementById('login-btn').click();
        }
	}, []);

    useEffect(() => {
        return () => {
            dispatch(resetMiniGame());
        }
	}, []);

    useEffect(() => {
        if(brandGameSetup !== undefined && brandGameSetup.length > 0) {
            let search = brandGameSetup.find(el => el.ProductID === 11);

            if (search === undefined) {
                window.location.href = '/';
            }
        }
    }, [brandGameSetup]);

    const UnderMaintenance = () => {
        return (
            <section class="game-maintenance-section">
                <div class="container-fluid h-100">
                    <div class="error-page">
                    <div class="row align-items-center justify-content-center full-height">
                        <div class="container">
                        <div class="box-container">
                            <div class="row align-items-center">
                            <div class="col-lg-auto mr-lg-2">
                                <img class="warning" src="/assets/img/icons/maintain.svg" />
                            </div>
                            <div class="col-lg">
                                <div class="content">
                                    <p class="title">{minigameState.errors.errors.content && minigameState.errors.errors.content.length !== 0 ? minigameState.errors.errors.content[lang].Title : t('game maintenance title')}</p>
                                </div>
                                <div class="browser-section mt-lg-4 mt-md-3 p-4">
                                    <p class="content-text mb-3">
                                    {ReactHtmlParser(minigameState.errors.errors.content && minigameState.errors.errors.content.length !== 0 ? minigameState.errors.errors.content[lang].Message : t('game maintenance text'))}
                                    </p>
                                    <div class="row align-items-center justify-content-center justify-content-lg-start">
                                        <div class="col-auto pl-lg-0">
                                            <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                <div class="col-auto icon p-0">
                                                    <img src="/assets/img/w365/icons/contact-us/cs-email.svg"/>
                                                </div>
                                                <div class="col p-0">
                                                    <p>{t('cs support email inqury')}</p>
                                                    <p className="value">{t('cs support email')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                <div class="col-auto icon p-0">
                                                    <img src="/assets/img/w365/icons/contact-us/telegram.svg"/>
                                                </div>
                                                <div class="col-auto p-0">
                                                    <p>{t('telegram')}</p>
                                                    <p className="value">{t('cs-telegram')}</p>
                                                </div>
                                                <div className="d-flex tg-qr-icon-maintenance">
                                                    <img src="/assets/img/w365/icons/contact-us/tg_qr.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
       <div style={{width:'100%',minHeight:'544px'}}>
            {!minigameState.loading && minigameState.url !== null && minigameState.success ? 
                <div className="iframe-container aligned-to-header" style={{margin:0}}>
                    <iframe id="RefFrame" className="RefFrame" src={minigameState.url} style={{width:'100%'}} title="Minigame"></iframe>
                </div>
            : null}
            {!minigameState.loading && !minigameState.success && minigameState.errors.errors !== undefined && minigameState.errors.errors.undermaintenance !== undefined && minigameState.errors.errors.undermaintenance ? <UnderMaintenance /> : null}
            {!minigameState.loading && !minigameState.success && (minigameState.errors.player_blacklisted !== undefined || minigameState.errors.player_closed !== undefined) ? <div className="blacklisted blacklisted-error"><BlackListedError /></div> : null}
        </div>
    );
}

export default MiniGame;
