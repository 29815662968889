import {
    IP_ADDRESS_ERRORS,
    IP_ADDRESS_SUCCESS
} from "../actions/types";

const initialState = {
    ip: ''
};

export default function(state = initialState, action) {
    switch (action.type) {
        case IP_ADDRESS_SUCCESS:
            return {
                ip: action.payload,
            };
        case IP_ADDRESS_ERRORS:
            return {
                ip: action.payload,
            };
        default:
            return state;
    }
}