import {
  SET_INITIAL_TRANSACTION,
  GET_TRANSACTION_HISTORY,
  SET_TRANSACTION_PAGE,
  SET_TRANSACTION_TYPE,
  SET_TRANSACTION_DATE,
  SET_TRANSACTION_FILTER
} from "./types";

export const getTransactionHistory = payload => {

    return {
        type: GET_TRANSACTION_HISTORY,
        provider: payload,
        payload
    };
};

export const selectType = event => {
    let params = {
        transaction_type: event.target.value,
        type: SET_TRANSACTION_TYPE,
        payload: {
            from: null,
            to: null,
        }
    };
    return params;
};

export const selectDate = event => {
    let params = {
        date: event.target.value,
        type: SET_TRANSACTION_DATE,
        payload: {
            from: null,
            to: null,
        }
    };
    return params;
};

export const resetTransaction = () => {
    return {
        type: SET_INITIAL_TRANSACTION
    }
}

export const selectPage = page => {
    let params = {
        type: SET_TRANSACTION_PAGE,
        page
    };
    return params;
};

export const setFilter = (payload) => {

    return {
        type: SET_TRANSACTION_FILTER,
        payload
    };
}

export const resetFilter = () => {
    return {
        type: SET_INITIAL_TRANSACTION
    };
}
