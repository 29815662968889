import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import Content from "./content";
import Cash from "./content/cash";
import HistoryContent from "./content/history";

import FilterSection from "./filter";
import FilterHistorySection from "./filter/history";
import { getProductProviders } from "../../../actions/product";
import { getProviders } from "../../../actions/casino";

class Bonus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 'coupon'
        }
    }

    componentDidMount() {
        this.props.getProductProviders({})
        this.props.getProviders({ transfer: true });
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
       });
    };

    changeTab(e, tabName) {
        e.preventDefault();
        this.setState({tab: tabName});
    }

    render() {
        const { t } = this.props;

        return (
            <div class="bonusCoupon" id="Bonus-CouponCash">
                <h2 class="form-title">{t('bonus / coupon / cash')}</h2>
                <ul>
                    <li><a href="/#" className={this.state.tab === 'coupon' ? 'active' : ''} onClick={e => this.changeTab(e, 'coupon')}>{t('bonus / coupon')}</a></li>
                    <li><a href="/#" className={this.state.tab === 'cash' ? 'active' : ''} onClick={e => this.changeTab(e, 'cash')}>{t('cash')}</a></li>
                    <li><a href="/#" className={this.state.tab === 'history' ? 'active' : ''} onClick={e => this.changeTab(e, 'history')}>{t('history')}</a></li>
                </ul>
                <div class="accountInfo" style={{marginBottom:'100px'}}>
                    <div>
                        {this.state.tab === 'coupon' ?
                            <form>
                                <div class="row">
                                    <FilterSection />
                                </div>
                                <Content />
                            </form>: null}
                        {this.state.tab === 'cash' ?
                            <div class="row">
                                <div className="col-lg-12">
                                    <Cash />
                                </div>
                            </div> : null}
                        {this.state.tab === 'history' ?
                            <form>
                                <div class="row">
                                    <FilterHistorySection />
                                </div>
                                <HistoryContent />
                            </form>: null}
                    </div>
                </div>
                {/* <div class="accountInfo">
                    <div class="mb-4 mt-4">
                        <form>
                            <div class="row">
                                <FilterSection />
                            </div>

                             <Content />
                        </form>
                    </div>
                </div>

                <h2 class="formTitle mt-4 mb-3">{t('cash')}</h2>
                <div class="accountInfo">
                    <div class="mb-4 mt-4">
                        <div class="row mt-4">
                            <Cash />
                        </div>
                    </div>
                </div>

                <h2 class="formTitle mt-4 mb-3">{t('history')}</h2>
                <div class="accountInfo">
                    <div class="mb-4 mt-4">
                        <form>
                            <div class="row">
                                <FilterHistorySection />
                            </div>
                            <HistoryContent />
                        </form>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default connect(
    null,
    { getProductProviders,getProviders }
)(withRouter(withTranslation() (Bonus)));
