import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
    getCountries,
    getCurrencies,
    getPartner,
    checkCanRegister,
    postRegister,
    postVerify,
    postVerifyAffiliate,
    postRegisterAffiliate,
    fetchSMSSetting
} from "../api/registration";

import setAuthToken from "../setAuthToken";

import {
    GET_BRAND_CURRENCY,
    GET_COUNTRIES,
    GET_PARTNER,
    GET_CAN_REGISTER,
    REQUEST_POST_VERIFY,
    REQUEST_POST_VERIFY_AFFILIATE,
    REQUEST_POST_REGISTER_AFFILIATE,
    REQUEST_POST_REGISTER,
    GET_REGISTER_ERRORS,
    GET_REGISTER2_ERRORS,
    REGISTER,
    REGISTER_SET_PAGE,
    SET_BRAND_CURRENCY,
    SET_COUNTRIES,
    SET_PARTNER,
    SET_CAN_REGISTER,
    SET_CURRENT_USER,
    GET_SMS_SETTING
} from "../actions/types";

import { setSMSSetting } from '../actions/registration';

export {
    registrationWatcher
};

function* registrationWatcher() {
    yield takeLatest(GET_BRAND_CURRENCY, fetchBrandCurrency)
    yield takeLatest(GET_COUNTRIES, fetchCountries)
    yield takeLatest(GET_PARTNER, fetchPartner)
    yield takeLatest(GET_CAN_REGISTER, fetchCanRegister)
    yield takeLatest(REQUEST_POST_VERIFY_AFFILIATE, fetchPostVerifyAffiliate)
    yield takeLatest(REQUEST_POST_VERIFY, fetchPostVerify)
    yield takeLatest(REQUEST_POST_REGISTER, fetchPostRegister)
    yield takeLatest(REQUEST_POST_REGISTER_AFFILIATE, fetchPostRegisterAffiliate)
    yield takeLatest(GET_SMS_SETTING, getSMSSetting)
}

function* getSMSSetting() {
    try {
        const request = yield call(fetchSMSSetting);
        yield put(setSMSSetting(request.data));
    } catch(err) {

    }
}

function* fetchBrandCurrency() {
    try {
        const result = yield call(getCurrencies);
        yield put({
            type: SET_BRAND_CURRENCY,
            payload: result.data.currencies
        });
    } catch (error) {}
}

function* fetchCountries() {
    try {
        const result = yield call(getCountries);
        yield put({
            type: SET_COUNTRIES,
            payload: result.data
        });
    } catch (error) {}
}

function* fetchPartner(action) {
     try {
         const result = yield call(getPartner, action.payload);
         yield put({
             type: SET_PARTNER,
             payload: result.data
         });
     } catch (error) {}
 }

function* fetchCanRegister(action) {
    try {
        const result = yield call(checkCanRegister, action.payload);
        yield put({
            type: SET_CAN_REGISTER,
            payload: result.data
        });
    } catch (error) {}
}

function* fetchPostRegisterAffiliate(action) {
    try {
        const result = yield call(postRegisterAffiliate, action.payload);
        localStorage.setItem("players", JSON.stringify(action.payload));

        yield put({
            type: REGISTER,
            payload: result.data
        });
        yield put({
            type: REGISTER_SET_PAGE,
            payload: 2
        });
    } catch (error) {
        yield put({
            type: GET_REGISTER_ERRORS,
            payload: error.response.data.errors
        });
    }
}

function* fetchPostRegister(action) {
    try {
        const result = yield call(postRegister, action.payload);
        localStorage.setItem("players", JSON.stringify(action.payload));
        const registerState = yield select(state => state.register);

        yield put({
            type: REGISTER,
            payload: result.data
        });

        if(registerState.sms.player) {
            yield put({
                type: REGISTER_SET_PAGE,
                payload: 2
            });
        } else {
            const verificationParams = {
                ...action.payload,
                vip: process.env.REACT_APP_VIP
            };
            const result = yield call(postVerify, verificationParams);
            const { token } = result.data.data;
    
            localStorage.setItem("appToken", token);
    
            setAuthToken(token);
            yield put({
                type: REGISTER_SET_PAGE,
                payload: 3
            });
            yield put({
                type: SET_CURRENT_USER,
                payload: token
            });
        }
    } catch (error) {
        yield put({
            type: GET_REGISTER_ERRORS,
            payload: error.response.data.errors
        });
    }
}

function* fetchPostVerify(action) {
    try {
        const result = yield call(postVerify, action.payload);
        const { token } = result.data.data;

        localStorage.setItem("appToken", token);

        setAuthToken(token);
        yield put({
            type: REGISTER_SET_PAGE,
            payload: 3
        });
        yield put({
            type: SET_CURRENT_USER,
            payload: token
        });
    } catch (error) {
        yield put({
            type: GET_REGISTER2_ERRORS,
            payload: error.response.data.errors
        });
    }
}

function* fetchPostVerifyAffiliate(action) {
    try {
        yield call(postVerifyAffiliate, action.payload);
        yield put({
            type: REGISTER_SET_PAGE,
            payload: 3
        });
    } catch (error) {
        yield put({
            type: GET_REGISTER2_ERRORS,
            payload: error.response.data.errors
        });
    }
}
