import React, { Component } from "react";
import Sidebar from "../../layouts/Sidebar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Deposit from "../Deposit";
import Withdrawal from "../Withdrawal";
//import Transfer from "../Transfer";
import Profile from "../Profile";
import Bonus from "../Bonus";
import History from "../History";
import Messages from "../Messages";
import Notices from "../Notices";
import Rebate from "../Rebate";
import {
  resetTransaction,
} from "../../../actions/transaction";
import {
  resetTransfer,
} from "../../../actions/payment";
import Error from "../../../errors"
import ErrorToast from "./error-transfer-toast"
import { ToastContainer } from 'react-toastify';

class MyAccount extends Component {
    state = {}
    components = {
        deposit: Deposit,
        withdrawal: Withdrawal,
        notices: Notices,
        //transfer: Transfer,
        profile: Profile,
        history: History,
        messages: Messages,
        bonus: Bonus,
        //rebate: Rebate
    };

    componentDidMount(){
        this.props.resetTransfer();
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(!nextProps.location.pathname.includes('history') && nextProps.location.pathname !== ('/myaccount/transfer')){
            nextProps.resetTransaction();
        }
        return true;
    }

    render() {
        const Account = this.components[this.props.match.params.any];

        return (
            <div id="contentForm" style={{paddingTop: '0'}}>
                <Sidebar />
                <div className="container-fluid">
                    {/* <div id="myAccount" className="row">
                        <aside className="col-auto">
                            <Sidebar />
                        </aside>
                        <section className="col">
                            <Error>
                                <Account />
                            </Error>
                        </section>
                        <ErrorToast/>
                    </div> */}
                    <Account />
                </div>
            </div>
        );
    }
}

export default connect(
  null,
    {
        resetTransaction,
        resetTransfer
    }
)(withRouter(MyAccount));
