import { displayAmount, displayFormAmount, totalAmount, sanitizeAmount, realAmount, thousandAmount, realFloatAmount } from "./amount.js";
import moment from "moment";

const noTrans = (value) => {
    if(!value || value === ''){
        return '##'+ value;
    }else{
        return value;
    }
}

const gmtParams = (params) => {
    let gmt = moment().format("Z");
    params['gmt'] = gmt;
    params['operator'] =  gmt.substring(0,1);
    params['hours'] = gmt.substring(1,3);
    params['minutes'] = gmt.substring(4,6);
    return params;
}

export { displayAmount, displayFormAmount, totalAmount, sanitizeAmount, realAmount, thousandAmount, noTrans, gmtParams, realFloatAmount };
