import React, { Component } from "react"; 
import { connect } from "react-redux"; 
import { withRouter, Link } from "react-router-dom"; 
import PropTypes from "prop-types"; 
import { postVerify, postRegister, resetPage } from "../../../actions/registration"; 
import ReactCodeInput from "./ReactCodeInput"; 
import classNames from "classnames"; 
import { withTranslation } from "react-i18next"; 

 
class Register2 extends Component { 
    state = { 
        smscode: "", 
        page: false, 
        errors: false, 
        disabled: false, 
        disabled2: false 
    }; 
    
    componentDidMount(){
        var elem = document.getElementById("main-wrap");
        elem.classList.add("register-section");
    }

    componentWillUnmount = () => {
        var elem = document.getElementById("main-wrap");
        elem.classList.remove("gradient-bg");
    };
 
    onChange = (e) => { 
        this.setState({ 
            [e.target.name]: e.target.value 
        }); 
    }; 
 
    updateSMSCode() { 
        this.setState({ 
            smscode: localStorage.getItem("smscode") 
        }); 
    }
 
    onSubmit = (e) => { 
        e.preventDefault(); 
        this.setState({ disabled2: true }); 
        const players = JSON.parse(localStorage.getItem("players")); 
        const smscode = localStorage.getItem("smscode"); 
        const vip = process.env.REACT_APP_VIP; 
 
        const register = { 
            ...players, 
            code: smscode, 
            vip: vip 
        }; 
        
        window.setTimeout(() => { 
            this.setState({ disabled2: false }); 
        }, 10000); 
        this.props.postVerify(register); 
    }; 
 
    onResend = (e) => { 
        e.preventDefault(); 
        const players = JSON.parse(localStorage.getItem("players")); 
        this.props.postRegister(players); 
        this.setState({ disabled: true }); 
        window.setTimeout(() => { 
            this.setState({ disabled: false }); 
        }, 60000); 
    }; 
 
    shouldComponentUpdate(nextProps, nextState) { 
        try { 
            if ( 
                nextProps.auth.isAuthenticated && 
                !this.props.auth.isAuthenticated 
            ) { 
                localStorage.removeItem("players"); 
            } 
        } catch (err) { 
            alert("something went wrong"); 
            return false; 
        } 
        return true; 
    }

    backToRegistrationForm(e) {
        e.preventDefault();
        this.props.resetPage();
    }
 
    render() { 
        const { errors } = this.props; 
        const { t } = this.props; 

        return ( 
            <section class="contentWrap registration-bg">
                <div class="container">
                    <div class="row align-items-center justify-content-end">
                        <div class="forms-container">
                            <form onSubmit={this.onSubmit} class="needs-validation" novalidate>
                                <div class="mb-3"><p class="div-title">{t("verify account")}</p></div>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <p class="sub-title">{t("please enter verification code")}</p>
                                        <p>{t("we sent you an access code via sms for mobile number verification")}</p>
                                    </div>
                                    <div class="col">
                                        {
                                            errors && Object.keys(errors).length > 0 ?
                                            <div class="alert alert-danger" role="alert">{t("invalid verification code")}</div>
                                            : ""
                                        }   
                                        <div className={classNames("form-group verification-box", {"error": errors && Object.keys(errors).length > 0 ? true : false})}>
                                            <div class="txt-top">
                                                <p>{t("please enter verification code")}{" "}{t("or")}</p>
                                                <button 
                                                    className="btn resend btn-transparent" 
                                                    onClick={this.onResend.bind()} 
                                                    disabled={ 
                                                        this.state.disabled === 
                                                        true 
                                                    } 
                                                    style={{padding: '0 5px'}}
                                                > 
                                                    {t("resend code")} 
                                                </button> 
                                            </div>
                                            <div class="row mt-1 input-box">
                                                <ReactCodeInput 
                                                    type="text" 
                                                    name="smscode" 
                                                    fields={6} 
                                                    onChange={(e) => 
                                                        this.updateSMSCode() 
                                                    } 
                                                /> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 text-center">
                                        <p>{t("you are just a few moment away from joining the joabet sports and casino")}</p>
                                        <p>{t("please enter your correct verification code")}</p>
                                    </div>
                                    <div className="btn-container mt-3">
                                        <button type="submit" name="register" id="registerAccount" disabled={this.state.disabled2} className="mt-3 btn btn-primary btn-block mb-3">{t("verify")}</button>
                                        <a href="/register" onClick={this.backToRegistrationForm.bind(this)} className="btn btn-dark btn-block">{t("back to registration form")}</a>
                                    </div>
                                    
                                    <div class="col-md-12 mt-4">
                                        <div class="text-right">
                                        <img src="/assets/img/18PlusSSSL.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        ); 
    } 
} 
 
Register2.propTypes = { 
    auth: PropTypes.object.isRequired 
}; 
 
const mapStateToProps = (state) => ({ 
    auth: state.auth, 
    errors: state.register.errors 
}); 
 
export default connect( 
    mapStateToProps, 
    { postVerify, postRegister, resetPage } 
)(withRouter(withTranslation()(Register2)));
