import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    getCash,
} from "../api/cash"

import {
    GET_CASH,
    CASH_SUCCESS,
    CASH_ERRORS,
    SET_CASH_PAGE
} from "../actions/types";

import { cashs } from "../selectors/cash";

const brand = process.env.REACT_APP_BRAND

function* cashWatcher() {
    yield takeLatest(GET_CASH, fetchCash);
    yield takeLatest(SET_CASH_PAGE, fetchCash);
}

function* fetchCash(action) {
    const provider = action.provider ? action.provider.provider : "";
    const type = action.provider ? action.provider.type : "";
    const { selectedFilter, page } = yield select(cashs);
    try {
        const result = yield call(getCash, {
            ...page,
            ...selectedFilter,
            provider: provider,
            type: type,
            per_page: 5
        });
        yield put({
            type: CASH_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: CASH_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

export { cashWatcher };
