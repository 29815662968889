import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';

import {
    getMessages,
    getAllMessages,
    getPlayer,
    getWallet,
    postPlayer,
    postAccount,
    postPassword,
    getDepositAccount
} from "../api/player"

import {
    DELETE_USER,
    GET_PLAYER,
    SET_PLAYER,
    GET_ERRORS,
    GET_MESSAGES,
    GET_ALL_MESSAGES,
    SET_MESSAGE_DATE,
    SET_MESSAGE_TYPE,
    SET_MESSAGES_CURRENT_PAGE,
    MESSAGES_SUCCESS,
    MESSAGES_ERRORS,
    PASSWORD_SUCCESS,
    PASSWORD_ERRORS,
    PLAYER_SUCCESS,
    PLAYER_ERRORS,
    BANK_SUCCESS,
    BANK_ERRORS,
    REQUEST_POST_ACCOUNT,
    REQUEST_POST_PASSWORD,
    REQUEST_POST_PLAYER,
    TRANSFER_SUCCESS,
    WALLET_GET,
    WALLET_SUCCESS,
    WALLET_ERRORS,
    SET_ACTIVE_PROVIDERS,
    PROCESS_WALLET_MESSAGE,
    GET_PLAYER_DEPOSIT_ACCOUNT,
    SET_PLAYER_DEPOSIT_ACCOUNT,
    ERROR_PLAYER_DEPOSIT_ACCOUNT
} from "../actions/types";

import player from "../selectors/player";
import provider from "../selectors/provider";
import { realFloatAmount } from "../helpers"

export {
    playerWatcher
};


function* playerWatcher() {
    yield takeLatest(GET_PLAYER, fetchPlayer);
    yield takeLatest(MESSAGES_SUCCESS, fetchPlayer);
    yield takeLatest(GET_MESSAGES, fetchMessages);
    yield takeLatest(GET_ALL_MESSAGES, fetchAllMessages);
    yield takeLatest(SET_MESSAGE_TYPE, fetchMessages);
    yield takeLatest(SET_MESSAGE_DATE, fetchMessages);
    yield takeLatest(SET_MESSAGES_CURRENT_PAGE, fetchMessages);
    yield takeLatest(REQUEST_POST_ACCOUNT, fetchPostAccount);
    yield takeLatest(REQUEST_POST_PASSWORD, fetchPostPassword);
    yield takeLatest(REQUEST_POST_PLAYER, fetchPostPlayer);
    yield takeLatest(PLAYER_SUCCESS, fetchPlayer);
    yield takeLatest(BANK_SUCCESS, fetchPlayer);
    yield takeLatest(TRANSFER_SUCCESS, fetchPlayer);
    yield takeEvery(WALLET_GET, fetchWallet);
    yield takeLatest(SET_ACTIVE_PROVIDERS, populateWallet);
    yield takeLatest(PROCESS_WALLET_MESSAGE, walletUpdateMessage);
    yield takeLatest(GET_PLAYER_DEPOSIT_ACCOUNT, fetchPlayerDepositAccount);
}

function* populateWallet(action) {
    try {
        const { providers } = yield select(provider);
        yield* providers.map(function* (_provider) {
            yield call(fetchWallet, {
                payload: { provider: _provider.id }
            });
        });
    }catch(error){
        console.log(error);
    }
}

function* fetchWallet(action) {
    try {
        const { payload } = action;
        const result = yield call(getWallet, {
            provider: payload.provider,
            lang: window.localStorage.i18nextLng.slice(0, 2)
        });
        if(!result.data.hasError) {
            yield put({
                type: WALLET_SUCCESS,
                payload: {
                    [result.data.id] : {
                        Status: result.data.Status,
                        UnderMaintenance: result.data.UnderMaintenance,
                        balance: result.data.balance,
                        currencyCode: result.data.currencyCode,
                        hasError: result.data.hasError,
                        status: result.data.status,
                        success: result.data.success,
                    }
                },
                payload2 : { [result.data.id] : realFloatAmount(result.data.balance)}
            });
        } else {
            yield put({
                type: WALLET_SUCCESS,
                payload: {
                    [result.data.id] : {
                        Status: result.data.Status,
                        UnderMaintenance: result.data.UnderMaintenance,
                        balance: realFloatAmount(result.data.balance),
                        currencyCode: result.data.currencyCode,
                        hasError: result.data.hasError,
                        status: result.data.status,
                        success: result.data.success,
                    }
                },
                payload2 : { [result.data.id] : realFloatAmount(result.data.balance)}
            });
        }
    }catch(error){
        yield put({
            type: WALLET_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status,

            }
        });
    }
}

function* fetchPlayer() {
    try {
        const result = yield call(getPlayer);
        yield put({
            type: SET_PLAYER,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: PLAYER_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchMessages(action) {
    const { selectedFilter, page } = yield select(player);
    const MessageID = action.payload ? action.payload.MessageID : null;
    try {
        const result = yield call(getMessages,{
                MessageID,
                date: selectedFilter.date,
                message_type: selectedFilter.type,
                page,
                per_page: 5
            });
        if(!action.payload){
            yield put({
                type: MESSAGES_SUCCESS,
                payload: result.data
            });
        }else{
            yield put({
                type: GET_MESSAGES,
            });
        }
    }catch(error){
        yield put({
            type: GET_ERRORS,
            payload: {
                ...error.response.data,
                status: error.response.status
            }
        });
    }
}

function* fetchAllMessages() {
    try {
        const { selectedFilter } = yield select(player);
        const result = yield call(getAllMessages, {
            date: selectedFilter.date
        })
        yield put({
            type: MESSAGES_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: MESSAGES_ERRORS,
            payload: {
                ...error.response.data.errors,
                status: error.response.status
            }
        });
    }
}

function* fetchPostPlayer(action) {
    try {
        let result = yield call(postPlayer, action.payload);
        yield put({
            type: PLAYER_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: PLAYER_ERRORS,
            payload: error.response.data.errors
        });
    }
}

function* fetchPostAccount(action) {
    try {
        let result = yield call(postAccount, action.payload);
        yield put({
            type: BANK_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: BANK_ERRORS,
            payload: error.response.data.errors
        });
    }
}

function* fetchPostPassword(action) {
    try {
        let result = yield call(postPassword, action.payload);
        yield put({
            type: PASSWORD_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: PASSWORD_ERRORS,
            payload: error.response.data.errors
        });
    }
}

function* walletUpdateMessage(action) {
    try {
        const { wallet } = yield select(player);
        const { balance, providerID } = action.payload;
        const updatedWallet = wallet[providerID];
        updatedWallet.balance = realFloatAmount(balance);
        yield put({
            type: WALLET_SUCCESS,
            payload: {
                [providerID] : updatedWallet
            },
            payload2 : { [providerID] : realFloatAmount(balance)}
        });
    }catch(error){

    }
}

function* fetchPlayerDepositAccount() {
    try {
        let result = yield call(getDepositAccount);
        yield put({
            type: SET_PLAYER_DEPOSIT_ACCOUNT,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: ERROR_PLAYER_DEPOSIT_ACCOUNT,
            payload: error.response.data
        });
    }
}
