import React from 'react';
import { useTranslation } from "react-i18next";

const SuccessAlert = ({ translationCode }) => {
    const { t } = useTranslation();
    
    return (
        <div className="d-flex w365-success-alert align-items-center">
            <div className="check mr-2 d-flex justify-content-center align-items-center"><i className="fas fa-check m-0"></i></div>
            <div>
            {
            translationCode === 'withdrawal sent' ? t(translationCode)+'!'
            : t(translationCode)
            }
            </div>
        </div>
    );
}

export default SuccessAlert;
