import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classname from 'classnames';
import { useDispatch } from "redux-react-hook";
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { resetSessionError } from '../../actions/session';
import { logoutUser } from '../../actions/authentication';

const SessionModal = ({props}) => {
    const { t } = useTranslation();
    const { loggedOut } = useSelector((state) => state.session);
    const dispatch = useDispatch();
    const ok = useCallback(() => {
        dispatch(resetSessionError());
        dispatch(logoutUser({
            payload: { history: { push: () => {} }}
        }));
    })

    return (
        <React.Fragment>
            <div
                className={classname("modal fade show",{
                })}
                style={{
                    display: loggedOut ? "block" : "none"
                }}
                id="DepositModal" tabIndex={-1} role="dialog" aria-labelledby="DepositModal" aria-modal="true">
                <Modal
                    centered
                    show={loggedOut}>
                    <Modal.Header>
                        <h5 className="modal-title" id="LoginModal">{t('session error')}</h5>
                    </Modal.Header>
                    <Modal.Body className="modal-body text-center row">
                        <div className="p-3 col-12">
                            <div className="mb-2">
                                <div style={{display: 'initial'}}/>  <br />
                                {t('your session has expired. you will be logged out from this page.')}
                            </div>
                            <button
                                className={classname("btn btn-success btn-loader",{
                                })} onClick={ok}>
                                    {t('ok') }
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default SessionModal;
