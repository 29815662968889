import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { resetPage } from "../../../actions/registration";
import ReactHtmlParser from "react-html-parser";

class Register3 extends Component {
    componentDidMount() {
        localStorage.removeItem("code");
        localStorage.removeItem("smscode");
        localStorage.removeItem("players"); 
        var elem = document.getElementById("main-wrap");
        elem.classList.add("register-section");
    }
    componentWillUnmount() {
        this.props.resetPage();
    }

    redirect() {
        window.location = '/';
    }

    render() {
        const { t } = this.props;

        return (
            <div  class="welcome-partner getHeight">
                <section class="contentWrap registration-bg">
                    <div class="container">
                        <div class="row align-items-center justify-content-end">
                            <div class="col text-highlight">
                                <h1 class="white">{t('high commision')}</h1>
                                <h1 class="green">{t('unlimited revenue')}</h1>
                            </div>
                            <div class="forms-container col">
                                <div class="row align-items-center justify-content-center">
                                  <div class="col">
                                    <p class="mb-3 div-title-partners">{t("welcome to w365")}</p>
                                      <p>{t("partner register success")}</p>
                                        <Link onClick={()=>this.redirect()} class="btn btn-success btn-lg">
                                            {t("go to w365 website")}
                                        </Link>
                                    </div>
                                  </div>
                                </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default connect(
    (state) => {},
    { resetPage }
)(withTranslation()(Register3));
