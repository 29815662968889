import {
    PARTNER_CONTACT_SUCCESS,
    PARTNER_CONTACT_ERRORS,
    PARTNER_CONTACT_SUCCESS_RESET
} from "../actions/types";

const initialState = {
    errors: [],
    message: [],
    status: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PARTNER_CONTACT_SUCCESS:
            return {
                ...state,
                errors: [],
                message: action.message,
                status: action.status
            };

        case PARTNER_CONTACT_ERRORS:
            return {
                ...state,
                errors: action.errors,
                message: action.message,
                status: action.status
            };

        case PARTNER_CONTACT_SUCCESS_RESET:
            return {
                ...state,
                errors: [],
                status: false
            };


        default:
            return state;
    }
}