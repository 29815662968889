import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { postSports, postPlay }

function postSports(params) {
    return axios.post(`${ROOT_URL}/sports/open`, params, {
    });
}

function postPlay(params) {
    return axios.post(`${ROOT_URL}/sports/play`, params, {
        headers: {
            Authorization: `Bearer ${findToken()}`
        }
    });
}

