import { takeLatest, put, call } from 'redux-saga/effects'; 
import { getBanks } from "../api/bank" 
import { 
    GET_BANKS, 
    SET_BANKS 
} from "../actions/types"; 
 
export { bankWatcher }; 
 
function* bankWatcher() { 
    yield takeLatest(GET_BANKS, fetchBank) 
} 
 
function* fetchBank(param) { 
    const result = yield call(getBanks, param.currency ); 
 
    yield put({ 
        type: SET_BANKS, 
        payload: result.data.data 
    }); 
} 