import React, { Component } from "react";
import Wallet from "../../layouts/Walletz";
import Provider from "../../layouts/Walletz/provider";
import All from "./modals/All";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import Message from "./Message";

import { displayAmount, totalAmount, sanitizeAmount, realAmount } from "../../../helpers";
import { postPlay, getProviders } from "../../../actions/casino";
import { getPlayer, getWallet, clearWallet } from "../../../actions/player";
import { updateSubscriptionModal } from "../../../actions/error-transfer-toast";

import {
    postTransfer,
    postTransferAll,
    postTransferWallet,
    resetTransfer,
    resetTransferError,
    postTransferWithoutSuccess,
    postTransferWalletWithoutSuccess
} from '../../../actions/payment';

import {
    getTransactionHistory
} from "../../../actions/transaction";

import {
    checkBonus,
    resetBonus
} from '../../../actions/bonus';

import { reloadWallet } from "../../../actions/player";

import { selectProvider, selectProvider1, selectProvider2, toggleModal } from "../../../actions/modal";
import { getProductProviders } from "../../../actions/product";

import BtnLoader from "../../common/BtnLoader";
import Unable from "../Withdrawal/modals/Unable";
import { Loader } from "../../common";
import { getActiveProviders, resetActiveCount } from '../../../actions/provider';

class Transfer extends Component {
    state = {
        showWallet: false,
        amount: "",
        orientation: false,
        wallet: {
            Name: "select wallet",
            ID: 0
        },
        wallet1: {
            Name: "select wallet",
            ID: 0
        },
        providers: {
            0: {
                name: "allwallet",
                currency: "KRW",
                balance: "0",
                id: 0
            },
            1: {
                name: "hogaming",
                balance: "0",
                id: 1
            },
            2: {
                name: "",
                balance: "0",
                id: 2
            }
        },
        provider: 0,
        success: false,
        validated: false,
        invalid_transfer_all: false,
        errors: {},
        key: 0,
        showAll: false,
        showUnable: false,
        showAuth: false,
        showFund: false,
        showDeposit: false,
        provider2: 0,
        show2: false,
        play: false,
        selectedGame: {
            Product: [
                {
                    balance: "0",
                    ProviderID: null
                }
            ]
        },
        reload: false,
        selectTo: "",
        counter: true,
        dropdownFrom: false,
        dropdownTo: false,
        providersWithWallet: []
    }

    constructor(props){
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.quickAddAmount = this.quickAddAmount.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    initializeProvider(props){
        this.props.checkBonus({provider: 0});
        props.selectProvider2({
            ID: 0,
            Name: "select wallet"
        });
        this.selectWallet({ID: 0, Name: "select wallet"});
        props.selectProvider1({
            ID: 0,
            Name: "select wallet"
        });
        this.selectWallet1({ID: 0, Name: "select wallet"});
    }
    
    shouldBeDisabled(){
        return (
            this.props.loadingSubscriptionCheck || this.state.hasPendingBet && this.state.wallet1.Name === "all games wallet" ||
            this.state.counter || this.state.wallet1.Name === "select wallet" || this.state.wallet.Name === "select wallet" ||
            !this.props.wallet[this.props.productProviders[0].Providers[0].ID] ||
            realAmount(this.state.amount) === 0
        )
    }

    componentWillReceiveProps(nextProps){
        const { wallet: providers } = nextProps;

        if(Object.keys(nextProps.wallet).length !== 0) {
            if(this.checkAllBalanceReceived(nextProps.wallet)) {
                const dump = this.props.provz.map((val) => {
                    return { 
                        ...val,
                        wallet: realAmount(nextProps.wallet[val.ID].balance)
                    }; 
                })

                this.setState({providersWithWallet: dump});
            }
        }

        if(nextProps.provz.length > 0) {
            const check = nextProps.provz.filter((val) => {
                return val.HasPendingBets.isSubscribed === true;
            });
            if(check.length > 0) {
                this.setState({ hasPendingBet: true });
                this.setState({ counter: false });
            } else {
                this.setState({ hasPendingBet: false });
                this.setState({ counter: false });
            }
        }

        if(nextProps.errors.amount){
            let amount;
            if(Array.isArray(nextProps.errors.amount)){
                amount = nextProps.errors.amount[0].toLowerCase();
            }else{
                amount = nextProps.errors.amount.toLowerCase();
            }
            this.setState({ errors: { amount } });
            this.setState({ success: false });

            toast.error(amount, {
                className: 'toast toast-error profile-toast'
            });
        }

        if(typeof nextProps.errors.errors === 'string'){
            this.setState({ errors: { amount:
                nextProps.errors.errors.toLowerCase()} });
            this.setState({ success: false });
        }

        

        this.setState({ providers });


        if (Object.keys(nextProps.success).length > 0) {
            this.setState({
                error: false,
                success: true,
            }, () => {
                if(this.props.subscriptionModal.show !== false){
                    this.props.updateSubscriptionModal({
                        loading: false,
                        show: false
                    });
                }
            });
            this.resetAmount();

            if(this.state.wallet !== undefined) {
                if(this.state.wallet.ID === 0) {
                    if(this.state.reload === true) {
                        this.refreshWallet();
                        this.setState({reload: false});
                    }
                }
            }
        }

        if (
            Object.keys(nextProps.casino.result).length > 0 &&
            this.state.play === true
        ) {
            localStorage.setItem("url", nextProps.casino.result.url);
            window.open(`http://${window.location.hostname}/live-casino/play/${this.state.hashid}`, "_blank");
            this.setState({ play: false });
            this.handleClose();
        }
    }

    resetAmount(){
        const { mainwallet } = this.props;
        if(this.state.orientation){
            if(this.props.provider.ID !== 0){
                this.setState({
                    amount: this.props.wallet[this.props.provider.ID].balance
                });
            }
        }else{
            this.setState({ amount: mainwallet.wallet });
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if(e.target.name === "amount") {
            if(this.state.wallet1.Name != "select wallet") {
                let balance = this.state.wallet1.Balance;
                let amount = e.target.value;
                balance = parseFloat(balance.toString().replace(/,/g,""));
                amount = parseFloat(amount.toString().replace(/,/g,""));
                if(amount > balance) {
                    this.setState({
                        amount: this.state.wallet1.Balance
                    });
                }
            }
        }
    };

    componentDidMount() {
        this.props.resetBonus();
        this.props.resetTransfer();
        this.props.reloadWallet();
        this.props.getProductProviders({});
        this.props.updateSubscriptionModal({
            loading: false,
            show: false
        });
        this.props.getProviders({ transfer: true });
        this.refreshWallet();
        this.initializeProvider(this.props);
        
        document.addEventListener('click', e => this.dropDownBlurHandle(e));
    }

    componentWillUnmount() {
        this.props.resetBonus();
        this.props.resetTransfer();
    }

    dropDownBlurHandle(e) {
        if(e.target.classList.contains('custom-selectbox')) {
            return false;
        }

        this.setState({
            dropdownFrom: false,
            dropdownTo: false
        });
    }

    toggleDropdown(){
        this.setState({ showWallet: !this.state.showWallet });
    }

    refreshPage = () => {
        window.location.reload();
    };

    resetForm = () => {
        this.setState({
            errors: {},
            invalid_transfer_all_amount: false,
            invalid_transfer_all: false,
            orientation: false,
            amount: "",
            counter: false
        });
        this.props.resetBonus();
        this.props.resetTransfer();
        this.props.resetTransferError();
        this.props.getTransactionHistory({});
        this.initializeProvider(this.props);
    };

    validAmount(total, amount){
        return (
            realAmount(total) >= realAmount(amount) && realAmount(total) > 0
        )
    }

    onSubmit = (e) => {
        const { t } = this.props;
        e.preventDefault();
        this.setState({ validated: true });
        if(this.form.checkValidity()){
            if(this.state.wallet1.Name === "select wallet" || this.state.wallet.Name === "select wallet" ) {
                toast.error( t("please select a wallet"), {
                    className: 'toast toast-error profile-toast'
                });
            } else {
                const subscription = this.props.subscription.ongoing;
                let amount = this.state.amount;
                amount = amount.toString().replace(/,/g,"");
                amount = Math.trunc(amount);

                this.props.postTransferWalletWithoutSuccess({
                    amount: realAmount(amount),
                    providerFrom: this.state.wallet1.ID,
                    providerTo: this.state.wallet.ID,
                    subscription
                });
                this.setState({ success: false });
                this.props.updateSubscriptionModal({
                    show: this.props.subscriptionModal.show,
                    loading: true
                });
                setTimeout(() => {
                    this.props.updateSubscriptionModal({
                        loading: false,
                        show: this.props.subscriptionModal.show,
                    });
                }, 1000);
            }
        }
    }

    refreshWallet = () => {
        if(!this.checkAllBalance()){
            this.props.clearWallet();
            this.props.resetActiveCount();
            this.props.getActiveProviders();
        }
    };

    quickAddAmount = (amountToAdd) => {
        let currentAmount = this.state.amount;
        currentAmount = currentAmount.toString().replace(/,/g,"");
        if (currentAmount) {
            currentAmount = Math.trunc(currentAmount);
        }
        if (String(currentAmount).match(/[a-z]/i) || currentAmount === "") {
            currentAmount = 0;
        }
        if(this.state.wallet1.Name != "select wallet") {
            if((Math.trunc(currentAmount) + Math.trunc(amountToAdd)) <= Math.trunc(this.state.wallet1.Balance))
            {
                currentAmount = Math.trunc(currentAmount) + Math.trunc(amountToAdd);
            }
        }
        currentAmount = Math.trunc(currentAmount);
        this.setState({ amount: currentAmount });
    };

    selectWallet(wallet){
        const mainwallet = this.props.mainwallet;
        const amountmain = Object.keys(mainwallet).length > 0 && mainwallet.player ? mainwallet.wallet : "0.0"
        this.setState({
            provider: wallet.ID,
            wallet: wallet,
            showWallet: false
        }, () => {
            if(this.state.orientation === true){
                if(this.state.provider === 0){
                    if(wallet.Name === "select wallet"){
                        this.setState({
                            amount: 0,
                            wallet:  {
                                Name: wallet.Name,
                                ID: 0
                            }
                        }); 
                    } else {
                        this.setState({
                            amount: wallet.Name === "main wallet" ? displayAmount(amountmain) : totalAmount(this.props.wallet),
                            wallet:  {
                                Name: wallet.Name,
                                ID: 0
                            }
                        });   
                    }
                }else{
                    this.setState({
                        amount: displayAmount(this.state.providers[wallet].balance),
                        wallet: wallet
                    });
                }
            }
        })
    }

    selectWallet1(wallet){
        const mainwallet = this.props.mainwallet;
        const amountmain = Object.keys(mainwallet).length > 0 && mainwallet.player ? mainwallet.wallet : "0.0"
        this.setState({
            provider1: wallet.ID,
            wallet1: wallet,
            showWallet: false
        }, () => {
                if(wallet.Name !== "main wallet"){
                    this.props.checkBonus({provider: wallet.ID});
                }
                if(this.state.provider1 === 0){
                    if(wallet.Name === "select wallet"){
                        this.setState({
                            amount: 0,
                            wallet1:  {
                                Name: wallet.Name,
                                ID: 0
                            }
                        }); 
                    } else {
                        this.setState({
                            amount: wallet.Name === "main wallet" ? displayAmount(amountmain) : totalAmount(this.props.wallet),
                            wallet1:  {
                                Name: wallet.Name,
                                ID: 0
                            }
                        });   
                    }
                }else{
                    this.setState({
                        amount: displayAmount(this.state.providers[wallet.ID].balance)
                    });
                }
        })
    }

    handleOpenAll = () =>{
        if(this.state.hasPendingBet === true ) {
            this.setState({ showUnable: true});
        } else {
            this.setState({ showAll: true});
        }
    }

    handleCloseAll = () =>{
        this.setState({ showUnable: false});
        this.setState({ showAll: false});
    }

    hideMessage(){
        this.setState({ success: false, loading: false, showAll: false });
        this.props.updateSubscriptionModal({
            loading: false,
            show: false
        });
        this.resetForm();
    }

    reInitModal = () => {
        this.props.resetTransfer();
        this.setState({ key: Math.random() });
    };

    closeDropdown() {
        this.setState({
            dropdownFrom: false,
            dropdownTo: false
        })
    }

    checkAllBalance(){
        if(this.objectLen(this.props.providersz) === 0) return false
        return (this.objectLen(this.props.providersz) <= this.objectLen(this.props.wallet));
    }

    checkAllBalanceReceived(wallet){
        if(this.objectLen(this.props.providersz) === 0) return false
        return (this.objectLen(this.props.providersz) <= this.objectLen(wallet));
    }

    objectLen(obj){
        return Object.keys(obj).length;
    }

    checkTrasnsferFromListCount() {
        return this.state.providersWithWallet.sort((a, b) => parseFloat(b.wallet) - parseFloat(a.wallet))
        .filter((val) => {
            return val.HasPendingBets.isSubscribed === false;
        }).length;
    }

    render() {
        let amount = this.state.amount;
        if(amount !== undefined) {
            amount = amount.toString().replace(/,/g,"");
            amount = Math.trunc(amount);
        } else {
            amount = 0;
        }
        const { t, i18n } = this.props;
        const language = i18n.language;
        var count = 0;
        
        return (
            <div id="containerForm">
                <ToastContainer autoClose={8000}/>
                <h2 className="formTitle">{t('transfer')}</h2>
                <div className="formContent">
                    { this.state.success ?
                        <div className="DepositContentWrap">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="DepositContentWrap">
                                        <div className="row">
                                            <Message closeMessage={this.hideMessage} onSubmit={this.onSubmit}/> 
                                            <Provider selectWallet={this.selectWallet.bind(this)}
                                                        selectWallet1={this.selectWallet1.bind(this)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            <div className="DepositContentWrap">
                                <div className="row myAccountTitle">
                                    <div className="col-md-12">
                                        <div id="MyAccount">
                                            <div className="formContent">
                                                <div className="DepositContentWrap">
                                                    <div className="row">
                                                        <div className="col-md-6 animated fadeIn" id="deposit-form">
                                                            <div className="accountInfo mb-5">
                                                                <div id="transferForm">
                                                                    <form
                                                                        className={this.state.validated ? "needs-validation was-validated": ""}
                                                                        noValidate method="post"
                                                                        onSubmit={this.onSubmit}
                                                                        ref={(form)=> this.form = form}>
                                                                        <div class="form-group row">
                                                                            <div class="col-md-12 ">
                                                                                <label class="col-form-label">{t("transfer from")}(-)</label>
                                                                            </div>
                                                                            <div class="col-md-12">
                                                                                <div class="selectbox">
                                                                                    <div 
                                                                                        class="form-control custom-selectbox dark"
                                                                                        onClick={ () => {this.setState({dropdownFrom: !this.state.dropdownFrom})}}
                                                                                    >
                                                                                        {
                                                                                            this.state.wallet1.Name === "select wallet" ?
                                                                                            <React.Fragment>
                                                                                                <div class="undefined"></div>
                                                                                                {t("select wallet from")}
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <span className={
                                                                                                    this.state.wallet1.ID === 21 ? 'icon js'
                                                                                                    : this.state.wallet1.ID === 22 ? 'icon es'
                                                                                                    : this.state.wallet1.ID === 23 ? 'icon pb'
                                                                                                    : 'undefined'
                                                                                                }></span>
                                                                                            {this.state.wallet1.Name} {` (${displayAmount(this.state.wallet1.Balance)})`}
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </div>
                                                                                    <div className={`selectbox-dropdown ${this.state.dropdownFrom ? 'active' : ''}`}>
                                                                                        <ul>
                                                                                            {
                                                                                                this.checkTrasnsferFromListCount() != 0 ?
                                                                                                this.state.providersWithWallet.sort((a, b) => parseFloat(b.wallet) - parseFloat(a.wallet))
                                                                                                .filter((val) => {
                                                                                                    return val.HasPendingBets.isSubscribed === false;
                                                                                                })
                                                                                                .map((provider) =>{
                                                                                                    const amount = this.props.wallet[provider.ID] || { balance: 0 };
                                                                                                    if(this.props.providerTo.ID != provider.ID) {
                                                                                                        if(displayAmount(amount.balance) != "0") {
                                                                                                            return (
                                                                                                                <li
                                                                                                                    onClick={() => {
                                                                                                                        this.props.selectProvider1({ID: provider.ID, Name: provider.ProviderName, Logo: provider.Logo})
                                                                                                                        this.selectWallet1({ID: provider.ID, Name: provider.ProviderName, Logo: provider.Logo, Balance: amount.balance})
                                                                                                                        this.closeDropdown();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className={
                                                                                                                        provider.ID === 21 ? 'icon js'
                                                                                                                        : provider.ID === 22 ? 'icon es'
                                                                                                                        : provider.ID === 23 ? 'icon pb'
                                                                                                                        : 'undefined'
                                                                                                                    }></span>
                                                                                                                    {provider.ProviderName} 
                                                                                                                    {
                                                                                                                        this.props.wallet[provider.ID] !== undefined ?
                                                                                                                        !provider.UnderMaintenance && !this.props.wallet[provider.ID].hasError ?
                                                                                                                        ` (${displayAmount(amount.balance)})`:
                                                                                                                        t('not available')
                                                                                                                        :<Loader hide={false}/>
                                                                                                                    }
                                                                                                                </li>
                                                                                                            )
                                                                                                        } else {
                                                                                                            count++;
                                                                                                            if(this.checkTrasnsferFromListCount() === count) {
                                                                                                                return (
                                                                                                                    <li><span className="undefined"></span>{t("no available balance")}</li>
                                                                                                                )
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                })
                                                                                                :
                                                                                                <li><span className="undefined"></span>{t("no wallet available")}</li>
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group row">
                                                                            <div class="col-md-12 ">
                                                                                <label class="col-form-label">{t("transfer amount")}</label>
                                                                            </div>
                                                                            <div class="col-md-12 txtbox-min-max">
                                                                                <div class="form-group mb-0">
                                                                                    <div class="input-group">
                                                                                        <NumberFormat
                                                                                            id="amount"
                                                                                            name="amount"
                                                                                            placeholder="0"
                                                                                            value={this.state.amount}
                                                                                            onChange={this.onChange}
                                                                                            onClick={this.onClick}
                                                                                            disabled={
                                                                                                this.state.wallet1 !== undefined ?
                                                                                                this.state.wallet1.ID === 0 ? 
                                                                                                this.state.wallet1.Name === "all games wallet" ? true 
                                                                                                : false 
                                                                                                : false 
                                                                                                : true
                                                                                            }
                                                                                            className={classnames("form-control ", { "is-invalid": !this.state.success && this.state.validated })}
                                                                                            thousandSeparator={true}
                                                                                            decimalSeparator={false}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row m-0">
                                                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(10000))}>{language === "ko-KR" ? "1만" : "10K"}</button>
                                                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(50000))}>{language === "ko-KR" ? "5만" : "50K"}</button>
                                                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(100000))}>{language === "ko-KR" ? "10만" : "100K"}</button>
                                                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(500000))}>{language === "ko-KR" ? "50만" : "500K"}</button>
                                                                                    <button className="btn btn-success col" type="button" onClick={() => (this.quickAddAmount(1000000)) }>{language === "ko-KR" ? "100만" : "1M"}</button>
                                                                                    <button className="btn btn-dark col" type="button" onClick={() => {this.setState({ amount: ""})}}>{t('reset')}</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group row">
                                                                            <div class="col-md-12 ">
                                                                                <label class="col-form-label">{t("transfer to")}(+)</label>
                                                                            </div>
                                                                            <div class="col-md-12">
                                                                                <div class="selectbox">
                                                                                    <div 
                                                                                        class="form-control custom-selectbox dark"
                                                                                        onClick={ () => {
                                                                                            if(this.state.wallet1.Name != "select wallet") {
                                                                                                this.setState({dropdownTo: !this.state.dropdownTo })
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            this.state.wallet.Name === "select wallet" ?
                                                                                            <React.Fragment>
                                                                                                <div class="undefined"></div>
                                                                                                {t("select wallet to")}
                                                                                            </React.Fragment>
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <span className={
                                                                                                    this.state.wallet.ID === 21 ? 'icon js'
                                                                                                    : this.state.wallet.ID === 22 ? 'icon es'
                                                                                                    : this.state.wallet.ID === 23 ? 'icon pb'
                                                                                                    : 'undefined'
                                                                                                }></span>
                                                                                            {this.state.wallet.Name} {` (${displayAmount(this.state.wallet.Balance)})`}
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </div>
                                                                                    <div className={`selectbox-dropdown ${this.state.dropdownTo ? 'active' : ''}`}>
                                                                                        <ul>
                                                                                            {
                                                                                                this.props.provz.map((provider) =>{
                                                                                                    const amount = this.props.wallet[provider.ID] || { balance: 0 };
                                                                                                    if(this.props.providerFrom.ID != provider.ID) {
                                                                                                        return (
                                                                                                            provider.HasPendingBets.isSubscribed === true ?
                                                                                                            <li
                                                                                                                className="disabled"
                                                                                                            >
                                                                                                                <span className={
                                                                                                                    provider.ID === 21 ? 'icon js'
                                                                                                                    : provider.ID === 22 ? 'icon es'
                                                                                                                    : provider.ID === 23 ? 'icon pb'
                                                                                                                    : 'undefined'
                                                                                                                }></span>
                                                                                                                {provider.ProviderName} 
                                                                                                                {
                                                                                                                    this.props.wallet[provider.ID] !== undefined ?
                                                                                                                    !provider.UnderMaintenance && !this.props.wallet[provider.ID].hasError ?
                                                                                                                    ` (${displayAmount(amount.balance)})`:
                                                                                                                    t('not available')
                                                                                                                    :<Loader hide={false}/>
                                                                                                                }
                                                                                                            </li>
                                                                                                            :
                                                                                                            <li
                                                                                                                onClick={() => {
                                                                                                                    this.props.selectProvider2({ID: provider.ID, Name: provider.ProviderName, Logo: provider.Logo})
                                                                                                                    this.selectWallet({ID: provider.ID, Name: provider.ProviderName, Logo: provider.Logo, Balance: amount.balance})
                                                                                                                    this.closeDropdown();
                                                                                                                }}
                                                                                                            >
                                                                                                                <span className={
                                                                                                                    provider.ID === 21 ? 'icon js'
                                                                                                                    : provider.ID === 22 ? 'icon es'
                                                                                                                    : provider.ID === 23 ? 'icon pb'
                                                                                                                    : 'undefined'
                                                                                                                }></span>
                                                                                                                {provider.ProviderName} 
                                                                                                                {
                                                                                                                    this.props.wallet[provider.ID] !== undefined ?
                                                                                                                    !provider.UnderMaintenance && !this.props.wallet[provider.ID].hasError ?
                                                                                                                    ` (${displayAmount(amount.balance)})`:
                                                                                                                    t('not available')
                                                                                                                    :<Loader hide={false}/>
                                                                                                                }
                                                                                                            </li>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="action-btn-modal text-center">
                                                                            <button
                                                                                disabled={this.shouldBeDisabled()}
                                                                                className={classnames("btn btn-success small-caps",{})} type="submit">
                                                                                    { !this.props.subscriptionModal.loading && t('submit') }
                                                                                    <BtnLoader classes="img-fluid btnLoader mx-auto" hide={!this.props.subscriptionModal.loading}/>
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Provider selectWallet={this.selectWallet.bind(this)}
                                                        selectWallet1={this.selectWallet1.bind(this)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <All
                                show={this.state.showAll}
                                handleClose={this.handleCloseAll}
                                total={totalAmount(this.props.wallet)}
                                success={this.state.success}
                            />
                            <Unable
                                show={this.state.showUnable}
                                handleClose={this.handleCloseAll}
                            />
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

Transfer.propTypes = {
    postTransfer: PropTypes.func.isRequired,
    getPlayer: PropTypes.func.isRequired
};

Transfer.defaultProps = {
    mainwallet: {
        player: {
            currency: {
            }
        }
    },
    success: {},
    errors: {
        amount: null
    },
    providers: []
}

const mapStateToProps = state => ({
  currency: state.auth.wallet.player,
  mainwallet: state.auth.wallet,
  wallet: state.player.wallet,
  providers: state.auth.providers,
  productProviders: state.products.result,
  transfer: state.payment,
  provider: state.payment.provider,
  errors: state.payment.transferErrors,
  success: state.payment.transferResult,
  subscription: state.bonuses,
  modal: state.modal,
  walletz: state.auth.wallet,
  loadingSubscriptionCheck: state.bonuses.loading,
  subscriptionModal: state.errorTransferToast,
  providersz: state.providers.providers,
  provz: state.casino.providers,
  providerFrom: state.payment.provider1,
  providerTo: state.payment.provider2,
  walletsBalance: state.player.walletsBalance,
  ...state
});

export default connect(
  mapStateToProps,
    {
        toggleModal,
        resetTransfer,
        resetTransferError,
        resetBonus,
        postTransfer,
        postTransferWallet,
        postTransferAll,
        reloadWallet,
        checkBonus,
        getTransactionHistory,
        getProductProviders,
        selectProvider,
        selectProvider1,
        selectProvider2,
        getPlayer,
        postPlay,
        postTransferWithoutSuccess,
        postTransferWalletWithoutSuccess,
        updateSubscriptionModal,
        getWallet,
        getProviders,
        getActiveProviders, 
        resetActiveCount, 
        clearWallet
    }
)(withTranslation()(withRouter(Transfer)));
