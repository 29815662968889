import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Error from "../../../errors";
import { postPlay, postSports, sportsClear } from "../../../actions/sports";
import { Link } from "react-router-dom";
import {
    getWallet
} from "../../../actions/player";
import ReactHtmlParser from "react-html-parser";
import BlackListedError from '../../common/BlackListedError';
import { getBrandGameSetup } from "../../../actions/page";

const IFrame = ({ url }) => {
    return (
        <div className="iframe-container" style={{ margin: "0px" }}>
            <iframe
                id="RefFrame"
                className="RefFrame"
                src={url}
                width="1903"
                style={{ width: "100%" }}
                title="sports"

            ></iframe>
        </div>
    );
};


class Sports extends Component {
    state = {
        url: "",
        success: true,
        undermaintenance: false,
        content: null,
        blacklistedError: false
    };

    componentDidMount() {
        this.props.getBrandGameSetup()
        if (localStorage.getItem("appToken")) {
            this.props.postPlay({ providerID: 23, productID: 10, integrationID: 23});
        } else {
            this.props.postSports({ providerID: 23, integrationID: 23 });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { result, errors }  = nextProps.sports;

        if(errors && (errors.player_blacklisted !== undefined || errors.player_closed !== undefined)) {
            this.setState({blacklistedError: true, success: false});
        }
        
        //result and errors initial value is false
        if(typeof result === 'object'){
            const { game_url,success } = result;
            this.setState({ url: game_url, success:success });
        }else if(typeof errors === 'object'){
            if(errors !== false) {
                if(typeof errors !== 'undefined'){
                    if(errors.errors !== undefined) {
                        this.setState({success: false})
                        if(errors.errors.undermaintenance !== undefined) {
                            this.setState({
                                undermaintenance: errors.errors.undermaintenance,
                                content: errors.errors.content
                            }, () => {
                                this.props.sportsClear();
                            });
                        }
                    }
                }
            }
        }

        if (nextProps.brandGameSetup !== undefined && nextProps.brandGameSetup.length > 0) {
        
            let search = nextProps.brandGameSetup.find(el => el.ProductID === 10);
            if (search === undefined) {
                window.location = '/'
            }
        }
    }

    render() {
        const { t, i18n } = this.props;
        const { language } = i18n;
        let lang = "", content = [];
        
        if(language !== undefined) {
            lang = language.split("-")[0]
        } else {
            lang = "ko";
        }
        if(this.state.content !== null) {
            content = this.state.content[lang];
        }

        return (
            <Error>
                {
                    this.state.success ?
                    <IFrame url={this.state.url} />
                    :
                    this.state.undermaintenance ?
                    <section class="game-maintenance-section">
                        <div class="container-fluid h-100">
                            <div class="error-page">
                            <div class="row align-items-center justify-content-center full-height">
                                <div class="container">
                                <div class="box-container">
                                    <div class="row align-items-center">
                                    <div class="col-lg-auto mr-lg-2">
                                        <img class="warning" src="/assets/img/icons/maintain.svg" />
                                    </div>
                                    <div class="col-lg">
                                        <div class="content">
                                            <p class="title">{this.state.content !== null ? content.Title : t('game maintenance title')}</p>
                                            <p class="content-text">
                                            {ReactHtmlParser(this.state.content !== null ? content.Message : t('game maintenance text'))}
                                            </p>
                                        </div>
                                        <div class="browser-section mt-lg-4 mt-md-3">
                                        <div class="row align-items-center justify-content-center justify-content-lg-start">
                                            <div class="col-auto pl-lg-0">
                                            <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                <div class="col-auto icon p-0">
                                                <img src="/assets/img/w365/icons/contact-us/telegram.svg"/>
                                                </div>
                                                <div class="col-auto p-0">
                                                <p>{t('cs-telegram')}</p>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-auto">
                                            <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                <div class="col-auto icon p-0">
                                                <img src="/assets/img/w365/icons/contact-us/cs-email.svg"/>
                                                </div>
                                                <div class="col p-0">
                                                <p>{t('cs support email inqury')}: {t('cs support email')}</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                    :
                    <>
                        {/* {this.state.blacklistedError ? <div className="blacklisted blacklisted-error"><BlackListedError /></div> :
                        <div className="error_page_2">
                            <div className="error_splash row text-center">
                                <div className="col-md-12">
                                    <div className="symbol_icon">
                                        <i className="svg-icon svg-warning" />

                                        <p >
                                            <span className="colgray">{t('code')}:</span> <b>4013</b>
                                        </p>
                                        <p></p>
                                    </div>
                                    <h1 style={{color:"#8EB50D"}}>{t('error opening game')}</h1>
                                    <p style={{fontSize: "20px", fontWeight: "600"}}>
                                        {t("please try again")}
                                    </p>
                                    <p>
                                        <Link to="/" className="btn btn-primary" style={{padding:"10px 20px 10px 20px"}}>
                                            {t('back to homepage')}{" "}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>} */}
                        <div className="blacklisted blacklisted-error"><BlackListedError /></div>
                    </>
                }
            </Error>
        )

    }
}

Sports.propTypes = {
    postPlay: PropTypes.func.isRequired
};

Sports.defaultProps = {
    sports: {
        result: {
            url: ""
        }
    }
};

const mapStateToProps = (state) => ({
    sports: state.sports,
    player: state.auth.wallet.player,
    brandGameSetup: state.page.brandGameSetup
});

export default connect(mapStateToProps, { postPlay, postSports, getWallet, sportsClear, getBrandGameSetup })(
    withTranslation()(withRouter(Sports))
);
