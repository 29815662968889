import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import NotFound from "../components/pages/NotFound"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log(error);
    }

    componentWillReceiveProps(nextProps) {
        //console.log(nextProps.error);
        const error = nextProps.error.message ? true : false;
        this.setState({ hasError: error });

        console.log(nextProps.error);
    }

    render() {
        

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <NotFound />
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    error: state.errors
});

export default connect(mapStateToProps, null)(withRouter(ErrorBoundary));
