import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ComingSoonModal from '../../../common/ComingSoonModal';
import { checkCanRegister } from "../../../../actions/registration";
import Langauge from "./language";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Login extends Component {
    state = {
        canSignUp: true,
    }

    componentDidMount(){
        this.props.checkCanRegister({domain: window.location.hostname === 'localhost' ? '15gaming.com' : window.location.hostname});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.canRegister.CanSignUp !== undefined) {
            this.setState({canSignUp:nextProps.canRegister.CanSignUp})
        }
    }
    
    render() {
        const { history, t } = this.props;
        return (
            <div className="login-area">
                <button type="button" className="btn btn-success btn-sm btn-login mr-2" id="login-btn" onClick={() => {this.props.handleOpen()}}>{t('login')}</button>
                {
                    this.state.canSignUp && (
                        <button  className="btn btn-secondary btn-sm btn-register mx-2" id="createAccount" name="Create an Account"  onClick={() => history.push('/register')}>
                            {t('register')}
                        </button>
                    )
                }
                {/* </div> */}
                <div className="lang_select px-2">
                    <div className="dropdown">
                        <Langauge/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    canRegister: state.register.canRegister
});

export default connect(
    mapStateToProps,
    { checkCanRegister }
)(withRouter(withTranslation()(Login)));
