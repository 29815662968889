import { OPEN_BETCONSTRUCT, OPENING_BETCONSTRUCT_SUCCESS, OPENING_BETCONSTRUCT_ERROR, RESET_BETCONSTRUCT } from './types';
  
export const openBetConstruct = (isLogin) => ({
    type: OPEN_BETCONSTRUCT,
    isLogin
});
  
export const openingBetConstructSuccess = (url) => ({
    type: OPENING_BETCONSTRUCT_SUCCESS,
    url
});
  
export const openingBetConstructError = response => ({
    type: OPENING_BETCONSTRUCT_ERROR,
    sucess: false,
    errors: response,
    loading: false
});

export const resetBetConstruct = () => ({
    type: RESET_BETCONSTRUCT
});
