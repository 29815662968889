import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";
import { gmtParams } from "../helpers";

export {
    getAllMessages,
    getPlayer,
    getMessages,
    postPlayer,
    postAccount,
    postPassword,
    getWallet,
    getDepositAccount
}

function getPlayer(){
  return axios
    .get(`${ROOT_URL}/player`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    });
}

function getWallet(params){
  return axios
    .get(`${ROOT_URL}/getBalance`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }, params
    });
}

//if ${id} passed will update message
function getMessages(params){
  params = gmtParams(params);
    const id = params && params.MessageID ? `/${params.MessageID}` : ""
    return axios
    .get(`${ROOT_URL}/messages${id}`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }, params
    });
}

function getAllMessages(params){
  params = gmtParams(params);
  const id = params && params.MessageID ? `/${params.MessageID}` : ""
    return axios
    .get(`${ROOT_URL}/messages${id}`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      },params
    });
}

function postPlayer(player){
 return axios
    .post(`${ROOT_URL}/player`, player, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

function postAccount(player){
  return axios
    .post(`${ROOT_URL}/bankAccount`, player, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

function postPassword(player){
  return axios
    .post(`${ROOT_URL}/password`, player, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    })
}

function getDepositAccount(){
  return axios
    .get(`${ROOT_URL}/playerDepositAccount`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    });
}