import { takeLatest, put, call } from 'redux-saga/effects';

import {
    getGames,
    getRecent,
    getCategories
} from "../api/slot"

import {
    SLOTS_GAME_SUCCESS,
    SLOTS_GAME_ERRORS,
    GET_SLOTS_GAMES,
    GET_SLOTS_RECENT,
    SLOTS_RECENT_SUCCESS,
    SLOTS_RECENT_ERRORS,
    GET_SLOTS_CATEGORIES,
    SLOTS_CATEGORIES_SUCCESS,
    SLOTS_CATEGORIES_ERRORS,
    GET_ERRORS
} from "../actions/types";

export { slotsWatcher };

function* slotsWatcher() {
    yield takeLatest(GET_SLOTS_GAMES, fetchGames)
    yield takeLatest(GET_SLOTS_RECENT, fetchRecent)
    yield takeLatest(GET_SLOTS_CATEGORIES, fetchCategories)
}

function* fetchGames(param){
    try{
        const result = yield call(getGames, { product: param.payload.product, new: param.payload.new, code: param.payload.code, provider: param.payload.provider, popular: param.payload.popular, name: param.payload.name });
            yield put({
                type: SLOTS_GAME_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: GET_ERRORS,
                payload: {
                    ...error.response.data,
                    status: error.response.status
                }
            });
    }
}

function* fetchRecent(param){
    try{
        const result = yield call(getRecent, { product: param.payload.product });
            yield put({
                type: SLOTS_RECENT_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: GET_ERRORS,
                payload: {
                    ...error.response.data,
                    status: error.response.status
                }
            });
    }
}

function* fetchCategories(param){
    try{
        const result = yield call(getCategories, { product: param.payload.product, provider: param.payload.provider });
            yield put({
                type: SLOTS_CATEGORIES_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: GET_ERRORS,
                payload: {
                    ...error.response.data,
                    status: error.response.status
                }
            });
    }
}
