import { SET_BANNERS } from "../actions/types";

const initialState = {
  banners: [],
  slot: [{ Image: ""}],
    mini: [{
        BannerID: 17,
        ButtonText: "Play now",
        Category: "Sliders",
        DateFrom: "06/10/2019 12:00 AM",
        DateTo: "06/30/2019 12:00 AM",
        Device: "Desktop",
        Image: "https://html.15b3t.com/EN/img/mini-games/fish-hunter/banner-fish-hunter.jpg",
        LanguageID: 1,
        PageID: 4,
        PageTitle: "Slot Page",
        Sort: "1",
        Status: 1,
        Status2: 1,
        Subtitle: "",
        Title: "Mini Games",
        Url: ""
    }]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_BANNERS:
      return {
        ...state,
          banners: action.payload.filter((banner) => banner.PageID === 1),
          slot: action.payload.filter((banner) => banner.PageID === 4)
      };
    default:
      return state;
  }
}
