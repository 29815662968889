import {
    SET_MESSAGE_DATE,
    SET_MESSAGE_TYPE,
    SET_MESSAGES_CURRENT_PAGE,
    SET_MESSAGES_FILTER,
    RESET_MESSAGES_FILTER,
    PLAYER_SUCCESS,
    PLAYER_RESET_ERRORS,
    PLAYER_ERRORS,
    PASSWORD_SUCCESS,
    PASSWORD_ERRORS,
    BANK_SUCCESS,
    BANK_ERRORS,
    MESSAGES_SUCCESS,
    WALLET_SUCCESS,
    WALLET_CLEAR,
    SET_PLAYER_DEPOSIT_ACCOUNT,
    CLEAR_PLAYER_DEPOSIT_ACCOUNT,
    ERROR_PLAYER_DEPOSIT_ACCOUNT,
    RESET_MESSAGES
} from "../actions/types";

const initialState = {
    player: [],
    result: [],
    errors: [],
    banks: [],
    selectedType: 'All',
    selectedDate: 'All',
    currentPage: 1,
    wallet: {},
    walletsBalance: {},
    selectedFilter: {
        type: 'All',
        date: 0
    },
    searchFields: {
        date : [
            { name: 'Today', value: 0 },
            { name: 'Yesterday', value: 1 },
            { name: 'Last 3 days', value: 3 },
            { name: 'Last 7 days', value: 7 }
        ],
        type : [
            { name: 'All', value: 'All' },
            { name: 'player notice', value: 'notice' },
            { name: 'player message', value: 'message' }
        ]
    },
    messages: {
        data: [],
        meta: {},
        links: {}
    },
    depositAccount: [],
    depositAccountErrors: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case RESET_MESSAGES: 
            return {
                ...state,
                messages: {
                    data: [],
                    meta: {},
                    links: {}
                },
            };
        case SET_MESSAGE_TYPE:
            return {
                ...state,
                selectedType: action.message_type,
            };
        case SET_MESSAGE_DATE:
            return {
                ...state,
                selectedDate: action.date
            };
        case SET_MESSAGES_CURRENT_PAGE:
            return {
                ...state,
                page: action.page
            };
        case RESET_MESSAGES_FILTER:
            return {
                ...state,
                selectedFilter: {
                    type: 'All',
                    date: 0
                },
                page: 0,
            };
        case SET_MESSAGES_FILTER:
            return {
                ...state,
                selectedFilter: action.payload,
                page: null
            };
        case MESSAGES_SUCCESS:
            return {
                ...state,
                messages: action.payload
            };
        case WALLET_SUCCESS:
            return {
                ...state,
                wallet: {
                    ...state.wallet,
                    ...action.payload
                },
                walletsBalance: {
                    ...state.walletsBalance,
                    ...action.payload2
                },
                errors: false
            };
        case WALLET_CLEAR:
            return {
                ...state,
                wallet: {}
            };
        case PLAYER_SUCCESS:
            return {
                ...state,
                result: action.payload,
                errors: false
            };
        case PLAYER_RESET_ERRORS:
            return {
                ...state,
                errors: []
            };
        case PLAYER_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload
            };
        case PASSWORD_SUCCESS:
            return {
                ...state,
                result: action.payload,
                errors: false
            };
        case PASSWORD_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload
            };
        case BANK_SUCCESS:
            return {
                ...state,
                result: action.payload,
                errors: false
            };
        case BANK_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload
            };
        case SET_PLAYER_DEPOSIT_ACCOUNT:
            return {
                ...state,
                depositAccount: action.payload
            }
        case CLEAR_PLAYER_DEPOSIT_ACCOUNT:
            return {
                ...state,
                depositAccount: [],
                depositAccountErrors: []
            }
        case ERROR_PLAYER_DEPOSIT_ACCOUNT: 
            return {
                ...state,
                depositAccountErrors: action.payload
            }
        default:
            return state;
    }
}
