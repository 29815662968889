import React, { 
    useEffect, 
    useRef 
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTronPurchase } from "../../../actions/payment";
import Loader from '../../common/Loader';

const TronRecharge = () => {
    const form = useRef(false);
    const dispatch = useDispatch();
    const tron = useSelector((state) => state.payment.tron);
    useEffect(() => {
        if (form.current && tron.id && tron.password && tron.url){
            form.current.submit();
        }
    }, [JSON.stringify(tron)])

    useEffect(() => {
        //call only once
        if(!tron.url) {
            dispatch(getTronPurchase());
        }
    }, [])

    return (
        <div>
            {/* <form ref={form} name="flogin" id="coinForm" action="https://dae.tronexc.com/bbs/login_check.php" method="post"> */}
            <form ref={form} name="flogin" id="coinForm" action={tron.url} method="post">
                <input type="text" name="url" value=" " className="hidden"/>
                {/* <input type="hidden" name="mb_id" id="login_id" required class="frm_input required" size="20" maxLength="20" placeholder="playerID" value="testdevjaypee" />
                <input type="hidden" name="mb_password" id="login_pw" required class="frm_input required" size="20" maxLength="20" placeholder="password" value="testdevjaypee12345" />  */}
                <input type="hidden" name="mb_id" id="login_id" required class="frm_input required" size="20" maxLength="20" placeholder="playerID" value={tron.id} />
                <input type="hidden" name="mb_password" id="login_pw" required class="frm_input required" size="20" maxLength="20" placeholder="password" value={tron.password} /> 
                <button type="submit" className="hidden">Submit </button>
            </form>
            <div className="d-flex justify-content-center">
                <Loader hide={false} style={{ width: "10%", marginTop: "50%" }} />
            </div>
        </div>
    )
};

export default TronRecharge;