import {
    REBATE_HISTORY_SUCCESS,
    REBATE_HISTORY_ERRORS,
    REBATE_SUCCESS,
    REBATE_ERRORS,
    REBATE_RESET,
    REBATE_SET_PAGE,
    REBATE_SET_FILTER,
    REBATE_RESET_FILTER,
    REBATE_POST_SUCCESS,
    REBATE_POST_ERRORS,
    REBATE_SET_MIN_TRANSFER,
    SET_REBATE_TRANSFER_DATA,
    SET_REBATE_TRANSFER_CHECKING_LOADING,
    SET_REBATE_TRANSFER_MODAL_TOGGLE,
    PROCESSING_REBATE_TRANSFER,
    SET_REBATE_TRANSFER_PROCESSED_DATA
} from "../actions/types";

const initialState = {
    rebate: {
        result: false,
        errors: false
    },
    history: {
        data: [],
        meta: {},
        links: {}
    },
    page: 0,
    errors: false,
    selectedFilter: {
        type: 0,
        date: 0
    },
    searchFields: {
        date: [
            { name: "Today", value: 0 },
            { name: "Yesterday", value: 1 },
            { name: "Last 3 days", value: 3 },
            { name: "Last 7 days", value: 7 }
        ]
    },
    minTransfer: {},
    transferData: {
        checkingLoading: false,
        transferProcessing: false,
        showModal: false,
        data: {},
        transferData: {}
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case REBATE_RESET_FILTER:
            return {
                ...state,
                selectedFilter: {
                    type: 0,
                    date: 0
                },
                page: 0,
            };
        case REBATE_SET_FILTER:
            return {
                ...state,
                selectedFilter: action.payload,
                page: null
            };
        case REBATE_HISTORY_SUCCESS:
            return {
                ...state,
                history: action.payload
            };
        case REBATE_HISTORY_ERRORS:
            return {
                ...state,
                ...action.payload
            };
        case REBATE_SET_PAGE:
          return {
              ...state,
              page: action.payload.page
          };
        case REBATE_POST_SUCCESS:
            return {
                ...state,
                rebate: {
                    ...state.rebate,
                    result: action.payload
                }
            };
        case REBATE_POST_ERRORS:
            return {
                ...state,
                rebate: {
                    ...state.rebate,
                    ...action.payload
                }
            };
        case REBATE_SUCCESS:
            return {
                ...state,
                rebate: action.payload,
            };
        case REBATE_ERRORS:
            return {
                ...state,
                ...action.payload
            };
        case REBATE_SET_MIN_TRANSFER:
            return {
                ...state,
                minTransfer: action.payload
            };
        case SET_REBATE_TRANSFER_CHECKING_LOADING:
            return {
                ...state,
                transferData: {
                    ...state.transferData,
                    checkingLoading: action.payload
                }
            };
        case SET_REBATE_TRANSFER_DATA:
            return {
                ...state,
                transferData: {
                    ...state.transferData,
                    data: action.payload.data,
                    checkingLoading: action.payload.checkingLoading,
                    showModal: action.payload.showModal
                }
            };
        case SET_REBATE_TRANSFER_MODAL_TOGGLE:
            return {
                ...state,
                transferData: {
                    ...state.transferData,
                    showModal: action.payload
                }
            };
        case PROCESSING_REBATE_TRANSFER:
            return {
                ...state,
                transferData: {
                    ...state.transferData,
                    transferProcessing: action.payload
                }
            };
        case SET_REBATE_TRANSFER_PROCESSED_DATA:
            return {
                ...state,
                transferData: {
                    ...state.transferData,
                    transferData: action.payload
                }
            };
        default:
            return state;
    }
}
