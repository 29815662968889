import React, { useEffect } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useSelector } from 'react-redux';
import { getRebateHistory, setPage } from '../../../../actions/rebate';
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paginate } from '../../../common';
import { displayAmount, thousandAmount } from '../../../../helpers';
import { player } from '../../../../selectors/auth';

const History = () => {
    const dispatch = useDispatch();
    const { history } = useSelector(state => state.rebate);
    const rebate = useSelector(state => state.rebate);
    const { currency: { Abbreviation } } = useSelector(player);

    const options = { ...history.meta, ...history.links }

    useEffect(() => {
        dispatch(getRebateHistory());
    }, []);

    const { t } = useTranslation();

    return (
        <>
        <table class="table table-striped table-dark">
          <thead>
            <tr>
                <th>{t('date')}</th>
                <th>{t('transaction type')}</th>
                <th>{t('from')}</th>
                <th>{t('to')}</th>
                <th>{t('amount')}</th>
                <th>{t('status')}</th>
            </tr>
            </thead>
            <tbody>

            { history.data.map((rebate, key) => {
                        return (
                            <tr class="tableBG-1" key={key}>
                                <td>{rebate.Date}</td>
                                <td>{t(rebate.TransactionType.toLowerCase())}</td>
                                <td>{t(rebate.From.toLowerCase())}</td>
                                <td>{rebate.To}</td>
                                <td class="txt-align-right">{Abbreviation} {thousandAmount(rebate.Amount)}</td>
                                <td className="text-center">
                                    <span className={classname("",{
                                        "green": rebate.Status ===
                                        "Success",
                                        "red":
                                        [
                                            "Expired",
                                            "Complete",
                                            "Forfeited",
                                            "Force To Serve",
                                            "Player Canceled",
                                            "System Cancelled"
                                        ].
                                        includes(rebate.Status)
                                    })}>
                                        {t(rebate.Status.toLowerCase())}
                                    </span>
                                 </td>
                            </tr>
                        )
                    })
                    }
                    <tr className={classname("",{
                        "d-none": history.data.length > 0
                    })}>
                        <td colSpan="12">
                            <h4>{t('no results found')}</h4>
                        </td>
                    </tr>
            </tbody>
        </table>
        <Paginate options={options} selectPage={(page) => {
            dispatch(setPage({page}));
            }
        }/>
        </>
    );
};

export default History;
