import {
  SET_ACTIVE_PROVIDERS,
  SET_FAVOURITE_PROVIDERS,
  ACTIVE_PROVIDERS_ERRORS,
  RESET_ACTIVE_COUNT
} from "../actions/types";

const initialState = {
  providers: [],
  favourites: [],
  count: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
        count: 1
      };
    case ACTIVE_PROVIDERS_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    case RESET_ACTIVE_COUNT:
      return {
        ...state,
        count: 0,
        providers: []
      }
    case SET_FAVOURITE_PROVIDERS:
      return {
        ...state,
        favourites: action.payload
      }
   default:
      return state;
  }
}
