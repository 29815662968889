import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import View from "./View";
import List from "./List";
// import FilterSection from "./filter";
import Compose from './compose';
import { Paginate } from "../../common";

import {
    getMessages,
    setFilter,
    resetMessages,
    selectPage
} from "../../../actions/player";

import {
    getUnreadMessage,
} from "../../../actions/authentication";

import ReactPaginate from "react-paginate";

class Messages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            getMessageCount: 0
        };
    }

    onRead = messageID => {
        this.props.history.push('/myaccount/messages/' + messageID);
        this.props.getUnreadMessage();
    };

    componentDidMount(){
        this.props.resetMessages();
        this.props.setFilter({
            ...this.props.player.selectedFilter,
            type: 'message',
            date: 30
        });
        
        //this.props.getMessages();
        this.props.getUnreadMessage();
    }

    componentWillUnmount(){
        // this.props.getUnreadMessage();
        this.props.resetMessages();
    }

    componentWillReceiveProps(nextProps){
        const { messages, selectedDate, selectedType } = nextProps.player;
        if(nextProps.player.selectedFilter.type) {
            if(nextProps.player.selectedFilter.type === 'message'){
                if(this.state.getMessageCount === 0) {
                    this.setState({getMessageCount: 1});
                    this.props.getMessages();
                }
            }
        }
        this.setState({
            messages: messages.data,
            options: { ...messages.meta, ...messages.links },
            selectedDate,
            selectedType
        });
    }

    handlePageClick = (e) => {
        if(this.props.player.selectedFilter.type === 'message') {
            this.props.selectPage(e.selected + 1)
        }
    };

    render() {
        const { messages, options } = this.state;
        const { selectPage } = this.props;
        const { t } = this.props;

        let pageCount = 0;
        if (options) {
            pageCount = [...Array(options.last_page).keys()].length;
        }

        if(this.props.match.params.id){
            return (
                <React.Fragment>
                    <h2 className="form-title">{t('messages')}</h2>
                    <div className="accountInfo">
                        <div className="message_content message_id_1 row animated fadeIn">
                            <View />
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <div style={{marginBottom: '80px'}}>
                <h2 className="form-title">{t('messages')}</h2>
                <div className="accountInfo">
                    <div className="mb-4 mt-4  message_inbox">
                        {/* <FilterSection /> */}
                        <div className="row">
                            <Compose />
                        </div>
                        <List messages={messages} onRead={this.onRead} ownProps={this.props} />
                        {/* <div className="col-md-12">
                            <Paginate options={options} selectPage={selectPage}/>
                        </div> */}
                        <div className="col-md-12 pagination-col mt-3">
                            <div className="pagination-box">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    initialPage={0}
                                    onPageChange={this.handlePageClick.bind()}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={3}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination-w365"
                                    pageLinkClassName="page-num"
                                    previousLinkClassName="page-num"
                                    nextLinkClassName="page-num"
                                    activeLinkClassName="active pagination-a"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Messages.propTypes = {
  getMessages: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  player: state.player,
  unreadMessage: state.auth.unreadMessage,
});

export default connect(
  mapStateToProps,
  { getMessages, setFilter, getUnreadMessage, resetMessages, selectPage }
)(withTranslation()(withRouter(Messages)));
