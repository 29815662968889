import { SESSION_ERROR, SESSION_ERROR_RESET } from "./types";

export const resetSessionError = payload => {
  return {
    type: SESSION_ERROR_RESET
  };
};

export const showSessionModal = payload => {
  return {
    type: SESSION_ERROR
  };
};
