import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch  } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProviders, postPlay, postOpen, clearResult } from "../../../actions/casino";
import { ToastContainer, toast } from 'react-toastify';

const Message = ({ closeMessage, onSubmit }) => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { providers } = useSelector((state) => state.casino);
    const { transactionHistory } = useSelector((state) => state);
    const { result } = useSelector((state) => state.casino);
    const [toastz, setToastz] = useState(false);
    const [gamez, setGame] = useState(false);
    const language = i18n.language;

    const { history } = useSelector((state) => state.rebate);
    const data = providers.filter((val) => {
        return history.data.some((item, key) => {
            if(key < 5) {
                return val.ID === item.ID;
            }
            return false;
        })
    })

    useEffect(() => {
        dispatch({type:"REBATE_HISTORY_GET"});
    }, []);

    useEffect(() => {
        if(toastz === true) {
            if(result === false) {
                toast.error("Error opening game. Please try again1.", {
                    className: 'toast toast-error'
                });
            } else {
                const params = JSON.parse(localStorage.getItem('slots'));
                var bc = new BroadcastChannel(params.systemName);
                bc.postMessage('load');
                bc.onmessage = function (ev) { 
                    if(ev.data === 'true') {
                        bc.postMessage('200');
                    }
                }
                const check = localStorage.getItem(params.systemName);
                if(!check) {
                    if(params.provider === 5) {
                        window.open(`http://${window.location.hostname}:${window.location.port}/playtech-games/play/${params.hashid}`, "_blank");
                        localStorage.setItem(params.systemName, true);
                    } else {
                        if(result.url !== undefined) {
                            if(result.is_iframe === 2 || params.provider === 20 || params.provider === 12) {
                                window.open(result.url, "_blank");
                            } else {
                                window.open(`http://${window.location.hostname}:${window.location.port}/live-casino/play/${params.hashid}`, "_blank");
                                localStorage.setItem(params.systemName, true);
                            }
                        }
                    }
                    dispatch(clearResult());
                }
            }
        }
        setGame(false);
        setToastz(false);
    }, [result]);

    const playdz = (game) => {
        setGame(game);
        startGame(game);
    }

    const startGame = (game) => {
        let params;
        const languages = {
            "en-US": "en",
            "ko-KR": "kr"
        };
        
        
        params = {
            hashid: game.Hash,
            type: game.GameTypeCode,
            language: languages[language],
            provider: game.ID,
            product: 2,
            systemName: game.SystemName,
            gameID: "bal",
        };

        localStorage.setItem("slots", JSON.stringify(params));
        
        dispatch(postPlay(params));
        setToastz(true);
    }

    return (
        <div class="col-md-6 accountInfo mb-5" id="deposit-message">
            <div class="depSentContent">

                <div class="depSent">
                    <h2 class="requestSentTitle"> <i class="fas fa-check-circle"></i> {t('transfer message title')}</h2>
                </div>

                <p class="requestTransSub pt-3">{t('transfer message')}</p>

                <div class="action-btn-modal text-center">
                    <button class="btn btn-confirm-deposit cancelBtn" type="reset"  onClick={closeMessage}>{t('close')}</button>
                </div>

                <div class="col-md-12 mt-4">
                     <p class="quickLinkTitle">{t('recently games played')}</p>
                     {
                        data.map((val, key) => {
                            return (
                                <div className="quickLinks" key={key}>
                                    <div className=" inline">
                                        <div className="col-md-3">
                                            <img src={val.Logo} className="img-fluid imgTransfer" alt={val.Slug}/>
                                        </div>
                                        <div class="col-md-9">
                                                {
                                                    val.Product.map((product, key) => {
                                                        let productType = "";
                                                        let link = "";
                                                        let textPlay = language === "ko-KR" ? "textPlay-kr" : "textPlay";
                                                        if (product.GameProductID === 2) {
                                                            productType = language === "ko-KR" ? "btnLive-kr" : "btnLive";
                                                            return (
                                                                <div key={key} className={`${textPlay}`}>
                                                                    <Link className={`btn ButtonStyle ${productType}`}
                                                                        onClick={() => {
                                                                            playdz(val);
                                                                        }}
                                                                    >
                                                                    </Link> 
                                                                </div>
                                                            );
                                                        } else if (product.GameProductID === 3) {
                                                            productType = language === "ko-KR" ? "btnSlot-kr" : "btnSlot";
                                                            link = "/slot-games/provider";
                                                        } else if (product.GameProductID === 9) {
                                                            productType = language === "ko-KR" ? "btnEsports-kr" : "btnEsports";
                                                        } else if (product.GameProductID === 6) {
                                                            productType = language === "ko-KR" ? "btnFishHunter-kr" : "btnFishHunter";
                                                            link = "/fish-hunter/provider";
                                                        } else if (product.GameProductID === 7) {
                                                            productType = language === "ko-KR" ? "btnP2P-kr" : "btnP2P";
                                                            link = "/p2p/provider";
                                                        } else if (product.GameProductID === 8) {
                                                            productType = language === "ko-KR" ? "btnArcade-kr" : "btnArcade";
                                                            link = "/arcade/provider";
                                                        } else if (product.GameProductID === 1) {
                                                            productType = language === "ko-KR" ? "btnSports-kr" : "btnSports";
                                                        } else if (product.GameProductID === 10) {
                                                            productType = language === "ko-KR" ? "btnPowerball-kr" : "btnPowerball";
                                                        }
                                                        if(product.GameProductID !== 5) {
                                                            return (
                                                                <div key={key} className={`${textPlay}`}><Link to={link + "/" + val.Slug} className={`btn ButtonStyle ${productType}`}></Link></div>
                                                            );
                                                        }
                                                    })
                                                }
                                        </div>
                                    </div>
                                </div> 
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default Message;
