import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";
import { gmtParams } from "../helpers";

export { getRebateHistory, getRebateBalance, postRebateBalance, getRebateMinTransfer, rebateTransferDataCheck, processRebateTransfer };

function getRebateHistory(params) {
    params = gmtParams(params);
    return axios.get(`${ROOT_URL}/transfer/history`,{
      headers: {
        Authorization: `Bearer ${findToken()}`
      }, params
    });
}

function getRebateBalance() {
    return axios.get(`${ROOT_URL}/getRebateBalance`,{
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    });
}

function postRebateBalance(params) {
    return axios.post(`${ROOT_URL}/transferRebate`, params, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    });
}

function getRebateMinTransfer() {
    return axios.get(`${ROOT_URL}/getRebateMinimum`,{
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    });
}

function rebateTransferDataCheck() {
  return axios.get(`${ROOT_URL}/rebate-transfer-check`,{
    headers: {
      Authorization: `Bearer ${findToken()}`
    }
  });
}

function processRebateTransfer(params) {
  return axios.post(`${ROOT_URL}/rebate-transfer`, params, {
    headers: {
      Authorization: `Bearer ${findToken()}`
    }
  });
}
