import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    getP2PGames,
    getP2PRecent
} from "../api/p2p"

import {
    REQUEST_POST_P2P,
    GET_P2P_GAMES,
    P2P_GAME_SUCCESS,
    P2P_GAME_ERRORS,
    P2P_SUCCESS,
    P2P_ERRORS,
    GET_P2P_RECENT,
    GET_ERRORS,
    P2P_RECENT_ERRORS,
    P2P_RECENT_SUCCESS
} from "../actions/types";

import { auth } from "../selectors/auth.js";

export {
    p2pWatcher
};

function* p2pWatcher() {
    yield takeLatest(GET_P2P_GAMES, fetchGames)
    yield takeLatest(GET_P2P_RECENT, fetchRecent)
}

function* fetchGames(param){
    try{
        const result = yield call(getP2PGames, { product: param.payload.product });
            yield put({
                type: P2P_GAME_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: P2P_GAME_ERRORS,
                payload: {
                    ...error.response.data,
                    status: error.response.status
                }
            });
    }
}

function* fetchRecent(param){
    try{
        const result = yield call(getP2PRecent, { product: param.payload.product });
            yield put({
                type: P2P_RECENT_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: P2P_RECENT_ERRORS,
                payload: {
                    ...error.response.data,
                    status: error.response.status
                }
            });
    }
}
