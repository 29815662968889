import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    postPlay,
    getProviders,
    getRecent,
    postOpen
} from "../api/virtual"

import {
    REQUEST_POST_VIRTUAL,
    GET_VIRTUAL_GAMES,
    VIRTUAL_GAME_SUCCESS,
    VIRTUAL_GAME_ERRORS,
    VIRTUAL_SUCCESS,
    VIRTUAL_ERRORS,
    GET_VIRTUAL_RECENT,
    GET_ERRORS,
    VIRTUAL_RECENT_ERRORS,
    VIRTUAL_RECENT_SUCCESS,
    REQUEST_POST_VIRTUAL_OPEN
} from "../actions/types";

import { auth } from "../selectors/auth.js";
const brand = process.env.REACT_APP_BRAND

export {
    virtualWatcher
};

function* virtualWatcher() {
    yield takeLatest(REQUEST_POST_VIRTUAL, fetchPlay)
    yield takeLatest(GET_VIRTUAL_GAMES, fetchGames)
    yield takeLatest(GET_VIRTUAL_RECENT, fetchRecent)
    yield takeLatest(REQUEST_POST_VIRTUAL_OPEN, fetchOpen)
}

function* fetchOpen(action) {
    const { wallet } = yield select(auth);
  
    try {
        const result = yield call(postOpen,{
                "hashid": action.payload.hashid,
                "gameType": action.payload.type,
                "gameID": action.payload.gameID,
                "lang": action.payload.language,
                "launchType": action.payload.launchtype,
                "currency": "krw",
                "product": action.payload.product,
                "provider": action.payload.provider,
                "systemName": action.payload.systemName,
                "id": action.payload.id,
                "brandID": brand,
                "domain": window.location.protocol + "//" + window.location.hostname
            });
            yield put({
                type: VIRTUAL_SUCCESS,
                payload: result.data
            });
    }catch(error){
            yield put({
                type: VIRTUAL_ERRORS,
                payload: error
            });
    }
}

function* fetchGames(param){
    try{
        const result = yield call(getProviders, { product: param.payload.product });
            yield put({
                type: VIRTUAL_GAME_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: VIRTUAL_GAME_ERRORS,
                payload: error
            });
    }
}

function* fetchPlay(action) {
    const { wallet } = yield select(auth);
    try {
        const result = yield call(postPlay,{
                "playerID": wallet.player.PlayerID,
                "username": wallet.username,
                "hashid": action.payload.hashid,
                "gameType": action.payload.type,
                "gameID": action.payload.gameID,
                "lang": action.payload.language,
                "launchType": action.payload.launchtype,
                "currency": "krw",
                "product": action.payload.product,
                "provider": action.payload.provider,
                "systemName": action.payload.systemName,
                "id": action.payload.id,
                "brandID": brand,
                "domain": window.location.protocol + "//" + window.location.hostname
            });
            yield put({
                type: VIRTUAL_SUCCESS,
                payload: result.data
            });
    }catch(error){
            yield put({
                type: VIRTUAL_ERRORS,
                payload: error
            });
    }
}

function* fetchRecent(param){
    try{
        const result = yield call(getRecent, { product: param.payload.product });
            yield put({
                type: VIRTUAL_RECENT_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: VIRTUAL_RECENT_ERRORS,
                payload: error.response.data
            });
    }
}
