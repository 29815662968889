import React, { Component } from "react";  
import { connect } from "react-redux";  
import { withRouter } from "react-router-dom";  
import PropTypes from "prop-types";  
import { withTranslation } from 'react-i18next';  
import { getProductProviders } from "../../../actions/product"; 
import {  displayAmount, thousandAmount } from "../../../helpers";
  
import {  
  selectDate,  
  selectType,  
  selectPage,  
  getTransactionHistory  
} from "../../../actions/transaction";  
  
import { Paginate } from "../../common";  
import FilterSection from "./filter";  
import ReactPaginate from "react-paginate";
  
class TransactionHistory extends Component {  
    state = {  
        transactionHistory: {  
            data: [],  
            meta: [],  
            links: [],  
            products: {}  
        }  
    }  
  
    componentDidMount() {  
        this.props.getTransactionHistory();  
        this.props.getProductProviders();  
    }  
  
    componentWillReceiveProps(nextProps) {  
        const { transactionHistory } = nextProps.transactionHistory;  
         
        this.setState({products: nextProps.products.result});  
        this.setState({  
            transactionHistory: transactionHistory.data,  
            options: { ...transactionHistory.meta, ...transactionHistory.links }  
        });  
    }  

    handlePageClick = (e) => {
        this.props.selectPage(e.selected + 1)
        this.props.getTransactionHistory();  
    };
  
    render() {  
        let { transactionHistory, options } = this.state;  
        const { t } = this.props;  
 
        let pageCount = 0;
        if (options) {
            pageCount = [...Array(options.last_page).keys()].length;
        }
  
        return (  
  
            <div className="transaction-history">
                <h2 className="form-title">{t('transaction history')}</h2>
                <div className="accountInfo accountInfo-w365">
                    <div id="account-details">
                        <form>
                            <div className="row">
                                <FilterSection />
                            </div>
                        
                        <div className="row mt-4">
                            <div className="col">
                                <div>
                                    <table className="table table-striped table-dark">
                                        <thead>
                                        <tr>
                                            <th scope="col">{t('transaction id')}</th>
                                            <th scope="col">{t('date')}</th>
                                            <th scope="col">{t('transaction type')}</th>
                                            {/* <th scope="col">{t('from')}</th>
                                            <th scope="col">{t('to')}</th> */}
                                            <th scope="col" className="txt-align-right">{t('amount')}</th>
                                            <th scope="col">{t('status')}</th>
                                        </tr>
                                        </thead>
                                        {transactionHistory.length > 0 ? (  
                                                        <tbody>  
                                                            {transactionHistory.map((history, id) => {  
                                                                return (  
                                                                    <tr key={id}>  
                                                                        
                                                                        <td>{history.ReferenceNo}</td>  
                                                                        <td>{history.Date}</td>  
                                                                        <td> {t(history.TransactionType.toLowerCase())} </td>  
                                                                        {/* <td>{history.From}</td>  
                                                                        <td>{history.To}</td>   */}
                                                                        <td className={"text-right"}>  
                                                                            {thousandAmount((history.Amount))}  
                                                                        </td>  
                                                                        <td style={{ width: "15%"}}>  
                                                                            <span style={{ color: history.Status === 'Approved' ? '#33c16c' : history.Status === 'Pending' ? '#C13333' : history.Color }}>  
                                                                            {t(history.Status.toLowerCase())}  
                                                                            </span>  
                                                                        </td>  
                                                                    </tr>  
                                                                );  
                                                            })}  
                                                        </tbody>  
                                                    ) : (  
                                                        <tbody className="text-center">  
                                                            <tr>  
                                                                <td colSpan="12">  
                                                                    <h4>{t('no results found')}</h4>  
                                                                </td>  
                                                            </tr>  
                                                        </tbody>  
                                                    )}  
                                    </table>
                                </div>
                            </div>
                        </div>


                        {/* <div className="col-md-12">  
                            <Paginate options={options} selectPage={(page) => {  
                                this.props.selectPage(page);  
                                this.props.getTransactionHistory();  
                                }  
                            }/>  
                        </div> */}

                        <div className="col-md-12 pagination-col mt-3">
                            <div className="pagination-box">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    initialPage={0}
                                    onPageChange={this.handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={3}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName="pagination-w365"
                                    pageLinkClassName="page-num"
                                    previousLinkClassName="page-num"
                                    nextLinkClassName="page-num"
                                    activeLinkClassName="active pagination-a"
                                />
                            </div>
                        </div>

                         </form>
                    </div>

                </div>
            </div>
  
        );  
    }  
}  
  
TransactionHistory.propTypes = {  
    getTransactionHistory: PropTypes.func.isRequired,  
    selectType: PropTypes.func,  
    transactionHistory: PropTypes.object,  
    transactionStatus: PropTypes.object,  
    transactionStatusColor: PropTypes.object,  
    transactionTypes: PropTypes.object  
};  
  
const mapStateToProps = state => ({  
    ...state  
});  
  
export default connect(  
    mapStateToProps,  
    { getTransactionHistory, getProductProviders, selectType, selectDate, selectPage }  
)(withRouter(withTranslation() (TransactionHistory)));  
  
 
 
