import {
    GET_ERRORS,
    SESSION_ERROR,
    SESSION_ERROR_RESET
} from "../actions/types";

const initialState = {
    loggedOut: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;
    case SESSION_ERROR:
          return {
              ...state,
              loggedOut: true
          };
    case SESSION_ERROR_RESET:
          return {
              loggedOut: false
          };
    default:
      return state;
  }
}
