import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    getArcadeGames,
    getArcadeRecent
} from "../api/arcade"

import {
    REQUEST_POST_ARCADE,
    GET_ARCADE_GAMES,
    ARCADE_GAME_SUCCESS,
    ARCADE_GAME_ERRORS,
    ARCADE_SUCCESS,
    ARCADE_ERRORS,
    GET_ARCADE_RECENT,
    GET_ERRORS,
    ARCADE_RECENT_ERRORS,
    ARCADE_RECENT_SUCCESS
} from "../actions/types";

import { auth } from "../selectors/auth.js";

export {
    arcadeWatcher
};

function* arcadeWatcher() {
    yield takeLatest(GET_ARCADE_GAMES, fetchArcadeGames)
    yield takeLatest(GET_ARCADE_RECENT, fetchArcadeRecent)
}

function* fetchArcadeGames(param){
    try{
        const result = yield call(getArcadeGames, { product: param.payload.product });
            yield put({
                type: ARCADE_GAME_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: ARCADE_GAME_ERRORS,
                payload: error
            });
    }
}

function* fetchArcadeRecent(param){
    try{
        const result = yield call(getArcadeRecent, { product: param.payload.product });
            yield put({
                type: ARCADE_RECENT_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: ARCADE_RECENT_ERRORS,
                payload: error.response.data
            });
    }
}
