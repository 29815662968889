import { OPENING_BETCONSTRUCT_SUCCESS, OPENING_BETCONSTRUCT_ERROR, RESET_BETCONSTRUCT } from '../actions/types';

const initialState = {
    url: null,
    loading: true,
    success: false,
    errors: {}
};

const betConstructReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPENING_BETCONSTRUCT_SUCCESS:
            return {
                ...state,
                url: action.url,
                loading: false,
                success: true,
                errors: {}
            }
        case OPENING_BETCONSTRUCT_ERROR:
            return {
                ...initialState,
                loading: false,
                success: false,
                errors: action.errors
            }
        case RESET_BETCONSTRUCT:
            return {
                ...initialState
            }
        default:
            return state;
    }
};

export default betConstructReducer;
