import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";
import { gmtParams } from "../helpers";

export function getTransactions(params){
  params = gmtParams(params);
  return axios
    .get(`${ROOT_URL}/transactions`, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }, params
    });
}
