export const GET_ERRORS = "GET_ERRORS";
export const GET_USER = "GET_USER";
export const GET_MESSAGES = "GET_MESSAGES";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";
export const MESSAGES_SUCCESS = "MESSAGES_SUCCESS";
export const MESSAGES_ERRORS = "MESSAGES_ERRORS";
export const SET_MESSAGE_DATE = "SET_MESSAGE_DATE";
export const SET_MESSAGE_TYPE = "SET_MESSAGE_TYPE";
export const SET_MESSAGES_CURRENT_PAGE = "SET_MESSAGES_CURRENT_PAGE";
export const SET_MESSAGES_FILTER = "SET_MESSAGES_FILTER";
export const RESET_MESSAGES_FILTER = "RESET_MESSAGES_FILTER";
export const DELETE_USER = "DELETE_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const RESET_CURRENT_USER = "RESET_CURRENT_USER";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const KICK_USER = "KICK_USER"

export const SET_SUBSCRIPTION_MODAL = "SET_SUBSCRIPTION_MODAL";

export const GET_ACTIVE_PROVIDERS = "GET_ACTIVE_PROVIDERS";
export const SET_ACTIVE_PROVIDERS = "SET_ACTIVE_PROVIDERS";
export const ACTIVE_PROVIDERS_SUCCESS = "ACTIVE_PROVIDERS_SUCCESS";
export const ACTIVE_PROVIDERS_ERRORS = "ACTIVE_PROVIDERS_ERRORS";

export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const TOGGLE_MODAL_TO = "TOGGLE_MODAL_TO";
export const TOGGLE_MODAL_FROM = "TOGGLE_MODAL_FROM";

export const GET_BONUS = "GET_BONUS";
export const GET_CASH = "GET_CASH";
export const SET_CASH_PAGE = "SET_CASH_PAGE";
export const CASH_SUCCESS = "CASH_SUCCESS";
export const CASH_ERRORS = "CASH_ERRORS";

export const GET_BONUS_HISTORY = "GET_BONUS_HISTORY";
export const BONUS_HISTORY_SUCCESS = "BONUS_HISTORY_SUCCESS";
export const BONUS_HISTORY_ERRORS = "BONUS_HISTORY_ERRORS";
export const SET_BONUS_HISTORY_PAGE = "SET_BONUS_HISTORY_PAGE";
export const BONUS_SET_ONGOING = "BONUS_SET_ONGOING";

export const SET_BONUS_PAGE = "SET_BONUS_PAGE";
export const SET_BONUS_FILTER = "SET_BONUS_FILTER";
export const RESET_BONUS_FILTER = "RESET_BONUS_FILTER";

export const REBATE_POST = "REBATE_POST";
export const REBATE_POST_SUCCESS = "REBATE_POST_SUCCESS";
export const REBATE_POST_ERRORS = "REBATE_POST_ERRORS";
export const REBATE_GET = "REBATE_GET";
export const REBATE_SET_PAGE = "REBATE_SET_PAGE";
export const REBATE_SET_FILTER = "REBATE_SET_FILTER";
export const REBATE_RESET_FILTER = "REBATE_RESET_FILTER";
export const REBATE_SUCCESS = "REBATE_SUCCESS";
export const REBATE_ERRORS = "REBATE_ERRORS";
export const REBATE_HISTORY_GET = "REBATE_HISTORY_GET";
export const REBATE_HISTORY_SUCCESS = "REBATE_HISTORY_SUCCESS";
export const REBATE_HISTORY_ERRORS = "REBATE_HISTORY_ERRORS";
export const REBATE_GET_MIN_TRANSFER = "REBATE_GET_MIN_TRANSFER";
export const REBATE_SET_MIN_TRANSFER = "REBATE_SET_MIN_TRANSFER";
export const REBATE_PROCESS_MESSAGE = "REBATE_PROCESS_MESSAGE";

export const BONUS_SUCCESS = "BONUS_SUCCESS";
export const BONUS_ERRORS = "BONUS_ERRORS";
export const BONUS_CHECK = "BONUS_CHECK";

export const RESET_BONUS_ERRORS = "RESET_BONUS_ERRORS";
export const REQUEST_POST_BONUS_REDEEM = "REQUEST_POST_BONUS_REDEEM";
export const BONUS_REDEEM_SUCCESS = "BONUS_REDEEM_SUCCESS";
export const BONUS_REDEEM_SUCCESS_RESET = "BONUS_REDEEM_SUCCESS_RESET";
export const BONUS_REDEEM_ERRORS = "BONUS_REDEEM_ERRORS";
export const REQUEST_POST_SUBSCRIPTION_CANCEL = "REQUEST_POST_SUBSCRIPTION_CANCEL";
export const SUBSCRIPTION_CANCEL_SUCCESS = "SUBSCRIPTION_CANCEL_SUCCESS";
export const SUBSCRIPTION_CANCEL_ERRORS = "SUBSCRIPTION_CANCEL_ERRORS";

export const REQUEST_POST_CASINO = "REQUEST_POST_CASINO";
export const REQUEST_POST_CASINO_OPEN = "REQUEST_POST_CASINO_OPEN";
export const REQUEST_POST_FAVOURITE = "REQUEST_POST_FAVOURITE";
export const CASINO_SUCCESS = "CASINO_SUCCESS";
export const CASINO_ERRORS = "CASINO_ERRORS";
export const CASINO_RESET_ERRORS = "CASINO_RESET_ERRORS";

export const FAVOURITE_SUCCESS = "FAVOURITE_SUCCESS";
export const FAVOURITE_ERRORS = "FAVOURITE_ERRORS";

export const GET_FAVOURITE_PROVIDERS = "GET_FAVOURITE_PROVIDERS";
export const SET_FAVOURITE_PROVIDERS = "SET_FAVOURITE_PROVIDERS";

export const MAIN_WALLET_SPIN = "MAIN_WALLET_SPIN"

export const GET_CASINO_GAMES = "GET_CASINO_GAMES";
export const GET_CASINO_PROVIDERS = "GET_CASINO_PROVIDERS";
export const GET_CASINO_CATEGORIES = "GET_CASINO_CATEGORIES";
export const GET_CASINO_MINI_CATEGORIES = "GET_CASINO_MINI_CATEGORIES";
export const CASINO_GAME_SUCCESS = "CASINO_GAMES_SUCCESS";
export const CASINO_GAME_ERRORS = "CASINO_GAMES_ERRORS";
export const CASINO_PROVIDER_SUCCESS = "CASINO_PROVIDER_SUCCESS";
export const CASINO_PROVIDER_ERRORS = "CASINO_PROVIDER_ERRORS";
export const CASINO_CATEGORY_SUCCESS = "CASINO_CATEGORY_SUCCESS";
export const CASINO_CATEGORY_ERRORS = "CASINO_CATEGORY_ERRORS";
export const CASINO_GAME_UPDATE = "CASINO_GAME_UPDATE";
export const GET_CASINO_GAME_LIST = "GET_CASINO_GAME_LIST";
export const CASINO_GAME_LIST_SUCCESS = "CASINO_GAME_LIST_SUCCESS";
export const CASINO_GAME_LIST_ERRORS = "CASINO_GAME_LIST_ERRORS";
export const CASINO_CLEAR_RESULT = "CASINO_CLEAR_RESULT";

export const GET_PRODUCT_PROVIDERS = "GET_PRODUCT_PROVIDERS";
export const PRODUCT_PROVIDER_ERRORS = "PRODUCT_PROVIDER_ERRORS";
export const PRODUCT_PROVIDER_SUCCESS = "PRODUCT_PROVIDER_SUCCESS";

export const GET_JACKPOT_GAMES = "GET_JACKPOT_GAMES";

export const JACKPOT_SUCCESS = "JACKPOT_SUCCESS";
export const JACKPOT_ERRORS = "JACKPOT_ERRORS";

export const REQUEST_POST_MINI = "REQUEST_POST_MINI";
export const MINI_SUCCESS = "MINI_SUCCESS";
export const MINI_ERRORS = "MINI_ERRORS";
export const GET_MINI_GAMES = "GET_MINI_GAMES";
export const MINI_GAME_SUCCESS = "MINI_GAMES_SUCCESS";
export const MINI_GAME_ERRORS = "MINI_GAMES_ERRORS";

export const REQUEST_POST_SPORTS = "REQUEST_POST_SPORTS";
export const REQUEST_POST_SPORTS_PLAY = "REQUEST_POST_SPORTS_PLAY";
export const SPORTS_SUCCESS = "SPORTS_SUCCESS";
export const SPORTS_ERRORS = "SPORTS_ERRORS";
export const SPORTS_CLEAR = "SPORTS_CLEAR";
export const REQUEST_POST_TRANSFER = "REQUEST_POST_TRANSFER";
export const REQUEST_POST_TRANSFER_ALL = "REQUEST_POST_TRANSFER_ALL";
export const REQUEST_POST_TRANSFER_WALLET = "REQUEST_POST_TRANSFER_WALLET";
export const TRANSFER_SUCCESS = "TRANSFER_SUCCESS";
export const TRANSFER_ERRORS = "TRANSFER_ERRORS";
export const TRANSFER_MODAL_RESET = "TRANSFER_MODAL_RESET";
export const TRANSFER_MODAL_SUCCESS = "TRANSFER_MODAL_SUCCESS";

export const WALLET_GET = "WALLET_GET";
export const WALLET_SUCCESS = "WALLET_SUCCESS";
export const WALLET_ERRORS = "WALLET_ERRORS";
export const WALLET_CLEAR = "WALLET_CLEAR";
export const PROCESS_WALLET_MESSAGE = "PROCESS_WALLET_MESSAGE";
export const PROCESS_MAIN_WALLET_MESSAGE = "PROCESS_MAIN_WALLET_MESSAGE";

export const GET_BANNERS = "GET_BANNERS";
export const SET_BANNERS = "SET_BANNERS";

export const GET_BANKS = "GET_BANKS";
export const SET_BANKS = "SET_BANKS";
export const BANKS_ERRORS = "BANKS_ERRORS";

export const GET_PAGES = "GET_PAGES";
export const GET_PARTNER_PAGES = "GET_PARTNER_PAGES";
export const GET_PAGE = "GET_PAGE";
export const PAGES_SUCCESS = "PAGES_SUCCESS";
export const PAGES_ERRORS = "PAGES_ERRORS";
export const PAGE_SUCCESS = "PAGE_SUCCESS";
export const PARTNER_PAGES_SUCCESS = "PARTNER_PAGES_SUCCESS";
export const PAGE_ERRORS = "PAGE_ERRORS";

export const GET_PROMO_BANNERS = "GET_PROMO_BANNERS";
export const SET_PROMO_BANNERS = "SET_PROMO_BANNERS";
export const GET_PROMO_CONTENT = "GET_PROMO_CONTENT";
export const SET_PROMO_CONTENT = "SET_PROMO_CONTENT";
export const GET_PROMO_MIN_DEPOSIT = "GET_PROMO_MIN_DEPOSIT";
export const SET_PROMO_MIN_DEPOSIT = "SET_PROMO_MIN_DEPOSIT";
export const RESET_PROMO_MIN_DEPOSIT = "RESET_PROMO_MIN_DEPOSIT";
export const RESET_RESEND_DEPOSIT = "RESET_RESEND_DEPOSIT";
export const RESET_RESEND_ERROR = "RESET_RESEND_ERROR";

export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const SET_PROMOTIONS = "SET_PROMOTIONS";
export const RESET_PROMOTIONS = "RESET_PROMOTIONS";
export const LOAD_PROMOTIONS = "LOAD_PROMOTIONS";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT = "SET_PRODUCT";
export const PROMOTION_ERRORS = "PROMOTION_ERRORS";
export const PRODUCT_ERRORS = "PRODUCT_ERRORS";

export const GET_PROVIDERS = "GET_PROVIDERS";
export const SET_PROVIDERS = "SET_PROVIDERS";
export const PROVIDERS_ERRORS = "PROVIDERS_ERRORS";
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_PROVIDER1 = "SET_PROVIDER1";
export const SET_PROVIDER2 = "SET_PROVIDER2";

export const GET_BRAND_CURRENCY = "GET_BRAND_CURRENCY";
export const SET_BRAND_CURRENCY = "SET_BRAND_CURRENCY";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const GET_PARTNER = "GET_PARTNER";
export const SET_PARTNER = "SET_PARTNER";
export const GET_CAN_REGISTER = "GET_CAN_REGISTER";
export const SET_CAN_REGISTER = "SET_CAN_REGISTER";
export const REGISTER = "REGISTER";
export const REGISTER_SET_PAGE = "REGISTER_SET_PAGE";
export const REQUEST_POST_REGISTER = "REQUEST_POST_REGISTER";
export const REQUEST_POST_REGISTER_AFFILIATE = "REQUEST_POST_REGISTER_AFFILIATE";
export const VERIFY = "VERIFY";
export const REQUEST_POST_VERIFY = "REQUEST_POST_VERIFY";
export const REQUEST_POST_VERIFY_AFFILIATE = "REQUEST_POST_VERIFY_AFFILIATE";
export const GET_REGISTER_ERRORS = "GET_REGISTER_ERRORS";
export const GET_REGISTER_SUCCESS = "GET_REGISTER_SUCCESS";
export const GET_REGISTER2_ERRORS = "GET_REGISTER2_ERRORS";
export const REGISTER_RESET_ERROR = "REGISTER_RESET_ERROR";

export const REQUEST_POST_PLAYER = "REQUEST_POST_PLAYER";
export const REQUEST_POST_ACCOUNT = "REQUEST_POST_ACCOUNT";
export const REQUEST_POST_PASSWORD = "REQUEST_POST_PASSWORD";

export const GET_TEMPLATE_CONTENT = "GET_TEMPLATE_CONTENT";
export const SET_TEMPLATE_CONTENT = "SET_TEMPLATE_CONTENT";
export const RESET_TEMPLATE_CONTENT = "RESET_TEMPLATE_CONTENT";

export const PLAYER_SUCCESS = "PLAYER_SUCCESS";
export const PLAYER_RESET_ERRORS = "PLAYER_RESET_ERRORS";
export const PLAYER_ERRORS = "PLAYER_ERRORS";

export const PASSWORD_SUCCESS = "PASSWORD_SUCCESS";
export const PASSWORD_ERRORS = "PASSWORD_ERRORS";

export const BANK_SUCCESS = "BANK_SUCCESS";
export const BANK_ERRORS = "BANK_ERRORS";

export const REQUEST_POST_DEPOSIT = "REQUEST_POST_DEPOSIT";
export const RESET_DEPOSIT_SUCCESS = "RESET_DEPOSIT_SUCCESS";
export const DEPOSIT_SUCCESS = "DEPOSIT_SUCCESS";
export const DEPOSIT_ERRORS = "DEPOSIT_ERRORS";
export const DEPOSIT_CHECK = "DEPOSIT_CHECK";
export const REQUEST_DEPOSIT_CHECK = "REQUEST_DEPOSIT_CHECK";
export const RESET_DEPOSIT_ERROR = "RESET_DEPOSIT_ERROR";
export const RESET_WITHDRAWAL_ERROR = "RESET_WITHDRAWAL_ERROR";

export const REQUEST_POST_RESEND = "REQUEST_POST_RESEND";
export const RESEND_SUCCESS = "RESEND_SUCCESS";
export const RESEND_ERRORS = "RESEND_ERRORS";

export const RESET_WITHDRAWAL_SUCCESS = "RESET_WITHDRAWAL_SUCCESS";
export const WITHDRAWAL_SUCCESS = "WITHDRAWAL_SUCCESS";
export const WITHDRAWAL_ERRORS = "WITHDRAWAL_ERRORS";
export const WITHDRAWAL_CHECK = "WITHDRAWAL_CHECK";
export const REQUEST_WITHDRAWAL_CHECK = "REQUEST_WITHDRAWAL_CHECK";
export const REQUEST_POST_WITHDRAWAL = "REQUEST_POST_WITHDRAWAL";

export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
export const SET_BANK_ACCOUNT = "SET_BANK_ACCOUNT";

export const GET_PLAYER = "GET_PLAYER";
export const SET_PLAYER = "SET_PLAYER";

export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const SET_TRANSACTION_HISTORY = "SET_TRANSACTION_HISTORY";
export const SET_TRANSACTION_STATUS = "SET_TRANSACTION_STATUS";
export const SET_TRANSACTION_PAGE = "SET_TRANSACTION_PAGE";
export const SET_TRANSACTION_TYPE = "SET_TRANSACTION_TYPE";
export const SET_TRANSACTION_DATE = "SET_TRANSACTION_DATE";
export const SET_TRANSACTION_FILTER = "SET_TRANSACTION_FILTER";
export const SET_INITIAL_TRANSACTION = "SET_INITIAL_TRANSACTION";
export const TRANSACTION_HISTORY_ERRORS = "TRANSACTION_HISTORY_ERRORS";

export const SESSION_ERROR = "SESSION_ERROR";
export const SESSION_ERROR_RESET = "SESSION_ERROR_RESET";

export const SLOTS_GAME_SUCCESS = "SLOTS_GAMES_SUCCESS";
export const SLOTS_GAME_ERRORS = "SLOTS_GAMES_ERRORS";
export const GET_SLOTS_GAMES = "GET_SLOTS_GAMES";
export const UPDATE_SLOTS_GAMES = "UPDATE_SLOTS_GAMES";
export const UPDATE_SLOTS_RECENT = "UPDATE_SLOTS_RECENT";

export const SLOTS_RECENT_SUCCESS = "SLOTS_RECENT_SUCCESS";
export const SLOTS_RECENT_ERRORS = "SLOTS_RECENT_ERRORS";
export const GET_SLOTS_RECENT = "GET_SLOTS_RECENT";

export const GET_SLOTS_CATEGORIES = "GET_SLOTS_CATEGORIES";
export const SLOTS_CATEGORIES_SUCCESS = "SLOTS_CATEGORIES_SUCCESS";
export const SLOTS_CATEGORIES_ERRORS = "SLOTS_CATEGORIES_ERRORS";

export const REQUEST_POST_PARTNER_CONTACT = "REQUEST_POST_PARTNER_CONTACT";
export const PARTNER_CONTACT_ERRORS = "PARTNER_CONTACT_ERRORS";
export const PARTNER_CONTACT_SUCCESS = "PARTNER_CONTACT_SUCCESS";
export const PARTNER_CONTACT_SUCCESS_RESET = "PARTNER_CONTACT_SUCCESS_RESET";

export const IP_ADDRESS_SUCCESS = "IP_ADDRESS_SUCCESS";
export const IP_ADDRESS_ERRORS = "IP_ADDRESS_ERRORS";
export const GET_IP_ADDRESS = "GET_IP_ADDRESS";

export const REQUEST_POST_TRANSFER_WITHOUT_SUCCESS = "REQUEST_POST_TRANSFER_WITHOUT_SUCCESS";
export const REQUEST_POST_TRANSFER_WALLET_WITHOUT_SUCCESS = "REQUEST_POST_TRANSFER_WALLET_WITHOUT_SUCCESS";
export const TRANSFER_SUCCESS_WITHOUT_SUCCESS = "TRANSFER_SUCCESS_WITHOUT_SUCCESS";
export const TRANSFER_ERRORS_WITHOUT_SUCCESS = "TRANSFER_ERRORS_WITHOUT_SUCCESS";

export const UPDATE_MAIN_WALLET = "UPDATE_MAIN_WALLET";

export const UPDATE_MINI_GAMES = "UPDATE_MINI_GAMES";
export const GET_MINI_RECENT = "GET_MINI_RECENT";
export const MINI_RECENT_SUCCESS = "MINI_RECENT_SUCCESS";
export const MINI_RECENT_ERRORS = "MINI_RECENT_ERRORS";

export const REQUEST_POST_VIRTUAL = "REQUEST_POST_VIRTUAL";
export const REQUEST_POST_VIRTUAL_OPEN = "REQUEST_POST_VIRTUAL_OPEN";
export const VIRTUAL_SUCCESS = "VIRTUAL_SUCCESS";
export const VIRTUAL_ERRORS = "VIRTUAL_ERRORS";
export const GET_VIRTUAL_GAMES = "GET_VIRTUAL_GAMES";
export const VIRTUAL_GAME_SUCCESS = "VIRTUAL_GAMES_SUCCESS";
export const VIRTUAL_GAME_ERRORS = "VIRTUAL_GAMES_ERRORS";
export const VIRTUAL_CLEAR_RESULT = "VIRTUAL_CLEAR_RESULT";

export const UPDATE_VIRTUAL_GAMES = "UPDATE_VIRTUAL_GAMES";
export const GET_VIRTUAL_RECENT = "GET_VIRTUAL_RECENT";
export const VIRTUAL_RECENT_SUCCESS = "VIRTUAL_RECENT_SUCCESS";
export const VIRTUAL_RECENT_ERRORS = "VIRTUAL_RECENT_ERRORS";

export const RESET_ACTIVE_COUNT = "RESET_ACTIVE_COUNT";


export const REQUEST_POST_P2P = "REQUEST_POST_P2P";
export const P2P_SUCCESS = "P2P_SUCCESS";
export const P2P_ERRORS = "P2P_ERRORS";
export const GET_P2P_GAMES = "GET_P2P_GAMES";
export const P2P_GAME_SUCCESS = "P2P_GAMES_SUCCESS";
export const P2P_GAME_ERRORS = "P2P_GAMES_ERRORS";

export const UPDATE_P2P_GAMES = "UPDATE_P2P_GAMES";
export const GET_P2P_RECENT = "GET_P2P_RECENT";
export const P2P_RECENT_SUCCESS = "P2P_RECENT_SUCCESS";
export const P2P_RECENT_ERRORS = "P2P_RECENT_ERRORS";


export const REQUEST_POST_ARCADE = "REQUEST_POST_ARCADE";
export const ARCADE_SUCCESS = "ARCADE_SUCCESS";
export const ARCADE_ERRORS = "ARCADE_ERRORS";
export const GET_ARCADE_GAMES = "GET_ARCADE_GAMES";
export const ARCADE_GAME_SUCCESS = "ARCADE_GAMES_SUCCESS";
export const ARCADE_GAME_ERRORS = "ARCADE_GAMES_ERRORS";

export const UPDATE_ARCADE_GAMES = "UPDATE_ARCADE_GAMES";
export const GET_ARCADE_RECENT = "GET_ARCADE_RECENT";
export const ARCADE_RECENT_SUCCESS = "ARCADE_RECENT_SUCCESS";
export const ARCADE_RECENT_ERRORS = "ARCADE_RECENT_ERRORS";

export const UPDATE_BADGE_COUNT = "UPDATE_BADGE_COUNT";
export const GET_UNREAD_MESSAGE = "GET_UNREAD_MESSAGE";
export const SET_UNREAD_MESSAGE = "SET_UNREAD_MESSAGE";
export const GET_UNREAD_NOTICE = "GET_UNREAD_NOTICE";
export const SET_UNREAD_NOTICE = "SET_UNREAD_NOTICE";
export const UPDATE_UNREAD_MESSAGE = "UPDATE_UNREAD_MESSAGE";
export const UPDATE_UNREAD_MESSAGE_FALSE = "UPDATE_UNREAD_MESSAGE_FALSE";

export const GET_PLAYER_DEPOSIT_ACCOUNT = "GET_PLAYER_DEPOSIT_ACCOUNT";
export const SET_PLAYER_DEPOSIT_ACCOUNT = "SET_PLAYER_DEPOSIT_ACCOUNT";
export const ERROR_PLAYER_DEPOSIT_ACCOUNT = "ERROR_PLAYER_DEPOSIT_ACCOUNT";
export const CLEAR_PLAYER_DEPOSIT_ACCOUNT = "CLEAR_PLAYER_DEPOSIT_ACCOUNT";

export const GET_LANDING_PAGE_WITHDRAWALS = "GET_LANDING_PAGE_WITHDRAWALS";
export const SET_LANDING_PAGE_WITHDRAWALS = "SET_LANDING_PAGE_WITHDRAWALS";
export const ERROR_LANDING_PAGE_WITHDRAWALS = "ERROR_LANDING_PAGE_WITHDRAWALS";
export const CLEAR_LANDING_PAGE_WITHDRAWALS = "CLEAR_LANDING_PAGE_WITHDRAWALS";
export const UPDATE_LANDING_PAGE_WITHDRAWALS = "UPDATE_LANDING_PAGE_WITHDRAWALS";

export const GET_LANDING_PAGE_WITHDRAWAL_SUMMARY = "GET_LANDING_PAGE_WITHDRAWAL_SUMMARY";
export const SET_LANDING_PAGE_WITHDRAWAL_SUMMARY = "SET_LANDING_PAGE_WITHDRAWAL_SUMMARY";
export const ERROR_LANDING_PAGE_WITHDRAWAL_SUMMARY = "ERROR_LANDING_PAGE_WITHDRAWAL_SUMMARY";
export const CLEAR_LANDING_PAGE_WITHDRAWAL_SUMMARY = "CLEAR_LANDING_PAGE_WITHDRAWAL_SUMMARY";
export const UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY = "UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY";

export const GET_LANDING_PAGE_WINNER_LIST = "GET_LANDING_PAGE_WINNER_LIST";
export const SET_LANDING_PAGE_WINNER_LIST = "SET_LANDING_PAGE_WINNER_LIST";
export const ERROR_LANDING_PAGE_WINNER_LIST = "ERROR_LANDING_PAGE_WINNER_LIST";
export const CLEAR_LANDING_PAGE_WINNER_LIST = "CLEAR_LANDING_PAGE_WINNER_LIST";
export const UPDATE_LANDING_PAGE_WINNER_LIST = "UPDATE_LANDING_PAGE_WINNER_LIST";

export const GET_LANDING_PAGE_WINNER_LIST_30_DAYS = "GET_LANDING_PAGE_WINNER_LIST_30_DAYS";
export const SET_LANDING_PAGE_WINNER_LIST_30_DAYS = "SET_LANDING_PAGE_WINNER_LIST_30_DAYS";
export const ERROR_LANDING_PAGE_WINNER_LIST_30_DAYS = "ERROR_LANDING_PAGE_WINNER_LIST_30_DAYS";
export const CLEAR_LANDING_PAGE_WINNER_LIST_30_DAYS = "CLEAR_LANDING_PAGE_WINNER_LIST_30_DAYS";
export const UPDATE_LANDING_PAGE_WINNER_LIST_30_DAYS = "UPDATE_LANDING_PAGE_WINNER_LIST_30_DAYS";

export const GET_LANDING_PAGE_WINNER_LIST_SUMMARY = "GET_LANDING_PAGE_WINNER_LIST_SUMMARY";
export const SET_LANDING_PAGE_WINNER_LIST_SUMMARY = "SET_LANDING_PAGE_WINNER_LIST_SUMMARY";
export const ERROR_LANDING_PAGE_WINNER_LIST_SUMMARY = "ERROR_LANDING_PAGE_WINNER_LIST_SUMMARY";
export const CLEAR_LANDING_PAGE_WINNER_LIST_SUMMARY = "CLEAR_LANDING_PAGE_WINNER_LIST_SUMMARY";
export const UPDATE_LANDING_PAGE_WINNER_LIST_SUMMARY = "UPDATE_LANDING_PAGE_WINNER_LIST_SUMMARY";

export const GET_PLAYER_DEPOSIT_TRON_BALANCE = "GET_PLAYER_DEPOSIT_TRON_BALANCE";
export const SET_PLAYER_DEPOSIT_TRON_BALANCE = "SET_PLAYER_DEPOSIT_TRON_BALANCE";
export const SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS = "SET_PLAYER_DEPOSIT_TRON_BALANCE_STATUS";
export const INITIAL_PLAYER_DEPOSIT_TRON_BALANCE = "INITIAL_PLAYER_DEPOSIT_TRON_BALANCE";
export const ERROR_PLAYER_DEPOSIT_TRON_BALANCE = "ERROR_PLAYER_DEPOSIT_TRON_BALANCE";
export const LOADING_PLAYER_DEPOSIT_TRON_BALANCE = "LOADING_PLAYER_DEPOSIT_TRON_BALANCE";
export const SUCCESS_PLAYER_DEPOSIT_TRON_BALANCE = "SUCCESS_PLAYER_DEPOSIT_TRON_BALANCE";

export const GET_PLAYER_DEPOSIT_TRON_PURCHASE = "GET_PLAYER_DEPOSIT_TRON_PURCHASE";
export const SET_PLAYER_DEPOSIT_TRON_PURCHASE = "SET_PLAYER_DEPOSIT_TRON_PURCHASE";
export const ERROR_PLAYER_DEPOSIT_TRON_PURCHASE = "ERROR_PLAYER_DEPOSIT_TRON_PURCHASE";
export const UPDATE_PLAYER_DEPOSIT_TRON_PURCHASE = "UPDATE_PLAYER_DEPOSIT_TRON_PURCHASE";

export const GET_PLAYER_DEPOSIT_TRON_STATUS = "GET_PLAYER_DEPOSIT_TRON_STATUS";
export const SET_PLAYER_DEPOSIT_TRON_STATUS = "SET_PLAYER_DEPOSIT_TRON_STATUS";
export const ERROR_PLAYER_DEPOSIT_TRON_STATUS = "ERROR_PLAYER_DEPOSIT_TRON_STATUS";
export const UPDATE_PLAYER_DEPOSIT_TRON_STATUS = "UPDATE_PLAYER_DEPOSIT_TRON_STATUS";

export const GET_PLAYER_DEPOSIT_OPTION = "GET_PLAYER_DEPOSIT_OPTION";
export const SET_PLAYER_DEPOSIT_OPTION = "SET_PLAYER_DEPOSIT_OPTION";
export const SET_PLAYER_DEPOSIT_OPTION_STATUS = "SET_PLAYER_DEPOSIT_OPTION_STATUS";
export const INITIAL_PLAYER_DEPOSIT_OPTION = "INITIAL_PLAYER_DEPOSIT_OPTION";
export const ERROR_PLAYER_DEPOSIT_OPTION = "ERROR_PLAYER_DEPOSIT_OPTION";
export const SUCCESS_PLAYER_DEPOSIT_OPTION = "SUCCESS_PLAYER_DEPOSIT_OPTION";

export const GET_LANDING_PAGE_POPUP_NOTICE = "GET_LANDING_PAGE_POPUP_NOTICE";
export const SET_LANDING_PAGE_POPUP_NOTICE  = "SET_LANDING_PAGE_POPUP_NOTICE ";
export const ERROR_LANDING_PAGE_POPUP_NOTICE  = "ERROR_LANDING_PAGE_POPUP_NOTICE ";
export const CLEAR_LANDING_PAGE_POPUP_NOTICE  = "CLEAR_LANDING_PAGE_POPUP_NOTICE ";
export const UPDATE_LANDING_PAGE_POPUP_NOTICE  = "UPDATE_LANDING_PAGE_POPUP_NOTICE ";

export const GET_BRAND_GAME_SETUP = "GET_BRAND_GAME_SETUP";
export const SET_BRAND_GAME_SETUP = "SET_BRAND_GAME_SETUP";

export const GET_DEPOSIT_PROMOTIONS = "GET_DEPOSIT_PROMOTIONS";
export const SET_DEPOSIT_PROMOTIONS = "SET_DEPOSIT_PROMOTIONS";
export const SET_DEPOSIT_PROMOTION_BONUS = "SET_DEPOSIT_PROMOTION_BONUS";

export const GET_DEPOSIT_COUNT = "GET_DEPOSIT_COUNT";
export const SET_DEPOSIT_COUNT = "SET_DEPOSIT_COUNT";

export const GET_DEPOSIT_PROMOTIONS_CHECK = "GET_DEPOSIT_PROMOTIONS_CHECK";
export const SET_DEPOSIT_PROMOTIONS_CHECK = "SET_DEPOSIT_PROMOTIONS_CHECK";

export const GET_PROMOTION_CONTENT_CATEOGRY = "GET_PROMOTION_CONTENT_CATEOGRY";
export const SET_PROMOTION_CONTENT_CATEOGRY = "SET_PROMOTION_CONTENT_CATEOGRY";

export const SET_HOTLINK_PROMOTION = "SET_HOTLINK_PROMOTION";

export const GET_LANDING_PAGE_CAROUSEL = "GET_LANDING_PAGE_CAROUSEL";
export const SET_LANDING_PAGE_CAROUSEL = "SET_LANDING_PAGE_CAROUSEL";
export const GET_LANDING_PAGE_CAROUSEL_SETTING = 'GET_LANDING_PAGE_CAROUSEL_SETTING';
export const SET_LANDING_PAGE_CAROUSEL_SETTING = "SET_LANDING_PAGE_CAROUSEL_SETTING";

export const GET_REBATE_TRANSFER_DATA = 'GET_REBATE_TRANSFER_DATA';
export const SET_REBATE_TRANSFER_DATA = 'SET_REBATE_TRANSFER_DATA';
export const SET_REBATE_TRANSFER_CHECKING_LOADING = 'SET_REBATE_TRANSFER_CHECKING_LOADING';
export const PROCESS_REBATE_TRANSFER = 'PROCESS_REBATE_TRANSFER';
export const PROCESSING_REBATE_TRANSFER = 'PROCESSING_REBATE_TRANSFER';
export const SET_REBATE_TRANSFER_PROCESSED_DATA = 'SET_REBATE_TRANSFER_PROCESSED_DATA';
export const SET_REBATE_TRANSFER_MODAL_TOGGLE = 'SET_REBATE_TRANSFER_MODAL_TOGGLE';
export const SET_LIVE_CASINO_SELECTED_PROVIDER = "SET_LIVE_CASINO_SELECTED_PROVIDER";

export const OPEN_MINIGAME = 'OPEN_MINIGAME';
export const OPEN_MINIGAME_SUCCESS = 'OPEN_MINIGAME_SUCCESS';
export const OPEN_MINIGAME_ERROR = 'OPEN_MINIGAME_ERROR';
export const RESET_MINIGAME = 'RESET_MINIGAME';

export const GET_SMS_SETTING = 'GET_SMS_SETTING';
export const SET_SMS_SETTING = 'SET_SMS_SETTING';

export const OPEN_BETCONSTRUCT = 'OPEN_BETCONSTRUCT';
export const OPENING_BETCONSTRUCT_SUCCESS = 'OPENING_BETCONSTRUCT_SUCCESS';
export const OPENING_BETCONSTRUCT_ERROR = 'OPENING_BETCONSTRUCT_ERROR';
export const RESET_BETCONSTRUCT = 'RESET_BETCONSTRUCT';

export const OPEN_GAME = 'OPEN_GAME';
export const OPENING_GAME_SUCCESS = 'OPENING_GAME_SUCCESS';
export const OPENING_GAME_ERROR = 'OPENING_GAME_ERROR';
export const RESET_GAME = 'RESET_GAME';
