import { takeLatest, put, call } from "redux-saga/effects";

import { getJackpot } from "../api/jackpot";

import {
    JACKPOT_SUCCESS,
    JACKPOT_ERRORS,
    GET_JACKPOT_GAMES
} from "../actions/types";

export { jackpotWatcher };

function* jackpotWatcher() {
    yield takeLatest(GET_JACKPOT_GAMES, fetchJackpot);
}

function* fetchJackpot(param) {

    try {
        const result = yield call(getJackpot, param.payload);
        yield put({
            type: JACKPOT_SUCCESS,
            payload: result.data.data
        });
    } catch (error) {
        yield put({
            type: JACKPOT_ERRORS,
            payload: error
        });
    }
}
