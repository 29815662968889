const displayAmount = (amount) => {
    return Math.trunc(sanitizeAmount(String(amount || 0)).replace(',', ''))
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const displayFormAmount = (amount) => {
    return amount.split('.')[0];
};

const totalAmount = (providers) => {
    let sum = 0;
    Object.values(providers).forEach((provider) => {
        if (
            provider.constructor === Object &&
            provider.balance !== ".00" &&
            provider.id !== 0 &&
            provider.id !== -1
        ) {
            sum = sum + Math.trunc(provider.balance);
        }
    });
    return displayAmount(sum);
};

const sanitizeAmount = (amt) => {
    if (amt) {
        return String(amt).replace(/[,]/g, "");
    } else {
        return "0.00";
    }
};

const realAmount = (amt) => {
    return Number(sanitizeAmount(amt));
};

const realFloatAmount = (amt) => {
    return parseFloat(sanitizeAmount(amt));
};

const thousandAmount = (amount) => {
    // return  Math.trunc(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return Math.trunc(sanitizeAmount(String(amount || 0)).replace(',', ''))
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export {
    displayAmount,
    displayFormAmount,
    totalAmount,
    sanitizeAmount,
    realAmount,
    thousandAmount,
    realFloatAmount
};
