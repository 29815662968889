import React from 'react';
import { useTranslation } from "react-i18next";

const BlackListedError = ({errorStatusCode}) => {
    const { t } = useTranslation();

    if (!errorStatusCode) {
        var errorStatusCode = 400;
    } else {
        var errorStatusCode = errorStatusCode;
    }

    return (
        <>
            <div className="mb-2"><img src="/assets/img/w365/warning_icon.svg" /></div>
            <div className='mb-2'><span style={{color:'#ADADAD',fontSize:'16px',fontWeight:400}}>{t('code')}: <span style={{fontWeight:700}}>{errorStatusCode}</span></span></div>
            <div><h1 style={{textTransform:'uppercase',fontSize:'30px',fontWeight:700}}>{t('error opening game')}</h1></div>
            <div style={{color:'#ADADAD',fontWeight:700,fontSize:'16px'}} className="mb-3">{t('not allowed domain')}</div>
            <div style={{marginTop:'18px'}}><a className="btn btn-success" href="/" style={{fontWeight:700,fontSize:'16px',padding:'12px 40px',borderRadius:'3px'}}>{t('back to homepage')}</a></div>
        </>
    );
}

export default BlackListedError;

