import { takeLatest, put, call, select } from 'redux-saga/effects';
import { realAmount, realFloatAmount } from '../helpers';
import {
    getRebateBalance,
    getRebateHistory,
    postRebateBalance,
    getRebateMinTransfer,
    rebateTransferDataCheck,
    processRebateTransfer
} from "../api/rebate"
import {
    REBATE_GET,
    REBATE_SET_PAGE,
    REBATE_HISTORY_GET,
    REBATE_POST,
    REBATE_POST_SUCCESS,
    REBATE_POST_ERRORS,
    REBATE_HISTORY_SUCCESS,
    REBATE_HISTORY_ERRORS,
    REBATE_SUCCESS,
    REBATE_ERRORS,
    REBATE_GET_MIN_TRANSFER,
    REBATE_SET_MIN_TRANSFER,
    WALLET_SUCCESS,
    REBATE_PROCESS_MESSAGE,
    GET_REBATE_TRANSFER_DATA,
    SET_REBATE_TRANSFER_DATA,
    SET_REBATE_TRANSFER_CHECKING_LOADING,
    PROCESS_REBATE_TRANSFER,
    PROCESSING_REBATE_TRANSFER,
    SET_REBATE_TRANSFER_PROCESSED_DATA,
    SET_REBATE_TRANSFER_MODAL_TOGGLE
} from "../actions/types";

import { player } from "../selectors/auth";
import { auth } from "../selectors/auth";
import playerState from "../selectors/player";

export { rebateWatcher };

const getItems = state => state.rebate;

function* rebateWatcher() {
    yield takeLatest(REBATE_GET, fetchRebate)
    yield takeLatest(REBATE_SET_PAGE, fetchRebateHistory)
    yield takeLatest(REBATE_HISTORY_GET, fetchRebateHistory)
    yield takeLatest(REBATE_POST, fetchPostRebate)
    yield takeLatest(REBATE_GET_MIN_TRANSFER, fetchRebateMinTransfer)
    yield takeLatest(REBATE_PROCESS_MESSAGE, rebateWalletUpdateMessage)
    yield takeLatest(GET_REBATE_TRANSFER_DATA, getRebateTransferData)
    yield takeLatest(PROCESS_REBATE_TRANSFER, rebateTransferProcess)
}

function* fetchRebateHistory(action) {
    try {
        const { selectedFilter, page } = yield select(getItems);
        const { date, type } = selectedFilter;
        const result = yield call(getRebateHistory, {
            ...action.payload,
            page,
            date,
            type
        });
        yield put({
            type: REBATE_HISTORY_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: REBATE_HISTORY_ERRORS,
            payload: error.response.data
        });
    }
}

function* fetchRebate() {
    try {
        const result = yield call(getRebateBalance);
        yield put({
            type: REBATE_SUCCESS,
            payload: result.data
        });
    }catch(error){
        yield put({
            type: REBATE_ERRORS,
            payload: error.response.data
        });
    }
}

function* fetchPostRebate(action) {

    const { currency: { Abbreviation } } = yield select(player);
    const { wallet: { username }} = yield select(auth);
    const _player = yield select(playerState);

    const params = {
        amount: action.payload.amount,
        brand: action.payload.brand,
        provider: action.payload.provider,
        username,
        currency:Abbreviation
    }

    try {
        const result = yield call(postRebateBalance, params);
        yield put({
            type: REBATE_POST_SUCCESS,
            payload: result.data
        });
        yield put({
            type: WALLET_SUCCESS,
            payload: {
                [action.payload.provider] :
                {
                    Status: _player.wallet[action.payload.provider].Status,
                    UnderMaintenance: _player.wallet[action.payload.provider].UnderMaintenance,
                    balance: realAmount(_player.wallet[action.payload.provider].balance) ,
                    currencyCode: _player.wallet[action.payload.provider].currencyCode,
                    hasError: _player.wallet[action.payload.provider].hasError,
                    status: _player.wallet[action.payload.provider].status,
                    success: _player.wallet[action.payload.provider].success
                }
            },
            payload2: {
                [action.payload.provider]: realFloatAmount(_player.wallet[action.payload.provider].balance) 
            }
        });
    }catch(error){
        yield put({
            type: REBATE_POST_ERRORS,
            payload: error.response.data
        });
    }
}

function* fetchRebateMinTransfer() {
    try {
        const result = yield call(getRebateMinTransfer);
        yield put({
            type: REBATE_SET_MIN_TRANSFER,
            payload: result.data
        });
    }catch(error){}
}

function* rebateWalletUpdateMessage(action) {
    try {
        const items = yield select(getItems);
        const rebate = items.rebate;
        const { balance } = action.payload;
        yield put({
            type: REBATE_SUCCESS,
            payload: {
                ...rebate,
                balance: balance
            }
        });
    }catch(error){

    }
}

function* getRebateTransferData() {
    try {
        yield put({
            type: SET_REBATE_TRANSFER_CHECKING_LOADING,
            payload: true
        });

        const result = yield call(rebateTransferDataCheck);

        yield put({
            type: SET_REBATE_TRANSFER_DATA,
            payload: {
                data: result.data,
                checkingLoading: false,
                showModal: true
            }
        });
    } catch(error) {
        yield put({
            type: SET_REBATE_TRANSFER_DATA,
            payload: {
                data: {},
                checkingLoading: false,
                showModal: true
            }
        });
    }
}

function* rebateTransferProcess(action) {
    try {
        yield put({
            type: PROCESSING_REBATE_TRANSFER,
            payload: true
        });

        const result = yield call(processRebateTransfer, action.payload);
        yield put({
            type: SET_REBATE_TRANSFER_PROCESSED_DATA,
            payload: result.data
        });

        yield put({
            type: PROCESSING_REBATE_TRANSFER,
            payload: false
        });

        yield put({
            type: SET_REBATE_TRANSFER_MODAL_TOGGLE,
            payload: false
        });
    } catch(error) {
        yield put({
            type: PROCESSING_REBATE_TRANSFER,
            payload: false
        });

        yield put({
            type: SET_REBATE_TRANSFER_MODAL_TOGGLE,
            payload: false
        });
    }
}
