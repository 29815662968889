import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export {
    getJackpot,
    getPopular
}

const auth= findToken();
let Authorization = null;
if(auth){
    Authorization = `Bearer ${findToken()}`
}

function getJackpot(params){
    return axios.get(`${ROOT_URL}/jackpotGames`, {
        headers: {
            Authorization
        }, params
    });
}

function getPopular(params){
    return axios.get(`${ROOT_URL}/jackpotGames`, {
        headers: {
            Authorization
        }, params
    });
}
