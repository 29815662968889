import { GET_ACTIVE_PROVIDERS, RESET_ACTIVE_COUNT, GET_FAVOURITE_PROVIDERS } from "./types";

const getActiveProviders = () => {
  return {
    type: GET_ACTIVE_PROVIDERS
  };
};

const resetActiveCount = () => {
  return {
    type: RESET_ACTIVE_COUNT
  };
};

const getFavouriteProviders = () => {
	return {
		type: GET_FAVOURITE_PROVIDERS
	};
} ;



export { getActiveProviders, resetActiveCount, getFavouriteProviders };
