import {
    GET_PROMO_BANNERS,
    GET_PRODUCTS,
    SET_PRODUCT,
    GET_PROMOTIONS,
    GET_PROMO_CONTENT,
    GET_PROMO_MIN_DEPOSIT,
    RESET_PROMO_MIN_DEPOSIT,
    GET_DEPOSIT_PROMOTIONS,
    SET_DEPOSIT_PROMOTION_BONUS,
    GET_DEPOSIT_PROMOTIONS_CHECK,
    GET_PROMOTION_CONTENT_CATEOGRY,
    SET_HOTLINK_PROMOTION
} from "./types";

export const getBanners = () => {
  return {
    type: GET_PROMO_BANNERS
  };
};

export const selectProduct = product => {
    return {
        type: SET_PRODUCT,
        payload: product
    }
}

export const getProducts = () => {
  return {
    type: GET_PRODUCTS
  };
};

export const getPromotions = payload => {
  return {
    type: GET_PROMOTIONS,
    payload
  };
};

export const getContent = id => {
  return {
    type: GET_PROMO_CONTENT,
    id: id
  };
};

export const getPromotionMinDeposit = payload => {
  return {
    type: GET_PROMO_MIN_DEPOSIT,
    payload: payload
  }
}

export const resetPromotionMinDeposit = () => {
  return {
    type: RESET_PROMO_MIN_DEPOSIT
  }
}

export const getDepositPromotions = payload => {
  return {
    type: GET_DEPOSIT_PROMOTIONS,
    payload: payload
  };
};

export const setDepositPromotionBonus = (id, promotion) => {
  return {
    type: SET_DEPOSIT_PROMOTION_BONUS,
    promotionID: id,
    promotion: promotion
  }
}

export const checkDepositPromotion = () => {
  return {
    type: GET_DEPOSIT_PROMOTIONS_CHECK
  }
}

export const getPromotionContentCategory = () => {
  return {
    type: GET_PROMOTION_CONTENT_CATEOGRY
  }
}

export const setDepositHotlink = payload => {
  return {
    type: SET_HOTLINK_PROMOTION,
    payload: payload
  };
};