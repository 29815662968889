import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.log(error);
    }

    componentWillReceiveProps(nextProps) {
        // console.log(nextProps.error);
        if(nextProps.error !== undefined) {
            const error = nextProps.error.message ? true : false;
            this.setState({ hasError: error });
            // console.log(error);
        }
    }

    render() {
        const { t } = this.props;

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error_page_2">
                    <div className="error_splash row text-center">
                        <div className="col-md-12">
                            <div className="symbol_icon">
                                <img src="/assets/img/error-page-warning.svg" className="mb-2"/>
                                <p style={{fontSize: "20px", color:"#9fa2ab"}}>
                                    {t('code')}: <b style={{color:"#FEFEFE"}}>500</b>
                                </p>
                                <p></p>
                            </div>
                            <h2 style={{color: "#8EB50D", fontSize: "55px"}}>{t('something went wrong')}</h2>
                            <span className="errorSpan" style={{color:"#FEFEFE", fontSize: "17px"}}>{ReactHtmlParser(t('the page you are looking for is not available'))}</span>
                            <p>
                                <Link to="/" className="btn btn-dark mt-4" style={{padding: "10px 15px", textTransform: "none", fontSize: "17px",}}>
                                    <i className="fas fa-arrow-left" />
                                    {" "}{t('back to homepage')}
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    error: state.errors
});

export default connect(mapStateToProps, null)(withRouter(withTranslation()(ErrorBoundary)));
