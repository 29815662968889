import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetFilter, setFilter, getRebateHistory  } from "../../../../actions/rebate";
import { Filter } from "../../../common";

const FilterSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isOpen = useSelector(state => state.modal.isOpen);
    const { selectedFilter, searchFields } = useSelector((state) => state.rebate );

    useEffect(() => {
        return () => {
            dispatch(resetFilter());
        }
    }, []);

    const onChange = useCallback((e) => {
        dispatch(
            setFilter({
                ...selectedFilter,
                [e.target.name]: e.target.value
            })
        );
    });

    const onReset = useCallback((e) => {
        dispatch(
            setFilter({ date: 0, type: 0 })
        );
    });

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        dispatch(getRebateHistory());
    });

    return (


        <form>
            <div class="row">
                
                {Object.keys(searchFields).map((key, idx) => {

                return (
                    <div
                        key={idx}
                        className="col-md-3"
                    >
                        <label class="col-form-label">{t(key.toLowerCase())}</label>
                        <Filter
                        name={key}
                        data={searchFields[key]}
                        value={selectedFilter[key]}
                        onChange={onChange}/>
                    </div>
                );

            })}
            

            <div class="col-auto row align-items-end justify-content-end rebMesBtn">
              <button type="reset" class="btn btn-dark btn-md  small-caps reset" onClick={onReset}>{t('reset')}
              </button><button class="btn btn-success btn-md small-caps" onClick={onSubmit}>{t('search')}</button>
            </div>

            </div>
        </form>


 
    );
};

export default FilterSection;
