import React from 'react';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const CasinoThumb = (props) => {
    const { t } = props;
    // let badgeLabel;
    // let badgeClass;

    // if(props.NewGame) {
    //     badgeLabel = t('new').toUpperCase();
    //     badgeClass = 'badgeNew';
    // }

    // if(props.Featured) {
    //     badgeLabel = t('hot').toUpperCase();
    //     badgeClass = 'badgeFeatured';
    // }

    // if(props.MonthlyTopGame) {
    //     badgeLabel = 'TOP';
    //     badgeClass = 'badgeTop';
    // }

    let badges = [];
    let i = 0;

    if(props.MonthlyTopGame) {
        badges[i] = {
            label: t('top'),
            class: 'badgeTop',
            top: 6
        }
        i++;
    }

    if(props.NewGame) {
        badges[i] = {
            label: t('new'),
            class: 'badgeNew',
            top: 6
        }
        i++;
    }

    if(props.Featured) {
        badges[i] = {
            label: t('hot'),
            class: 'badgeFeatured',
            top: !props.NewGame ? 6 : 40,
        }
    }

    const playButton = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('appToken');

        if(token === null) {
            document.querySelector('.login-area button.btn.btn-success.btn-sm').click();
        } else {
            window.open(`/live-casino/play/${props.GameTypeCode}?isMobile=0&providerID=${props.ProviderID}`, '_blank');
        }
    }

    let image = '';
        switch (props.ProviderID) {
        case 3: // Asian Gaming 
            image = '/assets/img/Live-Casino/provider/ag.png'
            break;
        case 9: // Microgaming
            image = '/assets/img/Live-Casino/provider/microgaming.png'
            break;
        case 15: // Evo
            image = '/assets/img/Live-Casino/provider/evo.png'
            break;
        case 29: // Pragmatic Play
            image = '/assets/img/Live-Casino/provider/pragmatic.png'
            break;
        default:
            image = '/assets/img/Live-Casino/provider/evo.png'
        }

    return (
        <Col xs="2" className="slotGameThumb">
            <div className="thumb-container m-2">
                {/* <span className={badgeClass}>{badgeLabel}</span> */}
                {badges.map(badge => <span className={badge.class} key={badge.class} style={{top:badge.top}}>{badge.label}</span>)}
                <div className="img-box">
                    <img src={`${process.env.REACT_APP_BO_STORAGE + props.GameTypeImageName}`} alt="" />
                    <div className={`img-overlay${!props.hasDemo ? ' no-demo' : ''}`}>
                        <Row className="align-items-center justify-content-center">
                            <div className="btn-container">
                                <div className="w-100 mb-1">
                                    <a href="#" onClick={playButton}>
                                        <div className="playBtn">
                                            <div className="slot-playBtn">
                                                <div className="play-button">
                                                    <svg className="play-circles" viewBox="0 0 152 152">
                                                        <circle className="play-circle-01" fill="none" stroke="#fff" strokeWidth="3" strokeDasharray="343 343" cx="76" cy="76" r="72.7"/>
                                                        <circle className="play-circle-02" fill="none" stroke="#fff" strokeWidth="3" strokeDasharray="309 309" cx="76" cy="76" r="65.5"/>
                                                    </svg>
                                                    <i className="fas fa-play"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                {props.hasDemo ? 
                                <div className="w-100 mb-1 btnDemo">
                                    <a href="#" className="DemoBtn caps">Play Demo</a>
                                </div> : null}
                            </div>
                        </Row>
                    </div>
                </div>
                <div className="gameInfo">
                    <Row className="align-items-center justify-content-center">
                        <Col xs="7"><h2 title={props.GameTypeName}>{t(props.GameTypeName.toLowerCase())}</h2></Col>
                        <Col xs="5"><div className="game-provider"><img src={image} alt="" /></div></Col>
                    </Row>
                </div>
            </div>
        </Col>
    );
}

export default withTranslation()(CasinoThumb);
