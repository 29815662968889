import React, { Component } from "react";
import Wallet from "../../layouts/Walletz";
import Provider from "../../layouts/Walletz/provider";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import NumberFormat from 'react-number-format';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Switch from "./Switch";
import Message from "./Message";
import BtnLoader from "../../common/BtnLoader";

import History from "./content/history";
import FilterSection from "./filter/history";

import {
    getRebateBalance,
    getRebateMinTransfer,
    postRebateBalance
} from '../../../actions/rebate';
import { reloadWallet } from "../../../actions/player";
import { selectProvider, toggleModal } from "../../../actions/modal";
import { getProductProviders } from "../../../actions/product";
import { displayAmount, realAmount, thousandAmount } from "../../../helpers";

class Rebate extends Component {
    state = {
        amount: "",
        success: false,
        errors: false,
        exceedAmount: false,
        isMinimum: false,
        isTransfer: false,
        valid: true,
        minimumAmount: 0,
        provider: 0,
        balance: 0,
        wallet: {},
        providers: {},
        init: false,
        count: false,
        showDropdown: false,
        filterProvz:[],
        walletz:{},
        rebateBalance: 0,
    }

    constructor(props){
        super(props);
        this.hideMessage = this.hideMessage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps){
        const { wallet: providers } = nextProps;
        let state = {};
        let balance = 0;
        const { rebate, errors } = nextProps;
        if(rebate.result){
            state.success = rebate.success;
        }else{
            state.success = false;
        }
        if(nextProps.errors || rebate.errors){
            state.errors = errors || { ...rebate.errors };
        }
        if (rebate.success){
            if(!this.state.count) {
                balance = rebate.balance;
                balance = balance.toString().replace(/,/g,"");
                balance = Math.trunc(balance);
                this.setState({balance: balance});
                this.setState({rebateBalance: balance});
                this.setState({count: true});

                const minTransfer = realAmount(nextProps.minTransfer)
                state.minimumAmount = minTransfer;
                state.isMinimum  = minTransfer  > this.state.balance  ? true : false;
            }
        }

        if (nextProps.minTransfer) {
            const minTransfer = realAmount(nextProps.minTransfer)
            state.minimumAmount = minTransfer;
            state.isMinimum  = minTransfer  > realAmount(this.state.balance)  ? true : false;
        }

        if (nextProps.minTransfer === 0) {
             state.isTransfer =  true;
        }

        if(nextProps.provz.length > 0) {
            if(this.state.init === false) {
                this.initializeProvider(nextProps);
                this.setState({init: true});
            }
        }
        
        this.setState(state);
        this.setState({walletz:nextProps.wallet});
        this.setState({ providers, wallet: providers[this.state.provider] });     
           
    }

    componentDidMount() {
        this.props.getRebateBalance();
        this.props.reloadWallet();
        this.props.getRebateMinTransfer();
        this.props.getProductProviders({});
    
        this.setState({ amount: this.props.mainwallet.wallet });

        document.addEventListener('click', e => this.dropDownBlurHandle(e));
    }

    dropDownBlurHandle(e) {
        if(e.target.classList.contains('custom-selectbox')) {
            return false;
        }

        this.setState({showDropdown: false});
    }

    componentWillUnmount() {
    }

    initializeProvider(props){
        const { productProviders, selectProvider, provz, t } = props;
        let providers = [];

        productProviders.map((product, k) => (
            product.Providers.map((provider,j) => {
                if(providers.some(item => provider.ID === item.ID)) {
                    
                } else {
                    providers.push(provider.ID)
                }
            })
        ))

        const test = provz.sort( function (a, b) {
            var A = a.ID, B = b.ID;
            
            if (providers.indexOf(A) > providers.indexOf(B)) {
              return 1;
            } else {
              return -1;
            }
            
        }).filter((val) => {
            return val.HasPendingBets.isSubscribed === false;
        });

        if (test.length > 0) {
            let logo = "";
            switch(test[0].ID) {
                case 21:
                    logo = 'icon js';
                    break;
                case 22:
                    logo = 'icon es';
                    break;
                case 23:
                    logo = 'icon pb';
                    break;
                default:
            }
            this.selectWallet(test[0].ID);
        }

        selectProvider({
            ID: 0,
            Name: t('select wallet to'),
        });
        this.setState({filterProvz:test});
    }

    selectWallet(provider){
        this.setState({
            provider: provider,
        });
    }

    selectRebateWallet(provider){
        this.selectWallet(provider.ID);
        this.props.selectProvider(provider);
    }

    showDropdown = () => {
        const {showDropdown} =  this.state
        this.setState({showDropdown: !showDropdown})
    }
 
    selectProvider = (provider) => {
        const {selectProvider} =  this.props
        const {showDropdown} =  this.state
 
        selectProvider({
             ID: provider.ID,
             Name: provider.Name,
             Logo: provider.Logo,
        });
        this.setState({showDropdown: !showDropdown, provider: provider.ID})
    }

     onChange = (e) => {
        const value = e.target.value;
        this.checkExceedAmount(value)
        this.checkMinimumAmount(value)
        this.setState({
            balance: value
        });
     }

     checkExceedAmount = (value) => {
        const { rebate } = this.props

        if (realAmount(value) > realAmount(rebate.balance)) {
            this.setState({ exceedAmount: true, valid: false});
        } else {
            this.setState({ exceedAmount: false, valid: true});
        }
     }

     checkMinimumAmount = (value) => {
        const { rebate } = this.props
        const { minimumAmount } = this.state

        if (realAmount(value) <  realAmount(minimumAmount) ) {
            this.setState({ isMinimum: true});
        } else {
            this.setState({ isMinimum: false});
        }
     }

    onSubmit = (e) => {
        let { rebate } = this.props;
        let {balance, provider, exceedAmount, isMinimum, isTransfer, valid} = this.state
        e.preventDefault();

        balance = balance.toString().replace(/,/g,"");
        balance = Math.trunc(balance);

        if ( !exceedAmount && !isMinimum && !isTransfer && valid && balance > 0) {
            const params = {
                amount: balance,
                brand: 1,
                provider: provider,
            }
            this.setState({valid:true});
            this.props.postRebateBalance(params);
        }
    }

    hideMessage(){
        const { t } = this.props
        this.props.selectProvider({ID: 0, Name: t('select wallet to'),});
        this.setState({ success: false, errors: false, count: false });
        this.props.getRebateBalance();
    }

    quickAddAmount = (amountToAdd) => {
        let currentAmount = this.state.balance;
        currentAmount = currentAmount.toString().replace(/,/g,"");
        if (currentAmount) {
            currentAmount = Math.trunc(currentAmount);
        }
        if (String(currentAmount).match(/[a-z]/i) || currentAmount === "") {
            currentAmount = 0;
        }
        currentAmount = Math.trunc(currentAmount) + Math.trunc(amountToAdd);
        currentAmount = Math.trunc(currentAmount);
        this.checkExceedAmount(currentAmount)
        this.checkMinimumAmount(currentAmount)
        this.setState({ balance: currentAmount });
    };

    resetForm = () => {
        this.setState({ count: false});
        // this.props.getRebateBalance();
        // this.initializeProvider(this.props);
        // this.setState({ exceedAmount: false});
        //this.setState({ exceedAmount: false, isMinimum: false, balance: this.state.rebateBalance});
        this.setState({ exceedAmount: false, isMinimum: false, balance: ""});
    };

    selectWalletz = () => {

    }

    selectAll = () => {

    }

    render() {
        const { t, mainwallet, rebate, i18n, provider } = this.props;
        const { providers, filterProvz, walletz } = this.state;
        const language = i18n.language;
        const amount = walletz[provider.ID] || { balance: 0 };
        return (
            <div id="containerForm">
                <h2 className="formTitle">{t('rebate transfer')}</h2>
                

                <div className="formContent">
                    <div className="DepositContentWrap">
                        <form
                            noValidate method="post"
                            onSubmit={this.onSubmit}
                            ref={(form)=> this.form = form}
                        >
                            <div className="row">
                                {
                                    this.state.success ? 
                                        <Message closeMessage={this.hideMessage} onSubmit={this.onSubmit}/>
                                    : 
                                  
                                    <div className="col-md-6" id="deposit-form">
                                        <div className="accountInfo mb-5">
                                            <div id="transferForm">
                                                

                                                <div className="form-group row">
                                                    <div className="col-md-12">
                                                        <label class="col-form-label">발신(-)</label>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <input type="text" class="form-control" value={t('rebate wallet')}  disabled />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div class="col-md-12 ">
                                                        <label class="col-form-label">이동 하실 금액을 입력하세요</label>
                                                    </div>
                                                    <div class="col-md-12 txtbox-min-max">
                                                        <div class="form-group mb-0">
                                                            <NumberFormat
                                                                    id="amount"
                                                                    name="amount"
                                                                    placeholder="0"
                                                                    value={this.state.balance}
                                                                    onClick={this.onClick}
                                                                    onChange={this.onChange}
                                                                    className={classnames("form-control", { "is-invalid": this.state.errors })}
                                                                    thousandSeparator={true}
                                                                    decimalSeparator={false}
                                                                    required
                                                                />
                                                            <div class="min-max-amount">
                                                                {t('minimum')} {thousandAmount(this.state.minimumAmount)}
                                                            </div>
                                                        </div>

                                                        <div className={classnames("d-none", {"invalid-tooltip d-block": this.state.errors })} >
                                                            {t(this.state.errors.errors)}
                                                        </div>
                                                        <div className={classnames("d-none", {"invalid-tooltip d-block": this.state.isMinimum && !this.state.exceedAmount })} >
                                                            {t('transfer amount should be at least') +' '+ this.state.minimumAmount}
                                                        </div>
                                                        <div className={classnames("d-none", {"invalid-tooltip d-block": this.state.isTransfer })} >
                                                            {t('rebate amount cannot be transferred now')}
                                                        </div>
                                                        <div className={classnames("d-none", {"invalid-tooltip d-block": this.state.exceedAmount })} >
                                                            {t('you have insufficient funds')}
                                                        </div>

                                                        <div class="row m-0">
                                                            <button type="button" onClick={this.quickAddAmount.bind(this, 10000)} class="btn btn-success col">{language === 'en-US' ? '+10K' : '+1만'}</button>
                                                            <button type="button" onClick={this.quickAddAmount.bind(this, 50000)} class="btn btn-success col">{language === 'en-US' ? '+50K' : '+5만'}</button>
                                                            <button type="button" onClick={this.quickAddAmount.bind(this, 100000)} class="btn btn-success col">{language === 'en-US' ? '+100K' : '+10만'}</button>
                                                            <button type="button" onClick={this.quickAddAmount.bind(this, 500000)} class="btn btn-success col">{language === 'en-US' ? '+500K' : '+50만'}</button>
                                                            <button type="button" onClick={this.quickAddAmount.bind(this, 1000000)} class="btn btn-success col">{language === 'en-US' ? '+1M' : '+100만'}</button>
                                                            <button type="button" onClick={this.resetForm} class="btn btn-dark col">{t('reset')}</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <div class="col-md-12 ">
                                                        <label class="col-form-label">수신(+)</label>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div className="selectbox">
                                                            <div className="form-control custom-selectbox dark" onClick={this.showDropdown} >
                                                              {
                                                                filterProvz.length > 0 ? <>
                                                                    <span className={`${provider.Logo}`}></span> {provider.Name} <>{provider.ID === 0 ? '' : thousandAmount(amount.balance) }</>
                                                                </> : t('no wallet available')
                                                              }    
                                                            </div>
                                                            <div className={classnames("selectbox-dropdown",{"active": this.state.showDropdown})}>
                                                                <ul>
                                                                    {
                                                                        filterProvz.length > 0 ?
                                                                            filterProvz.map((provider,j) => {
                                                                                let logo = "";
                                                                                switch(provider.ID) {
                                                                                  case 21:
                                                                                    logo = 'icon js';
                                                                                    break;
                                                                                  case 22:
                                                                                    logo = 'icon es';
                                                                                    break;
                                                                                  case 23:
                                                                                    logo = 'icon pb';
                                                                                    break;
                                                                                  default:
                                                                                }
                                                                                const amount = walletz[provider.ID] || { balance: 0 };
                                                                                return (
                                                                                    <li onClick={ () => this.selectProvider({ID: provider.ID, Name: provider.ProviderName, Logo: logo})} >
                                                                                        <span className={`${logo}`}></span>
                                                                                        {provider.ProviderName}  ({thousandAmount(amount.balance)})
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        : ''
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="action-btn-modal text-center">
                                                    <button className="btn btn-success small-caps" type="submit" disabled={this.state.balance === 0 || this.state.exceedAmount || this.state.isTransfer || this.state.isMinimum || provider.ID === 0}>{t('submit')}</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                } 

                                <Provider selectRebateWallet={this.selectRebateWallet.bind(this)} />
                            </div>

                        </form>
                    </div>
                </div>

                <h2 className="formTitle">{t('rebate transaction history')}</h2>
                <div className="accountInfo">
                    <div className="mb-4 mt-4">
                        <FilterSection />
                    </div>
                    <div className="row mt-4">
                        <History />
                    </div>
                </div>
            </div>        
        );
    }
}

Rebate.propTypes = {
};

const mapStateToProps = state => ({
  mainwallet: state.auth.wallet,
  rebate: state.rebate.rebate,
  errors: state.rebate.errors,
  minTransfer: state.rebate.minTransfer.rebateMinAmount,
  modal: state.modal,
  productProviders: state.products.result,
  providers: state.providers.providers,
  provz: state.casino.providers,
  wallet: state.player.wallet,
  provider: state.payment.provider
});

export default connect(
  mapStateToProps,
    {
        reloadWallet,
        getRebateBalance,
        postRebateBalance,
        selectProvider,
        getProductProviders,
        getRebateMinTransfer,
    }
)(withTranslation()(withRouter(Rebate)));
