import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Commission = () => {
    const { t } = useTranslation();
    return (
    <section class="animation-element in-view commission-section padding-top" id="commission">
      <div class="container">
        <div class="row align-items-center justify-content-end">
          <div class="col height">
            <div>
              <img src="assets/img/model-commission-structure.png" />
            </div>
          </div>
         
          <div class="col-6">
            <p class="title"> {t("commission structure")}</p>
            <div class="table-container">
              <table class="table border-unset">
                <thead>
                  <tr id="borderBottom">
                    <th class="green" colspan="2">
                      <h3>{t('revenue share with w365')}</h3>
                    </th>
                  </tr>
                  <tr>
                    <th class="green">{t('partner monthly deposit amount')}</th>
                    <th class="green">{t('revenue share')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t('partners commission table column one')}</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td>{t('partners commission table column two')}</td>
                    <td>45%</td>
                  </tr>
                  <tr>
                    <td>{t('partners commission table column three')}</td>
                    <td>40%</td>
                  </tr>
                  <tr>
                    <td>{t('partners commission table column four')}</td>
                    <td>35%</td>
                  </tr>
                  <tr>
                    <td>{t('all partner revenue')}</td>
                    <td>{t('revenue basic')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="note">
              <p>{t('commission note')}</p>
              <Link to="/partner/register" class="btn btn-success btn-lg" name="">{t('join us now as w365 partner')}</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
};

export default Commission;
