import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import View from "./View";
import List from "./List";
import { Paginate } from "../../common";

import ReactPaginate from "react-paginate";

import {
    getMessages,
    selectPage,
    setFilter,
    resetMessages
} from "../../../actions/player";

import {
    getUnreadNotice,
} from "../../../actions/authentication";

class Notices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            getMessageCount: 0
        };
    }

    onRead = messageID => {
        this.props.history.push('/myaccount/notices/' + messageID);
        this.props.getUnreadNotice();
    };

    componentDidMount(){
        this.props.resetMessages();
        this.props.setFilter({
            ...this.props.player.selectedFilter,
            type: 'notice',
            date: 30
        });
        //this.props.getMessages();
        this.props.getUnreadNotice();
    }

    componentWillUnmount(){
        // this.props.getUnreadNotice();
        this.props.resetMessages();
    }

    componentWillReceiveProps(nextProps){
        const { messages, selectedDate, selectedType } = nextProps.player;
        if(nextProps.player.selectedFilter.type) {
            if(nextProps.player.selectedFilter.type === 'notice'){
                if(this.state.getMessageCount === 0) {
                    this.setState({getMessageCount: 1});
                    this.props.getMessages();
                }
            }
        }
        this.setState({
            messages: messages.data,
            options: { ...messages.meta, ...messages.links },
            selectedDate,
            selectedType
        });
    }

    handlePageClick = (e) => {
        if(this.props.player.selectedFilter.type === 'notice') {
            this.props.selectPage(e.selected + 1)
        }
    };

    render() {
        const { messages, options } = this.state;
        //const { selectPage } = this.props;
        const { t } = this.props;
        let pageCount = 0;
        if (options) {
            pageCount = [...Array(options.last_page).keys()].length;
        }

        if(this.props.match.params.id){
            return (
                <React.Fragment>
                    <h2 className="form-title">{t('notices')}</h2>
                    <div className="accountInfo">
                        <div className="message_content message_id_1 row animated fadeIn">
                            <View />
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <h2 className="form-title">{t('notices')}</h2>
                <div className="accountInfo">
                    <div className="mb-4 mt-4  message_inbox">
                        <List messages={messages} onRead={this.onRead} ownProps={this.props} />
                    </div>
                    <div className="col-md-12 pagination-col">
                        <div className="pagination-box">
                        {/* <Paginate options={options} selectPage={selectPage}/> */}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            initialPage={0}
                            onPageChange={this.handlePageClick.bind()}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={3}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination-w365"
                            pageLinkClassName="page-num"
                            previousLinkClassName="page-num"
                            nextLinkClassName="page-num"
                            activeLinkClassName="active"
                        />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
        
    }
}

Notices.propTypes = {
  getMessages: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  player: state.player,
});

export default connect(
  mapStateToProps,
  { getMessages, selectPage, setFilter, getUnreadNotice, resetMessages }
)(withTranslation()(withRouter(Notices)));
