import {
    GET_PRODUCT_PROVIDERS,
} from "./types";


export const getProductProviders = (product) => {
  return {
    type: GET_PRODUCT_PROVIDERS,
    product: product
  }
}




