import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetPage } from "../../../actions/registration";
import { getPromotions } from "../../../actions/promotion";
import ReactHtmlParser from "react-html-parser";
import Promotion from "./promotion";

const Register3 = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const promotions = useSelector(state => state.promotions.promotions);
    const [ftd, setFtd] = useState([]);

    useEffect(() => {
        localStorage.removeItem("code");
        localStorage.removeItem("smscode");
        localStorage.removeItem("players");

        var elem = document.getElementById("main-wrap");
        elem.classList.add("welcome-registration");

        dispatch(getPromotions({}));
    }, []);

    useEffect(() => {
        if(promotions.length > 0) {
            setFtd(promotions.filter(promo => promo.promotion !== null && promo.promotion.SubType === 1));
        }
    }, [promotions]);

    useEffect(() => {
        return () => {
            dispatch(resetPage());

            var elem = document.getElementById("main-wrap");
            elem.classList.remove("welcome-registration");
        }
    }, []);

    return (
        <div class="container default-top-space">
            <div class="row m-0">
                <div class="col p-0 pr-1">
                    <div class="welcome-content-section">
                        <div class="row align-items-center justify-content-center m-0">
                            <div class="text-content">
                                <p class="div-title">{t("welcome to w365")}</p>
                                <span>{ReactHtmlParser(t("register success"))}</span>
                                <Link to="/myaccount/deposit" className="btn btn-success btn-registration">{t("deposit now")}</Link>
                                <div className='profile-page'>
                                    <p class='colgray'>{t("or")}&nbsp;
                                        <Link to="/myaccount/profile" class="colgray">{t("continue to my account page")}</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {ftd.length > 0 ? <Promotion data={ftd} /> : null}
            </div>
        </div>
    );
}

export default Register3;
