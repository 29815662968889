import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Paginate = ({ options, selectPage }) => {
    let pages = [];
    const { t } = useTranslation();
    if (options && options.total < options.per_page) return <></>;

    if(options && options.last_page){
        pages = [...Array(options.last_page).keys()];
    }

    return (
        <div
            className={classNames("col-md-4", {
                "d-none": pages.length === 0
            })}
        >
            <nav aria-label="...">
                <ul className="pagination">
                    <li
                        className={classNames("page-item", {
                            disabled: options && options.prev === null
                        })}
                    >
                        <a
                            className="page-link dark"
                            style={{color:'white'}}
                            href="#"
                            tabIndex="-1"
                            onClick={() => selectPage(options.current_page - 1)}
                        >
                            {t("previous")}
                        </a>
                    </li>
                    {pages.map((i) => {
                        return (
                            <li
                                key={i}
                                className={classNames("page-item", {
                                    active: options.current_page === i + 1
                                })}
                            >
                                <a
                                    className="page-link dark"
                                    style={{color:'white'}}
                                    href="#"
                                    onClick={() => selectPage(i + 1)}
                                >
                                    {i + 1}
                                </a>
                            </li>
                        );
                    })}
                    <li
                        className={classNames("page-item", {
                            disabled: options && options.next === null
                        })}
                    >
                        <a
                            className="page-link dark"
                            style={{color:'white'}}
                            href="#"
                            onClick={() => selectPage(options.current_page + 1)}
                        >
                            {t("next")}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Paginate;
