import React, { Component } from "react";
import { withTranslation, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import partnerBannerEN from "../../../assets/img/partners/banner-with-text-en.jpg";
import partnerBannerKR from "../../../assets/img/partners/banner-with-text-kr.jpg";
import { checkCanRegister } from "../../../actions/registration";
import ComingSoonModal from '../../common/ComingSoonModal';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Featured extends Component {
  state = {
      showLogin: false,
      canRegister: false,
  }

  handleOpen() {
      this.setState({showLogin: true});
  }

  handleClose() {
      this.setState({showLogin: false});
  }

  componentDidMount() { 
    this.props.checkCanRegister({domain: window.location.hostname});
  }
    

  componentWillReceiveProps(nextProps) {
    this.setState({
      canRegister: nextProps.canRegister.CanSignUp
    });
  }

    render() {
        const {t} = this.props;
        const {canRegister} = this.state;
        // const { i18n } = useTranslation();

        return (
            <section id="featured">
              <div class="slider">
                <div class="slide-content">
                  <div class="banner-content-section">
                    {/* <h1 class="white">{t('high commision')}</h1>
                    <h1 class="yellow">{t('unlimited revenue')}</h1> */}
                    {
                      canRegister ? <Link to="/partner/register" className="btn btn-success btn-lg">
                          {t('create an account')}
                      </Link> : ''
                    }
                    {/* <a href="#" className="btn btn-success btn-lg" onClick={this.handleOpen.bind(this)}>{t('create an account')}</a> */}
                  </div>
                  <a href="#" class="slide-img"><img src={partnerBannerEN} class="img-fluid animated" data-animation-in="zoomInImage" /></a>
                  {/* {i18n.language === "en-US" ? (
                    <a href="#" class="slide-img"><img src={partnerBannerEN} class="img-fluid animated" data-animation-in="zoomInImage" /></a>
                  ) : (
                    <a href="#" class="slide-img"><img src={partnerBannerKR} class="img-fluid animated" data-animation-in="zoomInImage" /></a>
                  )} */}
                </div>
              </div>
              <ComingSoonModal show={this.state.showLogin} handleClose={this.handleClose.bind(this)} translationType="partners" />
    </section>
        );
    }
}



const mapStateToProps = state => ({
    canRegister: state.register.canRegister
});

export default connect(
    mapStateToProps,
    {  checkCanRegister }
)(withRouter(withTranslation()(Featured)));
