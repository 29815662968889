import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { postPlay, postFavourite, getGames, getProviders, getCategories, getMiniCategories, postOpen, getGameList }

function postPlay(params) {
    return axios.post(`${ROOT_URL}/casino/play`, params, {
        headers: {
            Authorization: `Bearer ${findToken()}`
        }
    });
}

function postOpen(params) {
    return axios.post(`${ROOT_URL}/casino/open`, params, {
    });
}

function postFavourite(params) {
    return axios.post(`${ROOT_URL}/favourites`, params, {
        headers: {
            Authorization: `Bearer ${findToken()}`
        }
    });
}

function getGames(params){
    const url = window.location.pathname;
    let page;

    if(url === "/slot") {
        page = "slotGamesTest";
    } else if(url.includes("/slot/")) {
        page = "slotGamesTest";
    } else if(url.includes("/slot-provider")) {
        page = "slotGamesTest";
    } else if(url === "/slot-recent") {
        page = "recentSlot";
    } else if(url === "/slot-favourites") {
        page = "favouriteSlot";
    } else if(url === "/mini-games") {
        page = "testmini";
    } else if(url === "/mini-recent") {
        page = "recentCasino";
    } else if(url === "/mini-favourites") {
        page = "favouriteCasino";
    } else {
        page = "providers";
    }

    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/${page}`, {
        headers: {
            Authorization
        }, params
    });
    
}

function getProviders(params){
    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }
    return axios.get(`${ROOT_URL}/providers`, {
        headers: {
            Authorization
        }, params
    });
}

function getCategories(params){

    return axios.get(`${ROOT_URL}/gameCategories`, {
        params
    });
}

function getMiniCategories(params){

    return axios.get(`${ROOT_URL}/miniCategories`, {
        params
    });
}

function getGameList(params){
    let page = 'slot/games';
    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/${page}`, {
        headers: {
            Authorization
        }, params
    });
}