import {
    SET_INITIAL_TRANSACTION,
    SET_TRANSACTION_HISTORY,
    SET_TRANSACTION_TYPE,
    SET_TRANSACTION_DATE,
    SET_TRANSACTION_PAGE,
    SET_TRANSACTION_FILTER
} from "../actions/types";

const initialState = {
    transactionHistory: { data: [], links: {}, meta: {} },

    selectedFilter: {
        type: 0,
        date: 0
    },
    searchFields: {
        date: {
            data: [
                { name: 'Today', value: 0 },
                { name: 'Yesterday', value: 1 },
                { name: 'Last 3 days', value: 3 },
                { name: 'Last 7 days', value: 7 },
                { name: 'Last 30 days', value: 30 }
            ]
        },
        type: {
            data: [
                { name: 'All', value: 0 },
                { name: 'Deposit', value: 1000 },
                { name: 'Withdrawal', value: 2000 },
                //{ name: 'Transfer', value: [6000,7000] },
            ]
        },

    }
};

export default function(state = initialState, action) {
  switch (action.type) {
      case SET_INITIAL_TRANSACTION:
          return initialState;
      case SET_TRANSACTION_FILTER:
          return {
              ...state,
              selectedFilter: action.payload,
              page: null
          };
      case SET_TRANSACTION_HISTORY:
          return {
              ...state,
              transactionHistory: action.payload
          };
      case SET_TRANSACTION_TYPE:
          return {
              ...state
          };
      case SET_TRANSACTION_PAGE:
          return {
              ...state,
              page: action.page
          };
      case SET_TRANSACTION_DATE:
          return {
              ...state
          };
      default:
          return state;
  }
}
