import {
    PRODUCT_PROVIDER_ERRORS,
    PRODUCT_PROVIDER_SUCCESS,
} from "../actions/types";

const initialState = {
    errors: [],
    result: []
};

export default function(state = initialState, action) {
    switch (action.type) {

        case PRODUCT_PROVIDER_SUCCESS:
            return {
                ...state,
                result: action.payload,
                errors: false
            };

        case PRODUCT_PROVIDER_ERRORS:
            return {
                ...state,
                result: [],
                errors: action.payload
            };
        default:
            return state;
    }
}
