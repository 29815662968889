const games = [
    {
        route: '/live-vegas',
        productID: 12,
        integrationID: 55,
        providerID: 33,
        gameID: 1,
        gameType: 'livecasino',
        auth: true
    }
];

export default games;
