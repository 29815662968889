import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export {  getProductProviders }

function getProductProviders(params){
    return axios.get(`${ROOT_URL}/productProviders`,{
        headers: {
            Authorization: `Bearer ${findToken()}`
        }, params
    });
}

