import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook"; 
import classname from "classnames"; 
import Modal from "react-bootstrap/Modal"; 
import NumberFormat from 'react-number-format'; 
import ReactHtmlParser from "react-html-parser";
import { displayAmount, realAmount, totalAmount } from "../../../../../helpers"; 
import { postTransfer, successModal,postTransferWallet,postTransferAll, mainWalletSpin } from "../../../../../actions/payment";
import { useTranslation } from 'react-i18next'; 
import { Loader } from "../../../../common";
import classnames from "classnames";
import { getActiveProviders, resetActiveCount } from '../../../../../actions/provider';
import { getPlayer, clearWallet } from "../../../../../actions/player";
 
const All = ({show, handleClose, total, success}) => { 
 
   const { t } = useTranslation();
   const dispatch = useDispatch();

   const onClick = useCallback(() => { 
    let amount = total.toString().replace(/,/g,"");
    amount = realAmount(Math.trunc(amount));

        if (amount > 0) {
            dispatch(postTransferAll({ amount: amount }));
            dispatch(mainWalletSpin(true));
            dispatch(resetActiveCount());
            dispatch(clearWallet());
            handleClose();
        }
    }); 

   useEffect(() => {
        if(success === true) {
            dispatch(getPlayer());
            dispatch(getActiveProviders());
            window.setTimeout(() => {
                dispatch(mainWalletSpin(false));
            }, 10000);
        }
    }, [success]);

    const onClose = useCallback(() => { 
       handleClose();
    });

    let amount = total.toString().replace(/,/g,"");
    amount = realAmount(Math.trunc(amount));

    return ( 
        <> 
        <div 
            className={classname("modal fade show",{ 
            })} 
            style={{ 
                display: show ? "block" : "none" 
            }} 
            id="InsufficientModal" tabIndex={-1} role="dialog" aria-labelledby="InsufficientModal" aria-hidden="true"> 
            <Modal 
                centered 
                show={show} 
                onHide={() => onClose()} > 
                <Modal.Header> 
                    <h5 className="modal-title text-center" id="LoginModal">{t('transfer confirmation').toUpperCase()}</h5> 
                    <button 
                        type="button" 
                        className="close" 
                        onClick={ () => onClose()} 
                    > 
                        <span aria-hidden="true">&times;</span> 
                    </button> 
                </Modal.Header> 
                <Modal.Body className="modal-body text-center"> 
                    <h5 class="pt-3">{t('transfer all to main wallet')}?</h5>
                                    <div class="mb-3">
                <p class="mb-2">{ReactHtmlParser(t('you are about to transfer currency amount to your main wallet', { currency: 'KRW', amount: total}))}</p>
                
                <div class="col-md-6 offset-md-3 text-center">
                  <div class="row">
                  <div class="col-md-6">
                   <a aria-label="Close" data-dismiss="modal" class="btn transCancel" onClick = {()=>onClose()} >{t('cancel')}</a>
                 </div>
                 <div class="col-md-6">
                   <button  class="btn btn-success" data-dismiss="modal" id="quickIngameTransfer" disabled={amount <= 0} onClick = {()=>onClick()}>{t('transfer all')}</button>
                 </div>
               </div>
             </div>
              </div>
                </Modal.Body> 
            </Modal> 
        </div> 
            
        </>
    );
};

export default All;



