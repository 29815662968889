import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import classnames from "classnames";

class Submenu extends Component {
    constructor(props) {
      super(props);
        this.state = {
           active: 0,
        };
    }  

    componentDidMount() { 
      window.addEventListener('scroll', this.listenScrollEvent)
    }
    componentWillUnmount() {
    }

    listenScrollEvent = e => {
      if (window.scrollY > 550 && window.scrollY < 960) {
        this.setState({active: 1})
      } else if (window.scrollY > 960 && window.scrollY < 1830) {
        this.setState({active: 2})
      } else if (window.scrollY > 1830 && window.scrollY < 2410) {
        this.setState({active: 3})
      } else if (window.scrollY > 2410 && window.scrollY < 3360) {
        this.setState({active: 4})
      } else if (window.scrollY > 3360 && window.scrollY < 4480) {
        this.setState({active: 5})
      }else {
          this.setState({active: 0})
      }
    }

    render() {
        const {t} = this.props;
        const {active} = this.state;
        return (
            <section class="submenus">
                  <ul>
                    <li>
                      <a className={classnames("nav-link scrollspy-menu",{"active": active === 1  })} href="/partners#details" >{t('details')}</a>
                    </li>
                    <li>
                      <a className={classnames("nav-link scrollspy-menu",{"active": active === 2  })} href="/partners#commission">{t('commission')}</a>
                    </li>
                    <li>
                      <a className={classnames("nav-link scrollspy-menu",{"active": active === 3  })} href="/partners#marketingtools" >{t('tools')}</a>
                    </li>
                    <li>
                      <a className={classnames("nav-link scrollspy-menu",{"active": active === 4  })} href="/partners#faqs">{t('faqs')}</a>
                    </li>
                    <li>
                      <a className={classnames("nav-link scrollspy-menu",{"active": active === 5  })} href="/partners#contactus">{t('contact')}</a>
                    </li>
                  </ul>
                </section>
        );
    }
}

export default withTranslation()(Submenu);
