import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";


export {  getGames, getRecent, getCategories }

function getGames(params){
    let page = 'slot/games';
    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/${page}`, {
        headers: {
            Authorization
        }, params
    });
}

function getRecent(params){
    let page = "recentSlotTest";

    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/${page}`, {
        headers: {
            Authorization
        }, params
    });
}


function getCategories(params){

    return axios.get(`${ROOT_URL}/gameCategories`, {
        params
    });
}