import {
    GET_BANK_ACCOUNT,
    GET_PROVIDERS,
    SET_PROVIDER,
    RESET_DEPOSIT_SUCCESS,
    RESET_WITHDRAWAL_SUCCESS,
    REQUEST_POST_DEPOSIT,
    REQUEST_POST_RESEND,
    REQUEST_POST_TRANSFER,
    REQUEST_POST_TRANSFER_ALL,
    REQUEST_POST_TRANSFER_WALLET,
    REQUEST_POST_WITHDRAWAL,
    REQUEST_WITHDRAWAL_CHECK,
    REQUEST_DEPOSIT_CHECK,
    TRANSFER_SUCCESS,
    TRANSFER_ERRORS,
    REQUEST_POST_TRANSFER_WITHOUT_SUCCESS,
    TRANSFER_SUCCESS_WITHOUT_SUCCESS,
    TRANSFER_ERRORS_WITHOUT_SUCCESS,
    REQUEST_POST_TRANSFER_WALLET_WITHOUT_SUCCESS,
    TRANSFER_MODAL_SUCCESS,
    TRANSFER_MODAL_RESET,
    MAIN_WALLET_SPIN,
    RESET_RESEND_DEPOSIT,
    RESET_RESEND_ERROR,
    GET_PLAYER_DEPOSIT_TRON_BALANCE,
    SET_PLAYER_DEPOSIT_TRON_BALANCE,
    GET_PLAYER_DEPOSIT_TRON_PURCHASE,
    GET_PLAYER_DEPOSIT_TRON_STATUS,
    SET_PLAYER_DEPOSIT_TRON_STATUS,
    GET_PLAYER_DEPOSIT_OPTION,
    SET_PLAYER_DEPOSIT_OPTION,
    RESET_DEPOSIT_ERROR,
    RESET_WITHDRAWAL_ERROR,
    GET_DEPOSIT_COUNT
} from "./types";

export const resetDeposit = payload => {
    return {
        type: RESET_DEPOSIT_SUCCESS,
        payload: {}
    };
}

export const resetDepositError = payload => {
    return {
        type: RESET_DEPOSIT_ERROR,
        payload: {}
    };
}

export const resetWithdrawal = payload => {
    return {
        type: RESET_WITHDRAWAL_SUCCESS
    };
}

export const resetWithdrawalError = payload => {
    return {
        type: RESET_WITHDRAWAL_ERROR,
        payload: {}
    };
}

export const resetTransfer = payload => {
    return {
        type: TRANSFER_SUCCESS,
        payload: {}
    };
}

export const resetTransferError = payload => {
    return {
        type: TRANSFER_ERRORS,
        payload: {}
    };
    }

export const resetTransferWithoutSuccess = payload => {
    return {
        type: TRANSFER_SUCCESS_WITHOUT_SUCCESS,
        payload: {}
    };
}

export const resetTransferErrorWithoutSuccess = payload => {
    return {
        type: TRANSFER_ERRORS_WITHOUT_SUCCESS,
        payload: {}
    };
}

export const postTransferAll = payload => {
    return {
        type: REQUEST_POST_TRANSFER_ALL,
        payload
    };
}

export const postTransfer = payload => {
    return {
        type: REQUEST_POST_TRANSFER,
        payload
    };
}

export const postTransferWithoutSuccess = payload => {
    return {
        type: REQUEST_POST_TRANSFER_WITHOUT_SUCCESS,
        payload
    };
}

export const postTransferWallet = payload => { 
    return {
        type: REQUEST_POST_TRANSFER_WALLET,
        payload
    };
}

export const postTransferWalletWithoutSuccess = payload => { 
    return {
        type: REQUEST_POST_TRANSFER_WALLET_WITHOUT_SUCCESS,
        payload
    };
}

export const postDeposit = payment => {
    return {
        type: REQUEST_POST_DEPOSIT,
        payload: payment
    };
};

export const postResend = payload => {
    return {
        type: REQUEST_POST_RESEND,
        payload: payload
    };
};

export const checkDeposit = () => {
    return {
        type: REQUEST_DEPOSIT_CHECK
    };
};

export const getProviders = () => {
    return {
        type: GET_PROVIDERS
    };
};

export const checkWithdrawal = () => {
    return {
        type: REQUEST_WITHDRAWAL_CHECK
    };
};

export const getBankAccount = type => {
    return {
        type: GET_BANK_ACCOUNT,
        payload: type
    };
};

export const postWithdrawal = payment => {
    return {
        type: REQUEST_POST_WITHDRAWAL,
        payload: payment
    };
};

export const selectProvider = event => {
    return {
        type: SET_PROVIDER,
        payload: event.target.value,
    };
};

export const resetModal = () => {
    return {
        type: TRANSFER_MODAL_RESET
    };
};

export const successModal = () => {
    return {
        type: TRANSFER_MODAL_SUCCESS
    };
};

export const mainWalletSpin = payload => {
    return {
        type: MAIN_WALLET_SPIN,
        payload
    };
};


export const resetResendDeposit = () => {
    return {
        type: RESET_RESEND_DEPOSIT,
    };
};


export const resetResendError = () => {
    return {
        type: RESET_RESEND_ERROR,
    };
};


export const getTronBalance = type => {
    return {
        type: GET_PLAYER_DEPOSIT_TRON_BALANCE,
        payload: type
    };
};

export const setTronBalance = balance => {
    return {
        type: SET_PLAYER_DEPOSIT_TRON_BALANCE,
        payload: balance
    };
};

export const getTronPurchase = type => {
    return {
        type: GET_PLAYER_DEPOSIT_TRON_PURCHASE,
        payload: type
    };
};

export const setTronPurchase = url => {
    return {
        type: SET_PLAYER_DEPOSIT_TRON_BALANCE,
        payload: url
    };
}

export const getTronStatus = type => {
    return {
        type: GET_PLAYER_DEPOSIT_TRON_STATUS,
        payload: type
    };
};

export const setTronStatus = status => {
    return {
        type: SET_PLAYER_DEPOSIT_TRON_STATUS,
        payload: status
    };
}

export const getDepositOption = type => {
    return {
        type: GET_PLAYER_DEPOSIT_OPTION,
        payload: type
    };
};

export const setDepositOption = payment_type_ids => {
    return {
        type: SET_PLAYER_DEPOSIT_OPTION,
        payload: payment_type_ids
    };
}

export const getDepositCount = () => {
    return {
        type: GET_DEPOSIT_COUNT
    };
};