import axios from "axios"; 
import { ROOT_URL } from "../config/api"; 
 
export { getWithdrawals, getWithdrawalSummary, getWinnerList, getWinnerList30Days, getWinnerListSummary, getPopupNotice, getCarousel, getCarouselSetting }; 
 
function getWithdrawals(param) { 
  return axios.get(`${ROOT_URL}/landing/withdrawals`); 
} 

function getWithdrawalSummary(param) { 
    return axios.get(`${ROOT_URL}/landing/withdrawalSummary`); 
} 

function getWinnerList(param) { 
  return axios.get(`${ROOT_URL}/landing/winnerList`); 
} 

function getWinnerList30Days(param) { 
  return axios.get(`${ROOT_URL}/landing/winnerList30Days`); 
}

function getWinnerListSummary(param) { 
    return axios.get(`${ROOT_URL}/landing/winnerListSummary`); 
} 

function getPopupNotice(param) { 
  return axios.get(`${ROOT_URL}/popupNotice`, {params: param}); 
}

function getCarousel() {
  let lang = localStorage.getItem('i18nextLng');
  
  if(lang === null) {
    let defaultLang = navigator.language || navigator.userLanguage;
    lang = defaultLang.split('-')[0];
  } else {
    lang = lang.split('-')[0];
  }

  return axios.get(`${ROOT_URL}/homePageCarousel?lang=${lang}`); 
}

function getCarouselSetting() { 
  return axios.get(`${ROOT_URL}/homePageCarouselSetting`); 
}
