import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { openGame, resetGame } from '../../../actions/game';
import BlackListedError from '../../common/BlackListedError';
import games from '../../../config/games';
import { findToken } from '../../../config/api';

const Game = () => {
    const dispatch = useDispatch();
    // const history = useHistory();
    const { t, i18n } = useTranslation();
    const gameState = useSelector(state => state.game);
    const brandGameSetup = useSelector(state => state.page.brandGameSetup);
    const game = games.find(game => game.route === window.location.pathname);
    const { language } = i18n;
    let lang = '';
    
    if(language !== undefined) {
        lang = language.split('-')[0];
    } else {
        lang = 'ko';
    }

    useEffect(() => {
        if(!findToken()) {
            if(game.auth) {
                document.getElementById('login-btn').click();
            } else {
                dispatch(openGame());
            }
        } else {
            dispatch(openGame());
        }

        return () => {
            dispatch(resetGame());
        }
	}, []);

    useEffect(() => {
        if(brandGameSetup !== undefined && brandGameSetup.length > 0) {
            let search = brandGameSetup.find(el => el.ProductID === game.productID);

            if (search === undefined) {
                window.location.href = '/';
            }
        }
    }, [brandGameSetup]);

    const UnderMaintenance = () => {
        return (
            <section className="game-maintenance-section">
                <div className="container-fluid h-100">
                    <div className="error-page">
                    <div className="row align-items-center justify-content-center full-height">
                        <div className="container">
                        <div className="box-container">
                            <div className="row align-items-center">
                            <div className="col-lg-auto mr-lg-2">
                                <img className="warning" src="/assets/img/icons/maintain.svg" />
                            </div>
                            <div className="col-lg">
                                <div className="content">
                                    <p className="title">{gameState.errors.errors.content !== undefined ? gameState.errors.errors.content[lang].Title : t('game maintenance title')}</p>
                                    <p className="content-text">
                                    {ReactHtmlParser(gameState.errors.errors.content !== undefined ? gameState.errors.errors.content[lang].Message : t('game maintenance text'))}
                                    </p>
                                </div>
                                <div className="browser-section mt-lg-4 mt-md-3">
                                <div className="row align-items-center justify-content-center justify-content-lg-start">
                                    <div className="col-auto pl-lg-0">
                                    <div className="row align-items-center justify-content-center justify-content-lg-start browser">
                                        <div className="col-auto icon p-0">
                                        <img src="/assets/img/w365/icons/contact-us/telegram.svg"/>
                                        </div>
                                        <div className="col-auto p-0">
                                        <p>{t('cs-telegram')}</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-auto">
                                    <div className="row align-items-center justify-content-center justify-content-lg-start browser">
                                        <div className="col-auto icon p-0">
                                        <img src="/assets/img/w365/icons/contact-us/cs-email.svg"/>
                                        </div>
                                        <div className="col p-0">
                                        <p>{t('cs support email inqury')}: {t('cs support email')}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
       <div style={{width:'100%',minHeight:'544px'}}>
            {!gameState.loading && gameState.url !== null && gameState.success ? 
                <div className="iframe-container aligned-to-header" style={{margin:0}}>
                    <iframe id="game-frame" className="RefFrame" src={gameState.url} style={{width:'100%'}} title="Game" allowFullScreen></iframe>
                </div>
            : null}
            {!gameState.loading && !gameState.success && gameState.errors.errors !== undefined && gameState.errors.errors.undermaintenance !== undefined && gameState.errors.errors.undermaintenance ? <UnderMaintenance /> : null}
            {!gameState.loading && !gameState.success && (gameState.errors.player_blacklisted !== undefined || gameState.errors.player_closed !== undefined) ? <div className="blacklisted blacklisted-error"><BlackListedError /></div> : null}
        </div>
    );
}

export default Game;
