import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { getActiveProviders, getFavouriteProviders };

function getActiveProviders() {
    return axios.get(`${ROOT_URL}/activeProviders`,{
        headers: {
            Authorization: `Bearer ${findToken()}`
        }
    });
}

function getFavouriteProviders() {
	return axios.get(`${ROOT_URL}/favouriteProvider`,{
        headers: {
            Authorization: `Bearer ${findToken()}`
        }
    }); 
}
