import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from "redux-react-hook";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ReactHtmlParser from "react-html-parser";

import BtnLoader from '../../../common/BtnLoader';

import { thousandAmount } from '../../../../helpers';
import { setDepositPromotionBonus } from "../../../../actions/promotion"

const PromotionModal = ({show, handleClose, promotion, selectedCategory}) => {
    const { t } = useTranslation();
    const isLogin = localStorage.getItem("appToken");
    const history = useHistory();

    const handleButton = (promotion) => {
        if(!isLogin) {
            history.push('/register');
        } else {
            if(promotion.promotion.Type === 3) {
                history.push('/myaccount/deposit');
            } else {
                if(promotion.promotion.SubType === 1 || promotion.promotion.SubType === 2) {
                    history.push('/myaccount/deposit/' + promotion.promotion.PromotionID);
                }
            }
        }
    }

    const displayButton = () => {
        if(isLogin) {
            if(promotion.PromotionID !== 0) {
                if(promotion.promotion){
                    if(promotion.promotion.Type === 3) {
                        return true;
                    } else {
                        if(promotion.promotion.SubType === 1 || promotion.promotion.SubType === 2) {
                            return true;
                        }
                    }
                }
            }
        } else {
            return true;
        }
        return false;
    }

    const category = selectedCategory[0].Category === 'All' ? '' : selectedCategory[0].Category.toLowerCase();

    return (
        <React.Fragment>
            <div
                className="modal fade show"
                style={{
                    display: show ? "block" : "none"
                }}
                id="DepositModal" 
                tabIndex={-1} 
                role="dialog" 
                aria-labelledby="DepositModal" 
                aria-modal="true"
            >
                <Modal
                    className='promotion-modal'
                    centered
                    show={show}
                    onHide={handleClose}
                >
                    <Modal.Header>
                        <p className="modal-title" id="LoginModal">{t('promotion')}</p>
                        <button
                            type="button"
                            className="close"
                            onClick={handleClose}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        {
                            promotion ?
                            <React.Fragment>
                                <div className='row text-center'>
                                    <div className='col-6'>
                                        <img src={process.env.REACT_APP_BO_STORAGE + promotion.ThumbnailPath}/>
                                    </div>
                                    <div className='col-6 d-flex align-items-center'>
                                        <div style={{width: '100%'}}>
                                            <p className='title'>{promotion.Title}</p>
                                            <p className='short-description'>{promotion.ShortDescription}</p>
                                            {
                                                displayButton() ?
                                                <button className='btn btn-success' onClick={() => handleButton(promotion)}>{t('join now')}</button>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row py-5'>
                                    <div className='col-12'>
                                        <div className='table-container'>
                                            {ReactHtmlParser(promotion.LongDescription)}
                                        </div>
                                    </div>
                                </div>
                                <div className='row pb-5'>
                                    <div className='col-12'>
                                        <p className='tnc-title'>{t('bonus event condition')}</p>
                                        {ReactHtmlParser(promotion.TermsAndCondition)}
                                    </div>
                                </div>
                            </React.Fragment>
                            : <></>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default PromotionModal;
