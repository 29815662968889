import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import logo from "../../../assets/img/15bet_logo.svg";
import Error from "../../../errors";
import { Link } from "react-router-dom";
import { postPlay, postSports } from "../../../actions/sports";
import { withTranslation } from "react-i18next";
import {
    getWallet
} from "../../../actions/player";

const Betradar = ({ url }) => {
    return (
        <div className="iframe-container" style={{ margin: "0px" }}>
            <iframe
                id="RefFrame"
                className="RefFrame"
                src={url}
                width="1903"
                style={{ width: "100%" }}
                title="sports"

            ></iframe>
        </div>
    );
};

class Sports extends Component {
    state = {
        url: "",
        success: true
    };

    componentDidMount() {
        if (localStorage.getItem("appToken")) {
            this.props.postPlay({ providerID: 22, productID: 9});
        } else {
            this.props.postSports({ providerID: 22, productID: 9});
        }
    }

    componentWillReceiveProps(nextProps) {
        const { url, success } = nextProps.sports.result;
        this.setState({ url: url, success: success });
    }
 
    render() {
        const url = this.state.url;
        const { t } = this.props;

        return (
            
            <Error>
                {
                    this.state.success ?
                    <Betradar
                        url={url}
                    />
                    :
                    <div className="error_page_2">
                        <div className="error_splash row text-center">
                            <div className="col-md-12">
                                <div className="symbol_icon">
                                    <i className="svg-icon svg-warning" />
                     
                                    <p >
                                        <span className="colgray">{t('code')}:</span> <b>4013</b>
                                    </p>
                                    <p></p>
                                </div>
                                <h1 style={{color:"#8EB50D"}}>{t('error opening game')}</h1>
                                <p style={{fontSize: "20px", fontWeight: "600"}}>
                                    {t("please try again")}
                                </p>
                                <p>
                                    <Link to="/" className="btn btn-primary" style={{padding:"10px 20px 10px 20px"}}>
                                        {t('back to homepage')}{" "}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </Error>
           
        );
    }
}

Sports.propTypes = {
    postPlay: PropTypes.func.isRequired
};

Sports.defaultProps = {
    sports: {
        result: {
            url: ""
        }
    }
};

const mapStateToProps = (state) => ({
    sports: state.sports
});

export default connect(mapStateToProps, { postPlay, postSports, getWallet })(withTranslation()(withRouter(Sports))
);
