import { takeLatest, put, call } from 'redux-saga/effects'; 
import { getWithdrawals, getWithdrawalSummary, getWinnerList, getWinnerList30Days, getWinnerListSummary, getPopupNotice, getCarousel, getCarouselSetting } from "../api/landingpage"; 
import { 
    GET_LANDING_PAGE_WITHDRAWALS, 
    SET_LANDING_PAGE_WITHDRAWALS,
    GET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    SET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    GET_LANDING_PAGE_WINNER_LIST, 
    SET_LANDING_PAGE_WINNER_LIST,
    GET_LANDING_PAGE_WINNER_LIST_SUMMARY,
    SET_LANDING_PAGE_WINNER_LIST_SUMMARY,
    GET_LANDING_PAGE_WINNER_LIST_30_DAYS,
    SET_LANDING_PAGE_WINNER_LIST_30_DAYS,
    GET_LANDING_PAGE_POPUP_NOTICE,
    SET_LANDING_PAGE_POPUP_NOTICE,
    GET_LANDING_PAGE_CAROUSEL,
    SET_LANDING_PAGE_CAROUSEL,
    GET_LANDING_PAGE_CAROUSEL_SETTING,
    SET_LANDING_PAGE_CAROUSEL_SETTING
} from "../actions/types"; 
 
export { landingPageWatcher }; 
 
function* landingPageWatcher() { 
    yield takeLatest(GET_LANDING_PAGE_WITHDRAWALS, fetchLandingPageWithdrawals) 
    yield takeLatest(GET_LANDING_PAGE_WITHDRAWAL_SUMMARY, fetchLandingPageWithdrawalSummary) 
    yield takeLatest(GET_LANDING_PAGE_WINNER_LIST, fetchLandingPageWinnerList)
    yield takeLatest(GET_LANDING_PAGE_WINNER_LIST_30_DAYS, fetchLandingPageWinnerList30Days) 
    yield takeLatest(GET_LANDING_PAGE_WINNER_LIST_SUMMARY, fetchLandingPageWinnerListSummary) 
    yield takeLatest(GET_LANDING_PAGE_POPUP_NOTICE, fetchLandingPopupNotice)
    yield takeLatest(GET_LANDING_PAGE_CAROUSEL, fetchCarousel)
    yield takeLatest(GET_LANDING_PAGE_CAROUSEL_SETTING, fetchCarouselSetting)
}
 
function* fetchLandingPageWithdrawals(param) { 
    try {
        const result = yield call(getWithdrawals, param.currency ); 
        yield put({ 
            type: SET_LANDING_PAGE_WITHDRAWALS, 
            payload: result.data 
        }); 
    } catch(error) {

    }
} 

function* fetchLandingPageWithdrawalSummary(param) { 
    try {
        const result = yield call(getWithdrawalSummary, param.currency ); 
        yield put({ 
            type: SET_LANDING_PAGE_WITHDRAWAL_SUMMARY, 
            payload: result.data.data 
        }); 
    } catch(error) {

    }
} 

function* fetchLandingPageWinnerList(param) { 
    try {
        const result = yield call(getWinnerList, param.currency ); 
        yield put({ 
            type: SET_LANDING_PAGE_WINNER_LIST, 
            payload: result.data.data 
        }); 
    } catch(error) {

    }
} 

function* fetchLandingPageWinnerList30Days(param) { 
    try {
        const result = yield call(getWinnerList30Days, param.currency ); 
        yield put({ 
            type: SET_LANDING_PAGE_WINNER_LIST_30_DAYS, 
            payload: result.data.data 
        }); 
    } catch(error) {

    }
} 

function* fetchLandingPageWinnerListSummary(param) { 
    try {
        const result = yield call(getWinnerListSummary, param.currency ); 
        yield put({ 
            type: SET_LANDING_PAGE_WINNER_LIST_SUMMARY, 
            payload: result.data.data 
        }); 
    } catch(error) {

    }
} 

function* fetchLandingPopupNotice(data) { 
    const param = {
        domain: window.location.hostname === 'localhost' ? 'frontend-test.15gaming.com' : window.location.hostname,
        // brandID: 5,
        lang: data.language,
    }
    try {
        const result = yield call(getPopupNotice, param); 
        yield put({ 
            type: SET_LANDING_PAGE_POPUP_NOTICE, 
            payload: result.data 
        }); 
    } catch(error) {

    }
}

function* fetchCarousel() {
    try {
        const result = yield call(getCarousel);

        yield put({
            type: SET_LANDING_PAGE_CAROUSEL,
            payload: {
                data: result.data,
                loading: false,
                error: false
            }
        });
    } catch(err) {
        yield put({
            type: SET_LANDING_PAGE_CAROUSEL,
            payload: {
                data: [],
                loading: false,
                error: true
            }
        });
    }
}

function* fetchCarouselSetting() {
    try {
        const result = yield call(getCarouselSetting);

        yield put({
            type: SET_LANDING_PAGE_CAROUSEL_SETTING,
            payload: {
                data: result.data,
                loading: false,
                error: false
            }
        });
    } catch(err) {
        yield put({
            type: SET_LANDING_PAGE_CAROUSEL_SETTING,
            payload: {
                data: [],
                loading: false,
                error: true
            }
        });
    }
}
