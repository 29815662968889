import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { getCurrencies, postRegisterAffiliate,resetError,checkCanRegister } from "../../../actions/registration";
import { withTranslation } from 'react-i18next';
import { getIP } from "../../../actions/ipaddress";
import ReactHtmlParser from "react-html-parser";

class Register1 extends Component {
    state = {
        validated: false,
        username: "",
        password: "",
        confirm_password: "",
        currency: "2",
        phone: "",
        country: "",
        isShown: false,
        ref_code: "",
        ref_username: "",
        currencies: [],
        error: []
    };

    componentDidMount(){
        this.props.resetError();
        this.props.getIP();
        this.props.checkCanRegister({domain:window.location.hostname});
        this.props.getCurrencies();
        var elem = document.getElementById("main-wrap");
        elem.classList.add("register-section");

        if (this.props.canRegister.CanSignUp !== undefined) {
            if (!this.props.canRegister.CanSignUp) {
                this.props.history.push("/");
            }
        }
    }

    componentWillUnmount(){
        this.props.resetError();
    }

    // mapPhoneCode = currencies => {
    //     const phoneCodes = {};
    //     currencies.map(currency => {
    //         return (phoneCodes[currency.CurrencyID] = currency.country.PhoneCode)
    //     });
    //     this.setState({ phoneCodes }, () => {
    //         let key = Object.keys(this.state.phoneCodes)
    //         this.setState({
    //             currency: key[0],
    //             country: this.state.phoneCodes[key[0]]
    //         });
    //     });
    // };

    // //set default country code and currency
    // //if only one currency record from api
    // defaultCurrency(){
    //     if(this.props.currencies.length === 1){
    //         let key = Object.keys(this.state.phoneCodes)
    //         return [key[0], this.state.phoneCodes[key[0]]]
    //     }else{
    //         return [this.state.currency, this.state.country]
    //     }
    // }

    // static getDerivedStateFromProps(nextProps){
    //     return { ...nextProps }
    // }

    // shouldComponentUpdate(nextProps, nextState){
    //     if(!this.state.hasOwnProperty('phoneCodes')){
    //         this.mapPhoneCode(this.state.currencies);
    //         return false;
    //     }
    //     return true;
    // }

    componentWillReceiveProps(nextProps) {
        if(this.props.currencies.length === 0){
            this.props.getCurrencies();
        }
        this.setState({
            error: nextProps.error,
            currencies: nextProps.currencies
        });
    }

    togglePassword(){
        this.setState({ isShown: !this.state.isShown });
    }

    onSubmit(e){
        e.preventDefault();
        e.stopPropagation();
        // const [currency, country] = this.defaultCurrency();
        const { history } = this.props;
        const {
            i18n: { language }
        } = this.props;
        const languages = {
            "en-US": "en",
            "ko-KR": "kr"
        };
        const register = {
            username: this.state.username,
            password: this.state.password.replace(/\s/g, ""),
            confirm_password: this.state.confirm_password.replace(/\s/g, ""),
            phoneCode: 82,
            // phone: "010" + this.state.phone,
            phone: this.state.phone,
            currency: this.state.currency,
            history,
            ref_code: this.state.ref_code,
            deviceUrl: window.location.href,
            lang: languages[language],
            domain:window.location.hostname,
            // domain:"w365b.com",
            first_name: this.state.first_name,
        };
        this.setState({ validated: true });
        if(this.form.checkValidity()){
           this.props.postRegisterAffiliate(register);
           this.setState({ validated: false });
        }

    }

    onChange = e => {
        if (e.target.name === "username" || e.target.name === "phone") {
            this.handleSpace(e);
        }

        if (e.target.name === "currency") {
            this.setState({ country: this.state.phoneCodes[e.target.value] });
        }

        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleKeyDown = e => {
        if (e.key === " ") {
          e.preventDefault();
        }
      };

    handleSpace = e => {
        if (e.currentTarget.value.includes(" ")) {
          e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
        }
      };

    render(){
        const { currencies, error } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <section class="contentWrap partner-registration-bg">
                    <div class="container">
                        <div class="row align-items-center justify-content-end">
                            <div class="forms-container">
                                <form 
                                    id="regForm"
                                    className={this.state.validated ? "needs-validation was-validated": ""}
                                    noValidate
                                    method="post"
                                    onSubmit={this.onSubmit.bind(this)}
                                    ref={(form)=> this.form = form}
                                >
                                    <div class="mb-3"><p class="div-title">{t('create an account')}</p></div>
                                    <div class="row mb-3">
                                        <div class="col-12"><p class="sub-title-w365">{t('personal info')}</p></div>
                                        <div class="col-12">
                                            <div class="input-group">
                                                <input 
                                                    type="text" 
                                                    class={`form-control dark form-control-md ${error && error.username ? "is-invalid":""} `} 
                                                    id="regID" 
                                                    placeholder={t('id') + " *"}
                                                    onKeyDown={this.handleKeyDown}
                                                    onChange={this.onChange}
                                                    name="username"
                                                    required
                                                />
                                                <div className="invalid-tooltip">
                                                    { error && error.username && error.username.length > 0 ? "": t("this field is required") }
                                                    { error && (error.username && error.username[0] === "The username has already been taken.")
                                                    ?  t("the username has already been taken") : ""}
                                                    { error && error.username && error.username[0] === "The username must be at least 6 characters." ?  t("username validation") : ""}
                                                    { error && error.username && error.username[0] === "The username may only contain letters and numbers." ?  t("username alphanumeric") : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mt-3">
                                            <div class="input-group show_hide_password">
                                                <input 
                                                    type={!this.state.isShown ? 'password' : 'text'}
                                                    className={`form-control dark form-control-md ${error && error.password ? "is-invalid":""} `}
                                                    name="password"
                                                    onChange={this.onChange}
                                                    id="password" 
                                                    placeholder={t('password') + " *"}
                                                    required
                                                />
                                                <div className="invalid-tooltip">
                                                    { error && error.password && error.password.length > 0 ? "": t("this field is required") }
                                                    { error && (error.password && error.password[0] === "The password must be at least 6 characters.")
                                                    || error && (error.password && error.password[0] === "The password may not be greater than 12 characters.")
                                                    ?  t("password validation") : ""}
                                                    { error && error.password && error.password[0] === "The password and confirm password must match." ?  t("password do not match") : ""}
                                                </div>
                                                <div className="input-group-addon">
                                                    <a style={{'cursor':'pointer'}} onClick={this.togglePassword.bind(this)}>
                                                        <i className={classNames('fa',{
                                                            'fa-eye-slash' : !this.state.isShown,
                                                            'fa-eye' : this.state.isShown
                                                        })}
                                                        aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mt-3">
                                            <div class="input-group show_hide_password">
                                                <input
                                                    type={!this.state.isShown ? 'password' : 'text'}
                                                    className={`form-control dark form-control-md ${error && error.password && error.password[0] === "The password and confirm password must match." ? "is-invalid":""} `}
                                                    name="confirm_password"
                                                    onChange={this.onChange}
                                                    id="confirm_password"
                                                    placeholder={t('verify password') + " *"}
                                                    required
                                                />
                                                <div className="invalid-tooltip">
                                                    { this.state.confirm_password.length === 0 ? t("this field is required") : "" }
                                                    { error && error.password && error.password[0] === "The password and confirm password must match." ?  t("password do not match") : ""}
                                                </div>
                                                <div className="input-group-addon">
                                                    <a style={{'cursor':'pointer'}} onClick={this.togglePassword.bind(this)}>
                                                        <i className={classNames('fa',{
                                                            'fa-eye-slash' : !this.state.isShown,
                                                            'fa-eye' : this.state.isShown
                                                        })}
                                                        aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="input-group">
                                                <input 
                                                    type="text" 
                                                    className={`form-control dark form-control-md ${error && error.first_name ? "is-invalid":""} `} 
                                                    placeholder={t('full name')}
                                                    name="first_name"
                                                    required
                                                    onChange={this.onChange}
                                                />
                                                <div className="invalid-tooltip" style={{"margin-top": "-15px"}}>
                                                    { 
                                                        error ?
                                                        (
                                                            error && error.first_name ? t('the name must be at least 2 characters.') : t('this field is required')
                                                        ) :
                                                        (
                                                            t('this field is required')
                                                        ) 
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <p class="sub-title-w365">{t('select currency')}:</p>
                                            <div class="input-group">
                                                <select
                                                    className={`form-select currency-select form-control dark form-control-md registration-select ${error && error.currency ? "is-invalid" : ""} `}
                                                    name="currency"
                                                    onChange={this.onChange}
                                                    disabled={currencies.length === 1 ? 'disabled': false}
                                                    value={this.state.currency}
                                                    required
                                                    id="currency"
                                                >
                                                    { currencies && currencies.length > 1 ?
                                                        <option
                                                            key={currencies.length+1}
                                                            value={""}
                                                            disabled
                                                            defaultValue
                                                        ></option>: ""
                                                    }
                                                    { currencies.map(currency => (
                                                        <option
                                                            key={currency.CurrencyID}
                                                            value={currency.CurrencyID}
                                                            defaultValue={currencies.length === 1 ? true: false}
                                                        >
                                                            {currency.Abbreviation}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <p class="sub-title-w365">{t('mobile no')}:</p>
                                            <div class="input-group">
                                                <input className="form-control dark form-control-md col-1 noBorderRightTop p-0 text-center numberExtension font-white" id="flag" value="+82" disabled>
                                                </input>
                                                <input
                                                    type="tel"
                                                    className={`form-control col-11 dark form-control-md col noBorderLeftTop phoneNumber ${error && error.phone ? "is-invalid" : ""} `}
                                                    name="phone"
                                                    onKeyDown={this.handleKeyDown}
                                                    onChange={this.onChange}
                                                    id="phone"
                                                    placeholder="10XXXXXXXX"
                                                    required
                                                    maxlength="11"
                                                />
                                                <div className="phoneNumberText mt-2">
                                                    {ReactHtmlParser(t('please enter your mobile number in the following format'))}
                                                </div>
                                               
                                                <div className="invalid-tooltip">
                                                    {error && error.phone ? "" : t("phone missing validation")}
                                                    {error && error.phone && error.phone[0] === "phone validation" ? t('phone validation') : " "}
                                                    {error && error.phone && error.phone[0] === "phone format" ? t('phone format') : " "}
                                                    {error && error.phone && error.phone[0] === "The number is blacklisted." ? t('the number is blacklisted.') : " "}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    className={`form-control dark form-control-md ${error && error.ref_code ? 'is-invalid':''} `}
                                                    id="ref_code"
                                                    onChange={this.onChange}
                                                    name="ref_code"
                                                    placeholder={t('partner id')}
                                                />
                                                <div className="invalid-tooltip" >
                                                    {t('partner id validation')}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-4">
                                            <div class="form-group terms">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck1"
                                                    required
                                                />
                                                <label class="form-check-label noStyle notetxt" for="exampleCheck1">{ReactHtmlParser(t('terms and conditions and agreement'))}</label>
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="mt-3 btn btn-primary btn-block"
                                            disabled={currencies.length === 0 ? true : false}
                                        >
                                                {t('register')}
                                        </button>
                                        <div class="col-md-12 mt-4">
                                            <div class="text-right">
                                            <img src="/assets/img/18PlusSSSL.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currencies: state.register.currencies,
    error: state.register.errors,
    success: state.register.result,
    canRegister: state.register.canRegister
});

export default connect(
    mapStateToProps,
    { getCurrencies, postRegisterAffiliate, resetError, getIP, checkCanRegister }
)(withRouter(withTranslation()(Register1)));
