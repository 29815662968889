import io from "socket.io-client";
import { WEBSOCKET_URL, findToken } from "../config/api";

const socket = io( WEBSOCKET_URL, {
    //"reconnection": false,
    "upgrade": true,
    "transports": ["websocket"],
    "query": {
        // "token": findToken(),
        // "isBo": "1",
        "brandID": 5
    }
});

function notification(cb) {
    socket.on("connect", (data) => {
        //console.log("socket connected");
    });

    socket.on("message", (data) => {
        //console.log('message', data);
        cb(data);
    });

    socket.on("disconnect", () => {
        //console.log("socket disconnected");
    });

    socket.on("error", (error) => {
        //console.log("socket error", error);
    });

}

export { notification };
