import { takeLatest, put, call, select } from "redux-saga/effects";
import { getActiveProviders, getFavouriteProviders } from "../api/provider";
import setAuthToken from "../setAuthToken";

import {
    SET_CURRENT_USER,
    GET_ACTIVE_PROVIDERS,
    SET_ACTIVE_PROVIDERS,
    ACTIVE_PROVIDERS_ERRORS,
    GET_FAVOURITE_PROVIDERS,
    SET_FAVOURITE_PROVIDERS,
} from "../actions/types";

export { providerWatcher };

function* providerWatcher() {
    yield takeLatest(GET_ACTIVE_PROVIDERS, fetchActiveProviders);
    yield takeLatest(GET_FAVOURITE_PROVIDERS, fetchFavoriteProviders);
}

function* fetchActiveProviders() {
    const getProvider = (state) => state.providers;
    let project = yield select(getProvider);
    if(project.count === 0) {
        try {
            const result = yield call(getActiveProviders);
            yield put({
                type: SET_ACTIVE_PROVIDERS,
                payload: result.data
            });
        } catch (error) {
            yield put({
                type: ACTIVE_PROVIDERS_ERRORS,
                payload: error.response.data
            });
        }
    }
}

function* fetchFavoriteProviders() {
     try {
         const result = yield call(getFavouriteProviders);
         yield put({
             type: SET_FAVOURITE_PROVIDERS,
             payload: result.data
         });
     } catch (error) {}
 }
