import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { OPEN_GAME } from '../actions/types';
import { openingGameSuccess, openingGameError } from '../actions/game';
import { findToken } from '../config/api';
import { postSports, postPlay } from "../api/sports";
import games from '../config/games';

function* openGame() {
    try {
        let params = {}, url, result;
        const language = localStorage.getItem('i18nextLng') === null ? 'ko' : localStorage.getItem('i18nextLng').slice(0, 2);
        const game = games.find(game => game.route === window.location.pathname);

        if (findToken()) {
            const playerState = yield select(state => state.auth.wallet);

            params = {
                brandID: 5,
                gameID: game.gameID,
                isMobile: 0,
                gameType: game.gameType,
                launchType: 'html5',
                hashid: '',
                lang: language,
                currency: playerState.player.currency.Abbreviation,
                mode: 1,
                launchType: 'html5',
                playerID: playerState.player.PlayerID,
                username: playerState.username,
                productID: game.productID,
                providerID: game.providerID,
                integrationID: game.integrationID
            };

            result = yield call(postPlay, params);
        } else {
            params = {
                IsLive: 0,
                gameID: game.gameID,
                isMobile: 0,
                mode: 0,
                lang: language,
                gameType: game.gameType,
                integrationID: game.integrationID,
                providerID: game.providerID,
                productID: game.productID,
                domain: window.location.hostname === 'localhost' ? 'frontend-test.15gaming.com' : window.location.hostname
            };

            result = yield call(postSports, params);
        }

        url = (result.data.game_url === '' || result.data.game_url === null) ? result.data.url : result.data.game_url;
        yield put(openingGameSuccess(url));
    } catch(err) {
        yield put(openingGameError(err.response.data.errors));
    }
}

function* gameWatcher() {
    yield all([takeLatest(OPEN_GAME, openGame)]);
}

export { gameWatcher };
