import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { getP2PGames, getP2PRecent }



function getP2PGames(params){
    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/slot/games`, {
        headers: {
            Authorization
        }, params
    });
}

function getP2PRecent(params){
    let page = "recentSlotTest";

    const auth= findToken();
    let Authorization = null;
    if(auth){
        Authorization = `Bearer ${findToken()}`
    }

    return axios.get(`${ROOT_URL}/${page}`, {
        headers: {
            Authorization
        }, params
    });
}
