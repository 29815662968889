import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import Error from "../../common/error";
import {
    resetPlayerError,
    postAccount
} from "../../../actions/player";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getBanks
} from "../../../actions/bank";
import SuccessAlert from "../../common/SuccessAlert";

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validated2: false,
            bank: "",
            accountNumber: "",
            xxxx: "",
            yyyy: "",
            newAccountNumber: "",
            banks: [],
            errors: {},
            success: {},
            isBank: false,
            updateBank: false,
            bankID: "",
            bankName: "",
            accountName: "",
            newaccountName: "",
            isSubmitted: false,
            success2: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);

    }

    componentDidMount() {
        // this.props.history.listen((location, action) => {
        //     if (location.pathname.includes("profile")) {
        //         this.props.resetPlayerError();
        //     }
        // });

        this.props.resetPlayerError();

        if (!this.props.player) return false;

        this.props.getBanks(this.props.player.currency.CurrencyID);


        this.componentWillReceiveProps(this.props);
    }

    componentWillReceiveProps(nextProps) {

        const { isSubmitted } = this.state;
        const errors = nextProps.errors;
        this.setState({ errors });

        try {

            if (nextProps.player.bank) {
                if(nextProps.errors.newAccountNumber || nextProps.errors.accountNumber) {

                } else {
                    this.setState({
                        bankName: nextProps.player.bank.bank_details.Name,
                        bank: nextProps.player.bank.bank_details.BankID,
                        accountName: nextProps.player.bank.AccountName,
                        defaultAccountName: nextProps.player.bank.AccountName,
                        newaccountName: nextProps.player.bank.AccountName,
                        newAccountNumber: nextProps.player.bank.AccountNo,
                    });
                }

                if (isSubmitted === false) {
                    this.setState({ bankID: nextProps.player.bank.BankID });
                    this.setState({ accountNumber: nextProps.player.bank.AccountNo });
                    this.setState({ xxxx: nextProps.player.bank.AccountNo });
                    this.setState({ isBank: true });
                    this.setState({ yyyy: nextProps.player.bank.bank_details.LocalName });
                }else{
                    this.setState({  accountName: this.state.accountName });

                }
            }

            // if (nextProps.errors.accountNumber) {
            //     let { accountNumber } = nextProps.errors;
            //     this.setState({ errors: { accountNumber } });
            //     this.setState({ success2: false });
            // }

            // if (nextProps.errors.newAccountNumber) {
            //     let { newAccountNumber } = nextProps.errors;
            //     this.setState({ errors: { newAccountNumber } });
            //     this.setState({ success2: false });
            // }

            if ( nextProps.success && isSubmitted ) {

                if (
                    nextProps.success.message ===
                    "Player bank account succesfully saved"
                ) {
                    this.setState({ updateBank: false });
                    this.setState({ isBank: true });

                    if (this.state.bank) {
                        this.setState({ bankName: this.state.bank });
                        this.setState({ yyyy: this.state.bank });
                    }

                    this.setState({ success2: true });
                    
                    // {this.notify(nextProps.t("player bank account success"))}
                    setTimeout(() => {
                        this.setState({success2: false});
                    }, 5000);
                    this.setState({ isSubmitted: false });

                    if (this.state.newAccountNumber) {
                        this.setState({ accountNumber: this.state.newAccountNumber });
                        this.setState({ xxxx: this.state.newAccountNumber });

                    } else {
                        this.setState({ xxxx: this.state.accountNumber });
                    }
                }
            }

        } catch (error) {
            nextProps.showError();
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleClick() {
        this.setState({ updateBank: true });
    }

    handleClose() {
        this.setState({ updateBank: false, errors: [], accountName: this.state.defaultAccountName });
    }

    onSubmit2 = (e) => {
        e.preventDefault();
        this.setState({ isSubmitted: true });
        let player;

        if (this.state.newAccountNumber) {
            player = {
                bankID: this.state.bank ? this.state.bank : this.state.bankID,
                accountNumber: this.state.accountNumber,
                accountName: this.state.accountName,
                newAccountNumber: this.state.newAccountNumber
            };
        } else {
            player = {
                bankID: this.state.bank ? this.state.bank : this.state.bankID,
                accountNumber: this.state.accountNumber,
                accountName: this.state.accountName,
            };
        }

        this.setState({ bankName: this.state.bank });
        this.setState({ validated2: true });

        if (this.form2.checkValidity()) {
            this.props.postAccount(player);
            this.setState({ validated2: false });
        }
    };

    notify = message => {
        toast.success(message, {
            className: 'toast toast-success profile-toast'
        });
    };

    render() {
        const { t } = this.props;

        const isBank = this.state.isBank;
        const updateBank = this.state.updateBank;
        const bankName = this.state.bankName;
        const bankID = this.state.bankID;
        const accountName = this.state.accountName;
        const newaccountName = this.state.newaccountName;
        const { errors } = this.state;
        const accountNumber = "**********" + this.state.xxxx.substr(this.state.xxxx.length - 4);
        const banks = this.props.banks || [];
        let firstname = "";
        if (this.props.player.FirstName) {
            firstname = this.props.player.FirstName;
        }

        const BankDetails = () => {
            return (
                <>
                    {!updateBank ?
                    <div className="row m-0">
                        <div className="col-md-4">
                            <div className="colLeftInfos">
                                <div className="form-group row">
                                    <div className="col-4 col-form-label labelColor">{t('bank name')}</div>
                                    <div className="col"><p className="col text_display infoColor">{this.state.yyyy}</p></div>
                                </div>
                            </div>
                            <div className="colLeftInfos">
                                <div className="form-group row">
                                    <div className="col-4 col-form-label labelColor">{t('account name')}</div>
                                    <div className="col"><p className="col text_display infoColor">{newaccountName}</p></div>
                                </div>
                            </div>
                            <div className="colLeftInfos">
                                <div className="form-group row">
                                    <div className="col-4 col-form-label labelColor">{t('account number')}</div>
                                    <div className="col"><p className="col text_display infoColor">{accountNumber}</p></div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </>
            );
        }

        return (
            <form noValidate
                className={classNames({ "needs-validation was-validated": this.state.validated2  })}
                onSubmit={this.onSubmit2.bind(this)} ref={(form)=> this.form2 = form}
            >
                {/* <h2 class="formTitle">{t('bank details')}</h2> */}
                <div class="accountInfo accountInfo-w365 mb-5">
                    {this.state.success2 ? <SuccessAlert translationCode="player bank account success" /> : null}
                    {/* <div className="row m-0"> */}
                    {
                        isBank ? 
                            updateBank ? 
                            <React.Fragment>
                                <BankDetails />
                                {/* <div className="col-md-12">
                                    <h2 class="formTitle">{t('update bank account')}</h2>
                                </div> */}
                                <div className="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="transfer-amount" class="col-form-label labelColor mb-1">{t('bank name')} *</label>
                                            <div class="input-group show_hide_password mb-4">
                                                <select
                                                    className="custom-select dark"
                                                    name="bank"
                                                    id="bankName"
                                                    onChange={this.onChange}
                                                    defaultValue={bankID}
                                                    required
                                                >
                                                    {banks.map(bank => (
                                                        <option
                                                            key={bank.BankID}
                                                            value={bank.BankID}
                                                        >
                                                            {bank.BankName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="transfer-amount" class="col-form-label labelColor mb-1">{t('account name')} *</label>
                                            <div class="input-group show_hide_password mb-4">
                                                <input
                                                    type="text"
                                                    id="accountName"
                                                    name="accountName"
                                                    className={classNames("form-control dark", {
                                                        "is-invalid": errors.accountName
                                                    })}
                                                    defaultValue={accountName}
                                                    value={this.state.accountName}
                                                    onChange={this.onChange}
                                                    required
                                                />
                                                <Error errors={errors} id="accountName" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="transfer-amount" class="col-form-label labelColor mb-1">{t('account number')} *</label>
                                            <div class="input-group show_hide_password mb-4">
                                                <input
                                                    type="text"
                                                    id="newAccountNumber"
                                                    name="newAccountNumber"
                                                    className={classNames("form-control dark", {
                                                        "is-invalid": errors.newAccountNumber
                                                    })}
                                                    placeholder={t("account number")}
                                                    onChange={this.onChange}
                                                    required
                                                />
                                                <Error errors={errors} id="newAccountNumber" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="transfer-amount" class="col-form-label labelColor mb-1">{t('verify old bank account number')} *</label>
                                            <div class="input-group show_hide_password">
                                                <input
                                                    type="text"
                                                    id="accountNumber"
                                                    name="accountNumber"
                                                    className={classNames("form-control dark", {
                                                        "is-invalid": errors.accountNumber
                                                    })}
                                                    placeholder={accountNumber}
                                                    onChange={this.onChange}
                                                    required
                                                />
                                                <div className="invalid-tooltip" >
                                                    {errors && errors.accountNumber ? t(errors.accountNumber[0].toLowerCase()): t("this field is required")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment> 
                            : 
                            <React.Fragment>
                                <BankDetails />
                                {/* <div class="col-12 p-0 mt-4">
                                    <div class="row align-items-center justify-content-end">
                                        <div class="col-auto">
                                            <div><button class="btn btn-success small-caps" onClick={this.handleClick}>{t("update")}</button></div>
                                        </div>
                                    </div>
                                </div> */}
                            </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="transfer-amount" class="col-form-label labelColor mb-1">{t('bank name')}*</label>
                                        <div class="input-group show_hide_password mb-4">
                                            <select
                                                className="custom-select dark"
                                                name="bank"
                                                id="bankName"
                                                onChange={this.onChange}
                                                required
                                            >
                                                <option value="">{t('select bank')}</option>
                                                {banks.map(bank => (
                                                    <option
                                                        key={bank.BankID}
                                                        value={bank.BankID}
                                                    >
                                                        {bank.BankName}
                                                    </option>
                                                ))}
                                            </select>

                                            <Error errors={errors} id="bank"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="transfer-amount" class="col-form-label labelColor mb-1">{t('account name')} *</label>
                                        <div class="input-group show_hide_password mb-4">
                                            <input
                                                type="text"
                                                id="accountName"
                                                name="accountName"
                                                className={classNames("form-control dark", {
                                                    "is-invalid": errors.accountName
                                                })}
                                                placeholder={t("account name")}
                                                defaultValue={accountName}
                                                value={accountName}
                                                onChange={this.onChange}
                                                ref={e => this.bankAccountNameInput = e}
                                                required
                                            />
                                            <Error errors={errors} id="accountName" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("account number")} *</label>
                                        <div class="input-group show_hide_password mb-4">
                                            <input
                                                type="text"
                                                id="accountNumber"
                                                name="accountNumber"
                                                className={classNames("form-control dark", {
                                                    "is-invalid": errors.accountNumber
                                                })}
                                                placeholder={t("account number")}
                                                onChange={this.onChange}
                                                maxLength="20"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                      event.preventDefault();
                                                    }
                                                }}
                                                required
                                            />
                                            <Error errors={errors} id="accountNumber" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> 
                    }
                    {
                        isBank && !updateBank  ? 
                        ""
                        :
                        <div class="col-md-12 p-0 mt-4">
                            {/* {firstname === '' ? <label className="labelColor">{t('please update your account first before adding bank details')}</label> : null} */}
                            <div className="row align-items-center justify-content-end">
                                <div class="col-auto">
                                    <div class="contactBtn">
                                        <button
                                            type="submit"
                                            className="btn btn-success float-right saveBtn"
                                            // disabled={firstname === '' ? true: false }
                                        >
                                            {t("save")}
                                        </button>
                                        { 
                                            // firstname !== '' ?
                                            // <button
                                            //     type="reset"
                                            //     className="btn btn-dark float-right cancelBtn mr-3"
                                            //     onClick={this.handleClose}
                                            //     disabled={firstname === ''  ? true: false }
                                            // >
                                            //     {t("cancel")}
                                            // </button> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* </div> */}
                </div>
            </form>
        );
    }
}

Profile.propTypes = {
    postAccount: PropTypes.func.isRequired,
    getBanks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    player: state.auth.wallet.player,
    errors: state.player.errors,
    success: state.player.result,
    banks: state.banks.banks
});

export default connect(
    mapStateToProps,
    { postAccount, resetPlayerError, getBanks }
)(withTranslation()(withRouter(Profile)));
