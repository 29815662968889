import React, { useEffect, useCallback, Component } from "react";
import Featured from "./Featured";
import Marketing from "./Marketing";
import Commission from "./Commission";
import Tools from "./Tools";
import Faq from "./Faq";
import Contact from "./Contact";
import Submenu from "./Submenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook";
import { getPage } from "../../../actions/page";
import { postContact } from "../../../actions/partner";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from "react-accessible-accordion";

const Content = ({ slug }) => {
    const url = "/" + slug;
    const dispatch = useDispatch();
    const { page } = useSelector((state) => state.page);
    useEffect(() => {
        dispatch(getPage({ slug: url }));
    }, [url]);

    useEffect(() => {
         window.partnerPageScroll();
    }, []);

    const content = page.Content;
    const accordions = page.Accordion;
    let accordionData = "";

    if (content === undefined || content === null) {
        return <NotFound />;
    }

    if (accordions) {
        accordionData = (
            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={[0]}>
                {accordions.map((accordion, key) => {
                    return (
                        <AccordionItem key={key}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {accordion.title}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: accordion.content
                                    }}
                                />
                            </AccordionItemPanel>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        );
    }

    return (
        <div className="content-details clearfix">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {accordionData}
        </div>
    );
};

class NotFound extends Component {
    render() {
        return (
            <div className="container-fluid not-found">
                <div className="not-found-text">
                    <h1 className="display-4">
                        <span className="text-danger">404</span> Page Not Found
                    </h1>
                    <p className="lead"> Sorry, that page does not exist </p>
                </div>
            </div>
        );
    }
}

class Partner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            slug: ""
        };
    }

    componentDidMount = () => {
        this.props.history.listen((location, action) => {
            this.setState({ url: location.pathname });
        });

        this.setState({ url: this.props.location.pathname });

        this.setState({ slug: this.props.match.params.slug });
    };

    render() {
        const url = this.state.url;

        return url === "/partners" ? (
            <div id="partnerWrap" className = "partners-page">
                <Submenu />
                <Featured />
                <Marketing />
                <Commission />
                <Tools />
                <Faq />
                <Contact contact={this.props.postContact.bind(this)} />
            </div>
        ) : (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-md-12">
                            <div id="infos-content">
                                <article className="col-md-12">
                                    <Content
                                        slug={this.props.match.params.slug}
                                    />
                                </article>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    partner: state.partner
});

export default connect(mapStateToProps, {postContact})((withRouter(Partner)));
