import { 
    GET_LANDING_PAGE_WITHDRAWALS,
    GET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    UPDATE_LANDING_PAGE_WITHDRAWALS,
    UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    GET_LANDING_PAGE_WINNER_LIST,
    GET_LANDING_PAGE_WINNER_LIST_SUMMARY,
    GET_LANDING_PAGE_WINNER_LIST_30_DAYS,
    GET_LANDING_PAGE_POPUP_NOTICE,
    GET_LANDING_PAGE_CAROUSEL,
    SET_LANDING_PAGE_CAROUSEL,
    GET_LANDING_PAGE_CAROUSEL_SETTING,
    SET_LANDING_PAGE_CAROUSEL_SETTING
} from "./types"; 
 
export const getWithdrawals = payload => { 
  return { 
    type: GET_LANDING_PAGE_WITHDRAWALS, 
    currency: payload, 
  }; 
}; 

export const getWithdrawalSummary = payload => { 
    return { 
      type: GET_LANDING_PAGE_WITHDRAWAL_SUMMARY, 
      currency: payload, 
    }; 
}; 

export const updateWithdrawals = payload => { 
  return { 
    type: UPDATE_LANDING_PAGE_WITHDRAWALS, 
    payload: payload, 
  }; 
}; 

export const updateWithdrawalSummary = payload => { 
  return { 
    type: UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY, 
    currency: payload, 
  }; 
}; 

export const getWinnerList = payload => { 
  return { 
    type: GET_LANDING_PAGE_WINNER_LIST, 
    currency: payload, 
  }; 
}; 

export const getWinnerList30Days = payload => { 
  return { 
    type: GET_LANDING_PAGE_WINNER_LIST_30_DAYS, 
    currency: payload, 
  }; 
}; 

export const getWinnerListSummary = payload => { 
    return { 
      type: GET_LANDING_PAGE_WINNER_LIST_SUMMARY, 
      currency: payload, 
    }; 
}; 

export const getPopupNotice = payload => { 
  return { 
    type: GET_LANDING_PAGE_POPUP_NOTICE,
    language: payload
  }; 
};

export const getCarousel = params => {
  return { 
      type: GET_LANDING_PAGE_CAROUSEL,
      params
  };
};

export const setCarousel = payload => {
  return { 
      type: SET_LANDING_PAGE_CAROUSEL,
      payload: payload
  };
};

export const getCarouselSetting = () => {
  return { 
      type: GET_LANDING_PAGE_CAROUSEL_SETTING
  };
};

export const setCarouselSetting = payload => {
  return { 
      type: SET_LANDING_PAGE_CAROUSEL_SETTING,
      payload: payload
  };
};
