import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Error from "../../../errors";
import { postPlay, postSports, sportsClear } from "../../../actions/sports";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import BlackListedError from '../../common/BlackListedError';
import { getBrandGameSetup } from "../../../actions/page";

const BT1 = ({ url }) => {
    return (
        <div className="iframe-container aligned-to-header">
            <iframe
                id="RefFrame"
                className="RefFrame"
                src={url}
                width="1903"
                style={{ width: "100%", right: "0"}}
                title="sports"
            ></iframe>
        </div>
    );
};

class Sports extends Component {
    state = {
        url: "",
        success: true,
        undermaintenance: false,
        content: null,
        blacklistedError: false,
        errorStatusCode: 200
    };

    componentDidMount() {
        this.props.getBrandGameSetup()
        this.props.sportsClear();
        if (localStorage.getItem("appToken")) {
            const minutes = 10;
            const callback = () => this.props.postPlay({ providerID: 21, integrationID: 21, productID: 1, isBT1: 1});
            callback();
            this.startTimer(20*minutes, callback);
        } else {
            this.props.postSports({ providerID: 21, integrationID: 21, productID: 1, isBT1: 1});
        }
    }

    startTimer(duration, callback){
        let seconds = duration
        this.timer = setInterval(() => {
            if(--seconds < 0){
                callback();
                seconds = duration;
            }
        }, 1000);
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    componentWillReceiveProps(nextProps) {
        const { url, success } = nextProps.sports.result;
        this.setState({ url: url, success: success !== undefined ? success : this.state.success});
        if(nextProps.sports.errors !== false) {
            if(typeof nextProps.sports.errors !== 'undefined'){
                if(nextProps.sports.errors.errors !== undefined) {
                    this.setState({success: false})
                    if(nextProps.sports.errors.errors.undermaintenance !== undefined) {
                        if(nextProps.sports.errors.errors.content && nextProps.sports.errors.errors.content.length !== 0) {
                            this.setState({
                                undermaintenance: nextProps.sports.errors.errors.undermaintenance,
                                content: nextProps.sports.errors.errors.content
                            }, () => {
                                this.props.sportsClear();
                                });
                        } else {
                            this.setState({
                                undermaintenance: nextProps.sports.errors.errors.undermaintenance,
                                content: null
                            }, () => {
                                this.props.sportsClear();
                            });
                        }
                    }
                } else if(nextProps.sports.errors.player_blacklisted || nextProps.sports.errors.player_closed) {
                    this.setState({success: false, blacklistedError: true, errorStatusCode: 400});
                }
            }
        }
       
       if (nextProps.brandGameSetup !== undefined && nextProps.brandGameSetup.length > 0) {
        
            let search = nextProps.brandGameSetup.find(el => el.ProviderID === 21);
            if (search === undefined) {
                window.location = '/'
            }
        }

    }

    render() {
        const { t, i18n } = this.props;
        const { language } = i18n;
        let lang = "", content = [];
        
        if(language !== undefined) {
            lang = language.split("-")[0]
        } else {
            lang = "ko";
        }
        if(this.state.content !== null) {
            content = this.state.content[lang];
        }
        return (
            <Error>
                {
                    this.state.success ?
                    <BT1 url={this.state.url} />
                    :
                    this.state.undermaintenance ?
                    <section class="game-maintenance-section">
                        <div class="container-fluid h-100">
                            <div class="error-page">
                            <div class="row align-items-center justify-content-center full-height">
                                <div class="container">
                                <div class="box-container">
                                    <div class="row align-items-center">
                                    <div class="col-lg-auto mr-lg-2">
                                        <img class="warning" src="/assets/img/icons/maintain.svg" />
                                    </div>
                                    <div class="col-lg">
                                        <div class="content">
                                            <p class="title">{this.state.content !== null ? content.Title : t('game maintenance title')}</p>
                                        </div>
                                        <div class="browser-section mt-lg-4 mt-md-3 p-4">
                                            <p class="content-text mb-3">
                                            {ReactHtmlParser(this.state.content !== null ? content.Message : t('game maintenance text'))}
                                            </p>
                                            <div class="row align-items-center justify-content-center justify-content-lg-start">
                                                <div class="col-auto pl-lg-0">
                                                    <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                        <div class="col-auto icon p-0">
                                                            <img src="/assets/img/w365/icons/contact-us/cs-email.svg"/>
                                                        </div>
                                                        <div class="col p-0">
                                                            <p>{t('cs support email inqury')}</p>
                                                            <p className="value">{t('cs support email')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="row align-items-center justify-content-center justify-content-lg-start browser">
                                                        <div class="col-auto icon p-0">
                                                            <img src="/assets/img/w365/icons/contact-us/telegram.svg"/>
                                                        </div>
                                                        <div class="col-auto p-0">
                                                            <p>{t('telegram')}</p>
                                                            <p className="value">{t('cs-telegram')}</p>
                                                        </div>
                                                        <div className="d-flex tg-qr-icon-maintenance">
                                                            <img src="/assets/img/w365/icons/contact-us/tg_qr.png" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
                    :
                    <>
                        {/* {this.state.blacklistedError ? <div className="blacklisted blacklisted-error"><BlackListedError /></div> :
                        <div className="error_page_2">
                            <div className="error_splash row text-center">
                                <div className="col-md-12">
                                    <div className="symbol_icon">
                                        <i className="svg-icon svg-warning" />

                                        <p >
                                            <span className="colgray">{t('code')}:</span> <b>4013</b>
                                        </p>
                                        <p></p>
                                    </div>
                                    <h1 style={{color:"#8EB50D"}}>{t('error opening game')}</h1>
                                    <p style={{fontSize: "20px", fontWeight: "600"}}>
                                        {t("please try again")}
                                    </p>
                                    <p>
                                        <Link to="/" className="btn btn-primary" style={{padding:"10px 20px 10px 20px"}}>
                                            {t('back to homepage')}{" "}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>} */}
                        <div className="blacklisted blacklisted-error"><BlackListedError errorStatusCode={this.state.errorStatusCode}/></div>
                    </>
                }
            </Error>

        );
    }
}

Sports.propTypes = {
    postPlay: PropTypes.func.isRequired
};

Sports.defaultProps = {
    sports: {
        result: {
            url: ""
        }
    }
};

const mapStateToProps = (state) => ({
    sports: state.sports,
    brandGameSetup: state.page.brandGameSetup
});

export default connect(mapStateToProps, { postPlay, postSports, sportsClear, getBrandGameSetup})(
    withTranslation()(withRouter(Sports))
);
