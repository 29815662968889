import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook"; 
import classname from "classnames"; 
import Modal from "react-bootstrap/Modal"; 
import NumberFormat from 'react-number-format'; 
import ReactHtmlParser from "react-html-parser";
import { displayAmount, realAmount, totalAmount } from "../../../../../helpers"; 
import { postTransfer, successModal,postTransferWallet,postTransferAll } from "../../../../../actions/payment";
import { useTranslation } from 'react-i18next'; 
import { Loader } from "../../../../common";
import classnames from "classnames";
import { getActiveProviders, resetActiveCount } from '../../../../../actions/provider';
import { getPlayer, clearWallet } from "../../../../../actions/player";
 
const Unable = ({show, handleClose}) => { 
 
   const { t } = useTranslation();

  

    const onClose = useCallback(() => { 
       handleClose();
    });


    return ( 
        <> 
        <div 
            className={classname("modal fade show",{ 
            })} 
            style={{ 
                display: show ? "block" : "none" 
            }} 
            id="InsufficientModal" tabIndex={-1} role="dialog" aria-labelledby="InsufficientModal" aria-hidden="true"> 
            <Modal 
                centered 
                show={show} 
                onHide={() => onClose()} > 
                <Modal.Header> 
                    <h5 className="modal-title text-center" id="LoginModal">{t('transfer notice').toUpperCase()}</h5> 
                    <button 
                        type="button" 
                        className="close" 
                        onClick={ () => onClose()} 
                    > 
                        <span aria-hidden="true">&times;</span> 
                    </button> 
                </Modal.Header> 
                <Modal.Body className="modal-body text-center"> 
                    <h5 class="pt-3">{t('unable to transfer funds')}</h5>
                    <div class="mb-3">
                    <p class="mb-2">{ReactHtmlParser(t('have ongoing subscription'))}</p>

                
                <div class="col-md-6 offset-md-3 text-center">
                  <div class="row">
                 
                   <div class="col-md-12">
                     <a href="#" class="btn btn-success" data-dismiss="modal" id="quickIngameTransfer" onClick = {()=>onClose()}>{t('okay')}</a>
                   </div>
               </div>
             </div>
              </div>
                </Modal.Body> 
            </Modal> 
        </div> 
            
        </>
    );
};

export default Unable;



