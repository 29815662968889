import { OPENING_GAME_SUCCESS, OPENING_GAME_ERROR, RESET_GAME } from '../actions/types';

const initialState = {
    url: null,
    loading: true,
    success: false,
    errors: {}
};

const gameReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPENING_GAME_SUCCESS:
            return {
                ...state,
                url: action.url,
                loading: false,
                success: true,
                errors: {}
            }
        case OPENING_GAME_ERROR:
            return {
                ...initialState,
                loading: false,
                success: false,
                errors: action.errors
            }
        case RESET_GAME:
            return {
                ...initialState
            }
        default:
            return state;
    }
};

export default gameReducer;
