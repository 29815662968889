import {
    REQUEST_POST_PARTNER_CONTACT,
    PARTNER_CONTACT_SUCCESS_RESET
} from "./types";

export const postContact = payload => {
    return {
      type: REQUEST_POST_PARTNER_CONTACT,
      payload
    };
};

export const resetPostContact = payload => {
    return {
      type: PARTNER_CONTACT_SUCCESS_RESET,
  	  payload: {}
    };
};