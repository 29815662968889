import React, { useState, useCallback } from "react";
import { useDispatch } from "redux-react-hook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getBonus  } from "../../../../actions/bonus";
import { toggleModal as toggleModalAction, closeModal as closeModalAction } from "../../../../actions/modal";
import Modal from "react-bootstrap/Modal";
import allGamesWallet from "../../../../assets/img/logo/wallet/all-games.png";
import mainWallet from "../../../../assets/img/logo/wallet/main-wallet.png";
import { Filter } from "../../../common";

const ProviderIcon = ({ id, image, text }) => {
    const { t } = useTranslation();
    return(
        <>
            { text !== "all wallet" ? <img src={image} alt={text} className="provider-logo" /> : null }
            <input type="hidden" name="provider" value={id}/>
            <span>{t(text)}</span>
        </>
    );
};

const FilterSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.modal.isOpen);
    const [type, setType] = useState(0);
    const onChange = useCallback((e) => {
        setType(e.target.value)
    });

    const products = useSelector((state) => state.products.result);
    const [id, setID] = useState('');
    const [logo, setLogo] = useState('');
    const [text, setText] = useState('all wallet');
    const [productFilter, setProductFilter] = useState(false);

    const toggleModal = useCallback(() => {
        dispatch(toggleModalAction());
        setProductFilter(true);
    });

    const closeModal = useCallback(() => {
        dispatch(closeModalAction());
        setProductFilter(false);
    });

    function setProvider(id,logo,text) {
        setID(id);
        setLogo(logo);
        setText(text);
        closeModal();
    }

    const onReset = useCallback((e) => {
        setID('');
        setLogo('');
        setText('all wallet');
        setType(0);
    });

    const onSubmit = useCallback((e) => {

        e.preventDefault();
        dispatch(getBonus({type: type, provider: id}));
    });

    const providersList = () => {
        let providers = [];
        const wallets = [
            {
                name: 'all wallet',
                value: ''
            }
        ];
    
        for(var x in products) {
            if(products[x].Providers.length > 0) {
                providers[x] = {
                    value: products[x].Providers[0].ID,
                    name: products[x].Providers[0].Name,
                    disableTranslation: true
                }
            }
        }
    
        providers = [
            ...wallets,
            ...providers
        ];
    
        return providers;
    }

    return (
      <>
       <div className="col-lg-4">
            <label className="col-form-label">{t("type")}</label>
            <select name="type" className="form-control custom-select dark" onChange={onChange}>
                <option value="0">{t("all")}</option>
                <option value="1">{t("deposit")}</option>
                <option value="2">{t("coupon")}</option>
            </select>
        </div>

        {/* <div className="col-md-3">
            <label class="col-form-label">{t("provider")}</label> 
            <Filter
                name="provider"
                data={providersList()}
                value={null}
                onChange={e => setProvider(e.target.value, '', '')}
            />
        </div> */}

        <div className="col d-flex align-items-center" style={{marginTop:'16px'}}>
            <button className="btn btn-success btn-md small-caps" onClick={onSubmit} style={{marginRight:'10px'}}>{t('search')}</button>
            <button type="reset" className="btn btn-dark btn-md small-caps reset" style={{width:'130px',textTransform:'uppercase'}} onClick={onReset} >{t('reset')}</button>
        </div>

        <div className="modal fade show" style={{ display: productFilter && isOpen ? "block" : "none" }} 
                id="" tabIndex={-1} role="dialog" aria-labelledby="" aria-hidden="true"> 
            <Modal 
                centered 
                show={productFilter && isOpen} 
                onHide={() => closeModal()} > 
                <Modal.Header> 
                    <h5 className="modal-title " >{t('providers')}</h5> 
                    <button 
                        type="button" 
                        className="close" 
                        onClick={ () => closeModal()}
                    > 
                        <span aria-hidden="true">&times;</span> 
                    </button> 
                </Modal.Header> 
                
                    <div className="row m-0 providers">

                        <div className="col-4">
                            <div className="cardTransfer" onClick={ () => setProvider(null,allGamesWallet,"all wallet")} >
                                <div className="provider-logo">
                                    <img src={allGamesWallet} />
                                </div>
                                <p className="provider-type">{t('all wallet')}</p>
                            </div>
                        </div>

                        <div className="col-4">
                            
                        </div>


                        <div className="col-4">
                            
                        </div>


                          {products
                                ? products.map((product) => {
                                    return (
                                        <React.Fragment key={product.ID} >
                                            {product.Providers.map(
                                                (provider) => {
                                                    
                                                    return (
                                                        <div className="col-4">
                                                <div className="cardTransfer" onClick={() => setProvider(provider.ID, provider.Logo, provider.Name)}>
                                                    <div className="provider-logo">
                                                        <img src={provider.Logo} />
                                                    </div>
                                                    <p className="provider-type">{t(provider.Name.toLowerCase())}</p>
                                                </div>
                                            </div>
                                                    )
                                                }
                                            )}
                                        </React.Fragment>
                                      );
                                  })
                                : ""
                            }

                    </div>
               
            </Modal> 
        </div>
     </>
    );
};

export default FilterSection;
