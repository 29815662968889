import { JACKPOT_SUCCESS, JACKPOT_ERRORS } from "../actions/types";

const initialState = {
    games: [],
    errors: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case JACKPOT_SUCCESS:
            return {
                ...state,
                games: action.payload,
                errors: false
            };

        case JACKPOT_ERRORS:
            return {
                ...state,
                games: false,
                errors: action.payload
            };

        default:
            return state;
    }
}
