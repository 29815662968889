import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { OPEN_MINIGAME } from '../actions/types';
import { openMinigameSuccess, openMinigameError } from '../actions/minigame';
import { findToken } from '../config/api';
import { postPlay } from "../api/sports";

function* openMinigame() {
    try {
        let params = {
            brandID: 5,
            isMobile: 0,
            gameType: 'minigame',
            lang: localStorage.getItem('i18nextLng') === null ? 'ko' : localStorage.getItem('i18nextLng').slice(0, 2),
            mode: 0,
            gameID: 0,
            integrationID: 30,
            productID: 11,
            providerID: 30,
            hashid: ''
        };

        if (findToken()) {
            const playerState = yield select(state => state.auth.wallet);

            params = {
                ...params,
                currency: playerState.player.currency.Abbreviation,
                mode: 1,
                launchType: 'html5',
                playerID: playerState.player.PlayerID,
                username: playerState.username
            };
        }

        const result = yield call(postPlay, params);
        yield put(openMinigameSuccess(result.data.game_url));
    } catch(err) {
        yield put(openMinigameError(err.response.data.errors));
    }
}

function* miniGameWatcher() {
    yield all([takeLatest(OPEN_MINIGAME, openMinigame)]);
}

export { miniGameWatcher };
