import {
    REBATE_GET,
    REBATE_SET_PAGE,
    REBATE_SET_FILTER,
    REBATE_RESET_FILTER,
    REBATE_HISTORY_GET,
    REBATE_POST,
    REBATE_GET_MIN_TRANSFER,
    REBATE_PROCESS_MESSAGE,
    GET_REBATE_TRANSFER_DATA,
    SET_REBATE_TRANSFER_MODAL_TOGGLE,
    PROCESS_REBATE_TRANSFER
} from "./types";

export const getRebateHistory = (payload={}) => {
    return {
        type: REBATE_HISTORY_GET,
        payload
    };
}

export const getRebateBalance = () => {
    return {
        type: REBATE_GET
    };
};

export const postRebateBalance = payload => {
    return {
        type: REBATE_POST,
        payload
    };
};

export const setPage = payload => {
    return {
        type: REBATE_SET_PAGE,
        payload
    };
};

export const setFilter = (payload) => {
    return {
        type: REBATE_SET_FILTER,
        payload
    };
}

export const resetFilter = (payload) => {
    return {
        type: REBATE_RESET_FILTER,
    };
}

export const getRebateMinTransfer = (payload) => {
    return {
        type: REBATE_GET_MIN_TRANSFER,
    };
}

export const processRebateMesage = (payload) => {
    return {
        type: REBATE_PROCESS_MESSAGE,
        payload
    };
}

export const getRebateTransferData = () => {
    return {
        type: GET_REBATE_TRANSFER_DATA
    };
}

export const rebateTransferModalToggle = (payload) => {
    return {
        type: SET_REBATE_TRANSFER_MODAL_TOGGLE,
        payload
    };
}

export const processRebateTransfer = (payload) => {
    return {
        type: PROCESS_REBATE_TRANSFER,
        payload
    };
}
