import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { postUser, getUser,getUnreadMessageCount, getUnreadNoticeCount };

function postUser(params) {
    return axios.post(`${ROOT_URL}/login`, params);
}

function getUser() {

    const owsports = localStorage.getItem("owsports");
    const token = owsports ? owsports.includes("token") : false;
    localStorage.removeItem("owsports");
    return axios.get(`${ROOT_URL}/logout`,{
        headers: {
            Authorization: `Bearer ${findToken()}`
        }, params: { owsport: token }
    });

    
}



function getUnreadMessageCount(){ 
  return axios 
    .get(`${ROOT_URL}/getUnreadMessageCount`, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      }
    }) 
}

function getUnreadNoticeCount(){ 
  return axios 
    .get(`${ROOT_URL}/getUnreadNoticeCount`, { 
      headers: { 
        Authorization: `Bearer ${findToken()}` 
      }
    }) 
}
