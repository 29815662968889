import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    postPlay,
    getProviders,
    getRecent
} from "../api/mini"

import {
    REQUEST_POST_MINI,
    GET_MINI_GAMES,
    MINI_GAME_SUCCESS,
    MINI_GAME_ERRORS,
    MINI_SUCCESS,
    MINI_ERRORS,
    GET_MINI_RECENT,
    GET_ERRORS,
    MINI_RECENT_ERRORS,
    MINI_RECENT_SUCCESS
} from "../actions/types";

import { auth } from "../selectors/auth.js";

export {
    miniWatcher
};

function* miniWatcher() {
    yield takeLatest(REQUEST_POST_MINI, fetchPlay)
    yield takeLatest(GET_MINI_GAMES, fetchGames)
    yield takeLatest(GET_MINI_RECENT, fetchRecent)
}

function* fetchGames(param){
    try{
        const result = yield call(getProviders, { product: param.payload.product });
            yield put({
                type: MINI_GAME_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: MINI_GAME_ERRORS,
                payload: {
                    ...error,
                    status: error.response.status
                }
            });
    }
}

function* fetchPlay(action) {
    const { wallet } = yield select(auth);
    try {
        const result = yield call(postPlay,{
                "playerID": wallet.player.PlayerID,
                "username": wallet.username,
                "hashid": action.payload.hashid,
                "gameType": action.payload.type,
                "gameID": action.payload.code,
                "lang": action.payload.language,
                "launchType": "html5",
                "currency": "krw"
            });
            yield put({
                type: MINI_SUCCESS,
                payload: result.data
            });
    }catch(error){
            yield put({
                type: MINI_ERRORS,
                payload: {
                    ...error,
                    status: error.response.status
                }
            });
    }
}

function* fetchRecent(param){
    try{
        const result = yield call(getRecent, { product: param.payload.product });
            yield put({
                type: MINI_RECENT_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
            yield put({
                type: MINI_RECENT_ERRORS,
                payload: {
                    ...error.response.data,
                    status: error.response.status
                }
            });
    }
}
