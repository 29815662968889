import { takeLatest, put, call } from 'redux-saga/effects';
import { getPages, getPage, getParnetPages, getTemplateContent, getBrandGameSetup } from "../api/page"
import {
    GET_PAGE,
    GET_PARTNER_PAGES,
    GET_PAGES,
    PAGE_SUCCESS,
    PAGES_SUCCESS,
    GET_TEMPLATE_CONTENT,
    SET_TEMPLATE_CONTENT,
    GET_BRAND_GAME_SETUP,
} from "../actions/types";
import {
    errorPage,
    setBrandGameSetup
} from "../actions/page";

export { pageWatcher };

function* pageWatcher() {
    yield takeLatest(GET_PAGES, fetchPages)
    yield takeLatest(GET_PARTNER_PAGES, fetchPartnerPages)
    yield takeLatest(GET_PAGE, fetchPage)
    yield takeLatest(GET_TEMPLATE_CONTENT, fetchTemplateContent)
    yield takeLatest(GET_BRAND_GAME_SETUP, fetchBrandGameSetup)
}

function* fetchPage(action) {
    try{
        const result = yield call(getPage, action.payload.slug);
        yield put({
            type: PAGE_SUCCESS,
            payload: result.data
        });
    }
    catch(e){
        yield put(errorPage(e))
    }
}

function* fetchPages() {
    const result = yield call(getPages);
    yield put({
        type: PAGES_SUCCESS,
        payload: result.data.data
    });
}

function* fetchPartnerPages() {
    const result = yield call(getParnetPages);
    yield put({
        type: PAGES_SUCCESS,
        payload: result.data.data
    });
}

function* fetchTemplateContent(action) {
    try {
        const result = yield call(getTemplateContent, action.payload);
        yield put({
            type: SET_TEMPLATE_CONTENT,
            payload: result.data
        });
    } catch (error) {}
}

function* fetchBrandGameSetup(action) {
    try {
        const result = yield call(getBrandGameSetup, action.payload);

        if (result.data.data !== undefined) {
            yield put(setBrandGameSetup(result.data.data));
        }
    } catch (error) {}
}
