import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { StoreContext } from "redux-react-hook";
import { withRouter } from "react-router-dom";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";
import { PersistGate } from 'redux-persist/integration/react'
import ClearCache from 'react-clear-cache';
import { store, persistor } from "./store";
import routes from "./routes";
import axiosSettings from "./axiosSettings";
import "./i18n";

import Header from "./components/layouts/Header/";
import Footer from "./components/layouts/Footer/";
import Help from "./components/layouts/Help";
import CMS from "./components/pages/Cms";
import ScrollToTop from "./components/common/ScrollToTop";
import { Modal as CustomModal, SessionModal } from "./components/common/index";
import { PrivateRoute } from "./Auth";
import IdleTimer from 'react-idle-timer';
import { logoutUser } from '../src/actions/authentication';
import { findToken } from "./config/api";

window.addEventListener('storage', function(){
    const count = localStorage.getItem("reloadCount");
    if(findToken() === null && count === null){
        localStorage.setItem('reloadCount', 1)
        window.location.reload();
    }else if(findToken() !== null){
        localStorage.removeItem('reloadCount')
    }
})

class App extends Component {
    render() {
        const routeComponents = routes.map(({ path, component, auth }, key) =>
            !auth ? (
                <Route
                    exact
                    path={path}
                    component={withRouter(component)}
                    key={key}
                />
            ) : (
                <PrivateRoute
                    exact
                    path={path}
                    component={withRouter(component)}
                    key={key}
                />
            )
        );

        const url = window.location.pathname;
        const pageWithNoHeader = url.includes('tron-recharge') || url.includes("/live-casino/play/") || /^\/slots\/[0-9]+\/[0-9]+$/.test(url);
        const pageWithNoFooter = url.includes('register');

        return (
            <React.Fragment>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <StoreContext.Provider value={store}>
                            <ClearCache>
                                {({isLatestVersion, emptyCacheStorage}) =>
                                    {
                                        !isLatestVersion &&
                                            localStorage.clear() &&
                                            emptyCacheStorage()
                                        return null
                                    }
                                }
                            </ClearCache>
                            <Router>
                                <React.Fragment>
                                    <ScrollToTop>
                                    {/* {pageWithNoHeader ? null : <><Header /><Help /></>} */}
                                    {pageWithNoHeader ? null : <Header />}
                                    <div id="main-wrap" className={pageWithNoHeader ? 'pt-0' : ''}>
                                        <Switch>
                                            {routeComponents}
                                            <Route component={CMS} />
                                        </Switch>
                                        <CustomModal></CustomModal>
                                        <SessionModal/>
                                    </div>
                                    <Footer />
                                    { url.includes("/live-casino/play/") || url === '/' ? <div /> : '' }
                                    <ScrollUpButton
                                        ShowAtPosition={300}
                                        AnimationDuration={1000}
                                        ContainerClassName="scroll-up"
                                    />
                                    </ScrollToTop>
                                </React.Fragment>
                            </Router>
                        </StoreContext.Provider>
                    </PersistGate>
                </Provider>
            </React.Fragment>
        );
    }
}

export default App;
