import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

const ComingSoonModal = ({ show, handleClose, translationType }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{display: show ? "block" : "none"}}
            id="ComingSoonModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="ComingSoonModal"
            aria-hidden="true">
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title" id="ComingSoonModal">{t(translationType === 'partners' ? 'partners coming soon title' : 'coming soon title')}</p>
                    <button
                        type="button"
                        className="close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="mt-4 mb-5">
                    <div className="col text-center">
                        <p className="title">{t(translationType === 'partners' ? 'partners coming soon text' : 'coming soon text')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ComingSoonModal;