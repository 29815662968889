import React from "react";
import { useTranslation } from "react-i18next";
const Filter = ({ name, data, onChange, value }) => {
    const { t } = useTranslation();
    return (
        <select
            name={name}
            className=" form-control custom-select dark"
            value={value}
            onChange={onChange}
        >
            {data.map((obj, key) => {
                return (
                    obj.name === 'Select Wallet' ?
                    (
                        <option key={key} value={obj.value} disabled>
                            {t(obj.name.toLowerCase())}
                        </option>
                    ) :
                    (
                        <option key={key} value={obj.value}>
                            {obj.disableTranslation ? obj.name : t(obj.name.toLowerCase())}
                        </option>
                    )
                );
            })}
        </select>
    );
};

export default Filter;
