import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { openBetConstruct, resetBetConstruct } from '../../../actions/betconstruct';
import BlackListedError from '../../common/BlackListedError';

const BetConstruct = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const betConstructState = useSelector(state => state.betconstruct);
    // const brandGameSetup = useSelector(state => state.page.brandGameSetup);
    const { language } = i18n;
    let lang = '';
    
    if(language !== undefined) {
        lang = language.split('-')[0];
    } else {
        lang = 'ko';
    }

    useEffect(() => {
        var frame = document.getElementById('betconstruct-frame');
        var partnerInitialConfig = {};

        window.addEventListener('message', (event) => {
            if(event.data) {
                if (event.data.action === 'appReady') {
                    frame.contentWindow.postMessage('initialConfig', partnerInitialConfig, '*');
                }
    
                switch(event.data.type) {
                    case 'login':
                        document.getElementById('login-btn').click();
                        break;
                    case 'register':
                        history.push('/register');
                        break;
                    case 'deposit':
                        history.push('/myaccount/deposit');
                        break;
                    default:
                }
            }
        });

        dispatch(openBetConstruct());
	}, []);

    useEffect(() => {
        return () => {
            dispatch(resetBetConstruct());
        }
	}, []);

    // useEffect(() => {
    //     if(brandGameSetup !== undefined && brandGameSetup.length > 0) {
    //         let search = brandGameSetup.find(el => el.ProductID === 11);

    //         if (search === undefined) {
    //             window.location.href = '/';
    //         }
    //     }
    // }, [brandGameSetup]);

    const UnderMaintenance = () => {
        return (
            <section className="game-maintenance-section">
                <div className="container-fluid h-100">
                    <div className="error-page">
                    <div className="row align-items-center justify-content-center full-height">
                        <div className="container">
                        <div className="box-container">
                            <div className="row align-items-center">
                            <div className="col-lg-auto mr-lg-2">
                                <img className="warning" src="/assets/img/icons/maintain.svg" />
                            </div>
                            <div className="col-lg">
                                <div className="content">
                                    <p className="title">{betConstructState.errors.errors.content !== undefined ? betConstructState.errors.errors.content[lang].Title : t('game maintenance title')}</p>
                                    <p className="content-text">
                                    {ReactHtmlParser(betConstructState.errors.errors.content !== undefined ? betConstructState.errors.errors.content[lang].Message : t('game maintenance text'))}
                                    </p>
                                </div>
                                <div className="browser-section mt-lg-4 mt-md-3">
                                <div className="row align-items-center justify-content-center justify-content-lg-start">
                                    <div className="col-auto pl-lg-0">
                                    <div className="row align-items-center justify-content-center justify-content-lg-start browser">
                                        <div className="col-auto icon p-0">
                                        <img src="/assets/img/w365/icons/contact-us/telegram.svg"/>
                                        </div>
                                        <div className="col-auto p-0">
                                        <p>{t('cs-telegram')}</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-auto">
                                    <div className="row align-items-center justify-content-center justify-content-lg-start browser">
                                        <div className="col-auto icon p-0">
                                        <img src="/assets/img/w365/icons/contact-us/cs-email.svg"/>
                                        </div>
                                        <div className="col p-0">
                                        <p>{t('cs support email inqury')}: {t('cs support email')}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
       <div style={{width:'100%',minHeight:'544px'}}>
            {!betConstructState.loading && betConstructState.url !== null && betConstructState.success ? 
                <div className="iframe-container aligned-to-header" style={{margin:0}}>
                    <iframe id="betconstruct-frame" className="RefFrame" src={betConstructState.url} style={{width:'100%'}} title="BetConstruct"></iframe>
                </div>
            : null}
            {!betConstructState.loading && !betConstructState.success && betConstructState.errors.errors !== undefined && betConstructState.errors.errors.undermaintenance !== undefined && betConstructState.errors.errors.undermaintenance ? <UnderMaintenance /> : null}
            {!betConstructState.loading && !betConstructState.success && (betConstructState.errors.player_blacklisted !== undefined || betConstructState.errors.player_closed !== undefined) ? <div className="blacklisted blacklisted-error"><BlackListedError /></div> : null}
        </div>
    );
}

export default BetConstruct;
