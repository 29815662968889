import {
    REQUEST_POST_SPORTS,
    REQUEST_POST_SPORTS_PLAY,
    SPORTS_CLEAR
} from "./types";

export const postSports = payload => {
  return {
    type: REQUEST_POST_SPORTS,
    payload
  };
};

export const postPlay = payload => {
  return {
    type: REQUEST_POST_SPORTS_PLAY,
    payload
  };
};

export const sportsClear = () => {
  return {
    type: SPORTS_CLEAR
  };
};
