import {
    SLOTS_GAME_SUCCESS,
    SLOTS_GAME_ERRORS,
    UPDATE_SLOTS_GAMES,
    SLOTS_RECENT_SUCCESS,
    SLOTS_RECENT_ERRORS,
    SLOTS_CATEGORIES_SUCCESS,
    SLOTS_CATEGORIES_ERRORS,
    GET_SLOTS_CATEGORIES,
    GET_SLOTS_RECENT,
    UPDATE_SLOTS_RECENT
} from "../actions/types";

const initialState = {
    slots: [],
    recent: [],
    categories: [{}],
    errors: [],
    loading: false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SLOTS_GAME_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload,
                loading: false,
            };

        case SLOTS_GAME_SUCCESS:
            return {
                ...state,
                slots: action.payload,
                errors: false,
                loading: false,
            };

        case SLOTS_RECENT_SUCCESS:
            return {
                ...state,
                recent: action.payload,
                errors: false,
                loading: false,
            };

        case SLOTS_RECENT_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload,
                loading: false,
            };

        case UPDATE_SLOTS_GAMES:
            const key = action.payload.key;
            const statez = action.payload.state;
            if(statez === "recent") {
                return  {
                    ...state,
                    recent: state.recent.map((item) => {
                        if(item.ID !== key) {
                            return item
                        }
                        return {
                            ...item,
                            FavStatus: item.FavStatus === 1 ? 0 : 1
                        }
                    }),
                    slots: state.slots.map((item) => {
                        if(item.ID !== key) {
                            return item
                        }
                        return {
                            ...item,
                            FavStatus: item.FavStatus === 1 ? 0 : 1
                        }
                    })
                }
            } else {
                return  {
                    ...state,
                    slots: state.slots.map((item) => {
                        if(item.ID !== key) {
                            return item
                        }
                        return {
                            ...item,
                            FavStatus: item.FavStatus === 1 ? 0 : 1
                        }
                    })
                }
            }

        case UPDATE_SLOTS_RECENT:
            return  {
                ...state,
                recent: []
            }

        case SLOTS_CATEGORIES_ERRORS:
            return {
                ...state,
                result: false,
                errors: action.payload,
                loading: false,
            };

        case SLOTS_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                errors: false,
                loading: false,
            }; 

        case GET_SLOTS_CATEGORIES:
            return {
                ...state,
                loading: true,
            };   

        case GET_SLOTS_RECENT:
            return {
                ...state,
                loading: true,
            };  
             
        default:
            return state;
    }
}