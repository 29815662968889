import React, { useState, useRef } from 'react';
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { useDispatch } from "redux-react-hook";
import { ToastContainer, toast } from 'react-toastify';
import { ROOT_URL, findToken } from '../../../../config/api';
import { getMessages } from "../../../../actions/player";

const Compose = props => {
    const [errors, setErrors] = useState([]);
    const [opened, setOpened] = useState(false);
    const [disableBtn, setdisableBtn] = useState(false);
    const dispatch = useDispatch();
    const { t, ready } = useTranslation();
    const title = useRef('');
    const content = useRef('');

    const handleOpen = () => {
        setdisableBtn(true);
        setOpened(!opened);
    }

    const exitHandle = () => {
        setdisableBtn(false);
        setErrors([]);
        
        title.current.value = '';
        content.current.value = '';
    }

    const send = e => {
        e.preventDefault();
        setdisableBtn(true);

        axios.post(`${ROOT_URL}/messages/send`, {
            title: title.current.value,
            content: content.current.value
        }, {
            headers: {
                Authorization: `Bearer ${findToken()}`
            }
        })
        .then(response => {
            dispatch(getMessages());
            setOpened(false);

            toast.success( !ready ? "Compose" : t('player message sent'), {
                className: 'toast toast-success'
            });
        })
        .catch(errors => {
            setErrors(errors.response.data.errors);
            setdisableBtn(false);
            
            toast.error(t('pending message'), {
                className: "toast toast-error"
        });
        });
    }

    const handleChange = e => {
        if (title.current.value && content.current.value) {
            setdisableBtn(false);
        } else {
            setdisableBtn(true)
        }
    }

    return (
        <>
            <div className="col-lg-12 d-flex justify-content-end align-items-end">
                <ToastContainer autoClose={8000} />
                <div><button className="btn btn-success btn-md small-caps" onClick={handleOpen}><i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;{ !ready ? "Compose" : t('compose btn text')}</button></div>
            </div>
            <Modal centered show={opened} onExited={exitHandle} className="compose-message">
                <Modal.Header>
                    <Modal.Title>{t('compose popup title')}</Modal.Title>
                    <button type="button" className="close" onClick={handleOpen}><span aria-hidden="true">&times;</span></button>
                </Modal.Header>
                <Modal.Body className="mt-3">
                    <form className="w-100" onSubmit={send}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="title" style={{color: '#FFFFFF99'}}>{t('compose title label')} <sup>*</sup></label>
                            <div className="input-group">
                                <input 
                                className={`form-control dark${errors.title != undefined ? ' is-invalid' : ''}`} 
                                type="text" 
                                id="title" 
                                name="title" 
                                ref={title} 
                                onChange={handleChange}
                                style={{color: '#FFFFFF99'}}/>
                                <div className="invalid-tooltip mt-0">{t('player message title required')}</div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="message" style={{color: '#FFFFFF99'}}>{t('compose message label')} <sup>*</sup></label>
                            <div className="input-group">
                                <textarea 
                                className={`form-control dark${errors.content != undefined ? ' is-invalid' : ''}`} 
                                id="message" 
                                name="message" 
                                rows="7" 
                                ref={content} 
                                onChange={handleChange}
                                style={{color: '#FFFFFF99'}}>
                                </textarea>
                                <div className="invalid-tooltip mt-0">{t('player message content required')}</div>
                            </div>
                        </div>
                        <div className='text-right'><button type="submit" className="btn btn-success py-3 px-5" disabled={disableBtn}>{t('compose btn send')}</button></div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default (Compose);
