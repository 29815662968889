import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { getCurrencies, postRegister, resetError, checkCanRegister, getSMSSetting } from "../../../actions/registration";
import { getIP } from "../../../actions/ipaddress";
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";
import axios from 'axios';
import { getBanks } from "../../../actions/bank";

class Register1 extends Component {
    state = {
        validated: false,
        username: "",
        password: "",
        confirm_password: "",
        bank_acc_name: "",
        bank_id: "",
        bank_acc_number: "",
        currency: "2",
        phone: "",
        country: "",
        isShown: false,
        ref_code: "",
        ref_username: "",
        currencies: [],
        error: [],
        banks: []
    };

    componentDidMount() {
        this.props.getCurrencies();
        this.props.getIP();
        this.props.resetError();
        this.props.checkCanRegister({ domain: window.location.hostname === 'localhost' ? '15gaming.com' : window.location.hostname });
        this.props.getBanks(2);
        this.props.getSMSSetting();
        var elem = document.getElementById("main-wrap");
        elem.classList.add("register-section");

        if (this.props.canRegister.CanSignUp !== undefined) {
            if (!this.props.canRegister.CanSignUp) {
                this.props.history.push("/");

                // window.location = '/';
                // return false;
            }

            axios
                .get(`${process.env.REACT_APP_LOCAL}/partner?domain=${window.location.hostname}`)
                .then(response => {
                    if (response.data[0] !== undefined) {
                        this.setState({ ref_code: response.data[0].UserName });
                    }
                });

            let playerRegistrationFormFields;

            try {
                playerRegistrationFormFields = JSON.parse(localStorage.getItem('players'));
                this.setState({
                    username: playerRegistrationFormFields.username,
                    password: playerRegistrationFormFields.password,
                    confirm_password: playerRegistrationFormFields.confirm_password,
                    bank_acc_name: playerRegistrationFormFields.bank_acc_name,
                    bank_id: playerRegistrationFormFields.bank_id,
                    bank_acc_number: playerRegistrationFormFields.bank_acc_number,
                    phone: playerRegistrationFormFields.phone.replace(/^010/, ''),
                    ref_code: playerRegistrationFormFields.ref_code,
                    ref_username: playerRegistrationFormFields.ref_username
                });
            }
            catch (e) {
                playerRegistrationFormFields = {};
            }
        }
    }

    componentWillUnmount = () => {
        var elem = document.getElementById("main-wrap");
        elem.classList.remove("gradient-bg");
    };

    // mapPhoneCode = currencies => {
    //     const phoneCodes = {};
    //     currencies.map(currency => {
    //         return (phoneCodes[currency.CurrencyID] = currency.country.PhoneCode)
    //     });
    //     this.setState({ phoneCodes }, () => {
    //         let key = Object.keys(this.state.phoneCodes)
    //         this.setState({
    //             currency: key[0],
    //             country: this.state.phoneCodes[key[0]]
    //         });
    //     });
    // };

    //set default country code and currency
    //if only one currency record from api
    // defaultCurrency(){
    //     if(this.props.currencies.length === 1){
    //         let key = Object.keys(this.state.phoneCodes)
    //         return [key[0], this.state.phoneCodes[key[0]]]
    //     }else{
    //         return [this.state.currency, this.state.country]
    //     }
    // }

    componentWillReceiveProps(nextProps) {
        if (this.props.currencies.length === 0) {
            this.props.getCurrencies();
        }
        this.setState({
            error: nextProps.error,
            currencies: nextProps.currencies
        });

        if (nextProps.banks) {
            if (this.state.bank_id === "" && nextProps.banks.length > 0) {
                this.setState({ bank_id: nextProps.banks[0].BankID });
            }
        }
    }
    // static getDerivedStateFromProps(nextProps){
    //     if(nextProps.currencies.length === 1){
    //         this.props.getCurrencies();
    //     }
    //     return { ...nextProps }
    // }

    // shouldComponentUpdate(nextProps, nextState){
    //     if(!this.state.hasOwnProperty('phoneCodes')){
    //         //this.mapPhoneCode(this.state.currencies);
    //         return false;
    //     }
    //     return true;
    // }

    togglePassword() {
        this.setState({ isShown: !this.state.isShown });
    }

    onSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        //const [currency, country] = this.defaultCurrency();
        const { history } = this.props;
        const { ip } = this.props;
        const {
            i18n: { language }
        } = this.props;
        const languages = {
            "en-US": "en",
            "ko-KR": "kr"
        };
        const register = {
            username: this.state.username,
            password: this.state.password.replace(/\s/g, ""),
            confirm_password: this.state.confirm_password.replace(/\s/g, ""),
            bank_acc_name: this.state.bank_acc_name,
            bank_id: this.state.bank_id,
            bank_acc_number: this.state.bank_acc_number,
            phoneCode: 82,
            // phone: "010" + this.state.phone,
            phone: this.state.phone,
            currency: this.state.currency,
            history,
            ref_code: this.state.ref_code,
            ref_username: this.state.ref_username,
            deviceUrl: window.location.href,
            ip_address: ip.ip,
            lang: languages[language],
            domain: window.location.hostname === "localhost" ? "15gaming.com" : window.location.hostname
        };
        this.setState({ validated: true });
        if (this.form.checkValidity()) {
            this.props.postRegister(register);
            this.setState({ validated: false });
        }
    }

    onChange = e => {
        if (e.target.name === "currency") {
            this.setState({ country: this.state.phoneCodes[e.target.value] });
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const { currencies, error } = this.state;
        const { t } = this.props;
        const banks = this.props.banks || [];

        return (
            <React.Fragment>
                <section class="contentWrap registration-bg">
                    <div class="container">
                        <div class="row align-items-center justify-content-end">
                            <div class="forms-container">
                                <form
                                    id="regForm"
                                    className={this.state.validated ? "needs-validation was-validated" : ""}
                                    noValidate
                                    method="post"
                                    onSubmit={this.onSubmit.bind(this)}
                                    ref={(form) => this.form = form}
                                >
                                    <div class="mb-3"><p class="div-title">{t('create an account')}</p></div>
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <label for="personalInfo">{t('personal info')}:</label>
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    class={`form-control dark form-control-md ${error && error.username ? "is-invalid" : ""} `}
                                                    id="regID"
                                                    placeholder={t('id') + " *"}
                                                    onChange={this.onChange}
                                                    name="username"
                                                    value={this.state.username}
                                                    required
                                                />
                                                <div className="invalid-tooltip">
                                                    {error && error.username && error.username.length > 0 ? "" : t("this field is required")}
                                                    {error && (error.username && error.username[0] === "The username has already been taken.")
                                                        ? t("the username has already been taken") : ""}
                                                    {error && error.username && error.username[0] === "The username must be at least 6 characters." ? t("username validation") : ""}
                                                    {error && error.username && error.username[0] === "The username may only contain letters and numbers." ? t("username alphanumeric") : ""}
                                                    {error && error.username && error.username[0] === "please contact cs" ? t("please contact cs") : ""}
                                                    {error && error.username && error.username[0] === "The username format is invalid." ? t("invalid username") : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 mt-3">
                                            <div class="input-group show_hide_password">
                                                <input
                                                    type={!this.state.isShown ? 'password' : 'text'}
                                                    className={`form-control dark form-control-md ${error && error.password ? "is-invalid" : ""} `}
                                                    name="password"
                                                    onChange={this.onChange}
                                                    id="password"
                                                    placeholder={t('password') + " *"}
                                                    value={this.state.password}
                                                    required
                                                    style={{ paddingRight: '40px' }}
                                                    maxlength="12"
                                                />
                                                <div className="invalid-tooltip">
                                                    {error && error.password && error.password.length > 0 ? "" : t("this field is required")}
                                                    {error && (error.password && error.password[0] === "The password must be at least 6 characters.")
                                                        || error && (error.password && error.password[0] === "The password may not be greater than 12 characters.")
                                                        ? t("password validation") : ""}
                                                    {error && error.password && error.password[0] === "The password and confirm password must match." ? t("password do not match") : ""}
                                                </div>
                                                <div className="input-group-addon">
                                                    <a style={{ 'cursor': 'pointer' }} onClick={this.togglePassword.bind(this)}>
                                                        <i className={classNames('fa', {
                                                            'fa-eye-slash': !this.state.isShown,
                                                            'fa-eye': this.state.isShown
                                                        })}
                                                            aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 mt-3">
                                            <div class="input-group show_hide_password">
                                                <input
                                                    type={!this.state.isShown ? 'password' : 'text'}
                                                    className={`form-control dark form-control-md ${error && error.password && error.password[0] === "The password and confirm password must match." ? "is-invalid" : ""} `}
                                                    name="confirm_password"
                                                    onChange={this.onChange}
                                                    id="confirm_password"
                                                    placeholder={t('verify password') + " *"}
                                                    value={this.state.confirm_password}
                                                    required
                                                    style={{ paddingRight: '40px' }}
                                                    maxlength="12"
                                                />
                                                <div className="invalid-tooltip">
                                                    {this.state.confirm_password.length === 0 ? t("this field is required") : ""}
                                                    {error && error.password && error.password[0] === "The password and confirm password must match." ? t("password do not match") : ""}
                                                </div>
                                                <div className="input-group-addon">
                                                    <a style={{ 'cursor': 'pointer' }} onClick={this.togglePassword.bind(this)}>
                                                        <i className={classNames('fa', {
                                                            'fa-eye-slash': !this.state.isShown,
                                                            'fa-eye': this.state.isShown
                                                        })}
                                                            aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row m-3 bank-details mt-2">
                                            <div class="col-12 p-0">
                                                <label class="col-form-label pt-0">{t('bank account name')}</label>
                                                <div class="input-group">
                                                    <input
                                                        type="text"
                                                        class={`form-control dark form-control-md ${error && error.bank_acc_name && error.bank_acc_name[0] === "the account name format is invalid." ? "is-invalid" : ""}
                                                        ${error && error.bank_acc_name && error.bank_acc_name[0] === "duplicate bank account name" ? "is-invalid" : ""} `}
                                                        name="bank_acc_name"
                                                        onChange={this.onChange}
                                                        id="bank_acc_name"
                                                        placeholder={t('bank account name')}
                                                        value={this.state.bank_acc_name}
                                                        required
                                                    />
                                                    <div className="invalid-tooltip">
                                                        {error && error.bank_acc_name && error.bank_acc_name.length > 0 ? "" : t("this field is required")}
                                                        {error && (error.bank_acc_name && error.bank_acc_name[0] === "the account name format is invalid.")
                                                            ? t("the bank account name format is invalid") : ""}
                                                        {error && (error.bank_acc_name && error.bank_acc_name[0] === "duplicate bank account name")
                                                            ? t("the bank account name is already used") : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3 p-0">
                                                <label class="col-form-label pt-0">{t('bank')}</label>
                                                <div class="">
                                                    <select
                                                        class={`custom-select dark registration-select ${error && error.bank_id && error.bank_id[0] === "bank account is already used" ? "is-invalid" : ""} `}
                                                        name="bank_id"
                                                        onChange={this.onChange}
                                                        required
                                                        id="bank_id"
                                                        defaultValue={this.state.bank_id}
                                                    >
                                                        {banks.map(bank => (
                                                            <option
                                                                key={bank.BankID}
                                                                value={bank.BankID}
                                                            >
                                                                {bank.BankName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-tooltip">
                                                        {error && error.bank_id && error.bank_id[0] === "bank account is already used" ? t("bank account is already used") : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-3 p-0">
                                                <label class="col-form-label pt-0">{t('account number')}</label>
                                                <div class="input-group">
                                                    <input
                                                        type="text"
                                                        class={`form-control dark form-control-md ${error && error.bank_acc_number && error.bank_acc_number[0] === "the bank account number must be between 10 and 20 digits" ? "is-invalid" : ""}
                                                        ${error && error.bank_id && error.bank_id[0] === "bank account is already used" ? "is-invalid" : ""}
                                                        ${error && error.bank_acc_number && error.bank_acc_number[0] === "please contact cs" ? "is-invalid" : ""} `}
                                                        name="bank_acc_number"
                                                        onChange={this.onChange}
                                                        id="bank_acc_number"
                                                        placeholder={t('account number')}
                                                        value={this.state.bank_acc_number}
                                                        required
                                                    />
                                                    <div className="invalid-tooltip">
                                                        {error && error.bank_acc_number && error.bank_acc_number.length > 0 ? "" : t("this field is required")}
                                                        {error && (error.bank_acc_number && error.bank_acc_number[0] === "the bank account number must be between 10 and 20 digits")
                                                            ? t("the bank account number must be between 10 and 20 digits") : ""}
                                                        {error && (error.bank_acc_number && error.bank_acc_number[0] === "bank account is already used")
                                                            ? t("bank account is already used") : ""}
                                                        {error && (error.bank_acc_number && error.bank_acc_number[0] === "please contact cs")
                                                            ? t("please contact cs") : ""}
                                                        	
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="notetxt mt-3">
                                                <p>
                                                    {ReactHtmlParser(t('duplicate account number'))}
                                                </p>
                                            </div>
                                        </div>


                                        <div class="col-12 mt-3">
                                            <label for="regCurrency">{t('select currency')}:</label>
                                            <div class="input-group">
                                                <select
                                                    className={`form-select currency-select form-control dark form-control-md registration-select ${error && error.currency ? "is-invalid" : ""} `}
                                                    name="currency"
                                                    onChange={this.onChange}
                                                    disabled={currencies.length === 1 ? 'disabled' : false}
                                                    required
                                                    id="currency"
                                                >
                                                    {currencies && currencies.length > 1 ?
                                                        <option
                                                            key={currencies.length + 1}
                                                            value={""}
                                                            disabled
                                                            defaultValue
                                                        ></option> : ""
                                                    }
                                                    {currencies.map(currency => (
                                                        <option
                                                            key={currency.CurrencyID}
                                                            value={currency.CurrencyID}
                                                            defaultValue={currencies.length === 1 ? true : false}
                                                        >
                                                            {currency.Abbreviation}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <label for="regMobile">{t('mobile no')}:<span class="red">*</span></label>
                                            <div class="input-group">
                                                <input className="form-control dark form-control-md col-1 noBorderRightTop p-0 text-center numberExtension registration-select" id="flag" value="+82" disabled>
                                                </input>
                                                <input
                                                    type="tel"
                                                    className={`form-control col-11 dark form-control-md col noBorderLeftTop phoneNumber ${error && error.phone ? "is-invalid" : ""} `}
                                                    name="phone"
                                                    onChange={this.onChange}
                                                    id="phone"
                                                    placeholder="10xxxxxxxx"
                                                    value={this.state.phone}
                                                    required
                                                    maxlength="11"
                                                />
                                                <div className="phoneNumberText mt-2">
                                                    {ReactHtmlParser(t('please enter your mobile number in the following format'))}
                                                </div>
                                                <div className="invalid-tooltip">
                                                    {/* {error && error.phoneCode ? "" : t("phone missing validation")} */}
                                                    {/* {error && error.phone ? "" : t("phone missing validation")} */}
                                                    {/* {error && error.phone && error.phone[0] === "phone validation" ? t('phone validation') : " "}
                                                    {error && error.phone && error.phone[0] === "phone format" ? t('phone format') : " "}
                                                    {error && error.phone && error.phone[0] === "The number is blacklisted." ? t('the number is blacklisted.') : " "}
                                                    {error && error.phone && error.phone[0] === "please contact cs" ? t('please contact cs') : " "} */}
                                                    {error && error.phone && error.phone[0] ? t(error.phone[0]) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    className={`form-control dark form-control-md ${error && error.ref_username ? 'is-invalid' : ''} `}
                                                    id="ref_username"
                                                    onChange={this.onChange}
                                                    name="ref_username"
                                                    placeholder={t('referred by')}
                                                    value={this.state.ref_username}
                                                />
                                                <div className="invalid-tooltip">
                                                    {t('invalid id')}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-3">
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    className={`form-control dark form-control-md ${error && error.ref_code ? 'is-invalid' : ''} `}
                                                    id="ref_code"
                                                    onChange={this.onChange}
                                                    name="ref_code"
                                                    placeholder={t('partner id')}
                                                    value={this.state.ref_code}
                                                    disabled
                                                />
                                                <div className="invalid-tooltip" >
                                                    {error && error.ref_code && error.ref_code[0] === "The selected ref code is invalid." ? t('partner id validation') : " "}
                                                    {error && error.ref_code && error.ref_code[0] === "partner id is not active" ? t('partner id is not active') : " "}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-4">
                                            <div class="form-group terms">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="exampleCheck1"
                                                    required
                                                    style={{ marginTop: '8px' }}
                                                />
                                                <label class="form-check-label noStyle notetxt" for="exampleCheck1">{ReactHtmlParser(t('terms and conditions and agreement'))}</label>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2 text-center">
                                            <span style={{ color: "#E74C3C", fontSize: '15px' }}>{error && error.domain && error.domain[0] === "The domain is not available to sign up." ? t('not allowed domain') : ""}</span>
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-block mt-1 disabled-gray"
                                                disabled={currencies.length === 0 || error && error.domain && error.domain[0] === "The domain is not available to sign up." ? true : false}
                                            >
                                                {t('register')}
                                            </button>
                                        </div>
                                        <div class="col-md-12 mt-4">
                                            <div class="text-right">
                                                <img src="/assets/img/18PlusSSSL.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currencies: state.register.currencies,
    error: state.register.errors,
    success: state.register.result,
    ip: state.ip,
    canRegister: state.register.canRegister,
    banks: state.banks.banks
});

export default connect(
    mapStateToProps,
    { getCurrencies, postRegister, getIP, resetError, checkCanRegister, getBanks, getSMSSetting }
)(withTranslation()(withRouter((Register1))));
