import React from "react";
import classNames from "classnames";
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const List = ({messages, onRead, ownProps}) => {
    const { t } = ownProps;
    let currentPage = useSelector(state => state.player.page);
    currentPage = currentPage === null ? 1 : currentPage;

    return (
        <div className="row mt-4 message-section">
            <div className="col-lg-12">
                <table className="table table-striped table-dark">
                    <thead>
                        <tr>
                            <th className="txt-center num" scope="col">No.</th>
                            <th scope="col">{t('message')}</th>
                            <th scope="col" className="date">{t('date')}</th>
                            <th className="txt-center action" scope="col">{t('action')}</th>
                        </tr>
                    </thead>
                    { messages.length > 0 ?
                            (<tbody>
                                { messages.map((message, index) => {
                                    return (
                                        <tr key={message.MessageID} className={message.Status === 'Read' ? 'active' : ''} onClick={() => onRead(message.MessageID)} style={{cursor: "pointer"}}>
                                            <td className="txt-center">{(currentPage - 1) * 5 + (index + 1)}</td>
                                            <td>{message.Subject}</td>
                                            <td>{message.DateCreated}</td>
                                            <td className="text-center text-uppercase">
                                                <a
                                                    href="#"
                                                    className={classNames("btn btn-md small-caps btnMsg message_id_1_link",{
                                                        "btn-success": message.Status === "Read",
                                                        "btn-dark reset": message.Status === "View"
                                                    })}
                                                    onClick={() => onRead(message.MessageID)}
                                                >
                                                    { t(String(message.Status).toLowerCase()) }
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                }) }
                            </tbody>) :
                            (<tbody className="text-center">
                            <tr>
                                <td colSpan="12">
                                    <h4>{t('no results found')}</h4>
                                </td>
                            </tr>
                        </tbody>)
                    }
                </table>
            </div>
        </div>
    )
}

export default withTranslation() (List);
