import { takeLatest, put, call } from 'redux-saga/effects';

import {
    getProductProviders,
} from "../api/product"

import {
    DELETE_USER,
    GET_PRODUCT_PROVIDERS,
    PRODUCT_PROVIDER_SUCCESS,
    PRODUCT_PROVIDER_ERRORS,
} from "../actions/types";

export { productWatcher };

function* productWatcher() {
    yield takeLatest(GET_PRODUCT_PROVIDERS, fetchProducts)
}

function* fetchProducts(param){
    try{
        const result = yield call(getProductProviders, {  });
            yield put({
                type: PRODUCT_PROVIDER_SUCCESS,
                payload: result.data.data
            });
    }catch(error){
        yield put({
            type: PRODUCT_PROVIDER_ERRORS,
            payload: error
        });
    }
}
