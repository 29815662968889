import { GET_USER, DELETE_USER, SET_CURRENT_USER, UPDATE_MAIN_WALLET, PROCESS_MAIN_WALLET_MESSAGE, UPDATE_BADGE_COUNT, GET_UNREAD_MESSAGE, UPDATE_UNREAD_MESSAGE, GET_UNREAD_NOTICE, KICK_USER } from "./types";

const loginUser = user => {
  return {
    type: GET_USER,
    payload: user
  };
};

const logoutUser = history => {
  return {
    type: DELETE_USER,
    payload: history
  };
};

const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

const updateMainWallet = payload => {
  return {
    type: UPDATE_MAIN_WALLET,
    payload
  };
}

const processMainWalletMesage = (payload) => {
    return {
        type: PROCESS_MAIN_WALLET_MESSAGE,
        payload
    };
}

const updateBadgeCount = (payload) => {
    return {
        type: UPDATE_BADGE_COUNT,
        payload
    };
}

const getUnreadMessage = (payload) => {
    return {
        type: GET_UNREAD_MESSAGE,
        payload
    };
}

const updateUnreadMessage = (payload) => {
    return {
        type: UPDATE_UNREAD_MESSAGE,
        payload
    };
}

const getUnreadNotice = (payload) => {
    return {
        type: GET_UNREAD_NOTICE,
        payload
    };
}

const kickUser = () => {
  return {
      type: KICK_USER,
  };
}

export { loginUser, logoutUser, setCurrentUser, updateMainWallet, processMainWalletMesage, updateBadgeCount, getUnreadMessage, updateUnreadMessage, getUnreadNotice, kickUser };
