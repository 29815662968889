import React from 'react';
import loading from "../../assets/img/loader.gif";
import classnames from "classnames";

const BtnLoader = ({hide, style, classes}) => {
return (<img
        style={ style ? style : {}}
        className={classnames((classes !== "" ? classes : "float-right"), {
            'd-none': hide
            }) }
        alt="loader"
        src={loading} />)
}

export default BtnLoader;
