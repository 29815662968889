import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Error from "../../../errors";
import { postPlay, postSports, sportsClear } from "../../../actions/sports";
import { Link } from "react-router-dom";

const BTI = ({ url }) => {
    return (
        <div className="iframe-container aligned-to-header">
            <iframe
                id="RefFrame"
                className="RefFrame"
                src={url}
                width="1903"
                style={{ width: "100%" }}
                title="sports"
            ></iframe>
        </div>
    );
};

const UnderMaintenance = () => {
    return (
        <section className="bti-section">
            <div className="iframe-container">
                <iframe className="RefFrame" src="/game-maintenance-page.html"/>
            </div>
        </section>
    )
}

class Sports extends Component {
    state = {
        url: "",
        success: true,
        undermaintenance: false,
    };

    componentDidMount() {

        if (localStorage.getItem("appToken")) {
            const minutes = 10;
            const callback = () => this.props.postPlay({ providerID: 21, IsLive: 1, integrationID: 21, productID: 1 });
            callback();
            this.startTimer(20*minutes, callback);
        } else {
            this.props.postSports({ providerID: 21, IsLive: 1, integrationID: 21, productID: 1 });
        }
    }

    startTimer(duration, callback){
        let seconds = duration
        this.timer = setInterval(() => {
            if(--seconds < 0){
                callback();
                seconds = duration;
            }
        }, 1000);
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    componentWillReceiveProps(nextProps) {
        const { url, success } = nextProps.sports.result;
        this.setState({ url: url, success: typeof success !== 'undefined' ? success : this.state.success});
        if(nextProps.sports.errors !== false) {
            if(typeof nextProps.sports.errors !== 'undefined'){
                if(nextProps.sports.errors.errors !== undefined) {
                    this.setState({success: false})
                    if(nextProps.sports.errors.errors.undermaintenance !== undefined) {
                        this.setState({undermaintenance: nextProps.sports.errors.errors.undermaintenance});
                    }
                }
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Error>
                {
                    this.state.success ? 
                    <BTI url={this.state.url} />
                    :
                    this.state.undermaintenance ?
                    <UnderMaintenance/>
                    :
                    <div className="error_page_2">
                        <div className="error_splash row text-center">
                            <div className="col-md-12">
                                <div className="symbol_icon">
                                    <i className="svg-icon svg-warning" />
                     
                                    <p >
                                        <span className="colgray">{t('code')}:</span> <b>4013</b>
                                    </p>
                                    <p></p>
                                </div>
                                <h1 style={{color:"#8EB50D"}}>{t('error opening game')}</h1>
                                <p style={{fontSize: "20px", fontWeight: "600"}}>
                                    {t("please try again")}
                                </p>
                                <p>
                                    <Link to="/" className="btn btn-primary" style={{padding:"10px 20px 10px 20px"}}>
                                        {t('back to homepage')}{" "}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </Error>

        );
    }
}

Sports.propTypes = {
    postPlay: PropTypes.func.isRequired
};

Sports.defaultProps = {
    sports: {
        result: {
            url: ""
        }
    }
};

const mapStateToProps = (state) => ({
    sports: state.sports
});

export default connect(mapStateToProps, { postPlay, postSports, sportsClear })(
    withTranslation()(withRouter(Sports))
);
