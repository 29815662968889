import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { loadSlots } from '../../../actions/slot';
import { getGames } from '../../../api/slot';
import { getBrandGameSetup } from '../../../actions/page';
import Title from './Title';
import Filter from './Filter';
import Thumb from './Thumb';
import Loading from './Loading';
import Error from "../../../errors";
import { postPlay, postSports, sportsClear } from "../../../actions/sports";
import BlackListedError from '../../common/BlackListedError';

const Slots = (props) => {
    //temporary code, needs to be removed once live
    const auth = useSelector(state => state.auth);
    const brandGameSetup = useSelector((state) => state.page.brandGameSetup);

    // if(auth.wallet.player.IsTestAccount === 0) {
    //     window.location.href = '/';
    // }
    //temporary code, needs to be removed once live

    const dispatch = useDispatch();
    const { t } = props;
    const itemsPerPage = 20;
    let lang = [];

    if(localStorage.getItem('i18nextLng') === null) {
        lang = ['en'];
    } else {
        lang = localStorage.getItem('i18nextLng').match(/^[a-z]+/);
    }

    const [filterDefaults, setFilterDefaults] = useState({
        name: '',
        provider: 'all providers',
        game: 'all games',
        category: 'all categories'
    });
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [slots, setSlots] = useState([]);
    const [filteredSlots, setFilteredSlots] = useState([]);
    const [displayList, setDisplayList] = useState([]);
    const [topPicks, setTopPicks] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(filterDefaults);
    const [filterOptions, setFilterOptions] = useState({
        providers: [filterDefaults.provider],
        games: [filterDefaults.game],
        categories: [filterDefaults.category]
    });

    const sportsState = useSelector(state => state.sports);
    const playerState = useSelector(state => state.auth);
    const [success, setSuccess] = useState(true);
    const [launchCode, setLaunchCode] = useState("");
    const [blacklistedError, setBlacklistedError] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState(200);

    useEffect(() => {
        dispatch(sportsClear());
        setSuccess(true);
    }, []);

    useEffect(() => {
        // if(launchCode === "") {
        //     if (localStorage.getItem("appToken")) {
        //         dispatch(postPlay({ providerID: 25, integrationID: 51 }));
        //     } else {
        //         dispatch(postSports({ providerID: 25, integrationID: 51}));
        //     }
        // }
    }, [playerState]);

    useEffect(() => {
        if(sportsState.result.success !== undefined) {
            setSuccess(sportsState.result.success);
        }
    }, [sportsState.result.success]);

    useEffect(() => {
        if(sportsState.errors) {
            if(sportsState.errors.player_blacklisted || sportsState.errors.player_closed) {
                setSuccess(false)
                setBlacklistedError(true);
                setErrorStatusCode(sportsState.errors.status)
            }
            
        } else {
            setSuccess(true)
        }
    }, [sportsState.errors]);

    const notFiltered = () => {
        return selectedFilters.name === filterDefaults.name &&
        selectedFilters.provider === filterDefaults.provider &&
        selectedFilters.game === filterDefaults.game &&
        selectedFilters.category === filterDefaults.category;
    }

    const scrollListener = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollValue = winScroll / height;

        if(scrollValue >= 1) {
            setLoadMoreLoading(true);

            setTimeout(() => {
                setLoadMoreLoading(false);
                document.getElementById('loadMore').click();
            }, 700);
        } 
    }

    useEffect(() => {
        getGames({product:3}).then(response => {
            let fetchedSlots = response.data.data;
            let cleanFetchSlots = [];
            let x = 0;
            let i = 0;
            
            for(x in fetchedSlots) {
                if(fetchedSlots[x] !== null) {
                    const NameLang = JSON.parse(fetchedSlots[x].NameLang);
                    let nameText = NameLang.en;

                    if(typeof NameLang.ko === 'string' && lang[0] === 'ko') {
                        nameText = NameLang.ko;
                    }

                    cleanFetchSlots[i] = fetchedSlots[x];
                    cleanFetchSlots[i].nameText = nameText;
                    cleanFetchSlots[i].Type = t(cleanFetchSlots[i].Type.toLowerCase());
                    i++;
                }
            }

            setSlots(cleanFetchSlots);
            dispatch(loadSlots(cleanFetchSlots));
            dispatch(getBrandGameSetup({brandID:5}));
            setDisplayList(cleanFetchSlots.slice(0, itemsPerPage));
            setLoading(false);
        });

        //window.addEventListener('scroll', scrollListener);
    }, []);

    useEffect( () => {
        if (brandGameSetup !== undefined && brandGameSetup.length > 0) {
            
            let search = brandGameSetup.find(el => el.ProductID === 3);
            if (search === undefined) {
                window.location = '/'
            }
        }
    },[brandGameSetup])

    useEffect(() => {
        let increments = {
            providers: 0,
            categories: 0,
            topPicks: 0
        };
        let providers = [];
        let categories = [];
        let top_picks = [];
        let x = 0;

        if(slots.length > 0) {
            for(x in slots) {
                // if(!providers.includes(slots[x].Provider)) {
                //     providers[increments.providers] = slots[x].Provider;
                //     increments.providers++;
                // }

                if(!categories.includes(slots[x].Type)) {
                    categories[increments.categories] = slots[x].Type;
                    increments.categories++;
                }

                if(slots[x].NewGame || slots[x].Featured || slots[x].MonthlyTopGame) {
                    top_picks[increments.topPicks] = slots[x];
                    increments.topPicks++;
                }
            }
        }

        let options = {...filterOptions};
        options.providers = [...options.providers, ...providers];
        options.categories = [...options.categories, t('new'), t('hot'), ...categories];
        options.games = [options.games[0], t('new'), t('top'), t('featured')];
        setFilterOptions(options);
        setTopPicks(top_picks);
    }, [slots]);

    useEffect(() => {
        if(brandGameSetup){
            const slotProviders = brandGameSetup.filter(data => data.ProductID === 3).sort((a, b) => {
                return (a.SortOrder < b.SortOrder) ? -1 : 1 
            });
            let providers = [];
            let options = {...filterOptions};
            let x = 0;
            for(x in slotProviders) {
                providers[x] = slotProviders[x].ProviderName;
            }
            if(options.providers.length <= 1) {
                options.providers = [...options.providers, ...providers];
                setFilterOptions(options);
            }
        }
    }, [brandGameSetup])

    useEffect(() => {
        let options = {...filterOptions};
        options.games = [options.games[0], t('new'), t('top'), t('featured')];

        setFilterOptions(options);
    }, [t('new')]);

    useEffect(() => {
        window['slotsApplyFilterDropdownStyling']();
    }, [filterOptions]);

    const loadMore = () => {
        setLoadMoreLoading(true);

        setTimeout(() => {
            let slotsCopy = [];
            const newPage = page + 1;
    
            if(notFiltered()) {
                    slotsCopy = [...slots];
            } else {
                slotsCopy = [...filteredSlots];
            }
    
            setDisplayList(slotsCopy.slice(0, itemsPerPage * newPage));
            setPage(newPage);
            setLoadMoreLoading(false);
        }, 700);
    }

    const filter = (e, index) => {
        let selected_filters = {...selectedFilters};
        if(index === 'provider') {
            if(selectedFilters.provider === e) {
                selected_filters[index] = 'all providers';
                setSelectedFilters(selected_filters);
            } else {
                selected_filters[index] = e;
                setSelectedFilters(selected_filters);
            }
        } else {
            selected_filters[index] = e;
            setSelectedFilters(selected_filters);
        }
    }

    const filter2 = (e, index) => {
        let selected_filters = {...selectedFilters};
        selected_filters[index] = e.target.value;
        setSelectedFilters(selected_filters);
    }

    useEffect(() => {
        let slotsCopy = [...slots];
        let topPicksSLotsCopy = [...topPicks];
        let combineCopies = [];

        if(selectedFilters.name === filterDefaults.name &&
            selectedFilters.provider === filterDefaults.provider &&
            selectedFilters.game === filterDefaults.game &&
            selectedFilters.category === filterDefaults.category) {
                setFilteredSlots([]);
                setDisplayList(slotsCopy.slice(0, itemsPerPage));
                setPage(1);
        }

        const namePattern = new RegExp(selectedFilters.name.replace(/[&\/\[\]\\#+()$~%.'":*?<>{}^|]/g,'_'), 'i');

        if(selectedFilters.name !== filterDefaults.name) {
            slotsCopy = slotsCopy.filter(slot => namePattern.test(slot.nameText));
            topPicksSLotsCopy = topPicksSLotsCopy.filter(slot => namePattern.test(slot.nameText));
        }

        if(selectedFilters.provider !== filterDefaults.provider) {
            slotsCopy = slotsCopy.filter(slot => slot.Provider === selectedFilters.provider);
            topPicksSLotsCopy = topPicksSLotsCopy.filter(slot => slot.Provider === selectedFilters.provider);
        }

        if(selectedFilters.category !== filterDefaults.category) {
            // slotsCopy = slotsCopy.filter(slot => slot.Type === selectedFilters.category);
            // topPicksSLotsCopy = topPicksSLotsCopy.filter(slot => slot.Type === selectedFilters.category);
            slotsCopy = slotsCopy.filter(slot => ['new', 'hot'].includes(selectedFilters.category.toLowerCase()) ? selectedFilters.category.toLowerCase() === "new" ? slot.NewGame === 1 : slot.Featured === 1 : slot.Type === selectedFilters.category);
            topPicksSLotsCopy = topPicksSLotsCopy.filter(slot => ['new', 'hot'].includes(selectedFilters.category.toLowerCase()) ? selectedFilters.category.toLowerCase() === "new" ? slot.NewGame === 1 : slot.Featured === 1 : slot.Type === selectedFilters.category);
        }

        if(selectedFilters.game !== filterDefaults.game) {
            let index = '';

            switch(selectedFilters.game) {
                case t('new'):
                    index = 'NewGame';
                break;
                case t('top'):
                    index = 'MonthlyTopGame';
                break;
                case t('featured'):
                    index = 'Featured';
                break;
            }

            slotsCopy = slotsCopy.filter(slot => slot[index] === 1);
            topPicksSLotsCopy = topPicksSLotsCopy.filter(slot => slot[index] === 1);
        }
        
        if(notFiltered()) {
           combineCopies = [...slotsCopy];
        } else {
            let x = 0;
            for(x in topPicksSLotsCopy) {
                slotsCopy = slotsCopy.filter(slot => slot.ID !== topPicksSLotsCopy[x].ID);
            }

            combineCopies = [...topPicksSLotsCopy, ...slotsCopy];
        }

        setFilteredSlots(combineCopies);
        setDisplayList(combineCopies.slice(0, itemsPerPage));
        window['generateTopPicksPrevNextButtons'](topPicks.length);
    }, [selectedFilters]);

    useEffect(() => {
        window['generateTopPicksPrevNextButtons'](topPicks.length);
    }, [topPicks]);
    
    return (
        <Error>
            {
                success ?
                <div className="slot-section main-container">
                    <section id="featured">
                        <a href="#" className="slide-img" tabIndex={0}><img src="/assets/img/w365/banners/slots.jpg" className="img-fluid" alt="" /></a>
                    </section>
                    <Container className="Slot-target" fluid>
                        <Row className="my-5">
                            <Col lg="12">
                                <div className="tabbed-list text">
                                    <div className={`inner text${filterOptions.providers.length > 9 ? ' more' : ''}`}>
                                    {filterOptions.providers.map((data, index) => {
                                        const image = data.replace(' ', '_').toLowerCase();
                                        return (
                                            data !== 'all providers' ?
                                            <div key={index}>
                                                <a onClick={(e) => loading ? e.preventDefault() : filter(data,'provider')} className={data === selectedFilters.provider ? 'active' : ''} style={{cursor: 'pointer', padding: 0}}>
                                                    <img src={`/assets/img/w365/icons/providers/${image}_logo.svg`} alt="" style={{width: '170px'}}/>
                                                </a>
                                            </div>
                                            : <></>
                                        )
                                    })}
                                    </div>
                                </div>
                            </Col>
                            <Col lg="12">
                                <div className="tabbed-list text">
                                    <div className={`inner text${filterOptions.categories.length > 9 ? ' more' : ''}`} style ={{justifyContent: 'flex-start', marginLeft: '30px'}}>
                                    {filterOptions.categories.map((data, index) => {
                                        const name = data.replace(' Game', '').toLowerCase();
                                        return (
                                            <div key={index}>
                                                <a onClick={() => filter(data,'category')} className={data === selectedFilters.category ? 'active' : ''} style={{cursor: 'pointer'}}>
                                                    {t(name === 'all categories' ? 'all' : name)}
                                                </a>
                                            </div>
                                        )
                                    })}
                                    </div>
                                </div>
                                <div style={{float: 'right', marginTop: '-73px', marginRight: '25px'}}>
                                <form onSubmit={e => e.preventDefault()}>
                                    <div className="d-flex">
                                        <div>
                                            <Row className="searchbar">
                                                <div className="input-group form-sm form-2 search-filter">
                                                    <input
                                                        id="searchInput"
                                                        onChange={e => filter2(e, 'name')}
                                                        className="form-control-2"
                                                        type="text"
                                                        placeholder={t('search for slots')}
                                                        aria-label="Search"
                                                        style={{height: '50px', width: '370px', borderRadius: '8px', backgroundColor: '#172B36'}}
                                                        disabled={loading ? true : false}
                                                    />
                                                    <img src="/assets/img/SearchIcon.svg" class="fa-search"/>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </Col>
                        </Row>
                        {/* <div className="d-flex justify-content-center align-items-center my-3 slots-page-filter-row">
                            {
                                filterOptions.providers.map((data) => {
                                    const image = data.replace(' ', '_').toLowerCase();
                                    return (
                                        data !== 'all providers' ?
                                        <Col xs="auto" className={data === selectedFilters.provider ? "mx-5 py-3 slots-filer-active" : "mx-5 py-3 "}>
                                            <img className='fluid' src={`/assets/img/w365/icons/providers/${image}_logo.png`} onClick={() => filter(data,'provider')} style={{cursor: 'pointer'}}/>
                                        </Col>
                                        : <></>
                                    )
                                })
                            }
                        </div>
                        <Row className="justify-content-center align-items-center my-3 slots-page-filter-row">
                            {
                                filterOptions.categories.map((data) => {
                                    const name = data.replace(' Game', '').toLowerCase();
                                    return (
                                        <Col xs="auto" className={data === selectedFilters.category ? "mx-5 py-3 slots-filer-active" : "mx-5 py-3 "}>
                                            <div onClick={() => filter(data,'category')} className="slots-page-filter-category">{t(name === 'all categories' ? 'all' : name)}</div>
                                        </Col>
                                    )
                                })
                            }
                        </Row> */}
                    </Container>
                    <div style={{backgroundColor: '#12242F'}}>
                        {notFiltered() ?
                        <Container className="Slot-target" fluid>
                            <section style={{padding:'30px 0'}}>
                                <Row>
                                    <Col md="6" className="pl-2"><h4 className="form-title" style={{fontSize:'20px',color:'#FFFFFF', marginTop:0,marginBottom:'0.5rem'}}>{t('top picks')}</h4></Col>
                                    <Col md="6" className="d-flex align-items-center">
                                        <div className="d-flex justify-content-end w-100">
                                            <div>
                                                <img className="top-picks-button d-none" src="/assets/img/toppicks-arrow-left.svg" alt="" />&nbsp;&nbsp;
                                                <img className="top-picks-button d-none" src="/assets/img/toppicks-arrow-right.svg" alt="" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="p-0">
                                        <div style={{overflow:'hidden'}}>
                                            <div className="d-flex w-100 top-picks">
                                                {loading ? <Loading /> : <>{topPicks.length > 0 ? topPicks.map(topPick => <Thumb key={topPick.Name} {...topPick} />) : <p>{t('nothing to display')}</p>}</>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                        : null}
                    </div>
                    <Container className="Slot-target" fluid>
                        <section className="slot-container">
                            <Title notFiltered={notFiltered()} selectedFilters={selectedFilters} t={t}/>
                            <Row>
                                {loading ? <Loading /> : <>{displayList.length > 0 ? displayList.map(slot => slot !== null ? <Thumb key={slot.ID} {...slot} /> : null) : <p>{t('nothing to display')}</p>}</>}
                            </Row>
                            <Loading visibility={loadMoreLoading ? 'visible' : 'hidden'} />
                            {!loadMoreLoading && ((notFiltered() && displayList.length < slots.length) || (!notFiltered() && displayList.length < filteredSlots.length)) ? <div className="d-flex justify-content-center"><button onClick={loadMore} id="loadMore" className="btn btn-success load-more-btn">{t('load more')}</button></div> : null}
                        </section>
                    </Container>
                </div>
                :
                <>
                    <div className="blacklisted blacklisted-error"><BlackListedError errorStatusCode={errorStatusCode}/></div>
                </>
            }
        </Error>
    );
}

export default withTranslation()(Slots);
