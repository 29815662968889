import {
    GET_CASH,
    SET_CASH_PAGE
} from "./types";

export const getCash = payload => {
    return {
        type: GET_CASH,
        provider: payload,
    };
}

export const setPage = (payload) => {
    return {
        type: SET_CASH_PAGE,
        payload
    };
}
