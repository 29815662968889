import { takeLatest, put, call } from 'redux-saga/effects';

import {
    getIP
} from "../api/ipaddress"

import {
    GET_IP_ADDRESS,
    IP_ADDRESS_ERRORS,
    IP_ADDRESS_SUCCESS
} from "../actions/types";

export { ipWatcher };

function* ipWatcher() {
    yield takeLatest(GET_IP_ADDRESS, fetchIP)
}

function* fetchIP(){
    try{
        const result = yield call(getIP);
            yield put({
                type: IP_ADDRESS_SUCCESS,
                payload: result.data.ip
            });
    }catch(error){
            yield put({
                type: IP_ADDRESS_ERRORS,
                payload: error
            });
    }
}
